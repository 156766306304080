export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Currency: { input: string; output: string; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  ISO8601DateTime: { input: string; output: string; }
  JSON: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AccountActivationError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AccountActivationInput = {
  activate: Scalars['Boolean']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type AccountActivationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AccountActivationError;
  migrationStatus?: Maybe<WellevatePatientMigrationStatus>;
};

export type AccountType = {
  presentation: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AccountTypeFilterObject = {
  subset?: InputMaybe<AccountTypeFilters>;
};

export const AccountTypeFilters = {
  Classification: 'CLASSIFICATION'
} as const;

export type AccountTypeFilters = typeof AccountTypeFilters[keyof typeof AccountTypeFilters];
export const ActionSteps = {
  Completed: 'COMPLETED',
  CreatesPatient: 'CREATES_PATIENT',
  CreatesRx: 'CREATES_RX'
} as const;

export type ActionSteps = typeof ActionSteps[keyof typeof ActionSteps];
export type Actionable = {
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export const Actions = {
  ClickedStep: 'CLICKED_STEP',
  Dismiss: 'DISMISS'
} as const;

export type Actions = typeof Actions[keyof typeof Actions];
export type ActiveVariantFilterObject = {
  pastNpScript?: InputMaybe<Scalars['Boolean']['input']>;
  pastOrdered?: InputMaybe<Scalars['Boolean']['input']>;
  pastRecommended?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddDynamicDailyPackTemplateToPlanError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AddDynamicDailyPackTemplateToPlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId?: InputMaybe<Scalars['ID']['input']>;
  templatePackId: Scalars['ID']['input'];
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddDynamicDailyPackTemplateToPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: AddDynamicDailyPackTemplateToPlanError;
};

export type AddDynamicDailyPackToTreatmentPlanError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AddDynamicDailyPackToTreatmentPlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddDynamicDailyPackToTreatmentPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: AddDynamicDailyPackToTreatmentPlanError;
};

export type Address = Timestamps & {
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  addressName?: Maybe<Scalars['String']['output']>;
  avr?: Maybe<Avr>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  default?: Maybe<Scalars['Boolean']['output']>;
  defaultPatientAddress?: Maybe<Scalars['Boolean']['output']>;
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
  pavr?: Maybe<Pavr>;
  phone?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  stateId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zipcode: Scalars['String']['output'];
};

export type AddressAttributes = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  stateId?: InputMaybe<Scalars['ID']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type AddressFilterObject = {
  addressType?: InputMaybe<Scalars['String']['input']>;
};

export type AdherenceFeedback = {
  id: Scalars['ID']['output'];
  respondedAt?: Maybe<Scalars['DateTime']['output']>;
  response?: Maybe<AdherenceFeedbackResponse>;
  sendAt?: Maybe<Scalars['DateTime']['output']>;
};

export const AdherenceFeedbackResponse = {
  Elsewhere: 'ELSEWHERE',
  NotPlanning: 'NOT_PLANNING',
  Overwhelmed: 'OVERWHELMED',
  Planning: 'PLANNING',
  Price: 'PRICE'
} as const;

export type AdherenceFeedbackResponse = typeof AdherenceFeedbackResponse[keyof typeof AdherenceFeedbackResponse];
export type Admin = Node & Roleable & Timestamps & {
  anyCredentialApproved: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  storeName?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AgeRestrictedProductState = {
  age: Scalars['Int']['output'];
  product?: Maybe<Product>;
  state?: Maybe<State>;
};

export const AgreementTypes = {
  Basic: 'BASIC',
  Strict: 'STRICT'
} as const;

export type AgreementTypes = typeof AgreementTypes[keyof typeof AgreementTypes];
export type AlertsBanner = {
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  urgency: Scalars['String']['output'];
};

export type Allergen = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AlternativeAttributes = {
  amount?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  smartSuggestion: Scalars['Boolean']['input'];
  variantId: Scalars['ID']['input'];
};

export type AnalyticsLogEventError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AnalyticsLogEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventName?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
};

export type AnalyticsLogEventPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AnalyticsLogEventError;
};

export type AnalyticsStartExperimentError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AnalyticsStartExperimentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  experiment: Scalars['String']['input'];
  experimentVariant: Scalars['String']['input'];
};

export type AnalyticsStartExperimentPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AnalyticsStartExperimentError;
};

export type AnalyticsUpdateProfileError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AnalyticsUpdateProfileInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
};

export type AnalyticsUpdateProfilePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AnalyticsUpdateProfileError;
};

export type AndroidAddToWaitlistError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AndroidAddToWaitlistInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AndroidAddToWaitlistPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AndroidAddToWaitlistError;
  newlyAdded?: Maybe<Scalars['Boolean']['output']>;
};

export type ApiExternalMetadatum = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  externalIdType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  metadatableId: Scalars['ID']['output'];
  metadatableType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ApplePayItemAttributes = {
  id: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type ApplePayPaymentAttributes = {
  card: CardAttributes;
  id: Scalars['String']['input'];
};

export type ApplePayPlaceOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemsState: Array<ApplePayItemAttributes>;
  shippingAddress: ApplePayShippingAddressAttributes;
  shippingOption: ApplePayShippingMethodAttributes;
  token: ApplePayPaymentAttributes;
};

export type ApplePayPlaceOrderPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type ApplePayResetInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ApplePayResetPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type ApplePaySelectShippingRateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingOption: ApplePayShippingMethodAttributes;
};

export type ApplePaySelectShippingRatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type ApplePayShippingAddressAttributes = {
  addressLine?: InputMaybe<Array<Scalars['String']['input']>>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  region: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type ApplePayShippingMethodAttributes = {
  id: Scalars['ID']['input'];
};

export type ApplePayUpdateShippingAddressInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingAddress: ApplePayShippingAddressAttributes;
};

export type ApplePayUpdateShippingAddressPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type AtRiskVariantsFilterObject = {
  planType: PlanTypes;
  status?: InputMaybe<AtRiskVariantsStatusFilters>;
};

export type AtRiskVariantsSort = {
  direction: SortDirection;
  field?: InputMaybe<AtRiskVariantsSortOptions>;
};

export const AtRiskVariantsSortOptions = {
  ActivePlans: 'ACTIVE_PLANS',
  PlanCreationDate: 'PLAN_CREATION_DATE'
} as const;

export type AtRiskVariantsSortOptions = typeof AtRiskVariantsSortOptions[keyof typeof AtRiskVariantsSortOptions];
export const AtRiskVariantsStatusFilters = {
  Backordered: 'BACKORDERED',
  Discontinued: 'DISCONTINUED',
  OutOfStock: 'OUT_OF_STOCK',
  ToBeDiscontinued: 'TO_BE_DISCONTINUED'
} as const;

export type AtRiskVariantsStatusFilters = typeof AtRiskVariantsStatusFilters[keyof typeof AtRiskVariantsStatusFilters];
export type Attachable = {
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type AudioChunk = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  recordingTime: Scalars['Int']['output'];
  results: Scalars['String']['output'];
  summary: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AudioSession = Node & Timestamps & {
  awsToken: AwsToken;
  copilotAudioChunks: Array<AudioChunk>;
  copilotAudioSummaries?: Maybe<Array<AudioSummary>>;
  createdAt: Scalars['DateTime']['output'];
  currentSummary?: Maybe<AudioSummary>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  fullTranscript: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  patient: Patient;
  practitioner: Practitioner;
  recordingType: Scalars['String']['output'];
  startTime: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AudioSessionConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<AudioSessionEdge>>>;
  nodes?: Maybe<Array<Maybe<AudioSession>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AudioSessionEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<AudioSession>;
};

export type AudioSummary = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  patientMessage?: Maybe<Scalars['String']['output']>;
  protocols?: Maybe<Scalars['String']['output']>;
  resources?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  supplementList?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type AuthChangeRoleError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthChangeRoleInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roleId: Scalars['ID']['input'];
};

export type AuthChangeRolePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthChangeRoleError;
  redirectPath?: Maybe<Scalars['String']['output']>;
  role?: Maybe<RoleUnion>;
  token?: Maybe<Scalars['String']['output']>;
};

export type AuthClearCurrentRoleError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthClearCurrentRoleInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  storePlatformName?: InputMaybe<StorePlatformType>;
};

export type AuthClearCurrentRolePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthClearCurrentRoleError;
  redirectPath?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type AuthLoggersOfficeSignUpAttributes = {
  eventAggregateId: Scalars['String']['input'];
  eventType: Scalars['String']['input'];
  field?: InputMaybe<Scalars['String']['input']>;
  pageVersion: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
};

export type AuthLoggersOfficeSignUpError = {
  fields?: Maybe<AuthLoggersOfficeSignUpFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthLoggersOfficeSignUpFieldError = {
  eventAggregateId?: Maybe<Array<Scalars['String']['output']>>;
  eventType?: Maybe<Array<Scalars['String']['output']>>;
  field?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthLoggersOfficeSignUpInput = {
  attributes: AuthLoggersOfficeSignUpAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthLoggersOfficeSignUpPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthLoggersOfficeSignUpError;
};

export type AuthLoggersPatientSignUpAttributes = {
  eventAggregateId: Scalars['String']['input'];
  eventType: Scalars['String']['input'];
  field?: InputMaybe<Scalars['String']['input']>;
  pageVersion: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
};

export type AuthLoggersPatientSignUpError = {
  fields?: Maybe<AuthLoggersPatientSignUpFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthLoggersPatientSignUpFieldError = {
  eventAggregateId?: Maybe<Array<Scalars['String']['output']>>;
  eventType?: Maybe<Array<Scalars['String']['output']>>;
  field?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthLoggersPatientSignUpInput = {
  attributes: AuthLoggersPatientSignUpAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthLoggersPatientSignUpPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthLoggersPatientSignUpError;
};

export type AuthLoggersPractitionerSignUpAttributes = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  eventAggregateId: Scalars['String']['input'];
  eventType: Scalars['String']['input'];
  field?: InputMaybe<Scalars['String']['input']>;
  pageVersion: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};

export type AuthLoggersPractitionerSignUpError = {
  fields?: Maybe<AuthLoggersPractitionerSignUpFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthLoggersPractitionerSignUpFieldError = {
  apiClientId?: Maybe<Array<Scalars['String']['output']>>;
  eventAggregateId?: Maybe<Array<Scalars['String']['output']>>;
  eventType?: Maybe<Array<Scalars['String']['output']>>;
  field?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthLoggersPractitionerSignUpInput = {
  attributes: AuthLoggersPractitionerSignUpAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthLoggersPractitionerSignUpPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthLoggersPractitionerSignUpError;
};

export type AuthMutation = {
  authenticateWellevatePractitioner?: Maybe<AuthenticateWellevatePractitionerPayload>;
  changeRole?: Maybe<AuthChangeRolePayload>;
  clearCurrentRole?: Maybe<AuthClearCurrentRolePayload>;
  emersonSignIn?: Maybe<EmersonAuthUserSignInPayload>;
  officeActivateAccount?: Maybe<AuthOfficeActivateAccountPayload>;
  officeSignUp?: Maybe<AuthOfficeSignUpPayload>;
  officeSignUpLogger?: Maybe<AuthLoggersOfficeSignUpPayload>;
  patientInvitationCheck?: Maybe<AuthPatientInvitationCheckPayload>;
  patientNextLoginStep?: Maybe<AuthPatientNextLoginStepPayload>;
  patientSignUp?: Maybe<AuthPatientSignUpPayload>;
  patientSignUpFromRx?: Maybe<AuthPatientSignUpFromRxPayload>;
  patientSignUpLogger?: Maybe<AuthLoggersPatientSignUpPayload>;
  practitionerSignUp?: Maybe<AuthPractitionerSignUpPayload>;
  practitionerSignUpLogger?: Maybe<AuthLoggersPractitionerSignUpPayload>;
  requestSharedAccountVerificationEmail?: Maybe<PractitionerRequestSharedAccountVerificationEmailPayload>;
  smartUserSignIn?: Maybe<AuthSmartUserSignInPayload>;
  userCreateUniversalLink?: Maybe<AuthUserCreateUniversalLinkPayload>;
  userDisableTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationDisablePayload>;
  userEnableTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationEnablePayload>;
  userExistingAccountCheck?: Maybe<AuthUserExistingAccountCheckPayload>;
  userForgotPasswordEmail?: Maybe<UserForgotPasswordEmailPayload>;
  userGetExternalToken?: Maybe<AuthUserGetExternalTokenPayload>;
  userMobileSignInWithApple?: Maybe<UserMobileSignInWithApplePayload>;
  userMobileSignInWithGoogle?: Maybe<UserMobileSignInWithGooglePayload>;
  userMobileSignInWithMagicLink?: Maybe<AuthUserMobileSignInWithMagicLinkPayload>;
  userMobileSignOut?: Maybe<AuthUserMobileSignOutPayload>;
  userRegenerateBackupCode?: Maybe<AuthUserRegenerateBackupCodePayload>;
  userResendEmailSetupTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationResendEmailSetupPayload>;
  userResendTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationResendPayload>;
  userResetPassword?: Maybe<AuthUserResetPasswordPayload>;
  userSendMagicLink?: Maybe<AuthUserSendMagicLinkPayload>;
  userSetupTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationSetupPayload>;
  userSignIn?: Maybe<AuthUserSignInPayload>;
  userVerifyPassword?: Maybe<AuthUserVerifyPasswordPayload>;
  userVerifyTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationVerifyPayload>;
  wellevatePractitionerSignUp?: Maybe<AuthWellevatePractitionerSignUpPayload>;
};


export type AuthMutationAuthenticateWellevatePractitionerArgs = {
  input: AuthenticateWellevatePractitionerInput;
};


export type AuthMutationChangeRoleArgs = {
  input: AuthChangeRoleInput;
};


export type AuthMutationClearCurrentRoleArgs = {
  input: AuthClearCurrentRoleInput;
};


export type AuthMutationEmersonSignInArgs = {
  input: EmersonAuthUserSignInInput;
};


export type AuthMutationOfficeActivateAccountArgs = {
  input: AuthOfficeActivateAccountInput;
};


export type AuthMutationOfficeSignUpArgs = {
  input: AuthOfficeSignUpInput;
};


export type AuthMutationOfficeSignUpLoggerArgs = {
  input: AuthLoggersOfficeSignUpInput;
};


export type AuthMutationPatientInvitationCheckArgs = {
  input: AuthPatientInvitationCheckInput;
};


export type AuthMutationPatientNextLoginStepArgs = {
  input: AuthPatientNextLoginStepInput;
};


export type AuthMutationPatientSignUpArgs = {
  input: AuthPatientSignUpInput;
};


export type AuthMutationPatientSignUpFromRxArgs = {
  input: AuthPatientSignUpFromRxInput;
};


export type AuthMutationPatientSignUpLoggerArgs = {
  input: AuthLoggersPatientSignUpInput;
};


export type AuthMutationPractitionerSignUpArgs = {
  input: AuthPractitionerSignUpInput;
};


export type AuthMutationPractitionerSignUpLoggerArgs = {
  input: AuthLoggersPractitionerSignUpInput;
};


export type AuthMutationRequestSharedAccountVerificationEmailArgs = {
  input: PractitionerRequestSharedAccountVerificationEmailInput;
};


export type AuthMutationSmartUserSignInArgs = {
  input: AuthSmartUserSignInInput;
};


export type AuthMutationUserCreateUniversalLinkArgs = {
  input: AuthUserCreateUniversalLinkInput;
};


export type AuthMutationUserDisableTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationDisableInput;
};


export type AuthMutationUserEnableTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationEnableInput;
};


export type AuthMutationUserExistingAccountCheckArgs = {
  input: AuthUserExistingAccountCheckInput;
};


export type AuthMutationUserForgotPasswordEmailArgs = {
  input: UserForgotPasswordEmailInput;
};


export type AuthMutationUserGetExternalTokenArgs = {
  input: AuthUserGetExternalTokenInput;
};


export type AuthMutationUserMobileSignInWithAppleArgs = {
  input: UserMobileSignInWithAppleInput;
};


export type AuthMutationUserMobileSignInWithGoogleArgs = {
  input: UserMobileSignInWithGoogleInput;
};


export type AuthMutationUserMobileSignInWithMagicLinkArgs = {
  input: AuthUserMobileSignInWithMagicLinkInput;
};


export type AuthMutationUserRegenerateBackupCodeArgs = {
  input: AuthUserRegenerateBackupCodeInput;
};


export type AuthMutationUserResendEmailSetupTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationResendEmailSetupInput;
};


export type AuthMutationUserResendTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationResendInput;
};


export type AuthMutationUserResetPasswordArgs = {
  input: AuthUserResetPasswordInput;
};


export type AuthMutationUserSendMagicLinkArgs = {
  input: AuthUserSendMagicLinkInput;
};


export type AuthMutationUserSetupTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationSetupInput;
};


export type AuthMutationUserSignInArgs = {
  input: AuthUserSignInInput;
};


export type AuthMutationUserVerifyPasswordArgs = {
  input: AuthUserVerifyPasswordInput;
};


export type AuthMutationUserVerifyTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationVerifyInput;
};


export type AuthMutationWellevatePractitionerSignUpArgs = {
  input: AuthWellevatePractitionerSignUpInput;
};

export type AuthOfficeActivateAccountAttributes = {
  invitationToken: Scalars['String']['input'];
};

export type AuthOfficeActivateAccountError = {
  fields?: Maybe<AuthOfficeActivateAccountFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthOfficeActivateAccountFieldError = {
  invitationToken?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthOfficeActivateAccountInput = {
  attributes: AuthOfficeActivateAccountAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthOfficeActivateAccountPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthOfficeActivateAccountError;
  redirectPath?: Maybe<Scalars['String']['output']>;
  role?: Maybe<OfficeRoleUnion>;
};

export type AuthOfficeSignUpAttributes = {
  invitationToken: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
  password: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
};

export type AuthOfficeSignUpError = {
  fields?: Maybe<AuthOfficeSignUpFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthOfficeSignUpFieldError = {
  invitationToken?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthOfficeSignUpInput = {
  attributes: AuthOfficeSignUpAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthOfficeSignUpPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthOfficeSignUpError;
  redirectPath?: Maybe<Scalars['String']['output']>;
  role?: Maybe<OfficeRoleUnion>;
};

export type AuthPatientInvitationCheckAttributes = {
  email: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
};

export type AuthPatientInvitationCheckError = {
  fields?: Maybe<AuthPatientInvitationCheckFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthPatientInvitationCheckFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthPatientInvitationCheckInput = {
  attributes: AuthPatientInvitationCheckAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPatientInvitationCheckPayload = {
  accountExists?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthPatientInvitationCheckError;
};

export type AuthPatientNextLoginStepError = {
  fields?: Maybe<AuthPatientNextLoginStepFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthPatientNextLoginStepFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  redirectPath?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthPatientNextLoginStepInput = {
  attributes: AuthUserExistingAccountCheckAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPatientNextLoginStepPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthPatientNextLoginStepError;
  nextStep?: Maybe<Scalars['String']['output']>;
};

export type AuthPatientSignUpAttributes = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  intake?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  optional?: InputMaybe<AuthPatientSignUpFromRxOptionalAttributes>;
  password: Scalars['String']['input'];
  requestedPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  storeId: Scalars['String']['input'];
};

export type AuthPatientSignUpError = {
  fields?: Maybe<AuthPatientSignUpFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthPatientSignUpFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthPatientSignUpFromRxAttributes = {
  formVersion?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId?: InputMaybe<Scalars['String']['input']>;
  optional?: InputMaybe<AuthPatientSignUpFromRxOptionalAttributes>;
  pageVersion: Scalars['String']['input'];
  password: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
  storeSlug: Scalars['String']['input'];
  treatmentPlanSlug?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPatientSignUpFromRxError = {
  fields?: Maybe<AuthPatientSignUpFromRxFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthPatientSignUpFromRxFieldError = {
  formVersion?: Maybe<Array<Scalars['String']['output']>>;
  labRecommendationId?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
  storeSlug?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanSlug?: Maybe<Array<Scalars['String']['output']>>;
  uuid?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthPatientSignUpFromRxInput = {
  attributes: AuthPatientSignUpFromRxAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPatientSignUpFromRxOptionalAttributes = {
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  roleType?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  utmAdgroup?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmChannel?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmDevice?: InputMaybe<Scalars['String']['input']>;
  utmId?: InputMaybe<Scalars['String']['input']>;
  utmMatchtype?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPatientSignUpFromRxPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthPatientSignUpFromRxError;
  patient?: Maybe<Patient>;
  redirectPath?: Maybe<Scalars['String']['output']>;
};

export type AuthPatientSignUpInput = {
  attributes: AuthPatientSignUpAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPatientSignUpPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthPatientSignUpError;
  otpMethod?: Maybe<TwoFactorAuthenticationMethods>;
  patient?: Maybe<Patient>;
  phoneLast4?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  twoFactorAuthenticationToken?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type AuthPeerReferralAttributes = {
  referredEmail?: Maybe<Scalars['String']['output']>;
  referredFirstName?: Maybe<Scalars['String']['output']>;
  referredLastName?: Maybe<Scalars['String']['output']>;
  referrerAvatarUrl?: Maybe<Scalars['String']['output']>;
  referrerFirstName?: Maybe<Scalars['String']['output']>;
};

export type AuthPractitionerSignUpAdditionalAttributes = {
  googleClickId?: Maybe<Scalars['String']['output']>;
  micrositeId?: Maybe<Scalars['String']['output']>;
  peerReferralCode?: Maybe<Scalars['String']['output']>;
  peerReferralDisplayAttributes?: Maybe<AuthPeerReferralAttributes>;
  peerReferrerSlug?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referralCodeId?: Maybe<Scalars['String']['output']>;
  referralPartnerId?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type AuthPractitionerSignUpAdditionalAttributesInput = {
  googleClickId?: InputMaybe<Scalars['String']['input']>;
  micrositeId?: InputMaybe<Scalars['String']['input']>;
  peerReferralCode?: InputMaybe<Scalars['String']['input']>;
  peerReferrerSlug?: InputMaybe<Scalars['String']['input']>;
  referralPartnerSlug?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPractitionerSignUpAttributes = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  optional?: InputMaybe<AuthPractitionerSignUpOptionalAttributes>;
  pageVersion: Scalars['String']['input'];
  password: Scalars['String']['input'];
  practitionerTypeId: Scalars['ID']['input'];
  signUpPage: Scalars['String']['input'];
  storeName?: InputMaybe<Scalars['String']['input']>;
  storePlatform?: InputMaybe<Scalars['String']['input']>;
  tosAcceptedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AuthPractitionerSignUpError = {
  fields?: Maybe<AuthPractitionerSignUpFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthPractitionerSignUpFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
  storeName?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
  tosAcceptedAt?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthPractitionerSignUpInput = {
  attributes: AuthPractitionerSignUpAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPractitionerSignUpOptionalAttributes = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Bizible is no longer used. */
  bizibleId?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  googleAnalyticsId?: InputMaybe<Scalars['String']['input']>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  micrositeId?: InputMaybe<Scalars['String']['input']>;
  peerReferralCode?: InputMaybe<Scalars['String']['input']>;
  peerReferrerSlug?: InputMaybe<Scalars['String']['input']>;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  referralCode?: InputMaybe<Scalars['String']['input']>;
  referralCodeId?: InputMaybe<Scalars['String']['input']>;
  referralPartnerId?: InputMaybe<Scalars['String']['input']>;
  signupSource?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type AuthPractitionerSignUpPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthPractitionerSignUpError;
  practitioner?: Maybe<Practitioner>;
  redirectPath?: Maybe<Scalars['String']['output']>;
};

export type AuthSmartUserSignInError = {
  fields?: Maybe<AuthSmartUserSignInFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthSmartUserSignInFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  rememberMe?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  staySignedIn?: Maybe<Array<Scalars['String']['output']>>;
  usingBiometrics?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthSmartUserSignInInput = {
  attributes: AuthUserSignInAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthSmartUserSignInPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthSmartUserSignInError;
  jwt?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<PlainPatient>;
  role?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
  showPractitionerPicker?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlanId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type AuthUserCreateUniversalLinkError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserCreateUniversalLinkInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  redirectPath: Scalars['String']['input'];
  utmAdgroup?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmChannel?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmDevice?: InputMaybe<Scalars['String']['input']>;
  utmId?: InputMaybe<Scalars['String']['input']>;
  utmMatchtype?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserCreateUniversalLinkPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserCreateUniversalLinkError;
  universalLink?: Maybe<Scalars['String']['output']>;
};

export type AuthUserExistingAccountCheckAttributes = {
  email: Scalars['String']['input'];
  intake?: InputMaybe<Scalars['Boolean']['input']>;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  requestedPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
};

export type AuthUserExistingAccountCheckError = {
  fields?: Maybe<AuthUserExistingAccountCheckFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserExistingAccountCheckFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  redirectPath?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthUserExistingAccountCheckInput = {
  attributes: AuthUserExistingAccountCheckAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserExistingAccountCheckPayload = {
  accountExists?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserExistingAccountCheckError;
};

export type AuthUserForgotPasswordEmailAttributes = {
  email: Scalars['String']['input'];
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserGetExternalTokenError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserGetExternalTokenInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserGetExternalTokenPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserGetExternalTokenError;
  jwtToken?: Maybe<Scalars['String']['output']>;
};

export type AuthUserMobileSignInWithMagicLinkError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserMobileSignInWithMagicLinkInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type AuthUserMobileSignInWithMagicLinkPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserMobileSignInWithMagicLinkError;
  user?: Maybe<User>;
};

export type AuthUserMobileSignOutPayload = {
  success: Scalars['Boolean']['output'];
};

export type AuthUserMobileSingleSignOnAttributes = {
  accessToken: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  idToken: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  nonce?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserRegenerateBackupCodeError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserRegenerateBackupCodeInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserRegenerateBackupCodePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserRegenerateBackupCodeError;
  newBackupCode?: Maybe<Scalars['String']['output']>;
};

export type AuthUserResetPasswordAttributes = {
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  passwordResetToken: Scalars['String']['input'];
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserResetPasswordError = {
  fields?: Maybe<AuthUserResetPasswordFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserResetPasswordFieldError = {
  password?: Maybe<Array<Scalars['String']['output']>>;
  passwordConfirmation?: Maybe<Array<Scalars['String']['output']>>;
  passwordResetToken?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthUserResetPasswordInput = {
  attributes: AuthUserResetPasswordAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserResetPasswordPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserResetPasswordError;
  otpMethod?: Maybe<TwoFactorAuthenticationMethods>;
  phoneLast4?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  twoFactorAuthenticationToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type AuthUserSendMagicLinkError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserSendMagicLinkInput = {
  captchaToken: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  intake?: InputMaybe<Scalars['Boolean']['input']>;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  utmAdgroup?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmChannel?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmDevice?: InputMaybe<Scalars['String']['input']>;
  utmId?: InputMaybe<Scalars['String']['input']>;
  utmMatchtype?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserSendMagicLinkPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserSendMagicLinkError;
};

export type AuthUserSignInAdditionalAttributes = {
  redirectPath?: Maybe<Scalars['String']['output']>;
};

export type AuthUserSignInAttributes = {
  email: Scalars['String']['input'];
  intake?: InputMaybe<Scalars['Boolean']['input']>;
  optional?: InputMaybe<AuthUserSignInOptionalAttributes>;
  password: Scalars['String']['input'];
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
  requestedPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Currently unused. */
  staySignedIn?: InputMaybe<Scalars['Boolean']['input']>;
  usingBiometrics?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AuthUserSignInError = {
  fields?: Maybe<AuthUserSignInFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserSignInFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  rememberMe?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  staySignedIn?: Maybe<Array<Scalars['String']['output']>>;
  usingBiometrics?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthUserSignInInput = {
  attributes: AuthUserSignInAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserSignInOptionalAttributes = {
  externalApplicationId?: InputMaybe<Scalars['String']['input']>;
  externalPatientId?: InputMaybe<Scalars['String']['input']>;
  externalPracticeId?: InputMaybe<Scalars['String']['input']>;
  externalPractitionerId?: InputMaybe<Scalars['String']['input']>;
  formVersion?: InputMaybe<Scalars['String']['input']>;
  isOauth?: InputMaybe<Scalars['Boolean']['input']>;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
  roleType?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  storePlatform?: InputMaybe<Scalars['String']['input']>;
  utmAdgroup?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmChannel?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmDevice?: InputMaybe<Scalars['String']['input']>;
  utmId?: InputMaybe<Scalars['String']['input']>;
  utmMatchtype?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserSignInPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserSignInError;
  otpMethod?: Maybe<TwoFactorAuthenticationMethods>;
  phoneLast4?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  twoFactorAuthenticationToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type AuthUserTwoFactorAuthenticationDisableError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserTwoFactorAuthenticationDisableInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserTwoFactorAuthenticationDisablePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationDisableError;
  twoFactorAuthentication?: Maybe<TwoFactorAuthentication>;
};

export type AuthUserTwoFactorAuthenticationEnableError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserTwoFactorAuthenticationEnableInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otp: Scalars['String']['input'];
};

export type AuthUserTwoFactorAuthenticationEnablePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationEnableError;
  twoFactorAuthentication?: Maybe<TwoFactorAuthentication>;
};

export type AuthUserTwoFactorAuthenticationResendEmailSetupError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserTwoFactorAuthenticationResendEmailSetupInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserTwoFactorAuthenticationResendEmailSetupPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationResendEmailSetupError;
  sent: Scalars['Boolean']['output'];
};

export type AuthUserTwoFactorAuthenticationResendError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserTwoFactorAuthenticationResendInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type AuthUserTwoFactorAuthenticationResendPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationResendError;
  sent: Scalars['Boolean']['output'];
};

export type AuthUserTwoFactorAuthenticationSetupError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserTwoFactorAuthenticationSetupInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otpMethod: TwoFactorAuthenticationMethods;
  otpMethodField?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserTwoFactorAuthenticationSetupPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationSetupError;
  manualSetupCode?: Maybe<Scalars['String']['output']>;
  qrCodeUrl?: Maybe<Scalars['String']['output']>;
  twoFactorAuthentication?: Maybe<TwoFactorAuthentication>;
};

export type AuthUserTwoFactorAuthenticationVerifyError = {
  fields?: Maybe<AuthUserTwoFactorAuthenticationVerifyFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserTwoFactorAuthenticationVerifyFieldError = {
  otp?: Maybe<Array<Scalars['String']['output']>>;
  token?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthUserTwoFactorAuthenticationVerifyInput = {
  attributes: AuthUserVerifyTwoFactorAuthenticationAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthUserTwoFactorAuthenticationVerifyPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationVerifyError;
  locked?: Maybe<Scalars['Boolean']['output']>;
  newBackupCode?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  returnToSignin?: Maybe<Scalars['Boolean']['output']>;
  roleType?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type AuthUserVerifyPasswordError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthUserVerifyPasswordInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type AuthUserVerifyPasswordPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserVerifyPasswordError;
  user?: Maybe<User>;
};

export type AuthUserVerifyTwoFactorAuthenticationAttributes = {
  otp: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type AuthWellevatePractitionerSignUpAttributes = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  optional?: InputMaybe<AuthPractitionerSignUpOptionalAttributes>;
  pageVersion: Scalars['String']['input'];
  password: Scalars['String']['input'];
  practitionerTypeId: Scalars['ID']['input'];
  signUpPage: Scalars['String']['input'];
  storeName?: InputMaybe<Scalars['String']['input']>;
  storePlatform?: InputMaybe<Scalars['String']['input']>;
  tosAcceptedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wellevatePractitionerId: Scalars['ID']['input'];
};

export type AuthWellevatePractitionerSignUpError = {
  fields?: Maybe<AuthWellevatePractitionerSignUpFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthWellevatePractitionerSignUpFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
  storeName?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
  tosAcceptedAt?: Maybe<Array<Scalars['String']['output']>>;
  wellevatePractitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthWellevatePractitionerSignUpInput = {
  attributes: AuthWellevatePractitionerSignUpAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthWellevatePractitionerSignUpPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthWellevatePractitionerSignUpError;
  practitioner?: Maybe<Practitioner>;
  redirectPath?: Maybe<Scalars['String']['output']>;
};

export type AuthenticateWellevateCredentialsError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthenticateWellevateCredentialsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AuthenticateWellevateCredentialsPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthenticateWellevateCredentialsError;
};

export type AuthenticateWellevatePractitionerAttributes = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AuthenticateWellevatePractitionerError = {
  fields?: Maybe<AuthenticateWellevatePractitionerFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthenticateWellevatePractitionerFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthenticateWellevatePractitionerInput = {
  attributes: AuthenticateWellevatePractitionerAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthenticateWellevatePractitionerPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dispensaryName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<AuthenticateWellevatePractitionerError>;
  firstname?: Maybe<Scalars['String']['output']>;
  fullscriptPractitionerType?: Maybe<PractitionerType>;
  lastname?: Maybe<Scalars['String']['output']>;
  practitionerId?: Maybe<Scalars['String']['output']>;
};

export const AuthenticationProviders = {
  AppleSso: 'APPLE_SSO',
  EmailPassword: 'EMAIL_PASSWORD',
  GoogleSso: 'GOOGLE_SSO'
} as const;

export type AuthenticationProviders = typeof AuthenticationProviders[keyof typeof AuthenticationProviders];
export type Autoship = Node & Timestamps & {
  billingAddressId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creditCardId?: Maybe<Scalars['ID']['output']>;
  discountValue?: Maybe<Scalars['Int']['output']>;
  frequency: Scalars['String']['output'];
  frequencyValue?: Maybe<Scalars['Int']['output']>;
  hasCaliforniaAddress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isMissingCriticalFields: Scalars['Boolean']['output'];
  lastOrderFailureReason?: Maybe<Scalars['String']['output']>;
  lastOrderedOn?: Maybe<Scalars['Date']['output']>;
  nextOrderDate?: Maybe<Scalars['Date']['output']>;
  patient: Patient;
  receiveByDate?: Maybe<Scalars['Date']['output']>;
  shippingAddressId?: Maybe<Scalars['ID']['output']>;
  shippingRates?: Maybe<Array<AutoshipShippingRate>>;
  subscriptionsVariants: Array<SubscriptionsVariant>;
  subtotal: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variants: VariantConnection;
};


export type AutoshipSubscriptionsVariantsArgs = {
  filters?: InputMaybe<SubscriptionVariantFilterObject>;
};


export type AutoshipVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AutoshipCancelError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AutoshipCancelInput = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  openTextCancellation?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type AutoshipCancelPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AutoshipCancelError;
  patient?: Maybe<Patient>;
};

export type AutoshipFilterObject = {
  autoshipId?: InputMaybe<Scalars['ID']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
};

export type AutoshipGroup = Node & {
  id: Scalars['ID']['output'];
  nextOrderDate: Scalars['Date']['output'];
  shippingMethod?: Maybe<Scalars['String']['output']>;
  shippingRates?: Maybe<Array<AutoshipShippingRate>>;
  subscriptions: Array<Autoship>;
};


export type AutoshipGroupSubscriptionsArgs = {
  filters?: InputMaybe<AutoshipFilterObject>;
};

export type AutoshipGroupFilterObject = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
};

export type AutoshipGroupOrderNowError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AutoshipGroupOrderNowInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriptionsGroupId: Scalars['ID']['input'];
};

export type AutoshipGroupOrderNowPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AutoshipGroupOrderNowError;
  patient?: Maybe<Patient>;
};

export type AutoshipOrderNowError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AutoshipOrderNowInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type AutoshipOrderNowPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AutoshipOrderNowError;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

export type AutoshipRescheduleError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AutoshipRescheduleGroupError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AutoshipRescheduleGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nextOrderDate: Scalars['Date']['input'];
  subscriptionsGroupId: Scalars['ID']['input'];
};

export type AutoshipRescheduleGroupPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AutoshipRescheduleGroupError;
  patient?: Maybe<Patient>;
};

export type AutoshipRescheduleInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nextOrderDate: Scalars['Date']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type AutoshipReschedulePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AutoshipRescheduleError;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

export type AutoshipScheduleAttributes = {
  frequency: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  variantId: Scalars['ID']['input'];
};

export type AutoshipShippingRate = Node & Timestamps & {
  cost: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  estimatedShippingDates?: Maybe<Array<Scalars['Date']['output']>>;
  id: Scalars['ID']['output'];
  isShippingFree: Scalars['Boolean']['output'];
  selected: Scalars['Boolean']['output'];
  shippingMethod: Scalars['String']['output'];
  shippingMethodId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  usingReward: Scalars['Boolean']['output'];
};

export type AutoshipSkipNextGroupOrderError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AutoshipSkipNextGroupOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriptionsGroupId: Scalars['ID']['input'];
};

export type AutoshipSkipNextGroupOrderPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AutoshipSkipNextGroupOrderError;
  patient?: Maybe<Patient>;
};

export type AutoshipSkipNextOrderError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type AutoshipSkipNextOrderInput = {
  causedByVariantId?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type AutoshipSkipNextOrderPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AutoshipSkipNextOrderError;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

export type Avatar = {
  id: Scalars['ID']['output'];
  photoUrl: Scalars['String']['output'];
};


export type AvatarPhotoUrlArgs = {
  filter: PhotoSizeFilters;
};

export type AvataxCertificate = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  customer: AvataxCustomer;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  state: State;
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const AvataxCertificateFilterListField = {
  AboutToExpire: 'ABOUT_TO_EXPIRE',
  Accepted: 'ACCEPTED',
  Expired: 'EXPIRED'
} as const;

export type AvataxCertificateFilterListField = typeof AvataxCertificateFilterListField[keyof typeof AvataxCertificateFilterListField];
export type AvataxCertificateFilterObject = {
  by?: InputMaybe<AvataxCertificateFilterListField>;
};

export type AvataxCustomer = Node & Timestamps & {
  certexpressLink?: Maybe<Scalars['String']['output']>;
  certificates: Array<AvataxCertificate>;
  createdAt: Scalars['DateTime']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  store: Store;
  updatedAt: Scalars['DateTime']['output'];
};


export type AvataxCustomerCertificatesArgs = {
  filters?: InputMaybe<AvataxCertificateFilterObject>;
};

export type Avr = Timestamps & {
  addressableId?: Maybe<Scalars['String']['output']>;
  addressableType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  enteredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  ignoredAt?: Maybe<Scalars['DateTime']['output']>;
  suggestedAddress1?: Maybe<Scalars['String']['output']>;
  suggestedAddress2?: Maybe<Scalars['String']['output']>;
  suggestedCity?: Maybe<Scalars['String']['output']>;
  suggestedCountryId?: Maybe<Scalars['ID']['output']>;
  suggestedState?: Maybe<Scalars['String']['output']>;
  suggestedZipcode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  validatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AwsToken = {
  accessKeyId: Scalars['String']['output'];
  region: Scalars['String']['output'];
  secretAccessKey: Scalars['String']['output'];
  sessionToken: Scalars['String']['output'];
};

export type BalanceSheet = {
  balanceCurrency: Scalars['Currency']['output'];
  cogsTotalCurrency: Scalars['Currency']['output'];
  netIncomeCurrency: Scalars['Currency']['output'];
  salesTotalCurrency: Scalars['Currency']['output'];
  year: Scalars['Int']['output'];
};

export type BankAccount = Node & {
  accountNumber: Scalars['String']['output'];
  accountType: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type BankAccountActivateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type BankAccountActivateInput = {
  bankAccountId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type BankAccountActivatePayload = {
  bankAccount?: Maybe<BankAccount>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<BankAccountActivateError>;
};

export type BankAccountAttributes = {
  accountHeldBy: Scalars['String']['input'];
  accountHolder: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  accountType: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
  setAsDefault?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BankAccountCreateError = {
  fields?: Maybe<BankAccountCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type BankAccountCreateFieldError = {
  accountHeldBy?: Maybe<Array<Scalars['String']['output']>>;
  accountHolder?: Maybe<Array<Scalars['String']['output']>>;
  accountNumber?: Maybe<Array<Scalars['String']['output']>>;
  accountType?: Maybe<Array<Scalars['String']['output']>>;
  routingNumber?: Maybe<Array<Scalars['String']['output']>>;
  setAsDefault?: Maybe<Array<Scalars['String']['output']>>;
};

export type BankAccountCreateInput = {
  attributes: BankAccountAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type BankAccountCreatePayload = {
  bankAccount?: Maybe<BankAccount>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<BankAccountCreateError>;
  store?: Maybe<Store>;
};

export type BaseAddress = Timestamps & {
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  default?: Maybe<Scalars['Boolean']['output']>;
  defaultPatientAddress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  stateId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zipcode: Scalars['String']['output'];
};

export type BaseAddressMutationError = {
  fields?: Maybe<BaseAddressMutationFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type BaseAddressMutationFieldError = {
  billingAddress1?: Maybe<Array<Scalars['String']['output']>>;
  billingAddress2?: Maybe<Array<Scalars['String']['output']>>;
  billingAddressId?: Maybe<Array<Scalars['String']['output']>>;
  billingCity?: Maybe<Array<Scalars['String']['output']>>;
  billingStateId?: Maybe<Array<Scalars['String']['output']>>;
  billingZipcode?: Maybe<Array<Scalars['String']['output']>>;
  shippingAddress1?: Maybe<Array<Scalars['String']['output']>>;
  shippingAddress2?: Maybe<Array<Scalars['String']['output']>>;
  shippingAddressId?: Maybe<Array<Scalars['String']['output']>>;
  shippingAndBilling?: Maybe<Array<Scalars['String']['output']>>;
  shippingCity?: Maybe<Array<Scalars['String']['output']>>;
  shippingStateId?: Maybe<Array<Scalars['String']['output']>>;
  shippingZipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type BaseError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type BiomarkerCategoryForJourneyVariantEdge = {
  biomarkers?: Maybe<Array<LabsBiomarker>>;
  cursor: Scalars['String']['output'];
  node?: Maybe<LabsBiomarkerCategory>;
};

export const BiomarkerResultRangeCategories = {
  Optimal: 'OPTIMAL',
  OutOfRange: 'OUT_OF_RANGE',
  Suboptimal: 'SUBOPTIMAL'
} as const;

export type BiomarkerResultRangeCategories = typeof BiomarkerResultRangeCategories[keyof typeof BiomarkerResultRangeCategories];
export type Brand = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isLabs: Scalars['Boolean']['output'];
  isRestricted: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  npSlug?: Maybe<Scalars['String']['output']>;
  prescriptionOnly: Scalars['Boolean']['output'];
  products: ProductConnection;
  restrictionsConfig?: Maybe<BrandRestrictionsConfig>;
  slug: Scalars['String']['output'];
  topBrand?: Maybe<TopBrand>;
  updatedAt: Scalars['DateTime']['output'];
};


export type BrandProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type BrandConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<BrandEdge>>>;
  nodes?: Maybe<Array<Maybe<Brand>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BrandEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Brand>;
};

export const BrandFilterListField = {
  EeTopBrands: 'EE_TOP_BRANDS',
  FeaturedBrands: 'FEATURED_BRANDS',
  TopBrands: 'TOP_BRANDS',
  WithNewProducts: 'WITH_NEW_PRODUCTS'
} as const;

export type BrandFilterListField = typeof BrandFilterListField[keyof typeof BrandFilterListField];
export type BrandFilterObject = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  letter?: InputMaybe<Scalars['String']['input']>;
  list?: InputMaybe<BrandFilterListField>;
};

export type BrandList = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BrandRestrictionsConfig = Node & Timestamps & {
  allowFavorites: Scalars['Boolean']['output'];
  allowPreviouslyPurchased: Scalars['Boolean']['output'];
  allowPublicMultiPatientPlan: Scalars['Boolean']['output'];
  allowSelectiveMultiPatientPlan: Scalars['Boolean']['output'];
  allowShowcase: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  requireRecommendation: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BrandsMapAgreement = Node & Timestamps & {
  agreementUrl: Scalars['String']['output'];
  brand: Brand;
  createdAt: Scalars['DateTime']['output'];
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  statusForStore?: Maybe<UsersMapAgreementStatusTypes>;
  updatedAt: Scalars['DateTime']['output'];
};

export type BulkEditProcess = Node & Timestamps & {
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  discount?: Maybe<Scalars['Int']['output']>;
  entries: BulkEditProcessEntryConnection;
  id: Scalars['ID']['output'];
  store: Store;
  totalRecords?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userRole: Roleable;
};


export type BulkEditProcessEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type BulkEditProcessEntry = Node & Timestamps & {
  bulkEditProcess: BulkEditProcess;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BulkEditProcessEntryConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<BulkEditProcessEntryEdge>>>;
  nodes?: Maybe<Array<Maybe<BulkEditProcessEntry>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BulkEditProcessEntryEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<BulkEditProcessEntry>;
};

export type BulkEditSummary = {
  consistentPatientDiscount?: Maybe<Scalars['Int']['output']>;
  excludedFromBulkEditCount?: Maybe<Scalars['Int']['output']>;
  includedInBulkEditCount?: Maybe<Scalars['Int']['output']>;
};

export type BulkPatientAssignPractitionerAttributes = {
  allPatients?: InputMaybe<Scalars['Boolean']['input']>;
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  practitionerIdToAssign: Scalars['ID']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  withNoAssignedPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkPatientReassignPractitionerAttributes = {
  allPatients?: InputMaybe<Scalars['Boolean']['input']>;
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  practitionerIdToRemove: Scalars['ID']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  withNoAssignedPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkSavingsUpdateAllAttributes = {
  discount: Scalars['Int']['input'];
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  withNoAssignedPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkSavingsUpdateAttributes = {
  discount: Scalars['Int']['input'];
  patientIds: Array<Scalars['ID']['input']>;
};

export type BusinessAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type BusinessAddressFieldErrors = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  state?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type CardAttributes = {
  addressCity: Scalars['String']['input'];
  addressCountry: Scalars['String']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  addressState: Scalars['String']['input'];
  addressZip: Scalars['String']['input'];
  expMonth: Scalars['Int']['input'];
  expYear: Scalars['Int']['input'];
  last4: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CartItemAttributes = {
  autoshipFrequency?: InputMaybe<Scalars['String']['input']>;
  autoshipSelected?: InputMaybe<Scalars['Boolean']['input']>;
  labRecommendationId?: InputMaybe<Scalars['ID']['input']>;
  quantity: Scalars['Int']['input'];
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
  variantId: Scalars['ID']['input'];
};

export const CatalogPermissionTypes = {
  Closed: 'CLOSED',
  Open: 'OPEN',
  PrivateCategories: 'PRIVATE_CATEGORIES',
  Restricted: 'RESTRICTED'
} as const;

export type CatalogPermissionTypes = typeof CatalogPermissionTypes[keyof typeof CatalogPermissionTypes];
export type CatalogPermissionsUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type CatalogPermissionsUpdateInput = {
  catalogPermission: CatalogPermissionTypes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CatalogPermissionsUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: CatalogPermissionsUpdateError;
  store?: Maybe<Store>;
};

export type Category = Node & Timestamps & {
  categoryType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Not going to be used */
  grandParentId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isLabs: Scalars['Boolean']['output'];
  isVisibleToPatients?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated Not going to be used */
  parentId?: Maybe<Scalars['ID']['output']>;
  products: ProductConnection;
  updatedAt: Scalars['DateTime']['output'];
};


export type CategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
  nodes?: Maybe<Array<Maybe<Category>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Category>;
};

export type CategoryGroup = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  products: ProductConnection;
  updatedAt: Scalars['DateTime']['output'];
};


export type CategoryGroupProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryGroupConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<CategoryGroupEdge>>>;
  nodes?: Maybe<Array<Maybe<CategoryGroup>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryGroupEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<CategoryGroup>;
};

export const CategoryGroupFilterListField = {
  NotAdded: 'NOT_ADDED'
} as const;

export type CategoryGroupFilterListField = typeof CategoryGroupFilterListField[keyof typeof CategoryGroupFilterListField];
export type CategoryGroupFilterObject = {
  list?: InputMaybe<Array<CategoryGroupFilterListField>>;
};

export type Certification = Node & Timestamps & {
  certificationUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  practitionerType?: Maybe<PractitionerType>;
  state?: Maybe<State>;
  store: Store;
  storePlatform: StorePlatform;
  updatedAt: Scalars['DateTime']['output'];
};

export type CertificationOverwrites = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  modality?: InputMaybe<Scalars['String']['input']>;
};

export type Clerk = LegacyId & Node & Roleable & Timestamps & {
  _legacyId: Scalars['Int']['output'];
  anyCredentialApproved: Scalars['Boolean']['output'];
  avatar?: Maybe<Avatar>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  externalIntegrations?: Maybe<Array<ApiExternalMetadatum>>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  hasReportAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isLocked: Scalars['Boolean']['output'];
  isSignupPending?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shipState?: Maybe<State>;
  store: Store;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeName?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ClerkArchiveError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ClerkArchiveInput = {
  clerkId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ClerkArchivePayload = {
  clerk?: Maybe<Clerk>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ClerkArchiveError;
};

export type ClerkFilterObject = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClerkRestoreError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ClerkRestoreInput = {
  clerkId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ClerkRestorePayload = {
  clerk?: Maybe<Clerk>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ClerkRestoreError;
};

export type ClerkUpdateError = {
  fields?: Maybe<ClerkUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ClerkUpdateFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
};

export type ClerkUpdateInput = {
  attributes: OfficeClerkUpdateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ClerkUpdatePayload = {
  clerk?: Maybe<Clerk>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ClerkUpdateError;
};

export type CombinedOrdersFilterObject = {
  completedAt?: InputMaybe<DateRangeFilter>;
};

export type CombinedReorderLineItemsFilterObject = {
  orderedAt?: InputMaybe<DateRangeFilter>;
};

export type CombinedReorderLineItemsSort = {
  direction: SortDirection;
  field: CombinedReorderLineItemsSortField;
};

export const CombinedReorderLineItemsSortField = {
  CompletedAt: 'COMPLETED_AT',
  Name: 'NAME',
  Price: 'PRICE'
} as const;

export type CombinedReorderLineItemsSortField = typeof CombinedReorderLineItemsSortField[keyof typeof CombinedReorderLineItemsSortField];
export type Commentary = Timestamps & {
  aboutId?: Maybe<Scalars['String']['output']>;
  aboutType?: Maybe<Scalars['String']['output']>;
  contents?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CommentaryAttributes = {
  aboutId: Scalars['ID']['input'];
  contents: Scalars['String']['input'];
};

export type CommentaryCreateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type CommentaryCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  commentaryAttributes: CommentaryAttributes;
};

export type CommentaryCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  commentary?: Maybe<Commentary>;
  errors: CommentaryCreateError;
};

export type CommentaryUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type CommentaryUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  commentaryId: Scalars['ID']['input'];
  contents: Scalars['String']['input'];
};

export type CommentaryUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  commentary?: Maybe<Commentary>;
  errors: CommentaryUpdateError;
};

export type CommonlyPrescribedProductSort = {
  direction: SortDirection;
  field: CommonlyPrescribedProductSortField;
};

export const CommonlyPrescribedProductSortField = {
  Alphabetical: 'ALPHABETICAL',
  PrescriptionFrequency: 'PRESCRIPTION_FREQUENCY',
  Price: 'PRICE'
} as const;

export type CommonlyPrescribedProductSortField = typeof CommonlyPrescribedProductSortField[keyof typeof CommonlyPrescribedProductSortField];
export type CommonlyPrescribedVariantFilterObject = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export type CopilotAudioChunkCreateAttributes = {
  copilotAudioSessionId: Scalars['ID']['input'];
  results: Scalars['String']['input'];
};

export type CopilotAudioChunkCreateError = {
  fields?: Maybe<CopilotAudioChunkCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CopilotAudioChunkCreateFieldError = {
  copilotAudioSessionId?: Maybe<Array<Scalars['String']['output']>>;
  results?: Maybe<Array<Scalars['String']['output']>>;
};

export type CopilotAudioChunkCreateInput = {
  attributes: CopilotAudioChunkCreateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CopilotAudioChunkCreatePayload = {
  audioChunk?: Maybe<AudioChunk>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotAudioChunkCreateError>;
};

export type CopilotAudioSessionEndAttributes = {
  copilotAudioSessionId: Scalars['ID']['input'];
  kind?: InputMaybe<Scalars['String']['input']>;
};

export type CopilotAudioSessionEndError = {
  fields?: Maybe<CopilotAudioSessionEndFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CopilotAudioSessionEndFieldError = {
  copilotAudioSessionId?: Maybe<Array<Scalars['String']['output']>>;
  kind?: Maybe<Array<Scalars['String']['output']>>;
};

export type CopilotAudioSessionEndInput = {
  attributes: CopilotAudioSessionEndAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CopilotAudioSessionEndPayload = {
  audioSession?: Maybe<AudioSession>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotAudioSessionEndError>;
};

export type CopilotAudioSessionStartError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type CopilotAudioSessionStartInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  recordingType: Scalars['String']['input'];
};

export type CopilotAudioSessionStartPayload = {
  audioSession?: Maybe<AudioSession>;
  awsToken?: Maybe<AwsToken>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotAudioSessionStartError>;
};

export type CopilotCreateResourcesError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type CopilotCreateResourcesInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotAudioSessionId: Scalars['ID']['input'];
};

export type CopilotCreateResourcesPayload = {
  audioSession?: Maybe<AudioSession>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotCreateResourcesError>;
};

export type CopilotCreateSummaryAttributes = {
  copilotAudioSessionId: Scalars['ID']['input'];
  kind?: InputMaybe<Scalars['String']['input']>;
};

export type CopilotCreateSummaryError = {
  fields?: Maybe<CopilotCreateSummaryFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CopilotCreateSummaryFieldError = {
  copilotAudioSessionId?: Maybe<Array<Scalars['String']['output']>>;
  kind?: Maybe<Array<Scalars['String']['output']>>;
};

export type CopilotCreateSummaryInput = {
  attributes: CopilotCreateSummaryAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CopilotCreateSummaryPayload = {
  audioSession?: Maybe<AudioSession>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotCreateSummaryError>;
};

export type CopilotCreateSupplementsError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type CopilotCreateSupplementsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotAudioSessionId: Scalars['ID']['input'];
};

export type CopilotCreateSupplementsPayload = {
  audioSession?: Maybe<AudioSession>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotCreateSupplementsError>;
};

export type CopilotMutation = {
  sessionNotesCreateResources?: Maybe<SessionNotesCreateResourcesPayload>;
  sessionNotesCreateSummaryMessage?: Maybe<SessionNotesCreateSummaryMessagePayload>;
  sessionNotesCreateSupplements?: Maybe<SessionNotesCreateSupplementsPayload>;
  sessionNotesSave?: Maybe<SessionNotesSavePayload>;
};


export type CopilotMutationSessionNotesCreateResourcesArgs = {
  input: SessionNotesCreateResourcesInput;
};


export type CopilotMutationSessionNotesCreateSummaryMessageArgs = {
  input: SessionNotesCreateSummaryMessageInput;
};


export type CopilotMutationSessionNotesCreateSupplementsArgs = {
  input: SessionNotesCreateSupplementsInput;
};


export type CopilotMutationSessionNotesSaveArgs = {
  input: SessionNotesSaveInput;
};

export type CopilotUploadTranscriptError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type CopilotUploadTranscriptInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  transcript: Scalars['String']['input'];
};

export type CopilotUploadTranscriptPayload = {
  audioSession?: Maybe<AudioSession>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotUploadTranscriptError>;
};

export type CreateProductAccessRequestError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateProductAccessRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

export type CreateProductAccessRequestPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CreateProductAccessRequestError>;
  productAccessRequest?: Maybe<ProductAccessRequest>;
};

export type CreateSummaryMessageError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateSummaryMessageInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotAudioSessionId: Scalars['ID']['input'];
};

export type CreateSummaryMessagePayload = {
  audioSession?: Maybe<AudioSession>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CreateSummaryMessageError>;
};

export type CreditCard = Node & Timestamps & {
  ccType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  defaultCard?: Maybe<Scalars['Boolean']['output']>;
  expired: Scalars['Boolean']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastDigits: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  month: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  year: Scalars['String']['output'];
};

export type CreditCardAttributes = {
  defaultCard?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  oneTimeUse?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
};

export type CreditCardDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCardId: Scalars['ID']['input'];
};

export type CreditCardDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
};

export type CreditCardSetDefaultError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type CreditCardSetDefaultInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCardId: Scalars['ID']['input'];
  default: Scalars['Boolean']['input'];
};

export type CreditCardSetDefaultPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  creditCard?: Maybe<CreditCard>;
  errors: CreditCardSetDefaultError;
};

export type DailyPack = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nameWithSupply?: Maybe<Scalars['String']['output']>;
  packPills?: Maybe<PackPillConnection>;
  practitioner?: Maybe<Practitioner>;
  product: Product;
  updatedAt: Scalars['DateTime']['output'];
  validation?: Maybe<DailyPackValidationType>;
  variant: Variant;
};


export type DailyPackPackPillsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DailyPackConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<DailyPackEdge>>>;
  nodes?: Maybe<Array<Maybe<DailyPack>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DailyPackEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<DailyPack>;
};

export type DailyPackPill = Timestamps & {
  availabilityStatus: Scalars['String']['output'];
  brand: Brand;
  createdAt: Scalars['DateTime']['output'];
  defaultAmDosage?: Maybe<Scalars['Int']['output']>;
  defaultDosageInstruction?: Maybe<DefaultDosageInstruction>;
  defaultPmDosage?: Maybe<Scalars['Int']['output']>;
  dosageFormat?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  isMaster: Scalars['Boolean']['output'];
  monthlyMsrp: Scalars['Float']['output'];
  /** @deprecated Use 'monthlyMsrp' field instead */
  monthlyPrice: Scalars['Float']['output'];
  monthlySellPrice: Scalars['Float']['output'];
  msrp?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  pillCategories?: Maybe<Array<PillCategory>>;
  pillDescription?: Maybe<Scalars['String']['output']>;
  pillName?: Maybe<Scalars['String']['output']>;
  product: Product;
  productId: Scalars['ID']['output'];
  sellPrice: Scalars['Float']['output'];
  sellPriceCurrency: Scalars['Currency']['output'];
  sku: Scalars['String']['output'];
  upc?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  wholesale: Scalars['Boolean']['output'];
  wholesalePrice?: Maybe<Scalars['Float']['output']>;
  wholesalePriceCurrency?: Maybe<Scalars['Currency']['output']>;
};

export type DailyPackPillConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<DailyPackPillEdge>>>;
  nodes?: Maybe<Array<Maybe<DailyPackPill>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DailyPackPillEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<DailyPackPill>;
};

export type DailyPackPillFilterObject = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type DailyPackTemplateFilterObject = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type DailyPackTemplateSort = {
  direction: SortDirection;
  field: DailyPackTemplateSortField;
};

export const DailyPackTemplateSortField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME'
} as const;

export type DailyPackTemplateSortField = typeof DailyPackTemplateSortField[keyof typeof DailyPackTemplateSortField];
export type DailyPackValidationType = {
  isAmOverfilled?: Maybe<Scalars['Boolean']['output']>;
  isPmOverfilled?: Maybe<Scalars['Boolean']['output']>;
  isUnderfilled?: Maybe<Scalars['Boolean']['output']>;
};

export type DateRangeFilter = {
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DefaultDosageInstruction = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  defaultVariant: Variant;
  dosageAmount?: Maybe<Scalars['String']['output']>;
  dosageDuration?: Maybe<Scalars['String']['output']>;
  dosageFormat?: Maybe<Scalars['String']['output']>;
  dosageFrequency?: Maybe<Scalars['String']['output']>;
  dosagePermutations?: Maybe<Scalars['String']['output']>;
  /** @deprecated Removed practitioner ability to select dosage quantity. */
  dosageQuantity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isTextOnly: Scalars['Boolean']['output'];
  practitioner: Practitioner;
  product: Product;
  takeWith?: Maybe<Scalars['String']['output']>;
  timeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
  withFood: Scalars['Boolean']['output'];
};

export type DeleteDailyPackTemplateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type DeleteDailyPackTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dailyPackId: Scalars['ID']['input'];
};

export type DeleteDailyPackTemplatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: DeleteDailyPackTemplateError;
};

export type DemographicVariation = {
  category: Scalars['String']['output'];
  evidence?: Maybe<Array<Evidence>>;
};

export type DiscontinueError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type DiscontinueInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dailyPackId: Scalars['ID']['input'];
};

export type DiscontinuePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: DiscontinueError;
};

export type Document = Node & Timestamps & Uploadable & {
  attachment: Scalars['String']['output'];
  attachmentFileName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  documentCategory?: Maybe<ImatContentCategory>;
  documentLibrary: Scalars['Boolean']['output'];
  documentType?: Maybe<ImatContentType>;
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAttached: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  trackingUrl: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type DocumentIsAttachedArgs = {
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

export type DocumentAttachment = Node & Timestamps & {
  attachable: Attachable;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploadable: Uploadable;
};

export type DocumentAttachmentConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<DocumentAttachmentEdge>>>;
  nodes?: Maybe<Array<Maybe<DocumentAttachment>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DocumentAttachmentEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<DocumentAttachment>;
};

export type DocumentConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  nodes?: Maybe<Array<Maybe<Document>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DocumentEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Document>;
};

export type DocumentFilterObject = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  otherCategory?: InputMaybe<Scalars['Boolean']['input']>;
  otherType?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['ID']['input']>;
};

export type DocumentSort = {
  direction: SortDirection;
  field: DocumentSortField;
};

export const DocumentSortField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME'
} as const;

export type DocumentSortField = typeof DocumentSortField[keyof typeof DocumentSortField];
export type DosageFormat = Node & Timestamps & {
  baseFormat: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variation: Scalars['String']['output'];
};

export type DosageFormatSort = {
  direction: SortDirection;
  field: DosageFormatSortField;
};

export const DosageFormatSortField = {
  SumProductsCount: 'SUM_PRODUCTS_COUNT'
} as const;

export type DosageFormatSortField = typeof DosageFormatSortField[keyof typeof DosageFormatSortField];
export type DraftAutoshipVariantError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type DraftAutoshipVariantInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  lineItemId: Scalars['ID']['input'];
};

export type DraftAutoshipVariantPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: DraftAutoshipVariantError;
  lineItem?: Maybe<LineItem>;
};

export type DraftDynamicDailyPack = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  multiPatientPlan?: Maybe<MultiPatientPlan>;
  treatmentPlan?: Maybe<TreatmentPlan>;
  updatedAt: Scalars['DateTime']['output'];
  variant: Variant;
};

export type EDeliveryConsent1099kUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type EDeliveryConsent1099kUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eDeliveryConsent: Scalars['Boolean']['input'];
};

export type EDeliveryConsent1099kUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<EDeliveryConsent1099kUpdateError>;
  store?: Maybe<Store>;
};

export type EarningsReport = {
  entries?: Maybe<Array<EarningsReportEntry>>;
};


export type EarningsReportEntriesArgs = {
  patientId?: InputMaybe<Scalars['Int']['input']>;
  payees?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type EarningsReportEntry = {
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  orders?: Maybe<Array<EarningsReportOrder>>;
  patientId?: Maybe<Scalars['ID']['output']>;
  payee?: Maybe<Practitioner>;
  payeesClinic?: Maybe<Array<Store>>;
  payeesOther?: Maybe<Array<Practitioner>>;
  payeesPracs?: Maybe<Array<Practitioner>>;
};

export type EarningsReportOrder = {
  attributablePractitionerId?: Maybe<Scalars['Int']['output']>;
  bonusEarningsAmount?: Maybe<Scalars['Currency']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  earningsAmount?: Maybe<Scalars['Currency']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  items?: Maybe<Array<EarningsReportOrderItem>>;
  number?: Maybe<Scalars['String']['output']>;
  payee?: Maybe<Practitioner>;
};

export type EarningsReportOrderItem = {
  deletedInventoryUnitAmount?: Maybe<Scalars['Int']['output']>;
  deletedInventoryUnitAmountCurrency?: Maybe<Scalars['Currency']['output']>;
  deletedInventoryUnitCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedInventoryUnitId?: Maybe<Scalars['Int']['output']>;
  disbType?: Maybe<Scalars['String']['output']>;
  orderItemType?: Maybe<Scalars['String']['output']>;
  prescriber?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  rmaAmount?: Maybe<Scalars['Int']['output']>;
  rmaAmountCurrency?: Maybe<Scalars['Currency']['output']>;
  rmaCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  rmaId?: Maybe<Scalars['Int']['output']>;
  variantSku?: Maybe<Scalars['String']['output']>;
};

export type EditClickAttributes = {
  pageVersion: Scalars['String']['input'];
  treatmentPlanId: Scalars['ID']['input'];
  treatmentPlanStatus: Scalars['String']['input'];
};

export type ElationLinkPatientError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ElationLinkPatientInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalPatient: ExternalPatient;
  integrationUid: Scalars['String']['input'];
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

export type ElationLinkPatientPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ElationLinkPatientError;
  patientMapping?: Maybe<ApiExternalMetadatum>;
};

export type ElationLinkPractitionerError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ElationLinkPractitionerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalPractitionerId: Scalars['String']['input'];
  integrationUid: Scalars['String']['input'];
};

export type ElationLinkPractitionerPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ElationLinkPractitionerError;
  practitionerMapping?: Maybe<ApiExternalMetadatum>;
};

export type EmersonAuthUserSignInError = {
  fields?: Maybe<EmersonAuthUserSignInFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type EmersonAuthUserSignInFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  rememberMe?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  staySignedIn?: Maybe<Array<Scalars['String']['output']>>;
  usingBiometrics?: Maybe<Array<Scalars['String']['output']>>;
};

export type EmersonAuthUserSignInInput = {
  attributes: AuthUserSignInAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type EmersonAuthUserSignInPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: EmersonAuthUserSignInError;
  otpMethod?: Maybe<TwoFactorAuthenticationMethods>;
  phoneLast4?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  twoFactorAuthenticationToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type EmersonClassicAddress = {
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  addressName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  state?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type EmersonClassicBackorderedItemInput = {
  itemCode: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

export type EmersonClassicCombinedOrdersSort = {
  direction: SortDirection;
  field: EmersonClassicCombinedOrdersSortField;
};

export const EmersonClassicCombinedOrdersSortField = {
  CompletedAt: 'COMPLETED_AT',
  Total: 'TOTAL'
} as const;

export type EmersonClassicCombinedOrdersSortField = typeof EmersonClassicCombinedOrdersSortField[keyof typeof EmersonClassicCombinedOrdersSortField];
export type EmersonClassicCreditCard = {
  ccType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastDigits?: Maybe<Scalars['String']['output']>;
};

export type EmersonClassicInventoryUnit = {
  id: Scalars['ID']['output'];
  lineItem: EmersonClassicOrderLineItem;
  quantity: Scalars['Int']['output'];
  requestedCancellation: Scalars['Boolean']['output'];
  shippedQuantity: Scalars['Int']['output'];
  variant?: Maybe<Variant>;
};

export type EmersonClassicInventoryUnitFilterObject = {
  notShipped?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmersonClassicInvoice = {
  id: Scalars['ID']['output'];
  invoiceUrl: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

export type EmersonClassicOrder = Node & {
  billingAddress?: Maybe<EmersonClassicAddress>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invoices: Array<EmersonClassicInvoice>;
  isFromCyberMonday: Scalars['Boolean']['output'];
  itemCount: Scalars['Int']['output'];
  itemTotal: Scalars['Float']['output'];
  lineItems: Array<EmersonClassicOrderLineItem>;
  number: Scalars['String']['output'];
  payment?: Maybe<EmersonClassicPayment>;
  poNumber?: Maybe<Scalars['String']['output']>;
  savingsTotal: Scalars['Float']['output'];
  shipmentState: Scalars['String']['output'];
  shipments: Array<EmersonClassicShipment>;
  shippingAddress: EmersonClassicAddress;
  shippingTotal?: Maybe<Scalars['Float']['output']>;
  state: Scalars['String']['output'];
  taxTotal?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};


export type EmersonClassicOrderShipmentsArgs = {
  filters?: InputMaybe<EmersonClassicShipmentFilterObject>;
};

export type EmersonClassicOrderConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<EmersonOrdersUnionEdge>>>;
  nodes?: Maybe<Array<Maybe<EmersonOrdersUnion>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EmersonClassicOrderLineItem = {
  amount: Scalars['Float']['output'];
  brandName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  itemCancelled: Scalars['Boolean']['output'];
  itemCode: Scalars['String']['output'];
  itemDescription: Scalars['String']['output'];
  lineItem: EmersonClassicOrderLineItem;
  orderedAt?: Maybe<Scalars['DateTime']['output']>;
  price: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
  requestedCancellation: Scalars['Boolean']['output'];
  variant?: Maybe<Variant>;
};

export type EmersonClassicPayment = {
  id: Scalars['ID']['output'];
  paymentDescription: Scalars['String']['output'];
  source?: Maybe<EmersonClassicCreditCard>;
};

export type EmersonClassicReorderLineItemConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<EmersonReorderLineItemsUnionEdge>>>;
  nodes?: Maybe<Array<Maybe<EmersonReorderLineItemsUnion>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EmersonClassicShipment = {
  grandTotal: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  inventoryUnits?: Maybe<Array<EmersonClassicInventoryUnit>>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  shipmentLines: Array<EmersonClassicShipmentLine>;
  shipmentReceiptUrl?: Maybe<Scalars['String']['output']>;
  shippedAt?: Maybe<Scalars['DateTime']['output']>;
  state: Scalars['String']['output'];
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  trackings?: Maybe<Array<EmersonClassicTracking>>;
};


export type EmersonClassicShipmentInventoryUnitsArgs = {
  filters?: InputMaybe<EmersonClassicInventoryUnitFilterObject>;
};

export type EmersonClassicShipmentFilterObject = {
  shipped?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmersonClassicShipmentLine = {
  id: Scalars['ID']['output'];
  itemCode: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  quantityShipped: Scalars['Int']['output'];
};

export type EmersonClassicTracking = {
  id: Scalars['ID']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export type EmersonMerchantDataAddressAttributes = {
  city: Scalars['String']['input'];
  graduationDate?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
  region: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
};

export type EmersonOrdersUnion = EmersonClassicOrder | WholesaleOrder;

export type EmersonOrdersUnionEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<EmersonOrdersUnion>;
};

export type EmersonReorderLineItemsUnion = EmersonClassicOrderLineItem | WholesaleLineItem;

export type EmersonReorderLineItemsUnionEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<EmersonReorderLineItemsUnion>;
};

export type EmersonUserAdditionalInfoMigrationError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type EmersonUserAdditionalInfoMigrationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type EmersonUserAdditionalInfoMigrationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: EmersonUserAdditionalInfoMigrationError;
  store?: Maybe<Store>;
};

export type EnableSharedAccountError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type EnableSharedAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  requestedPlatform: StorePlatformType;
};

export type EnableSharedAccountPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: EnableSharedAccountError;
};

export const EqpLevelType = {
  Gold: 'Gold',
  Silver: 'Silver'
} as const;

export type EqpLevelType = typeof EqpLevelType[keyof typeof EqpLevelType];
export const EventTypes = {
  TracksOrder: 'TRACKS_ORDER',
  ViewReceipt: 'VIEW_RECEIPT'
} as const;

export type EventTypes = typeof EventTypes[keyof typeof EventTypes];
export type Evidence = {
  authors?: Maybe<Scalars['String']['output']>;
  journalName?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  year?: Maybe<Scalars['String']['output']>;
};

export type ExperimentCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  experiment: Scalars['String']['input'];
  group: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  subjectId: Scalars['ID']['input'];
};

export type ExperimentCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  experimentCreated?: Maybe<Scalars['Boolean']['output']>;
};

export type Explore = {
  healthAreas?: Maybe<Array<ExploreHealthArea>>;
};

export type ExploreHealthArea = {
  healthConditions?: Maybe<Array<ExploreHealthCondition>>;
  iconName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ExploreHealthCondition = {
  ingredients?: Maybe<Array<Ingredient>>;
  name?: Maybe<Scalars['String']['output']>;
  template?: Maybe<TreatmentPlanTemplate>;
  variants?: Maybe<Array<Variant>>;
};

export type ExplorePopularSearch = {
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  searches?: Maybe<Array<ExplorePopularSearchItem>>;
};

export type ExplorePopularSearchItem = {
  filterType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ExternalPatient = {
  biologicalSex?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
  smsNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalProduct = Node & {
  brandName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type FavoriteProductAddError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type FavoriteProductAddInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

export type FavoriteProductAddPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FavoriteProductAddError;
  isDefaultCategory?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<Product>;
};

export type FavoriteProductRemoveError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type FavoriteProductRemoveInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

export type FavoriteProductRemovePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FavoriteProductRemoveError;
  isDefaultCategory?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<Product>;
};

export type FavoriteProductsAddError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type FavoriteProductsAddInput = {
  categoryId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productIds: Array<Scalars['ID']['input']>;
};

export type FavoriteProductsAddPayload = {
  category?: Maybe<Category>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FavoriteProductsAddError;
};

export type FavoriteProductsRemoveError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type FavoriteProductsRemoveInput = {
  categoryId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productIds: Array<Scalars['ID']['input']>;
};

export type FavoriteProductsRemovePayload = {
  category?: Maybe<Category>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FavoriteProductsRemoveError;
};

export type FavoriteSearchSort = {
  direction: SortDirection;
  field?: InputMaybe<FavoriteSearchSortField>;
};

export const FavoriteSearchSortField = {
  Alphabetical: 'ALPHABETICAL',
  MostPrescribed: 'MOST_PRESCRIBED'
} as const;

export type FavoriteSearchSortField = typeof FavoriteSearchSortField[keyof typeof FavoriteSearchSortField];
export type FavoriteVariantFilterObject = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type FavoriteVariantSort = {
  direction: SortDirection;
  field?: InputMaybe<FavoriteVariantSortField>;
};

export const FavoriteVariantSortField = {
  Name: 'NAME',
  Recommendation: 'RECOMMENDATION'
} as const;

export type FavoriteVariantSortField = typeof FavoriteVariantSortField[keyof typeof FavoriteVariantSortField];
export type Flipper = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  result: Scalars['Boolean']['output'];
};

export type FlipperUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enable: Scalars['Boolean']['input'];
  flipper: Scalars['String']['input'];
};

export type FlipperUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  flipper?: Maybe<Flipper>;
};

export type FollowUpClickedAttributes = {
  pageVersion: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
};

export type FollowUpSentAttributes = {
  followUpType: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
};

export type FsAssistGeneratePlanMessageError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type FsAssistGeneratePlanMessageInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  maxWordLength?: InputMaybe<Scalars['Int']['input']>;
  minWordLength?: InputMaybe<Scalars['Int']['input']>;
  patientHealthInfo: Scalars['String']['input'];
  prescription: Scalars['String']['input'];
  specialInstructions?: InputMaybe<Scalars['String']['input']>;
};

export type FsAssistGeneratePlanMessagePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<FsAssistGeneratePlanMessageError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type FsAssistMutation = {
  generatePlanMessage?: Maybe<FsAssistGeneratePlanMessagePayload>;
};


export type FsAssistMutationGeneratePlanMessageArgs = {
  input: FsAssistGeneratePlanMessageInput;
};

export type FsExperienceUpgradeStatus = {
  cohort?: Maybe<Scalars['String']['output']>;
  eligibleAt?: Maybe<Scalars['DateTime']['output']>;
  forcedCutoverAt?: Maybe<Scalars['DateTime']['output']>;
  movedOverAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FsExperienceUpgradeStatusUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type FsExperienceUpgradeStatusUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type FsExperienceUpgradeStatusUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FsExperienceUpgradeStatusUpdateError;
};

export type FullscriptContentSort = {
  direction: SortDirection;
  field: FullscriptContentSortField;
};

export const FullscriptContentSortField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME'
} as const;

export type FullscriptContentSortField = typeof FullscriptContentSortField[keyof typeof FullscriptContentSortField];
export type FullscriptProtocol = Timestamps & {
  category: FullscriptProtocolCategory;
  contents: Array<FullscriptProtocolContent>;
  createdAt: Scalars['DateTime']['output'];
  evidenceRating: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  overviewHtml: Scalars['String']['output'];
  recentlyAdded: Scalars['Boolean']['output'];
  referencesHtml: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  treatmentPlanTemplate: TreatmentPlanTemplate;
  updatedAt: Scalars['DateTime']['output'];
};

export type FullscriptProtocolCategory = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FullscriptProtocolConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<FullscriptProtocolEdge>>>;
  nodes?: Maybe<Array<Maybe<FullscriptProtocol>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FullscriptProtocolContent = Timestamps & {
  catalogLink: Scalars['String']['output'];
  contentHtml?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  recommendation: Recommendation;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FullscriptProtocolEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<FullscriptProtocol>;
};

export type FullscriptProtocolFilterObject = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type FullscriptProtocolSort = {
  direction: SortDirection;
  field: FullscriptProtocolSortField;
};

export const FullscriptProtocolSortField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type FullscriptProtocolSortField = typeof FullscriptProtocolSortField[keyof typeof FullscriptProtocolSortField];
export type GenAiGetProductRecommendationError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type GenAiGetProductRecommendationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  soapNotesOrQuestion: Scalars['String']['input'];
};

export type GenAiGetProductRecommendationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  conditions?: Maybe<Array<Scalars['String']['output']>>;
  conditionsIngredients?: Maybe<Array<ResearchAssistantConditionIngredient>>;
  demographic?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<GenAiGetProductRecommendationError>;
};

export type GenAiMutation = {
  researchAssistantGetIngredientRecommendation?: Maybe<GenAiGetProductRecommendationPayload>;
};


export type GenAiMutationResearchAssistantGetIngredientRecommendationArgs = {
  input: GenAiGetProductRecommendationInput;
};

export type GlobalPatientAccessUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type GlobalPatientAccessUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  globalPatients: Scalars['Boolean']['input'];
};

export type GlobalPatientAccessUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: GlobalPatientAccessUpdateError;
  store?: Maybe<Store>;
};

export type GlobalPatientPromotion = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  optIn: Scalars['Boolean']['output'];
  targetedDiscount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GlobalPatientPromotionAttributes = {
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  targetedDiscount?: InputMaybe<Scalars['Int']['input']>;
};

export type GlobalPatientPromotionUpdateError = {
  fields?: Maybe<GlobalPatientPromotionUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GlobalPatientPromotionUpdateFieldError = {
  optIn?: Maybe<Array<Scalars['String']['output']>>;
  targetedDiscount?: Maybe<Array<Scalars['String']['output']>>;
};

export type GlobalPatientPromotionUpdateInput = {
  attributes: GlobalPatientPromotionAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type GlobalPatientPromotionUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<GlobalPatientPromotionUpdateError>;
  globalPatientPromotion?: Maybe<GlobalPatientPromotion>;
};

export type HealthCondition = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type HideMigrationBannerError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type HideMigrationBannerInput = {
  bannerKey: MigrationBanners;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type HideMigrationBannerPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: HideMigrationBannerError;
  migrationStatus?: Maybe<MigrationStatus>;
};

export type HideNotInCatalogBannerError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type HideNotInCatalogBannerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

export type HideNotInCatalogBannerPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: HideNotInCatalogBannerError;
};

export type HidePatientMigrationBannerError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type HidePatientMigrationBannerInput = {
  bannerKey: PatientMigrationBanners;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type HidePatientMigrationBannerPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: HidePatientMigrationBannerError;
  patientMigrationStatus?: Maybe<WellevatePatientMigrationStatus>;
};

export type Image = Node & Viewable & {
  alt?: Maybe<Scalars['String']['output']>;
  contentType: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Int']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  urlLarge: Scalars['String']['output'];
  urlMini: Scalars['String']['output'];
  urlOriginal: Scalars['String']['output'];
  urlProduct: Scalars['String']['output'];
  urlSmall: Scalars['String']['output'];
  urlXlarge: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export const ImageFilterFormatField = {
  Png: 'PNG',
  Webp: 'WEBP'
} as const;

export type ImageFilterFormatField = typeof ImageFilterFormatField[keyof typeof ImageFilterFormatField];
export type ImageFilterObject = {
  format?: InputMaybe<ImageFilterFormatField>;
  size?: InputMaybe<ImageFilterSizeField>;
};

export const ImageFilterSizeField = {
  Large: 'LARGE',
  Medium: 'MEDIUM',
  Small: 'SMALL'
} as const;

export type ImageFilterSizeField = typeof ImageFilterSizeField[keyof typeof ImageFilterSizeField];
export type ImatContent = Node & Timestamps & Uploadable & {
  attachment: Scalars['String']['output'];
  contentCategory?: Maybe<ImatContentCategory>;
  contentType?: Maybe<ImatContentType>;
  contentUrl: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  documentImageUrl?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  fileType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAttached: Scalars['Boolean']['output'];
  isFavourited?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  recentlyAdded: Scalars['Boolean']['output'];
  trackingUrl: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type ImatContentIsAttachedArgs = {
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

export type ImatContentCategory = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ImatContentConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<ImatContentEdge>>>;
  nodes?: Maybe<Array<Maybe<ImatContent>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ImatContentEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<ImatContent>;
};

export type ImatContentFilterObject = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  otherCategory?: InputMaybe<Scalars['Boolean']['input']>;
  otherType?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['ID']['input']>;
};

export type ImatContentType = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ImportLoyalistDataError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ImportLoyalistDataInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ImportLoyalistDataPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ImportLoyalistDataError;
  migrationStatus?: Maybe<MigrationStatus>;
};

export type InAppPatientNotification = Node & {
  createdAt: Scalars['DateTime']['output'];
  dismissedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inAppPatientNotificationType: InAppPatientNotificationType;
  metadata?: Maybe<Scalars['JSON']['output']>;
  notificationable?: Maybe<InAppPatientNotificationableTypeUnion>;
  patient?: Maybe<Patient>;
  readAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InAppPatientNotificationConnection = {
  edges?: Maybe<Array<Maybe<InAppPatientNotificationEdge>>>;
  nodes?: Maybe<Array<Maybe<InAppPatientNotification>>>;
  pageInfo: PageInfo;
};

export type InAppPatientNotificationEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<InAppPatientNotification>;
};

export type InAppPatientNotificationFilterObject = {
  code?: InputMaybe<Array<Scalars['String']['input']>>;
  includeDismissed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InAppPatientNotificationMarkAsDismissedError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type InAppPatientNotificationMarkAsDismissedInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

export type InAppPatientNotificationMarkAsDismissedPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: InAppPatientNotificationMarkAsDismissedError;
};

export type InAppPatientNotificationMarkAsReadError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type InAppPatientNotificationMarkAsReadInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

export type InAppPatientNotificationMarkAsReadPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: InAppPatientNotificationMarkAsReadError;
};

export type InAppPatientNotificationType = {
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type InAppPatientNotificationableTypeUnion = TreatmentPlan;

export type Ingredient = LegacyId & Node & Timestamps & {
  _legacyId: Scalars['Int']['output'];
  children: IngredientConnection;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parents: Array<Ingredient>;
  synonyms: Array<Ingredient>;
  unitOne?: Maybe<Scalars['String']['output']>;
  unitTwo?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type IngredientChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IngredientAttributes = {
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageUnit?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type IngredientConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<IngredientEdge>>>;
  nodes?: Maybe<Array<Maybe<Ingredient>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IngredientEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Ingredient>;
};

export type IngredientFilterObject = {
  ingredientId?: InputMaybe<Scalars['ID']['input']>;
  topLevel?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IngredientSearchAddons = {
  dose?: InputMaybe<Scalars['Float']['input']>;
  maxDose?: InputMaybe<Scalars['Float']['input']>;
  minDose?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<IngredientUnitAddon>;
};

export type IngredientSearchAttributes = {
  addon?: InputMaybe<IngredientSearchAddons>;
  id: Scalars['ID']['input'];
};

export type IngredientSort = {
  direction: SortDirection;
  field: IngredientSortField;
};

export const IngredientSortField = {
  Name: 'NAME',
  SearchPopularity: 'SEARCH_POPULARITY'
} as const;

export type IngredientSortField = typeof IngredientSortField[keyof typeof IngredientSortField];
export type IngredientSuggestionAttributes = {
  includeDemographics?: InputMaybe<Scalars['Boolean']['input']>;
  labBiomarkerResultIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  medicationProductIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientId: Scalars['ID']['input'];
  pubMedConditionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplementPlanProductIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type IngredientSuggestions = {
  hasRecentLabResults: Scalars['Boolean']['output'];
  labBiomarkerResults: Array<LabBiomarkerResult>;
  medications: Array<Medication>;
  patient: Patient;
  pubMedConditions: Array<WholePersonPlanPubMedCondition>;
  supplementPlanProducts: Array<SupplementPlanProductType>;
};

export type IngredientUnitAddon = {
  unitOne?: InputMaybe<Scalars['String']['input']>;
  unitTwo?: InputMaybe<Scalars['String']['input']>;
};

export type InitiateMigrationError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type InitiateMigrationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type InitiateMigrationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: InitiateMigrationError;
  migrationStatus?: Maybe<MigrationStatus>;
};

export type IntegrationAuthorizationConsent = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  patientsCount?: Maybe<Scalars['Int']['output']>;
  phone: Scalars['String']['output'];
  practiceName: Scalars['String']['output'];
  providersCount?: Maybe<Scalars['Int']['output']>;
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type IntegrationAuthorizationConsentAttributes = {
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  oauthApplicationId: Scalars['ID']['input'];
  patientsCount?: InputMaybe<Scalars['Int']['input']>;
  phone: Scalars['String']['input'];
  practiceName: Scalars['String']['input'];
  providersCount?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationAuthorizationConsentsCreateError = {
  fields?: Maybe<IntegrationAuthorizationConsentsCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type IntegrationAuthorizationConsentsCreateFieldError = {
  metadata?: Maybe<Array<Scalars['String']['output']>>;
  oauthApplicationId?: Maybe<Array<Scalars['String']['output']>>;
  patientsCount?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  practiceName?: Maybe<Array<Scalars['String']['output']>>;
  providersCount?: Maybe<Array<Scalars['String']['output']>>;
};

export type IntegrationAuthorizationConsentsCreateInput = {
  attributes: IntegrationAuthorizationConsentAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type IntegrationAuthorizationConsentsCreatePayload = {
  authorizationConsent?: Maybe<IntegrationAuthorizationConsent>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: IntegrationAuthorizationConsentsCreateError;
};

export type IntegrationCategory = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IntegrationListing = Timestamps & {
  additionalInstructions?: Maybe<Scalars['String']['output']>;
  categories: Array<IntegrationCategory>;
  connectionUrl: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isConnected: Scalars['Boolean']['output'];
  keyFeatures?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  modalities: Array<IntegrationModality>;
  name: Scalars['String']['output'];
  oauthApplication: OauthApplication;
  oauthApplicationId: Scalars['Int']['output'];
  organization: Scalars['String']['output'];
  screenshotUrls: Array<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type IntegrationListingConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<IntegrationListingEdge>>>;
  nodes?: Maybe<Array<Maybe<IntegrationListing>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IntegrationListingEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<IntegrationListing>;
};

export type IntegrationModality = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InventoryUnit = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lineItem: LineItem;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variant: Variant;
};

export type InvoiceAddress = Timestamps & {
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  default?: Maybe<Scalars['Boolean']['output']>;
  defaultPatientAddress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  stateId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zipcode: Scalars['String']['output'];
};

export type InvoiceAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type LabAddTestToPlanAttributes = {
  labTestId: Scalars['ID']['input'];
  parentTestId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

export type LabBiomarkerResult = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  recommendedIngredients: Array<RecommendedIngredient>;
  unit?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type LabFavoritesAttributes = {
  labTestId: Scalars['ID']['input'];
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export type LabFulfillmentLabBiomarkerResultFilterObject = {
  biomarkerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  biomarkerName?: InputMaybe<Scalars['String']['input']>;
  healthCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  markerStatuses?: InputMaybe<Array<BiomarkerResultRangeCategories>>;
};

export type LabFulfillmentsAuthorization = Node & Timestamps & {
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  errorText?: Maybe<Scalars['String']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  fulfillmentRecommendation: LabFulfillmentsRecommendation;
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabFulfillmentsBiomarkerCategoryOptimality = {
  labBiomarkerCategory: LabsBiomarkerCategory;
  optimality: LabFulfillmentsBiomarkerResultGroupOptimality;
};

export type LabFulfillmentsBiomarkerResult = Node & Timestamps & {
  allResults: Array<LabFulfillmentsBiomarkerResult>;
  amended: Scalars['Boolean']['output'];
  biomarker?: Maybe<LabsBiomarker>;
  biomarkerResultInterpretation: LabFulfillmentsBiomarkerResultInterpretation;
  /** @deprecated Use biomarker_result_interpretation instead. */
  biomarkerResultInterpretations: Array<LabFulfillmentsBiomarkerResultInterpretation>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  labResult: LabFulfillmentsLabResult;
  previousResult?: Maybe<LabFulfillmentsBiomarkerResult>;
  rawData: Scalars['String']['output'];
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
  valueType: Scalars['String']['output'];
};

export type LabFulfillmentsBiomarkerResultConnection = {
  edges?: Maybe<Array<Maybe<LabFulfillmentsBiomarkerResultEdge>>>;
  nodes?: Maybe<Array<Maybe<LabFulfillmentsBiomarkerResult>>>;
  pageInfo: PageInfo;
};

export type LabFulfillmentsBiomarkerResultEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<LabFulfillmentsBiomarkerResult>;
};

export type LabFulfillmentsBiomarkerResultGroupOptimality = {
  numberOptimal: Scalars['Int']['output'];
  numberOutOfRange: Scalars['Int']['output'];
  numberSuboptimal: Scalars['Int']['output'];
};

export type LabFulfillmentsBiomarkerResultInterpretation = Node & Timestamps & {
  areaOfConcern: Scalars['Boolean']['output'];
  biomarkerResult: LabFulfillmentsBiomarkerResult;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  labResultInterpretation: LabFulfillmentsLabResultInterpretation;
  matchedRange?: Maybe<LabsBiomarkerResultRange>;
  note?: Maybe<Scalars['String']['output']>;
  noteIsEdited: Scalars['Boolean']['output'];
  rangeGroup: LabsBiomarkerResultRangeGroup;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabFulfillmentsInvocation = Node & Timestamps & {
  action: Scalars['String']['output'];
  attempts: Scalars['Int']['output'];
  awaitingResponseAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  errorText?: Maybe<Scalars['String']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  queuedAt?: Maybe<Scalars['DateTime']['output']>;
  recordingUrl?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabFulfillmentsLabResult = Node & Timestamps & {
  amended: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  critical: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  latestLabResultInterpretation?: Maybe<LabFulfillmentsLabResultInterpretation>;
  notes?: Maybe<Scalars['String']['output']>;
  resultPdfUrl?: Maybe<Scalars['String']['output']>;
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabFulfillmentsLabResultInterpretation = Node & Timestamps & {
  categoryOptimalities: Array<LabFulfillmentsBiomarkerCategoryOptimality>;
  createdAt: Scalars['DateTime']['output'];
  flaggedBiomarkerResults?: Maybe<Array<LabFulfillmentsBiomarkerResult>>;
  id: Scalars['ID']['output'];
  isReleased: Scalars['Boolean']['output'];
  isSummaryFullscriptGenerated: Scalars['Boolean']['output'];
  /** @deprecated Use `paginatedLabBiomarkerResults` instead */
  labBiomarkerResults: Array<LabFulfillmentsBiomarkerResult>;
  outOfRangeConsentAt?: Maybe<Scalars['DateTime']['output']>;
  outOfRangeConsentRequired: Scalars['Boolean']['output'];
  paginatedLabBiomarkerResults: LabFulfillmentsBiomarkerResultConnection;
  patientAge: Scalars['Int']['output'];
  patientBiologicalSex: Scalars['String']['output'];
  patientLactatingStatus?: Maybe<Scalars['Boolean']['output']>;
  patientPregnancyStatus?: Maybe<Scalars['Boolean']['output']>;
  patientSmokingStatus?: Maybe<Scalars['Boolean']['output']>;
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  totalOptimality: LabFulfillmentsBiomarkerResultGroupOptimality;
  updatedAt: Scalars['DateTime']['output'];
};


export type LabFulfillmentsLabResultInterpretationCategoryOptimalitiesArgs = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type LabFulfillmentsLabResultInterpretationLabBiomarkerResultsArgs = {
  filters?: InputMaybe<LabFulfillmentLabBiomarkerResultFilterObject>;
};


export type LabFulfillmentsLabResultInterpretationPaginatedLabBiomarkerResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LabFulfillmentLabBiomarkerResultFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type LabFulfillmentsLineItem = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  labFulfillmentRecommendation: LabFulfillmentsRecommendation;
  labResults?: Maybe<Array<LabFulfillmentsLabResult>>;
  spreeLineItem: LineItem;
  state: LineItemStates;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabFulfillmentsRecommendation = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  labFulfillmentAuthorization?: Maybe<LabFulfillmentsAuthorization>;
  labFulfillmentLineItems?: Maybe<Array<LabFulfillmentsLineItem>>;
  labRecommendation: LabsRecommendation;
  latestLabResult?: Maybe<LabFulfillmentsLabResult>;
  order?: Maybe<Order>;
  requiresAuthorization: Scalars['Boolean']['output'];
  requisitionFormUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabPanelAttributes = {
  instructions?: InputMaybe<Scalars['String']['input']>;
  labPanelId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  requiresFasting?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabPanelTestAttributes = {
  labPanelId: Scalars['ID']['input'];
  labTestId: Scalars['ID']['input'];
};

export type LabPractitionerSettingsUpdateAttributes = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  licenseState?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type LabRecommendationAttributes = {
  instructions?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  requiresFasting?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabRecommendationCustomFeeAttributes = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LabRecommendationDeleteAttributes = {
  labRecommendationId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

export type LabRecommendationDeleteTestAttributes = {
  labTestId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

export type LabRecommendationFilterObject = {
  actionRequired?: InputMaybe<Scalars['Boolean']['input']>;
  ordered?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<RecommendationFilterTypeObject>;
};

export type LabRecommendationSort = {
  direction: SortDirection;
  field: LabRecommendationSortField;
};

export const LabRecommendationSortField = {
  AvailableAt: 'AVAILABLE_AT',
  CreatedAt: 'CREATED_AT',
  RecentlyUpdated: 'RECENTLY_UPDATED',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type LabRecommendationSortField = typeof LabRecommendationSortField[keyof typeof LabRecommendationSortField];
export type LabTestSearchFilterObject = {
  biomarkerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  favoritesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  healthCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  phlebotomyNotRequired?: InputMaybe<Scalars['Boolean']['input']>;
  phlebotomyRequired?: InputMaybe<Scalars['Boolean']['input']>;
  sampleTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  testTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LabTestSearchSort = {
  direction: SortDirection;
  field: LabTestSearchSortField;
};

export const LabTestSearchSortField = {
  Alphabetical: 'ALPHABETICAL',
  BestMatch: 'BEST_MATCH',
  Popularity: 'POPULARITY'
} as const;

export type LabTestSearchSortField = typeof LabTestSearchSortField[keyof typeof LabTestSearchSortField];
export type LabTypeahead = {
  entityType: Scalars['String']['output'];
  entityTypeId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LabTypeaheadGroupType = {
  data: Array<LabTypeahead>;
  entityType: Scalars['String']['output'];
};

export type LabsBiomarker = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  highDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  includedInTests: Array<Scalars['Boolean']['output']>;
  labBiomarkerCategory?: Maybe<LabsBiomarkerCategory>;
  lowDescription?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type LabsBiomarkerIncludedInTestsArgs = {
  testIds: Array<Scalars['ID']['input']>;
};

export type LabsBiomarkerCategory = {
  biomarkers?: Maybe<Array<LabsBiomarker>>;
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LabsBiomarkerCategoryConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<BiomarkerCategoryForJourneyVariantEdge>>>;
  nodes?: Maybe<Array<Maybe<LabsBiomarkerCategory>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LabsBiomarkerConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<LabsBiomarkerEdge>>>;
  nodes?: Maybe<Array<Maybe<LabsBiomarker>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LabsBiomarkerEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<LabsBiomarker>;
};

export type LabsBiomarkerResultRange = Node & Timestamps & {
  category: BiomarkerResultRangeCategories;
  createdAt: Scalars['DateTime']['output'];
  defaultInterpretation?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  rangeGroup: LabsBiomarkerResultRangeGroup;
  rangeMax?: Maybe<Scalars['String']['output']>;
  rangeMin?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsBiomarkerResultRangeGroup = Node & Timestamps & {
  ageMax?: Maybe<Scalars['Int']['output']>;
  ageMin?: Maybe<Scalars['Int']['output']>;
  biologicalSex?: Maybe<Scalars['String']['output']>;
  biomarker: LabsBiomarker;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  interpretations: Array<LabFulfillmentsBiomarkerResultInterpretation>;
  lactatingStatus: Scalars['Boolean']['output'];
  pregnancyStatus: Scalars['Boolean']['output'];
  ranges: Array<LabsBiomarkerResultRange>;
  references: Array<LabsBiomarkerResultRangeGroupReference>;
  smokingStatus: Scalars['Boolean']['output'];
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  valueType: Scalars['String']['output'];
};

export type LabsBiomarkerResultRangeGroupReference = Node & Timestamps & {
  authors: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  rangeGroup: LabsBiomarkerResultRangeGroup;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type LabsCompany = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  requisitionFee?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsHealthCategory = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsJourneysOptionValue = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsJourneysOrder = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isOrdered: Scalars['Boolean']['output'];
  journeySubscription: LabsJourneysSubscription;
  order?: Maybe<Order>;
  orderDate: Scalars['Date']['output'];
  recommendations: Array<LabsRecommendation>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsJourneysSubscription = Node & Timestamps & {
  brand: Brand;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledBy?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  frequency: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  initialRecommendation?: Maybe<LabsRecommendation>;
  labJourneyVariant: LabsJourneysVariant;
  nextOrderDate?: Maybe<Scalars['Date']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  orders: Array<LabsJourneysOrder>;
  patient: Patient;
  practitioner: Practitioner;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsJourneysVariant = Node & Timestamps & {
  /** @deprecated Use categories instead */
  biomarkerCategories: Array<LabsBiomarkerCategory>;
  biomarkerCount: Scalars['Int']['output'];
  canOrder: OrderingPermissions;
  categories: LabsBiomarkerCategoryConnection;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  optionValues: Array<LabsJourneysOptionValue>;
  patientInstructions?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  tests: Array<LabsTest>;
  updatedAt: Scalars['DateTime']['output'];
};


export type LabsJourneysVariantCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export const LabsLicenseStatuses = {
  FullyLicensed: 'fully_licensed',
  PartiallyLicensed: 'partially_licensed',
  Unlicensed: 'unlicensed'
} as const;

export type LabsLicenseStatuses = typeof LabsLicenseStatuses[keyof typeof LabsLicenseStatuses];
export type LabsOnboardingAcceptAgreementError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type LabsOnboardingAcceptAgreementInput = {
  agreementType: PractitionerAgreementTypes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  docuSignEnvelopeId?: InputMaybe<Scalars['String']['input']>;
};

export type LabsOnboardingAcceptAgreementPayload = {
  agreement?: Maybe<PractitionerAgreement>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: LabsOnboardingAcceptAgreementError;
  practitioner?: Maybe<Practitioner>;
};

export type LabsOnboardingGenerateBaaAgreementError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type LabsOnboardingGenerateBaaAgreementInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type LabsOnboardingGenerateBaaAgreementPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  docuSignEnvelopeId?: Maybe<Scalars['String']['output']>;
  errors: LabsOnboardingGenerateBaaAgreementError;
  practitioner?: Maybe<Practitioner>;
  url?: Maybe<Scalars['String']['output']>;
};

export const LabsOrderingPermissionLevels = {
  AllExceptRegistrationRequiredTests: 'all_except_registration_required_tests',
  AllTests: 'all_tests',
  NetworkRequiredForAllTests: 'network_required_for_all_tests',
  NetworkRequiredForSomeTests: 'network_required_for_some_tests',
  NoTests: 'no_tests',
  UnrestrictedTestsOnly: 'unrestricted_tests_only'
} as const;

export type LabsOrderingPermissionLevels = typeof LabsOrderingPermissionLevels[keyof typeof LabsOrderingPermissionLevels];
export type LabsPanel = Node & Timestamps & {
  authNetworkFee?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  practitioner: Practitioner;
  price?: Maybe<Scalars['Float']['output']>;
  priceCurrency?: Maybe<Scalars['Currency']['output']>;
  requiresFasting: Scalars['Boolean']['output'];
  requisitionFee?: Maybe<Scalars['Float']['output']>;
  tests: Array<LabsTest>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsPanelConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<LabsPanelEdge>>>;
  nodes?: Maybe<Array<Maybe<LabsPanel>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LabsPanelEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<LabsPanel>;
};

export type LabsRecommendation = Node & Timestamps & {
  authNetworkFee?: Maybe<Scalars['Float']['output']>;
  availableAt?: Maybe<Scalars['DateTime']['output']>;
  canPatientOrder: Scalars['Boolean']['output'];
  canSaveAsPanel: Scalars['Boolean']['output'];
  company: LabsCompany;
  companyRequisitionFee: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  customFee?: Maybe<LabsRecommendationCustomFee>;
  events: Array<LabsRecommendationEvent>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  isFutureJourney: Scalars['Boolean']['output'];
  isJourney: Scalars['Boolean']['output'];
  journeyBrandName?: Maybe<Scalars['String']['output']>;
  journeyOrder?: Maybe<LabsJourneysOrder>;
  journeySubscription?: Maybe<LabsJourneysSubscription>;
  labFulfillmentRecommendation?: Maybe<LabFulfillmentsRecommendation>;
  latestStateTransitionedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  patientFriendlyState: PatientRecommendationStates;
  practitionerFriendlyState: PractitionerRecommendationStates;
  price?: Maybe<Scalars['Float']['output']>;
  priceCurrency?: Maybe<Scalars['Currency']['output']>;
  recommendationTests?: Maybe<Array<LabsRecommendationTest>>;
  requiresFasting?: Maybe<Scalars['Boolean']['output']>;
  subTotal?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use recommendationTests instead */
  tests: Array<LabsTest>;
  treatmentPlan: TreatmentPlan;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsRecommendationConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<LabsRecommendationEdge>>>;
  nodes?: Maybe<Array<Maybe<LabsRecommendation>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LabsRecommendationCustomFee = {
  amount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type LabsRecommendationEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<LabsRecommendation>;
};

export type LabsRecommendationEvent = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  eventType: RecommendationEventTypes;
  fulfillmentAuthorization?: Maybe<LabFulfillmentsAuthorization>;
  fulfillmentLabResult?: Maybe<LabFulfillmentsLabResult>;
  fulfillmentRecommendation?: Maybe<LabFulfillmentsRecommendation>;
  id: Scalars['ID']['output'];
  order?: Maybe<Order>;
  shipment?: Maybe<Shipment>;
  treatmentPlan?: Maybe<TreatmentPlan>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsRecommendationTest = Timestamps & {
  childTests?: Maybe<Array<LabsTest>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  recommendation: LabsRecommendation;
  test: LabsTest;
  topLevelTest: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsSampleType = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsTest = LegacyId & Node & Timestamps & {
  _legacyId: Scalars['Int']['output'];
  biomarkers?: Maybe<Array<LabsBiomarker>>;
  canOrder: OrderingPermissions;
  company: LabsCompany;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  estimatedDaysForResults?: Maybe<Scalars['String']['output']>;
  externalTestId: Scalars['String']['output'];
  healthCategories: Array<LabsHealthCategory>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isFavorited: Scalars['Boolean']['output'];
  isShippable: Scalars['Boolean']['output'];
  minAge?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  referenceRanges?: Maybe<Scalars['String']['output']>;
  requiresAtHomeSampleCollection: Scalars['Boolean']['output'];
  requiresFasting?: Maybe<Scalars['Boolean']['output']>;
  requiresPhlebotomy: Scalars['Boolean']['output'];
  sampleReportUrl?: Maybe<Scalars['String']['output']>;
  sampleTypes: Array<LabsSampleType>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  testPreparation: Scalars['String']['output'];
  testType?: Maybe<LabsTestType>;
  tests?: Maybe<Array<LabsTest>>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  variant: Variant;
  videoInstructionsUrl?: Maybe<Scalars['String']['output']>;
};


export type LabsTestCanOrderArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};


export type LabsTestIsFavoritedArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export type LabsTestConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<LabsTestEdge>>>;
  nodes?: Maybe<Array<Maybe<LabsTest>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LabsTestEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<LabsTest>;
};

export type LabsTestType = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsTreatmentPlanSigningPractitioner = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  practitioner?: Maybe<Practitioner>;
  treatmentPlan: TreatmentPlan;
  updatedAt: Scalars['DateTime']['output'];
};

export type LegacyId = {
  _legacyId: Scalars['Int']['output'];
};

export type LibraryFilterObject = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['ID']['input']>;
};

export type LineItem = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  draftAutoshipVariant?: Maybe<SubscriptionsVariant>;
  id: Scalars['ID']['output'];
  labRecommendationTest?: Maybe<LabsRecommendationTest>;
  msrpPrice?: Maybe<Scalars['Float']['output']>;
  msrpTotal?: Maybe<Scalars['Float']['output']>;
  obligations?: Maybe<Array<LineItemObligation>>;
  price: Scalars['Float']['output'];
  priceTotal: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
  selectedTimedSupplyOption?: Maybe<TimedSupplyOption>;
  timedSupplyInformation?: Maybe<TimedSupplyInformation>;
  timedSupplyOptions?: Maybe<Array<TimedSupplyOption>>;
  updatedAt: Scalars['DateTime']['output'];
  variant: Variant;
};

export type LineItemObligation = Timestamps & {
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  feeType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  obligationType: Scalars['String']['output'];
  taxable?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const LineItemStates = {
  Approved: 'approved',
  Canceled: 'canceled',
  PartialResults: 'partial_results',
  Pending: 'pending',
  PendingApproval: 'pending_approval',
  Processing: 'processing',
  Rejected: 'rejected',
  RequisitionReady: 'requisition_ready',
  ResultsReady: 'results_ready',
  Settled: 'settled'
} as const;

export type LineItemStates = typeof LineItemStates[keyof typeof LineItemStates];
export type ManageDynamicDailyPackError = {
  fields?: Maybe<ManageDynamicDailyPackFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ManageDynamicDailyPackFieldError = {
  dailyPackId?: Maybe<Array<Scalars['String']['output']>>;
  multiPatientPlanId?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTemplateName?: Maybe<Array<Scalars['String']['output']>>;
  recommendationId?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  variantAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

export type ManageDynamicDailyPackInput = {
  attributes: OfficeDynamicDailyPackAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ManageDynamicDailyPackPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: ManageDynamicDailyPackError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type ManageStaticDailyPackError = {
  fields?: Maybe<ManageStaticDailyPackFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ManageStaticDailyPackFieldError = {
  dailyPackId?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
  variantAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

export type ManageStaticDailyPackInput = {
  attributes: OfficeStaticDailyPackAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ManageStaticDailyPackPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: ManageStaticDailyPackError;
};

export type ManageTemplateDailyPackError = {
  fields?: Maybe<ManageTemplateDailyPackFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ManageTemplateDailyPackFieldError = {
  dailyPackId?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
  variantAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

export type ManageTemplateDailyPackInput = {
  attributes: OfficeTemplateDailyPackAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ManageTemplateDailyPackPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: ManageTemplateDailyPackError;
};

export type MapAgreement = Node & Timestamps & {
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  agreementType: AgreementTypes;
  agreementUrl?: Maybe<Scalars['String']['output']>;
  brandsMapAgreements: Array<BrandsMapAgreement>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  statusForStore?: Maybe<UsersMapAgreementStatusTypes>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MassAssignPayeeError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type MassAssignPayeeInput = {
  attributablePractitionerId: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderIds: Array<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

export type MassAssignPayeePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<MassAssignPayeeError>;
  orders?: Maybe<Array<EarningsReportOrder>>;
};

export type Medication = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  recommendedIngredients: Array<RecommendedIngredient>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicationProduct = {
  id: Scalars['ID']['output'];
  ingredients: Scalars['String']['output'];
};

export type MedicationProductIngredients = {
  id: Scalars['ID']['output'];
  ingredients: Scalars['String']['output'];
};

export type MedicationsTypeahead = {
  brandName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  genericName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `ids` instead */
  id: Scalars['ID']['output'];
  ids: Array<Scalars['ID']['output']>;
};

export type MenuCategory = {
  filterType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subCategories?: Maybe<Array<MenuSubCategory>>;
};

export const MenuCategoryType = {
  Dtp: 'DTP',
  Emerson: 'EMERSON',
  Patient: 'PATIENT',
  Wholesale: 'WHOLESALE'
} as const;

export type MenuCategoryType = typeof MenuCategoryType[keyof typeof MenuCategoryType];
export type MenuSubCategory = {
  filterType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHighlighted?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<MenuSubCategoryItem>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MenuSubCategoryItem = {
  filterType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  targetUrl?: Maybe<Scalars['String']['output']>;
};

export type MerchantData = Node & Timestamps & {
  accountType: Scalars['String']['output'];
  birthDay?: Maybe<Scalars['String']['output']>;
  birthMonth?: Maybe<Scalars['String']['output']>;
  birthYear?: Maybe<Scalars['String']['output']>;
  businessAddress?: Maybe<BaseAddress>;
  businessName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use spree_stores.business_number instead. */
  businessNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  classification?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  employerId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceAddress?: Maybe<InvoiceAddress>;
  isEquityOwner?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastVerifiedTaxInformationAt?: Maybe<Scalars['DateTime']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  store: Store;
  streetAddress?: Maybe<Scalars['String']['output']>;
  streetAddress2?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MerchantDataAddressAttributes = {
  accountType?: InputMaybe<Scalars['String']['input']>;
  bankAccountHeldBy?: InputMaybe<Scalars['String']['input']>;
  bankAccountHolder?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankAccountType?: InputMaybe<Scalars['String']['input']>;
  bankRoutingNumber?: InputMaybe<Scalars['String']['input']>;
  businessAddress?: InputMaybe<BusinessAddressAttributes>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  directors?: InputMaybe<Array<StripePersonAttributes>>;
  email?: InputMaybe<Scalars['String']['input']>;
  employerId?: InputMaybe<Scalars['String']['input']>;
  equityOwners?: InputMaybe<Array<StripePersonAttributes>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  invoiceAddress?: InputMaybe<InvoiceAddressAttributes>;
  isEquityOwner?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  region: Scalars['String']['input'];
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  useBusinessAddressAsInvoiceAddress?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MerchantDataAddressConfirmationAttributes = {
  phone: Scalars['String']['input'];
  region: Scalars['String']['input'];
};

export type MerchantDataAddressUpdateInput = {
  attributes: MerchantDataAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantDataAddressUpdatePayload = {
  bankAccount?: Maybe<BankAccount>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeSettingsPaymentsAndTaxesErrors>;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
};

export type MerchantDataFinancialAttributes = {
  accountType: Scalars['String']['input'];
  businessName?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  employerId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantDataFinancialUpdateError = {
  fields?: Maybe<MerchantDataFinancialUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MerchantDataFinancialUpdateFieldError = {
  accountType?: Maybe<Array<Scalars['String']['output']>>;
  businessName?: Maybe<Array<Scalars['String']['output']>>;
  classification?: Maybe<Array<Scalars['String']['output']>>;
  dateOfBirth?: Maybe<Array<Scalars['String']['output']>>;
  employerId?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  ssn?: Maybe<Array<Scalars['String']['output']>>;
};

export type MerchantDataFinancialUpdateInput = {
  attributes: MerchantDataFinancialAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantDataFinancialUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MerchantDataFinancialUpdateError;
  merchantData: MerchantData;
};

export type MerchantDataSsnAttributes = {
  ssn: Scalars['String']['input'];
};

export type MerchantDataSsnUpdateError = {
  fields?: Maybe<MerchantDataSsnUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MerchantDataSsnUpdateFieldError = {
  ssn?: Maybe<Array<Scalars['String']['output']>>;
};

export type MerchantDataSsnUpdateInput = {
  attributes: MerchantDataSsnAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantDataSsnUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MerchantDataSsnUpdateError;
  merchantData: MerchantData;
};

export const MigrationBanners = {
  Favorites: 'FAVORITES',
  PatientList: 'PATIENT_LIST',
  Protocols: 'PROTOCOLS'
} as const;

export type MigrationBanners = typeof MigrationBanners[keyof typeof MigrationBanners];
export type MigrationBannersVisibility = {
  favorites: Scalars['Boolean']['output'];
  patientList: Scalars['Boolean']['output'];
  protocols: Scalars['Boolean']['output'];
};

export type MigrationStatus = Node & Timestamps & {
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  cutoffAt?: Maybe<Scalars['DateTime']['output']>;
  expectedFavoriteProductsCount?: Maybe<Scalars['Int']['output']>;
  expectedOrdersCount?: Maybe<Scalars['Int']['output']>;
  expectedPatientsCount?: Maybe<Scalars['Int']['output']>;
  expectedProtocolsCount?: Maybe<Scalars['Int']['output']>;
  expectedTreatmentPlansCount?: Maybe<Scalars['Int']['output']>;
  hasWellevatePatients: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  importedFavoriteCategoryId?: Maybe<Scalars['ID']['output']>;
  importedFavoriteProductsCount?: Maybe<Scalars['Int']['output']>;
  importedPatientsCount?: Maybe<Scalars['Int']['output']>;
  importedProtocolsCount?: Maybe<Scalars['Int']['output']>;
  migrationBannersVisibility?: Maybe<MigrationBannersVisibility>;
  segmentValue: Scalars['String']['output'];
  status: MigrationStatuses;
  updatedAt: Scalars['DateTime']['output'];
  wellevateMigrationErrors?: Maybe<Array<WellevateMigrationErrorType>>;
};

export const MigrationStatuses = {
  Completed: 'COMPLETED',
  Failed: 'FAILED',
  Initiated: 'INITIATED',
  InProgress: 'IN_PROGRESS',
  Pending: 'PENDING'
} as const;

export type MigrationStatuses = typeof MigrationStatuses[keyof typeof MigrationStatuses];
export type ModifyError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ModifyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dailyPackId: Scalars['ID']['input'];
  packType: PackTypes;
};

export type ModifyPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: ModifyError;
};

export type MoveToSetupCompleteAttributes = {
  storePlatform: Scalars['String']['input'];
};

export type MultiIngredientDailyIntake = {
  remainingIngredients?: Maybe<Array<PlanIngredient>>;
  sharedIngredients?: Maybe<Array<PlanIngredient>>;
};

export type MultiPatientPlan = Attachable & Node & Timestamps & {
  aggregatedIngredients: Array<PlanIngredient>;
  allMultiPatientPlansPatients: Array<MultiPatientPlansPatient>;
  availableAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  documentAttachments: DocumentAttachmentConnection;
  draftDynamicDailyPack?: Maybe<DraftDynamicDailyPack>;
  id: Scalars['ID']['output'];
  isPatientFavorited: Scalars['Boolean']['output'];
  isValidDailyPacks: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
  multiPatientPlansPatients: MultiPatientPlansPatientConnection;
  name: Scalars['String']['output'];
  patientShareableUrl?: Maybe<Scalars['String']['output']>;
  planOptimizationCategories?: Maybe<Array<PlanOptimizationCategory>>;
  planType: Scalars['String']['output'];
  practitioner: Practitioner;
  productsWithoutIngredients: Array<Scalars['String']['output']>;
  recommendations: Array<Recommendation>;
  shareableWithPatients: Scalars['String']['output'];
  shouldNotifyPatients: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  source: Scalars['String']['output'];
  sourcePlatform: Scalars['String']['output'];
  totalActiveRecommendations: Scalars['Int']['output'];
  totalDiscountedPrice: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  totalSavings: Scalars['Float']['output'];
  treatmentPlanPdfUrl: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type MultiPatientPlanDocumentAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderByAddedAt?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MultiPatientPlanMultiPatientPlansPatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type MultiPatientPlanRecommendationsArgs = {
  filterObject?: InputMaybe<RecommendationFilterObject>;
};

export type MultiPatientPlanConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<MultiPatientPlanEdge>>>;
  nodes?: Maybe<Array<Maybe<MultiPatientPlan>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MultiPatientPlanEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<MultiPatientPlan>;
};

export type MultiPatientPlanFilterObject = {
  nameQuery?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<MultiPatientPlanFilters>>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

export const MultiPatientPlanFilters = {
  ScopeActive: 'SCOPE_ACTIVE'
} as const;

export type MultiPatientPlanFilters = typeof MultiPatientPlanFilters[keyof typeof MultiPatientPlanFilters];
export type MultiPatientPlanSort = {
  direction: SortDirection;
  field: MultiPatientPlanSortField;
};

export const MultiPatientPlanSortField = {
  Alphabetical: 'ALPHABETICAL',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type MultiPatientPlanSortField = typeof MultiPatientPlanSortField[keyof typeof MultiPatientPlanSortField];
export type MultiPatientPlansActivateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type MultiPatientPlansActivateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
};

export type MultiPatientPlansActivatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<MultiPatientPlansActivateError>;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

export type MultiPatientPlansCreateAllAttributes = {
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MultiPatientPlansCreateAllError = {
  fields?: Maybe<MultiPatientPlansCreateAllFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MultiPatientPlansCreateAllFieldError = {
  excludedPatientIds?: Maybe<Array<Scalars['String']['output']>>;
  includeBlocked?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  query?: Maybe<Array<Scalars['String']['output']>>;
  source?: Maybe<Array<Scalars['String']['output']>>;
  sourcePlatform?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscounts?: Maybe<Array<Scalars['String']['output']>>;
  withDraftTreatmentPlan?: Maybe<Array<Scalars['String']['output']>>;
  withFirstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
};

export type MultiPatientPlansCreateAllInput = {
  attributes: MultiPatientPlansCreateAllAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type MultiPatientPlansCreateAllPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MultiPatientPlansCreateAllError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

export type MultiPatientPlansCreateAttributes = {
  attachmentsUploadableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  recommendationsAttributes?: InputMaybe<Array<OfficeRecommendationAttributes>>;
  source: Scalars['String']['input'];
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MultiPatientPlansCreateError = {
  fields?: Maybe<MultiPatientPlansCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MultiPatientPlansCreateFieldError = {
  attachmentsUploadableIds?: Maybe<Array<Scalars['String']['output']>>;
  excludedPatientIds?: Maybe<Array<Scalars['String']['output']>>;
  includeBlocked?: Maybe<Array<Scalars['String']['output']>>;
  patientIds?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  query?: Maybe<Array<Scalars['String']['output']>>;
  recommendationsAttributes?: Maybe<Array<Scalars['String']['output']>>;
  source?: Maybe<Array<Scalars['String']['output']>>;
  sourcePlatform?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscounts?: Maybe<Array<Scalars['String']['output']>>;
  withDraftTreatmentPlan?: Maybe<Array<Scalars['String']['output']>>;
  withFirstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
};

export type MultiPatientPlansCreateInput = {
  attributes: MultiPatientPlansCreateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type MultiPatientPlansCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MultiPatientPlansCreateError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

export type MultiPatientPlansDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type MultiPatientPlansDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
};

export type MultiPatientPlansDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<MultiPatientPlansDeleteError>;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

export type MultiPatientPlansDraftResetError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type MultiPatientPlansDraftResetInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
};

export type MultiPatientPlansDraftResetPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MultiPatientPlansDraftResetError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

export type MultiPatientPlansPatient = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  patient: Patient;
  updatedAt: Scalars['DateTime']['output'];
};

export type MultiPatientPlansPatientConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<MultiPatientPlansPatientEdge>>>;
  nodes?: Maybe<Array<Maybe<MultiPatientPlansPatient>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MultiPatientPlansPatientEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<MultiPatientPlansPatient>;
};

export type MultiPatientPlansUpdateAccessAttributes = {
  allCurrentPatients?: InputMaybe<Scalars['Boolean']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
  notifyPatients?: InputMaybe<Scalars['Boolean']['input']>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientIdsToAdd?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientIdsToRemove?: InputMaybe<Array<Scalars['ID']['input']>>;
  sharingLevel: Scalars['String']['input'];
};

export type MultiPatientPlansUpdateAccessError = {
  fields?: Maybe<MultiPatientPlansUpdateAccessFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MultiPatientPlansUpdateAccessFieldError = {
  allCurrentPatients?: Maybe<Array<Scalars['String']['output']>>;
  multiPatientPlanId?: Maybe<Array<Scalars['String']['output']>>;
  notifyPatients?: Maybe<Array<Scalars['String']['output']>>;
  patientIds?: Maybe<Array<Scalars['String']['output']>>;
  patientIdsToAdd?: Maybe<Array<Scalars['String']['output']>>;
  patientIdsToRemove?: Maybe<Array<Scalars['String']['output']>>;
  sharingLevel?: Maybe<Array<Scalars['String']['output']>>;
};

export type MultiPatientPlansUpdateAccessInput = {
  attributes: MultiPatientPlansUpdateAccessAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type MultiPatientPlansUpdateAccessPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MultiPatientPlansUpdateAccessError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

export type MultiPatientPlansUpdateAttributes = {
  multiPatientPlanId: Scalars['ID']['input'];
  planMessage?: InputMaybe<Scalars['String']['input']>;
  planName?: InputMaybe<Scalars['String']['input']>;
};

export type MultiPatientPlansUpdateError = {
  fields?: Maybe<MultiPatientPlansUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MultiPatientPlansUpdateFieldError = {
  multiPatientPlanId?: Maybe<Array<Scalars['String']['output']>>;
  planMessage?: Maybe<Array<Scalars['String']['output']>>;
  planName?: Maybe<Array<Scalars['String']['output']>>;
};

export type MultiPatientPlansUpdateInput = {
  attributes: MultiPatientPlansUpdateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type MultiPatientPlansUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MultiPatientPlansUpdateError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

export type MultiSourceProduct = Node & {
  brandName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  internal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  servingSize?: Maybe<Scalars['Float']['output']>;
  servingUnit: Scalars['String']['output'];
};

export type MultiSourceProductConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<MultiSourceProductEdge>>>;
  nodes?: Maybe<Array<Maybe<MultiSourceProduct>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MultiSourceProductEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<MultiSourceProduct>;
};

export type Mutation = {
  analyticsLogEvent?: Maybe<AnalyticsLogEventPayload>;
  analyticsStartExperiment?: Maybe<AnalyticsStartExperimentPayload>;
  analyticsUpdateProfile?: Maybe<AnalyticsUpdateProfilePayload>;
  androidAddToWaitlist?: Maybe<AndroidAddToWaitlistPayload>;
  applePayPlaceOrder?: Maybe<ApplePayPlaceOrderPayload>;
  applePayReset?: Maybe<ApplePayResetPayload>;
  applePaySelectShippingRate?: Maybe<ApplePaySelectShippingRatePayload>;
  applePayUpdateShippingAddress?: Maybe<ApplePayUpdateShippingAddressPayload>;
  auth?: Maybe<AuthMutation>;
  autoshipCancel?: Maybe<AutoshipCancelPayload>;
  autoshipGroupOrderNow?: Maybe<AutoshipGroupOrderNowPayload>;
  autoshipOrderNow?: Maybe<AutoshipOrderNowPayload>;
  autoshipReschedule?: Maybe<AutoshipReschedulePayload>;
  autoshipRescheduleGroup?: Maybe<AutoshipRescheduleGroupPayload>;
  autoshipSkipNextGroupOrder?: Maybe<AutoshipSkipNextGroupOrderPayload>;
  autoshipSkipNextOrder?: Maybe<AutoshipSkipNextOrderPayload>;
  bankAccountActivate?: Maybe<BankAccountActivatePayload>;
  bankAccountCreate?: Maybe<BankAccountCreatePayload>;
  commentaryCreate?: Maybe<CommentaryCreatePayload>;
  commentaryUpdate?: Maybe<CommentaryUpdatePayload>;
  copilot?: Maybe<CopilotMutation>;
  copilotAudioChunkCreate?: Maybe<CopilotAudioChunkCreatePayload>;
  copilotAudioSessionEnd?: Maybe<CopilotAudioSessionEndPayload>;
  copilotAudioSessionStart?: Maybe<CopilotAudioSessionStartPayload>;
  copilotCreateResources?: Maybe<CopilotCreateResourcesPayload>;
  copilotCreateSummary?: Maybe<CopilotCreateSummaryPayload>;
  copilotCreateSummaryMessage?: Maybe<CreateSummaryMessagePayload>;
  copilotCreateSupplements?: Maybe<CopilotCreateSupplementsPayload>;
  copilotUploadTranscript?: Maybe<CopilotUploadTranscriptPayload>;
  createProductAccessRequest?: Maybe<CreateProductAccessRequestPayload>;
  creditCardDelete?: Maybe<CreditCardDeletePayload>;
  creditCardSetDefault?: Maybe<CreditCardSetDefaultPayload>;
  draftAutoshipVariant?: Maybe<DraftAutoshipVariantPayload>;
  eDeliveryConsent1099kUpdate?: Maybe<EDeliveryConsent1099kUpdatePayload>;
  emersonNotificationEmailUpdate?: Maybe<PractitionerEmersonNotificationEmailUpdatePayload>;
  emersonUserAdditionalInfoMigration?: Maybe<EmersonUserAdditionalInfoMigrationPayload>;
  enableSharedAccount?: Maybe<EnableSharedAccountPayload>;
  experimentCreate?: Maybe<ExperimentCreatePayload>;
  favoriteProductsAdd?: Maybe<FavoriteProductsAddPayload>;
  favoriteProductsRemove?: Maybe<FavoriteProductsRemovePayload>;
  flipperUpdate?: Maybe<FlipperUpdatePayload>;
  fsAssist?: Maybe<FsAssistMutation>;
  fsExperienceUpgradeStatus?: Maybe<FsExperienceUpgradeStatusUpdatePayload>;
  genAi?: Maybe<GenAiMutation>;
  globalPatientPromotionUpdate?: Maybe<GlobalPatientPromotionUpdatePayload>;
  inAppPatientNotificationMarkAsDismissed?: Maybe<InAppPatientNotificationMarkAsDismissedPayload>;
  inAppPatientNotificationMarkAsRead?: Maybe<InAppPatientNotificationMarkAsReadPayload>;
  massAssignPayee?: Maybe<MassAssignPayeePayload>;
  merchantDataAddressUpdate?: Maybe<MerchantDataAddressUpdatePayload>;
  merchantDataFinancialUpdate?: Maybe<MerchantDataFinancialUpdatePayload>;
  merchantDataSsnUpdate?: Maybe<MerchantDataSsnUpdatePayload>;
  notificationSettingUnsubscribeAll?: Maybe<NotificationSettingUnsubscribeAllPayload>;
  office?: Maybe<OfficeMutation>;
  patient?: Maybe<PatientMutation>;
  patientAddressCreate?: Maybe<PatientAddressCreatePayload>;
  patientAddressDelete?: Maybe<PatientAddressDeletePayload>;
  patientAddressUpdate?: Maybe<PatientAddressUpdatePayload>;
  patientBrowseAbandonTrigger?: Maybe<PatientBrowseAbandonTriggerPayload>;
  patientCartAddToCart?: Maybe<PatientCartAddToCartPayload>;
  patientCartReset?: Maybe<PatientCartResetPayload>;
  patientCartUpdate?: Maybe<PatientCartUpdatePayload>;
  patientCreateCreditCard?: Maybe<PatientCreateCreditCardPayload>;
  patientCreateRefund?: Maybe<PatientCreateRefundPayload>;
  patientFunnelLoggerCreate?: Maybe<PatientFunnelLoggerPayload>;
  patientIntakeSendRequest?: Maybe<PatientIntakeSendRequestPayload>;
  patientIntakeUpdateStatus?: Maybe<PatientIntakeUpdateStatusPayload>;
  patientNotificationSettingPushInitiate?: Maybe<PatientNotificationSettingPushInitiatePayload>;
  patientNotificationSettingPushUpdate?: Maybe<PatientNotificationSettingPushUpdatePayload>;
  patientNotificationSettingSubscribeAll?: Maybe<PatientNotificationSettingSubscribeAllPayload>;
  patientNotificationSettingToggleAll?: Maybe<PatientNotificationSettingToggleAllPayload>;
  patientNotificationSettingUnsubscribeAll?: Maybe<PatientNotificationSettingUnsubscribeAllPayload>;
  patientNotificationSettingUpdate?: Maybe<PatientNotificationSettingUpdatePayload>;
  patientRefundShipping?: Maybe<PatientRefundShippingPayload>;
  patientUpdate?: Maybe<PatientUpdatePayload>;
  patientUpdateIterableLastAppLogin?: Maybe<PatientUpdateIterableLastAppLoginPayload>;
  patientVariantNotificationCreate?: Maybe<PatientVariantNotificationPayload>;
  patientVariantNotificationDelete?: Maybe<PatientVariantNotificationDeletePayload>;
  platformRedirect?: Maybe<PlatformRedirectPayload>;
  practitionerCertificationCreate?: Maybe<PractitionerCertificationCreatePayload>;
  practitionerCertificationUpdate?: Maybe<PractitionerCertificationUpdatePayload>;
  practitionerOnboardingChecklistLogger?: Maybe<OnboardingChecklistLoggerPayload>;
  practitionerSignUp?: Maybe<PractitionerSignUpPayload>;
  practitionerTypeUpdate?: Maybe<PractitionerTypeUpdatePayload>;
  practitionerVariantNotificationCreate?: Maybe<PractitionerVariantNotificationPayload>;
  practitionerVariantNotificationDelete?: Maybe<PractitionerVariantNotificationDeletePayload>;
  recentlyViewedProductsAdd?: Maybe<RecentlyViewedProductsAddPayload>;
  recentlyViewedProductsClear?: Maybe<RecentlyViewedProductsClearPayload>;
  recommendationTherapeuticSwitchingRequest?: Maybe<TherapeuticSwitchingRequestPayload>;
  retailCheckoutAddToCart?: Maybe<RetailCheckoutAddToCartPayload>;
  retailCheckoutPlaceOrder?: Maybe<RetailCheckoutPlaceOrderPayload>;
  retailCheckoutPrevalidate?: Maybe<RetailCheckoutPrevalidatePayload>;
  retailCheckoutProp65Swap?: Maybe<RetailCheckoutProp65SwapPayload>;
  retailCheckoutRemoveLab?: Maybe<RetailCheckoutRemoveLabPayload>;
  retailCheckoutSelectShippingAddress?: Maybe<RetailCheckoutSelectShippingAddressPayload>;
  retailCheckoutSelectShippingRate?: Maybe<RetailCheckoutSelectShippingRatePayload>;
  retailCheckoutUpdateCart?: Maybe<RetailCheckoutUpdateCartPayload>;
  retailCheckoutUpdateShippingAddress?: Maybe<RetailCheckoutUpdateShippingAddressPayload>;
  savePatientDefaultAddress?: Maybe<SavePatientDefaultAddressPayload>;
  searchHistoriesClear?: Maybe<SearchHistoriesClearPayload>;
  searchHistoriesDelete?: Maybe<SearchHistoriesDeletePayload>;
  searchHistoriesSave?: Maybe<SearchHistoriesSavePayload>;
  shipStateUpdate?: Maybe<ShipStateUpdatePayload>;
  shippingAddressCreate?: Maybe<ShippingAddressCreatePayload>;
  shippingAddressDelete?: Maybe<ShippingAddressDeletePayload>;
  shippingAddressUpdate?: Maybe<ShippingAddressUpdatePayload>;
  storeAdditionalDiscountsUpdate?: Maybe<StoreAdditionalDiscountsUpdatePayload>;
  storeAddressCreate?: Maybe<StoreAddressCreatePayload>;
  storeAddressDelete?: Maybe<StoreAddressDeletePayload>;
  storeAddressNewUpdate?: Maybe<StoreAddressNewUpdatePayload>;
  /** @deprecated Use store_address_new_update instead. */
  storeAddressUpdate?: Maybe<StoreAddressUpdatePayload>;
  storeCurrentSetupPageUpdate?: Maybe<StoreCurrentSetupPageUpdatePayload>;
  storeProfitMarginUpdate?: Maybe<StoreProfitMarginUpdatePayload>;
  storeResetToAccountStepUpdate?: Maybe<StoreResetToAccountStepUpdatePayload>;
  storesLabCustomFeeAddToStore?: Maybe<StoreLabCustomFeesAddToStorePayload>;
  storesLabCustomFeeApplyToPlan?: Maybe<StoreLabCustomFeesApplyToPlanPayload>;
  storesLabCustomFeeRemoveFromStore?: Maybe<StoreLabCustomFeesRemoveFromStorePayload>;
  storesLabCustomFeeUpdate?: Maybe<StoreLabCustomFeesUpdatePayload>;
  storesLabSigningPractitionerAddToStore?: Maybe<StoreLabSigningPractitionersAddToStorePayload>;
  storesLabSigningPractitionerRemoveFromStore?: Maybe<StoreLabSigningPractitionersRemoveFromStorePayload>;
  storesLandingPageUpdate?: Maybe<StoreLandingPageUpdatePayload>;
  storesPatientPromotionUpdate?: Maybe<StoresPatientPromotionUpdatePayload>;
  studentProfileCreate?: Maybe<StudentProfileCreatePayload>;
  subscriptionAddressesCreate?: Maybe<SubscriptionAddressesCreatePayload>;
  subscriptionCreate?: Maybe<SubscriptionCreatePayload>;
  subscriptionCreateCreditCard?: Maybe<SubscriptionCreateCreditCardPayload>;
  subscriptionProp65Swap?: Maybe<SubscriptionProp65SwapPayload>;
  subscriptionScheduleMultiple?: Maybe<SubscriptionScheduleMultiplePayload>;
  subscriptionUpdate?: Maybe<SubscriptionUpdatePayload>;
  subscriptionsGroupUpdateShippingMethod?: Maybe<SubscriptionsGroupUpdatePayload>;
  subscriptionsVariantCreate?: Maybe<SubscriptionsVariantCreatePayload>;
  subscriptionsVariantFrequencyUpdate?: Maybe<SubscriptionsVariantFrequencyUpdatePayload>;
  subscriptionsVariantUpdate?: Maybe<SubscriptionsVariantUpdatePayload>;
  supplementPlanAddCustomMedication?: Maybe<SupplementPlanAddCustomMedicationPayload>;
  supplementPlanAddCustomProduct?: Maybe<SupplementPlanAddCustomProductPayload>;
  supplementPlanAddProduct?: Maybe<SupplementPlanAddProductPayload>;
  supplementPlanAddProducts?: Maybe<SupplementPlanAddProductsPayload>;
  supplementPlanArchiveProduct?: Maybe<SupplementPlanArchiveProductPayload>;
  supplementPlanUpdateProduct?: Maybe<SupplementPlanUpdateProductPayload>;
  toursUsersCreate?: Maybe<ToursUsersCreatePayload>;
  toursUsersCreateOrUpdate?: Maybe<ToursUsersCreateOrUpdatePayload>;
  treatmentPlanCancel?: Maybe<TreatmentPlanCancelPayload>;
  treatmentPlanDelete?: Maybe<TreatmentPlanDeletePayload>;
  treatmentPlanSendReminder?: Maybe<TreatmentPlanSendReminderPayload>;
  treatmentPlanTemplateCancel?: Maybe<TreatmentPlanTemplateCancelPayload>;
  treatmentPlanTemplateLogCopyUrl?: Maybe<TreatmentPlanTemplateLogCopyUrlPayload>;
  userAvatarCreate?: Maybe<UserAvatarCreatePayload>;
  userAvatarDelete?: Maybe<UserAvatarDeletePayload>;
  userChangeStore?: Maybe<UserChangeStorePayload>;
  userNotificationSettingUpdate?: Maybe<UserNotificationSettingUpdatePayload>;
  userPasswordUpdate?: Maybe<UserPasswordUpdatePayload>;
  userSecurityFieldsUpdate?: Maybe<UserSecurityFieldsUpdatePayload>;
  userSignIn?: Maybe<UserSignInPayload>;
  userVariantNotificationDelete?: Maybe<UserVariantNotificationDeletePayload>;
  usersMapAgreementCreate?: Maybe<UsersMapAgreementCreatePayload>;
  wellevateNewAccountEmail?: Maybe<WellevateNewAccountEmailPayload>;
  wholesaleAddressUpdate?: Maybe<WholesaleAddressUpdatePayload>;
  wholesaleCartAddToCart?: Maybe<WholesaleCartAddToCartPayload>;
  wholesaleCartAdminCreate?: Maybe<WholesaleCartAdminCreatePayload>;
  wholesaleCartAdminDelete?: Maybe<WholesaleCartAdminDeletePayload>;
  wholesaleCartAdminSelect?: Maybe<WholesaleCartAdminSelectPayload>;
  wholesaleCartConfirm?: Maybe<WholesaleCartConfirmPayload>;
  wholesaleCartPoNumberUpdate?: Maybe<WholesaleCartPoNumberUpdatePayload>;
  wholesaleCartProp65Swap?: Maybe<WholesaleCartProp65SwapPayload>;
  wholesaleCartReset?: Maybe<WholesaleCartResetPayload>;
  wholesaleCartUpdate?: Maybe<WholesaleCartUpdatePayload>;
  wholesalePaymentUpdate?: Maybe<WholesalePaymentUpdatePayload>;
  wholesalePromotionApply?: Maybe<WholesalePromotionApplyPayload>;
  wholesalePromotionRemove?: Maybe<WholesalePromotionRemovePayload>;
  wholesaleRequestBackorderCancellation?: Maybe<WholesaleRequestBackorderCancellationPayload>;
  wholesaleShippingAddressSelect?: Maybe<WholesaleShippingAddressSelectPayload>;
  wholesaleShippingRateUpdate?: Maybe<WholesaleShippingRateUpdatePayload>;
  zendeskPatientDelete?: Maybe<ZendeskPatientDeletePayload>;
};


export type MutationAnalyticsLogEventArgs = {
  input: AnalyticsLogEventInput;
};


export type MutationAnalyticsStartExperimentArgs = {
  input: AnalyticsStartExperimentInput;
};


export type MutationAnalyticsUpdateProfileArgs = {
  input: AnalyticsUpdateProfileInput;
};


export type MutationAndroidAddToWaitlistArgs = {
  input: AndroidAddToWaitlistInput;
};


export type MutationApplePayPlaceOrderArgs = {
  input: ApplePayPlaceOrderInput;
};


export type MutationApplePayResetArgs = {
  input: ApplePayResetInput;
};


export type MutationApplePaySelectShippingRateArgs = {
  input: ApplePaySelectShippingRateInput;
};


export type MutationApplePayUpdateShippingAddressArgs = {
  input: ApplePayUpdateShippingAddressInput;
};


export type MutationAutoshipCancelArgs = {
  input: AutoshipCancelInput;
};


export type MutationAutoshipGroupOrderNowArgs = {
  input: AutoshipGroupOrderNowInput;
};


export type MutationAutoshipOrderNowArgs = {
  input: AutoshipOrderNowInput;
};


export type MutationAutoshipRescheduleArgs = {
  input: AutoshipRescheduleInput;
};


export type MutationAutoshipRescheduleGroupArgs = {
  input: AutoshipRescheduleGroupInput;
};


export type MutationAutoshipSkipNextGroupOrderArgs = {
  input: AutoshipSkipNextGroupOrderInput;
};


export type MutationAutoshipSkipNextOrderArgs = {
  input: AutoshipSkipNextOrderInput;
};


export type MutationBankAccountActivateArgs = {
  input: BankAccountActivateInput;
};


export type MutationBankAccountCreateArgs = {
  input: BankAccountCreateInput;
};


export type MutationCommentaryCreateArgs = {
  input: CommentaryCreateInput;
};


export type MutationCommentaryUpdateArgs = {
  input: CommentaryUpdateInput;
};


export type MutationCopilotAudioChunkCreateArgs = {
  input: CopilotAudioChunkCreateInput;
};


export type MutationCopilotAudioSessionEndArgs = {
  input: CopilotAudioSessionEndInput;
};


export type MutationCopilotAudioSessionStartArgs = {
  input: CopilotAudioSessionStartInput;
};


export type MutationCopilotCreateResourcesArgs = {
  input: CopilotCreateResourcesInput;
};


export type MutationCopilotCreateSummaryArgs = {
  input: CopilotCreateSummaryInput;
};


export type MutationCopilotCreateSummaryMessageArgs = {
  input: CreateSummaryMessageInput;
};


export type MutationCopilotCreateSupplementsArgs = {
  input: CopilotCreateSupplementsInput;
};


export type MutationCopilotUploadTranscriptArgs = {
  input: CopilotUploadTranscriptInput;
};


export type MutationCreateProductAccessRequestArgs = {
  input: CreateProductAccessRequestInput;
};


export type MutationCreditCardDeleteArgs = {
  input: CreditCardDeleteInput;
};


export type MutationCreditCardSetDefaultArgs = {
  input: CreditCardSetDefaultInput;
};


export type MutationDraftAutoshipVariantArgs = {
  input: DraftAutoshipVariantInput;
};


export type MutationEDeliveryConsent1099kUpdateArgs = {
  input: EDeliveryConsent1099kUpdateInput;
};


export type MutationEmersonNotificationEmailUpdateArgs = {
  input: PractitionerEmersonNotificationEmailUpdateInput;
};


export type MutationEmersonUserAdditionalInfoMigrationArgs = {
  input: EmersonUserAdditionalInfoMigrationInput;
};


export type MutationEnableSharedAccountArgs = {
  input: EnableSharedAccountInput;
};


export type MutationExperimentCreateArgs = {
  input: ExperimentCreateInput;
};


export type MutationFavoriteProductsAddArgs = {
  input: FavoriteProductsAddInput;
};


export type MutationFavoriteProductsRemoveArgs = {
  input: FavoriteProductsRemoveInput;
};


export type MutationFlipperUpdateArgs = {
  input: FlipperUpdateInput;
};


export type MutationFsExperienceUpgradeStatusArgs = {
  input: FsExperienceUpgradeStatusUpdateInput;
};


export type MutationGlobalPatientPromotionUpdateArgs = {
  input: GlobalPatientPromotionUpdateInput;
};


export type MutationInAppPatientNotificationMarkAsDismissedArgs = {
  input: InAppPatientNotificationMarkAsDismissedInput;
};


export type MutationInAppPatientNotificationMarkAsReadArgs = {
  input: InAppPatientNotificationMarkAsReadInput;
};


export type MutationMassAssignPayeeArgs = {
  input: MassAssignPayeeInput;
};


export type MutationMerchantDataAddressUpdateArgs = {
  input: MerchantDataAddressUpdateInput;
};


export type MutationMerchantDataFinancialUpdateArgs = {
  input: MerchantDataFinancialUpdateInput;
};


export type MutationMerchantDataSsnUpdateArgs = {
  input: MerchantDataSsnUpdateInput;
};


export type MutationNotificationSettingUnsubscribeAllArgs = {
  input: NotificationSettingUnsubscribeAllInput;
};


export type MutationPatientAddressCreateArgs = {
  input: PatientAddressCreateInput;
};


export type MutationPatientAddressDeleteArgs = {
  input: PatientAddressDeleteInput;
};


export type MutationPatientAddressUpdateArgs = {
  input: PatientAddressUpdateInput;
};


export type MutationPatientBrowseAbandonTriggerArgs = {
  input: PatientBrowseAbandonTriggerInput;
};


export type MutationPatientCartAddToCartArgs = {
  input: PatientCartAddToCartInput;
};


export type MutationPatientCartResetArgs = {
  input: PatientCartResetInput;
};


export type MutationPatientCartUpdateArgs = {
  input: PatientCartUpdateInput;
};


export type MutationPatientCreateCreditCardArgs = {
  input: PatientCreateCreditCardInput;
};


export type MutationPatientCreateRefundArgs = {
  input: PatientCreateRefundInput;
};


export type MutationPatientFunnelLoggerCreateArgs = {
  input: PatientFunnelLoggerInput;
};


export type MutationPatientIntakeSendRequestArgs = {
  input: PatientIntakeSendRequestInput;
};


export type MutationPatientIntakeUpdateStatusArgs = {
  input: PatientIntakeUpdateStatusInput;
};


export type MutationPatientNotificationSettingPushInitiateArgs = {
  input: PatientNotificationSettingPushInitiateInput;
};


export type MutationPatientNotificationSettingPushUpdateArgs = {
  input: PatientNotificationSettingPushUpdateInput;
};


export type MutationPatientNotificationSettingSubscribeAllArgs = {
  input: PatientNotificationSettingSubscribeAllInput;
};


export type MutationPatientNotificationSettingToggleAllArgs = {
  input: PatientNotificationSettingToggleAllInput;
};


export type MutationPatientNotificationSettingUnsubscribeAllArgs = {
  input: PatientNotificationSettingUnsubscribeAllInput;
};


export type MutationPatientNotificationSettingUpdateArgs = {
  input: PatientNotificationSettingUpdateInput;
};


export type MutationPatientRefundShippingArgs = {
  input: PatientRefundShippingInput;
};


export type MutationPatientUpdateArgs = {
  input: PatientUpdateInput;
};


export type MutationPatientUpdateIterableLastAppLoginArgs = {
  input: PatientUpdateIterableLastAppLoginInput;
};


export type MutationPatientVariantNotificationCreateArgs = {
  input: PatientVariantNotificationInput;
};


export type MutationPatientVariantNotificationDeleteArgs = {
  input: PatientVariantNotificationDeleteInput;
};


export type MutationPlatformRedirectArgs = {
  input: PlatformRedirectInput;
};


export type MutationPractitionerCertificationCreateArgs = {
  input: PractitionerCertificationCreateInput;
};


export type MutationPractitionerCertificationUpdateArgs = {
  input: PractitionerCertificationUpdateInput;
};


export type MutationPractitionerOnboardingChecklistLoggerArgs = {
  input: OnboardingChecklistLoggerInput;
};


export type MutationPractitionerSignUpArgs = {
  input: PractitionerSignUpInput;
};


export type MutationPractitionerTypeUpdateArgs = {
  input: PractitionerTypeUpdateInput;
};


export type MutationPractitionerVariantNotificationCreateArgs = {
  input: PractitionerVariantNotificationInput;
};


export type MutationPractitionerVariantNotificationDeleteArgs = {
  input: PractitionerVariantNotificationDeleteInput;
};


export type MutationRecentlyViewedProductsAddArgs = {
  input: RecentlyViewedProductsAddInput;
};


export type MutationRecentlyViewedProductsClearArgs = {
  input: RecentlyViewedProductsClearInput;
};


export type MutationRecommendationTherapeuticSwitchingRequestArgs = {
  input: TherapeuticSwitchingRequestInput;
};


export type MutationRetailCheckoutAddToCartArgs = {
  input: RetailCheckoutAddToCartInput;
};


export type MutationRetailCheckoutPlaceOrderArgs = {
  input: RetailCheckoutPlaceOrderInput;
};


export type MutationRetailCheckoutPrevalidateArgs = {
  input: RetailCheckoutPrevalidateInput;
};


export type MutationRetailCheckoutProp65SwapArgs = {
  input: RetailCheckoutProp65SwapInput;
};


export type MutationRetailCheckoutRemoveLabArgs = {
  input: RetailCheckoutRemoveLabInput;
};


export type MutationRetailCheckoutSelectShippingAddressArgs = {
  input: RetailCheckoutSelectShippingAddressInput;
};


export type MutationRetailCheckoutSelectShippingRateArgs = {
  input: RetailCheckoutSelectShippingRateInput;
};


export type MutationRetailCheckoutUpdateCartArgs = {
  input: RetailCheckoutUpdateCartInput;
};


export type MutationRetailCheckoutUpdateShippingAddressArgs = {
  input: RetailCheckoutUpdateShippingAddressInput;
};


export type MutationSavePatientDefaultAddressArgs = {
  input: SavePatientDefaultAddressInput;
};


export type MutationSearchHistoriesClearArgs = {
  input: SearchHistoriesClearInput;
};


export type MutationSearchHistoriesDeleteArgs = {
  input: SearchHistoriesDeleteInput;
};


export type MutationSearchHistoriesSaveArgs = {
  input: SearchHistoriesSaveInput;
};


export type MutationShipStateUpdateArgs = {
  input: ShipStateUpdateInput;
};


export type MutationShippingAddressCreateArgs = {
  input: ShippingAddressCreateInput;
};


export type MutationShippingAddressDeleteArgs = {
  input: ShippingAddressDeleteInput;
};


export type MutationShippingAddressUpdateArgs = {
  input: ShippingAddressUpdateInput;
};


export type MutationStoreAdditionalDiscountsUpdateArgs = {
  input: StoreAdditionalDiscountsUpdateInput;
};


export type MutationStoreAddressCreateArgs = {
  input: StoreAddressCreateInput;
};


export type MutationStoreAddressDeleteArgs = {
  input: StoreAddressDeleteInput;
};


export type MutationStoreAddressNewUpdateArgs = {
  input: StoreAddressNewUpdateInput;
};


export type MutationStoreAddressUpdateArgs = {
  input: StoreAddressUpdateInput;
};


export type MutationStoreCurrentSetupPageUpdateArgs = {
  input: StoreCurrentSetupPageUpdateInput;
};


export type MutationStoreProfitMarginUpdateArgs = {
  input: StoreProfitMarginUpdateInput;
};


export type MutationStoreResetToAccountStepUpdateArgs = {
  input: StoreResetToAccountStepUpdateInput;
};


export type MutationStoresLabCustomFeeAddToStoreArgs = {
  input: StoreLabCustomFeesAddToStoreInput;
};


export type MutationStoresLabCustomFeeApplyToPlanArgs = {
  input: StoreLabCustomFeesApplyToPlanInput;
};


export type MutationStoresLabCustomFeeRemoveFromStoreArgs = {
  input: StoreLabCustomFeesRemoveFromStoreInput;
};


export type MutationStoresLabCustomFeeUpdateArgs = {
  input: StoreLabCustomFeesUpdateInput;
};


export type MutationStoresLabSigningPractitionerAddToStoreArgs = {
  input: StoreLabSigningPractitionersAddToStoreInput;
};


export type MutationStoresLabSigningPractitionerRemoveFromStoreArgs = {
  input: StoreLabSigningPractitionersRemoveFromStoreInput;
};


export type MutationStoresLandingPageUpdateArgs = {
  input: StoreLandingPageUpdateInput;
};


export type MutationStoresPatientPromotionUpdateArgs = {
  input: StoresPatientPromotionUpdateInput;
};


export type MutationStudentProfileCreateArgs = {
  input: StudentProfileCreateInput;
};


export type MutationSubscriptionAddressesCreateArgs = {
  input: SubscriptionAddressesCreateInput;
};


export type MutationSubscriptionCreateArgs = {
  input: SubscriptionCreateInput;
};


export type MutationSubscriptionCreateCreditCardArgs = {
  input: SubscriptionCreateCreditCardInput;
};


export type MutationSubscriptionProp65SwapArgs = {
  input: SubscriptionProp65SwapInput;
};


export type MutationSubscriptionScheduleMultipleArgs = {
  input: SubscriptionScheduleMultipleInput;
};


export type MutationSubscriptionUpdateArgs = {
  input: SubscriptionUpdateInput;
};


export type MutationSubscriptionsGroupUpdateShippingMethodArgs = {
  input: SubscriptionsGroupUpdateInput;
};


export type MutationSubscriptionsVariantCreateArgs = {
  input: SubscriptionsVariantCreateInput;
};


export type MutationSubscriptionsVariantFrequencyUpdateArgs = {
  input: SubscriptionsVariantFrequencyUpdateInput;
};


export type MutationSubscriptionsVariantUpdateArgs = {
  input: SubscriptionsVariantUpdateInput;
};


export type MutationSupplementPlanAddCustomMedicationArgs = {
  input: SupplementPlanAddCustomMedicationInput;
};


export type MutationSupplementPlanAddCustomProductArgs = {
  input: SupplementPlanAddCustomProductInput;
};


export type MutationSupplementPlanAddProductArgs = {
  input: SupplementPlanAddProductInput;
};


export type MutationSupplementPlanAddProductsArgs = {
  input: SupplementPlanAddProductsInput;
};


export type MutationSupplementPlanArchiveProductArgs = {
  input: SupplementPlanArchiveProductInput;
};


export type MutationSupplementPlanUpdateProductArgs = {
  input: SupplementPlanUpdateProductInput;
};


export type MutationToursUsersCreateArgs = {
  input: ToursUsersCreateInput;
};


export type MutationToursUsersCreateOrUpdateArgs = {
  input: ToursUsersCreateOrUpdateInput;
};


export type MutationTreatmentPlanCancelArgs = {
  input: TreatmentPlanCancelInput;
};


export type MutationTreatmentPlanDeleteArgs = {
  input: TreatmentPlanDeleteInput;
};


export type MutationTreatmentPlanSendReminderArgs = {
  input: TreatmentPlanSendReminderInput;
};


export type MutationTreatmentPlanTemplateCancelArgs = {
  input: TreatmentPlanTemplateCancelInput;
};


export type MutationTreatmentPlanTemplateLogCopyUrlArgs = {
  input: TreatmentPlanTemplateLogCopyUrlInput;
};


export type MutationUserAvatarCreateArgs = {
  input: UserAvatarCreateInput;
};


export type MutationUserAvatarDeleteArgs = {
  input: UserAvatarDeleteInput;
};


export type MutationUserChangeStoreArgs = {
  input: UserChangeStoreInput;
};


export type MutationUserNotificationSettingUpdateArgs = {
  input: UserNotificationSettingUpdateInput;
};


export type MutationUserPasswordUpdateArgs = {
  input: UserPasswordUpdateInput;
};


export type MutationUserSecurityFieldsUpdateArgs = {
  input: UserSecurityFieldsUpdateInput;
};


export type MutationUserSignInArgs = {
  input: UserSignInInput;
};


export type MutationUserVariantNotificationDeleteArgs = {
  input: UserVariantNotificationDeleteInput;
};


export type MutationUsersMapAgreementCreateArgs = {
  input: UsersMapAgreementCreateInput;
};


export type MutationWellevateNewAccountEmailArgs = {
  input: WellevateNewAccountEmailInput;
};


export type MutationWholesaleAddressUpdateArgs = {
  input: WholesaleAddressUpdateInput;
};


export type MutationWholesaleCartAddToCartArgs = {
  input: WholesaleCartAddToCartInput;
};


export type MutationWholesaleCartAdminCreateArgs = {
  input: WholesaleCartAdminCreateInput;
};


export type MutationWholesaleCartAdminDeleteArgs = {
  input: WholesaleCartAdminDeleteInput;
};


export type MutationWholesaleCartAdminSelectArgs = {
  input: WholesaleCartAdminSelectInput;
};


export type MutationWholesaleCartConfirmArgs = {
  input: WholesaleCartConfirmInput;
};


export type MutationWholesaleCartPoNumberUpdateArgs = {
  input: WholesaleCartPoNumberUpdateInput;
};


export type MutationWholesaleCartProp65SwapArgs = {
  input: WholesaleCartProp65SwapInput;
};


export type MutationWholesaleCartResetArgs = {
  input: WholesaleCartResetInput;
};


export type MutationWholesaleCartUpdateArgs = {
  input: WholesaleCartUpdateInput;
};


export type MutationWholesalePaymentUpdateArgs = {
  input: WholesalePaymentUpdateInput;
};


export type MutationWholesalePromotionApplyArgs = {
  input: WholesalePromotionApplyInput;
};


export type MutationWholesalePromotionRemoveArgs = {
  input: WholesalePromotionRemoveInput;
};


export type MutationWholesaleRequestBackorderCancellationArgs = {
  input: WholesaleRequestBackorderCancellationInput;
};


export type MutationWholesaleShippingAddressSelectArgs = {
  input: WholesaleShippingAddressSelectInput;
};


export type MutationWholesaleShippingRateUpdateArgs = {
  input: WholesaleShippingRateUpdateInput;
};


export type MutationZendeskPatientDeleteArgs = {
  input: ZendeskPatientDeleteInput;
};

export type NextAutoRefillDate = {
  frequency: Scalars['String']['output'];
  nextAutoRefillDate: Scalars['Date']['output'];
};

export type NextStep = {
  neverMargin?: Maybe<Scalars['String']['output']>;
  variableMargin?: Maybe<Scalars['String']['output']>;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type NotificationSettingType = Node & Timestamps & {
  category: Scalars['String']['output'];
  channel: Scalars['String']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dispensarySpecific: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NotificationSettingUnsubscribeAllError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type NotificationSettingUnsubscribeAllInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationSettingUnsubscribeAllPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<NotificationSettingUnsubscribeAllError>;
  patientNotificationSettings?: Maybe<Array<PatientNotificationSetting>>;
  userNotificationSettings?: Maybe<Array<UserNotificationSetting>>;
  variantNotifications?: Maybe<Array<VariantNotification>>;
};

export type NotificationSettings = {
  notifyLabs?: Maybe<Scalars['Boolean']['output']>;
  notifyOrders?: Maybe<Scalars['Boolean']['output']>;
  notifyOutOfStock?: Maybe<Scalars['Boolean']['output']>;
  notifyPatients?: Maybe<Scalars['Boolean']['output']>;
  notifyProductAvailability?: Maybe<Scalars['String']['output']>;
  notifyShipments?: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationSettingsUpdateError = {
  fields?: Maybe<NotificationSettingsUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type NotificationSettingsUpdateFieldError = {
  notifyLabs?: Maybe<Array<Scalars['String']['output']>>;
  notifyOrders?: Maybe<Array<Scalars['String']['output']>>;
  notifyOutOfStock?: Maybe<Array<Scalars['String']['output']>>;
  notifyPatients?: Maybe<Array<Scalars['String']['output']>>;
  notifyProductAvailability?: Maybe<Array<Scalars['String']['output']>>;
  notifyShipments?: Maybe<Array<Scalars['String']['output']>>;
};

export type NotificationSettingsUpdateInput = {
  attributes: PractitionerNotificationSettingsAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationSettingsUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: NotificationSettingsUpdateError;
  practitioner?: Maybe<Practitioner>;
};

export type NpLineItem = Node & Timestamps & {
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  itemDescription: Scalars['String']['output'];
  npOnly: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
  totalShipped: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variant?: Maybe<Variant>;
};

export type NpOrder = Node & Timestamps & {
  billingAddress?: Maybe<Address>;
  completedAt: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  itemTotal: Scalars['Float']['output'];
  lineItems: Array<NpLineItem>;
  number: Scalars['String']['output'];
  savingsTotal: Scalars['Float']['output'];
  shippingAddress: Address;
  shippingTotal: Scalars['Float']['output'];
  state: Scalars['String']['output'];
  taxTotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  trackingNumbers?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type NpOrderConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<NpOrderEdge>>>;
  nodes?: Maybe<Array<Maybe<NpOrder>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type NpOrderEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<NpOrder>;
};

export type NpOrderFilterObject = {
  completedAt?: InputMaybe<DateRangeFilter>;
};

export type NpOrderSort = {
  direction: SortDirection;
  field: NpOrderSortField;
};

export const NpOrderSortField = {
  CompletedAt: 'COMPLETED_AT',
  Total: 'TOTAL'
} as const;

export type NpOrderSortField = typeof NpOrderSortField[keyof typeof NpOrderSortField];
export type OauthScopeDescription = {
  description: Scalars['String']['output'];
  includes?: Maybe<Array<Scalars['String']['output']>>;
  kind?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Office = Timestamps & {
  addresses: Array<UserRoleAddress>;
  createdAt: Scalars['DateTime']['output'];
  creditCards?: Maybe<Array<CreditCard>>;
  currentPatientImport?: Maybe<PatientImport>;
  cyberMondayBrands: BrandConnection;
  dailyPackPill?: Maybe<DailyPackPill>;
  dailyPackPills: DailyPackPillConnection;
  dailyPackTemplate?: Maybe<DailyPack>;
  dailyPackTemplates: DailyPackConnection;
  documents: DocumentConnection;
  draftMultiPatientPlan?: Maybe<MultiPatientPlan>;
  draftTreatmentPlan?: Maybe<TreatmentPlan>;
  dynamicDailyPack?: Maybe<DailyPack>;
  examplePatientImportCsvUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ingredientSuggestions?: Maybe<IngredientSuggestions>;
  integrationListing?: Maybe<IntegrationListing>;
  integrationListings: IntegrationListingConnection;
  isInofficeCheckoutGqlExperimentGroup: Scalars['Boolean']['output'];
  labRecommendations: LabsRecommendationConnection;
  latestDraftPlan?: Maybe<PlanUnion>;
  multiPatientPlans: MultiPatientPlanConnection;
  peerReferralProgram: PeerReferralProgram;
  plan?: Maybe<PlanUnion>;
  productSuggestions?: Maybe<ProductSuggestions>;
  promotionBrands: BrandConnection;
  /** @deprecated Use the promotionCampaigns field in ViewerType */
  promotionCampaigns: Array<PromotionCampaign>;
  staff: Array<User>;
  staticDailyPack?: Maybe<DailyPack>;
  staticDailyPacks?: Maybe<DailyPackConnection>;
  store?: Maybe<Store>;
  treatmentPlanTemplates: TreatmentPlanTemplateConnection;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  wholesaleCart?: Maybe<WholesaleOrder>;
};


export type OfficeCyberMondayBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OfficeDailyPackPillArgs = {
  id: Scalars['ID']['input'];
};


export type OfficeDailyPackPillsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DailyPackPillFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OfficeDailyPackTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type OfficeDailyPackTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DailyPackTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<DailyPackTemplateSort>>;
};


export type OfficeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  sort?: InputMaybe<Array<DocumentSort>>;
};


export type OfficeDraftTreatmentPlanArgs = {
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};


export type OfficeDynamicDailyPackArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
};


export type OfficeIngredientSuggestionsArgs = {
  attributes: IngredientSuggestionAttributes;
};


export type OfficeIntegrationListingArgs = {
  slug: Scalars['String']['input'];
};


export type OfficeIntegrationListingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OfficeLabRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LabRecommendationFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<LabRecommendationSort>>;
};


export type OfficeLatestDraftPlanArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};


export type OfficeMultiPatientPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<MultiPatientPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MultiPatientPlanSort>>;
};


export type OfficePlanArgs = {
  id: Scalars['ID']['input'];
};


export type OfficeProductSuggestionsArgs = {
  attributes: ProductSuggestionsAttributes;
};


export type OfficePromotionBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OfficeStaticDailyPackArgs = {
  id: Scalars['ID']['input'];
};


export type OfficeStaticDailyPacksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OfficeTreatmentPlanTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TreatmentPlanTemplateSort>>;
};


export type OfficeWholesaleCartArgs = {
  updatePromotions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfficeAddressCreateError = {
  fields?: Maybe<OfficeAddressCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeAddressCreateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeAddressCreateInput = {
  attributes: UserRoleAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeAddressCreatePayload = {
  address?: Maybe<UserRoleAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeAddressCreateError;
};

export type OfficeAddressDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeAddressDeleteInput = {
  addressId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeAddressDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeAddressDeleteError;
};

export type OfficeAddressUpdateError = {
  fields?: Maybe<OfficeAddressUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeAddressUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeAddressUpdateInput = {
  addressId: Scalars['ID']['input'];
  attributes: UserRoleAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeAddressUpdatePayload = {
  address?: Maybe<UserRoleAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeAddressUpdateError;
};

export type OfficeApiEmbeddableEventPatientSelectedInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalPatientId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

export type OfficeApiEmbeddableEventPatientSelectedPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: BaseError;
  fsJsEventPayload: Scalars['JSON']['output'];
};

export type OfficeBulkPatientAssignPractitionerError = {
  fields?: Maybe<OfficeBulkPatientAssignPractitionerFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeBulkPatientAssignPractitionerFieldError = {
  allPatients?: Maybe<Array<Scalars['String']['output']>>;
  excludedPatientIds?: Maybe<Array<Scalars['String']['output']>>;
  includeBlocked?: Maybe<Array<Scalars['String']['output']>>;
  patientIds?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerIdToAssign?: Maybe<Array<Scalars['String']['output']>>;
  query?: Maybe<Array<Scalars['String']['output']>>;
  sourcePlatform?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscounts?: Maybe<Array<Scalars['String']['output']>>;
  withDraftTreatmentPlan?: Maybe<Array<Scalars['String']['output']>>;
  withFirstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
  withNoAssignedPractitioner?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeBulkPatientAssignPractitionerInput = {
  attributes: BulkPatientAssignPractitionerAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeBulkPatientAssignPractitionerPayload = {
  bulkEditProcess?: Maybe<BulkEditProcess>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeBulkPatientAssignPractitionerError;
};

export type OfficeBulkPatientReassignPractitionerError = {
  fields?: Maybe<OfficeBulkPatientReassignPractitionerFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeBulkPatientReassignPractitionerFieldError = {
  allPatients?: Maybe<Array<Scalars['String']['output']>>;
  excludedPatientIds?: Maybe<Array<Scalars['String']['output']>>;
  includeBlocked?: Maybe<Array<Scalars['String']['output']>>;
  patientIds?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerIdToRemove?: Maybe<Array<Scalars['String']['output']>>;
  query?: Maybe<Array<Scalars['String']['output']>>;
  sourcePlatform?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscounts?: Maybe<Array<Scalars['String']['output']>>;
  withDraftTreatmentPlan?: Maybe<Array<Scalars['String']['output']>>;
  withFirstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
  withNoAssignedPractitioner?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeBulkPatientReassignPractitionerInput = {
  attributes: BulkPatientReassignPractitionerAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeBulkPatientReassignPractitionerPayload = {
  bulkEditProcess?: Maybe<BulkEditProcess>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeBulkPatientReassignPractitionerError>;
};

export type OfficeBulkSavingsUpdateAllError = {
  fields?: Maybe<OfficeBulkSavingsUpdateAllFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeBulkSavingsUpdateAllFieldError = {
  discount?: Maybe<Array<Scalars['String']['output']>>;
  excludedPatientIds?: Maybe<Array<Scalars['String']['output']>>;
  includeBlocked?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  query?: Maybe<Array<Scalars['String']['output']>>;
  sourcePlatform?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscounts?: Maybe<Array<Scalars['String']['output']>>;
  withDraftTreatmentPlan?: Maybe<Array<Scalars['String']['output']>>;
  withFirstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
  withNoAssignedPractitioner?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeBulkSavingsUpdateAllInput = {
  attributes: BulkSavingsUpdateAllAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeBulkSavingsUpdateAllPayload = {
  bulkEditProcess?: Maybe<BulkEditProcess>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeBulkSavingsUpdateAllError>;
};

export type OfficeBulkSavingsUpdateError = {
  fields?: Maybe<OfficeBulkSavingsUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeBulkSavingsUpdateFieldError = {
  discount?: Maybe<Array<Scalars['String']['output']>>;
  patientIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeBulkSavingsUpdateInput = {
  attributes: BulkSavingsUpdateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeBulkSavingsUpdatePayload = {
  bulkEditProcess?: Maybe<BulkEditProcess>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeBulkSavingsUpdateError>;
};

export type OfficeCatalogGetProductDailyIntakeError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeCatalogGetProductDailyIntakeInput = {
  amount: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

export type OfficeCatalogGetProductDailyIntakePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyIntake: Array<PlanIngredient>;
  errors: OfficeCatalogGetProductDailyIntakeError;
};

export type OfficeCatalogGetProductMonthlyCostError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeCatalogGetProductMonthlyCostInput = {
  amount: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

export type OfficeCatalogGetProductMonthlyCostPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeCatalogGetProductMonthlyCostError;
  monthlyCost: Scalars['Float']['output'];
};

export type OfficeCategoryDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeCategoryDeleteInput = {
  categoryId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeCategoryDeletePayload = {
  category?: Maybe<Category>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeCategoryDeleteError;
};

export type OfficeCategorySaveAttributes = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  isVisibleToPatients?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type OfficeCategorySaveError = {
  fields?: Maybe<OfficeCategorySaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeCategorySaveFieldError = {
  categoryId?: Maybe<Array<Scalars['String']['output']>>;
  isVisibleToPatients?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeCategorySaveInput = {
  attributes: OfficeCategorySaveAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeCategorySavePayload = {
  category?: Maybe<Category>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeCategorySaveError;
};

export type OfficeClerkUpdateAttributes = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeDefaultDosageInstructionAttributes = {
  defaultDosageInstructionId?: InputMaybe<Scalars['ID']['input']>;
  defaultVariantId: Scalars['ID']['input'];
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageDuration?: InputMaybe<Scalars['String']['input']>;
  dosageFormat?: InputMaybe<Scalars['String']['input']>;
  dosageFrequency?: InputMaybe<Scalars['String']['input']>;
  dosagePermutations?: InputMaybe<Scalars['String']['input']>;
  dosageQuantity?: InputMaybe<Scalars['Int']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
  withFood?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfficeDefaultDosageInstructionSaveError = {
  fields?: Maybe<OfficeDefaultDosageInstructionSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeDefaultDosageInstructionSaveFieldError = {
  defaultDosageInstructionId?: Maybe<Array<Scalars['String']['output']>>;
  defaultVariantId?: Maybe<Array<Scalars['String']['output']>>;
  dosageAmount?: Maybe<Array<Scalars['String']['output']>>;
  dosageDuration?: Maybe<Array<Scalars['String']['output']>>;
  dosageFormat?: Maybe<Array<Scalars['String']['output']>>;
  dosageFrequency?: Maybe<Array<Scalars['String']['output']>>;
  dosagePermutations?: Maybe<Array<Scalars['String']['output']>>;
  dosageQuantity?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  productId?: Maybe<Array<Scalars['String']['output']>>;
  takeWith?: Maybe<Array<Scalars['String']['output']>>;
  timeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  withFood?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeDefaultDosageInstructionSaveInput = {
  attributes: OfficeDefaultDosageInstructionAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeDefaultDosageInstructionSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  defaultDosageInstruction?: Maybe<DefaultDosageInstruction>;
  errors: OfficeDefaultDosageInstructionSaveError;
};

export type OfficeDocumentAttachmentAttributes = {
  documentAttachmentId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
  uploadableId?: InputMaybe<Scalars['ID']['input']>;
};

export type OfficeDocumentAttachmentDeleteError = {
  fields?: Maybe<OfficeDocumentAttachmentDeleteFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeDocumentAttachmentDeleteFieldError = {
  documentAttachmentId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  uploadableId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeDocumentAttachmentDeleteInput = {
  attributes: OfficeDocumentAttachmentAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeDocumentAttachmentDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentAttachment?: Maybe<DocumentAttachment>;
  errors: OfficeDocumentAttachmentDeleteError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeDocumentAttachmentSaveError = {
  fields?: Maybe<OfficeDocumentAttachmentSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeDocumentAttachmentSaveFieldError = {
  documentAttachmentId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  uploadableId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeDocumentAttachmentSaveInput = {
  attributes: OfficeDocumentAttachmentAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeDocumentAttachmentSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentAttachment?: Maybe<DocumentAttachment>;
  errors: OfficeDocumentAttachmentSaveError;
};

export type OfficeDocumentAttachmentUndoError = {
  fields?: Maybe<OfficeDocumentAttachmentUndoFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeDocumentAttachmentUndoFieldError = {
  documentAttachmentId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  uploadableId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeDocumentAttachmentUndoInput = {
  attributes: OfficeDocumentAttachmentAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeDocumentAttachmentUndoPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentAttachment?: Maybe<DocumentAttachment>;
  errors: OfficeDocumentAttachmentUndoError;
};

export type OfficeDynamicDailyPackAttributes = {
  dailyPackId?: InputMaybe<Scalars['ID']['input']>;
  multiPatientPlanId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  practitionerTemplateName?: InputMaybe<Scalars['String']['input']>;
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
  variantAttributes?: InputMaybe<Array<OfficeDynamicDailyPackVariantAttributes>>;
};

export type OfficeDynamicDailyPackVariantAttributes = {
  amDosage?: InputMaybe<Scalars['Int']['input']>;
  pmDosage?: InputMaybe<Scalars['Int']['input']>;
  variantId: Scalars['ID']['input'];
};

export type OfficeExternalMetadatumDeactivateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeExternalMetadatumDeactivateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalMetadatumId: Scalars['ID']['input'];
};

export type OfficeExternalMetadatumDeactivatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeExternalMetadatumDeactivateError;
  externalIntegration?: Maybe<ApiExternalMetadatum>;
};

export type OfficeIntegrationListingDisconnectError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeIntegrationListingDisconnectInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  integrationListingId: Scalars['ID']['input'];
};

export type OfficeIntegrationListingDisconnectPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeIntegrationListingDisconnectError;
  integrationListing?: Maybe<IntegrationListing>;
  status: Scalars['String']['output'];
};

export type OfficeLabsAddTestToPlanError = {
  fields?: Maybe<OfficeLabsAddTestToPlanFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsAddTestToPlanFieldError = {
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
  parentTestId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsAddTestToPlanInput = {
  attributes: LabAddTestToPlanAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsAddTestToPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsAddTestToPlanError;
  labRecommendation?: Maybe<LabsRecommendation>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeLabsFavoritesAddError = {
  fields?: Maybe<OfficeLabsFavoritesAddFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsFavoritesAddFieldError = {
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsFavoritesAddInput = {
  attributes: LabFavoritesAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsFavoritesAddPayload = {
  category?: Maybe<Category>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsFavoritesAddError;
  labTest?: Maybe<LabsTest>;
};

export type OfficeLabsFavoritesRemoveError = {
  fields?: Maybe<OfficeLabsFavoritesRemoveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsFavoritesRemoveFieldError = {
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsFavoritesRemoveInput = {
  attributes: LabFavoritesAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsFavoritesRemovePayload = {
  category?: Maybe<Category>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsFavoritesRemoveError;
  labTest?: Maybe<LabsTest>;
};

export type OfficeLabsJourneysAddToPlanError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsJourneysAddToPlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
  journeyVariantId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

export type OfficeLabsJourneysAddToPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysAddToPlanError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interpretationId: Scalars['ID']['input'];
};

export type OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernError;
  interpretation?: Maybe<LabFulfillmentsBiomarkerResultInterpretation>;
};

export type OfficeLabsJourneysBiomarkerResultInterpretationsUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsJourneysBiomarkerResultInterpretationsUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labBiomarkerResultInterpretationId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsJourneysBiomarkerResultInterpretationsUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysBiomarkerResultInterpretationsUpdateError;
  labBiomarkerResultInterpretation?: Maybe<LabFulfillmentsBiomarkerResultInterpretation>;
};

export type OfficeLabsJourneysCancelSubscriptionError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsJourneysCancelSubscriptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  journeySubscriptionId: Scalars['ID']['input'];
};

export type OfficeLabsJourneysCancelSubscriptionPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysCancelSubscriptionError;
  journeySubscription?: Maybe<LabsJourneysSubscription>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeLabsJourneysInterpretationsUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsJourneysInterpretationsUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labResultInterpretationId: Scalars['ID']['input'];
  summary: Scalars['String']['input'];
};

export type OfficeLabsJourneysInterpretationsUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysInterpretationsUpdateError;
  labResultInterpretation?: Maybe<LabFulfillmentsLabResultInterpretation>;
};

export type OfficeLabsJourneysLabResultInterpretationsReleaseToPatientError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsJourneysLabResultInterpretationsReleaseToPatientInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interpretationId: Scalars['ID']['input'];
  outOfRangeConsent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfficeLabsJourneysLabResultInterpretationsReleaseToPatientPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysLabResultInterpretationsReleaseToPatientError;
  interpretation?: Maybe<LabFulfillmentsLabResultInterpretation>;
};

export type OfficeLabsJourneysRemoveFromPlanError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsJourneysRemoveFromPlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  journeySubscriptionId: Scalars['ID']['input'];
};

export type OfficeLabsJourneysRemoveFromPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysRemoveFromPlanError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeLabsJourneysUpdateDetailsError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsJourneysUpdateDetailsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  journeySubscriptionId: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
};

export type OfficeLabsJourneysUpdateDetailsPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysUpdateDetailsError;
  journeySubscription?: Maybe<LabsJourneysSubscription>;
};

export type OfficeLabsJourneysUpdateFrequencyError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsJourneysUpdateFrequencyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
  journeySubscriptionId: Scalars['ID']['input'];
};

export type OfficeLabsJourneysUpdateFrequencyPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysUpdateFrequencyError;
  journeySubscription?: Maybe<LabsJourneysSubscription>;
};

export type OfficeLabsPanelsActivateError = {
  fields?: Maybe<OfficeLabsPanelsActivateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsPanelsActivateFieldError = {
  instructions?: Maybe<Array<Scalars['String']['output']>>;
  labPanelId?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  requiresFasting?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsPanelsActivateInput = {
  attributes: LabPanelAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsPanelsActivatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsActivateError;
  labPanel?: Maybe<LabsPanel>;
};

export type OfficeLabsPanelsAddTestError = {
  fields?: Maybe<OfficeLabsPanelsAddTestFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsPanelsAddTestFieldError = {
  labPanelId?: Maybe<Array<Scalars['String']['output']>>;
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsPanelsAddTestInput = {
  attributes: LabPanelTestAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsPanelsAddTestPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsAddTestError;
  labPanel?: Maybe<LabsPanel>;
};

export type OfficeLabsPanelsApplyToPlanError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsPanelsApplyToPlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labPanelId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

export type OfficeLabsPanelsApplyToPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsApplyToPlanError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeLabsPanelsCancelError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsPanelsCancelInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labPanelId: Scalars['ID']['input'];
};

export type OfficeLabsPanelsCancelPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsCancelError;
  labPanel?: Maybe<LabsPanel>;
};

export type OfficeLabsPanelsDuplicateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsPanelsDuplicateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labPanelId: Scalars['ID']['input'];
};

export type OfficeLabsPanelsDuplicatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsDuplicateError;
  labPanel?: Maybe<LabsPanel>;
};

export type OfficeLabsPanelsFindOrCreateDraftError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsPanelsFindOrCreateDraftInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export type OfficeLabsPanelsFindOrCreateDraftPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsFindOrCreateDraftError;
  labPanel?: Maybe<LabsPanel>;
};

export type OfficeLabsPanelsRemoveFromPlanError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsPanelsRemoveFromPlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labPanelId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

export type OfficeLabsPanelsRemoveFromPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsRemoveFromPlanError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeLabsPanelsRemoveTestError = {
  fields?: Maybe<OfficeLabsPanelsRemoveTestFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsPanelsRemoveTestFieldError = {
  labPanelId?: Maybe<Array<Scalars['String']['output']>>;
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsPanelsRemoveTestInput = {
  attributes: LabPanelTestAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsPanelsRemoveTestPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsRemoveTestError;
  labPanel?: Maybe<LabsPanel>;
};

export type OfficeLabsPanelsSaveError = {
  fields?: Maybe<OfficeLabsPanelsSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsPanelsSaveFieldError = {
  instructions?: Maybe<Array<Scalars['String']['output']>>;
  labPanelId?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  requiresFasting?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsPanelsSaveInput = {
  attributes: LabPanelAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsPanelsSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsSaveError;
  labPanel?: Maybe<LabsPanel>;
};

export type OfficeLabsRecommendationCustomFeesUpdateError = {
  fields?: Maybe<OfficeLabsRecommendationCustomFeesUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsRecommendationCustomFeesUpdateFieldError = {
  amount?: Maybe<Array<Scalars['String']['output']>>;
  labRecommendationId?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsRecommendationCustomFeesUpdateInput = {
  attributes: LabRecommendationCustomFeeAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsRecommendationCustomFeesUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customFee?: Maybe<LabsRecommendationCustomFee>;
  errors?: Maybe<OfficeLabsRecommendationCustomFeesUpdateError>;
  labRecommendation?: Maybe<LabsRecommendation>;
};

export type OfficeLabsRecommendationDeleteError = {
  fields?: Maybe<OfficeLabsRecommendationDeleteFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsRecommendationDeleteFieldError = {
  labRecommendationId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsRecommendationDeleteInput = {
  attributes: LabRecommendationDeleteAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsRecommendationDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationDeleteError;
  labRecommendation?: Maybe<LabsRecommendation>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeLabsRecommendationDeleteTestError = {
  fields?: Maybe<OfficeLabsRecommendationDeleteTestFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsRecommendationDeleteTestFieldError = {
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsRecommendationDeleteTestInput = {
  attributes: LabRecommendationDeleteTestAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsRecommendationDeleteTestPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationDeleteTestError;
  labRecommendation?: Maybe<LabsRecommendation>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeLabsRecommendationSaveAsPanelError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsRecommendationSaveAsPanelInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export type OfficeLabsRecommendationSaveAsPanelPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationSaveAsPanelError;
  labPanel?: Maybe<LabsPanel>;
};

export type OfficeLabsRecommendationSaveError = {
  fields?: Maybe<OfficeLabsRecommendationSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsRecommendationSaveFieldError = {
  instructions?: Maybe<Array<Scalars['String']['output']>>;
  labRecommendationId?: Maybe<Array<Scalars['String']['output']>>;
  requiresFasting?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeLabsRecommendationSaveInput = {
  attributes: LabRecommendationAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsRecommendationSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationSaveError;
  labRecommendation?: Maybe<LabsRecommendation>;
};

export type OfficeLabsRecommendationSendPatientInstructionsError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsRecommendationSendPatientInstructionsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isText?: InputMaybe<Scalars['Boolean']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  patientNumber?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsRecommendationSendPatientInstructionsPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationSendPatientInstructionsError;
};

export type OfficeLabsRecommendationSendRequisitionError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsRecommendationSendRequisitionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isText?: InputMaybe<Scalars['Boolean']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  patientNumber?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsRecommendationSendRequisitionPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationSendRequisitionError;
};

export type OfficeLabsWaitlistsJoinError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeLabsWaitlistsJoinInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeLabsWaitlistsJoinPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsWaitlistsJoinError;
  practitioner?: Maybe<Practitioner>;
};

export type OfficeMultiPatientPlanTemplateApplyError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeMultiPatientPlanTemplateApplyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type OfficeMultiPatientPlanTemplateApplyPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeMultiPatientPlanTemplateApplyError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

export type OfficeMutation = {
  addressCreate?: Maybe<OfficeAddressCreatePayload>;
  addressDelete?: Maybe<OfficeAddressDeletePayload>;
  addressUpdate?: Maybe<OfficeAddressUpdatePayload>;
  apiEmbeddableEventPatientSelected?: Maybe<OfficeApiEmbeddableEventPatientSelectedPayload>;
  bulkPatientAssignPractitioner?: Maybe<OfficeBulkPatientAssignPractitionerPayload>;
  bulkPatientReassignPractitioner?: Maybe<OfficeBulkPatientReassignPractitionerPayload>;
  bulkSavingsUpdate?: Maybe<OfficeBulkSavingsUpdatePayload>;
  bulkSavingsUpdateAll?: Maybe<OfficeBulkSavingsUpdateAllPayload>;
  bulkSwapVariant?: Maybe<OfficeProductSwappingBulkSwapVariantPayload>;
  catalogGetProductDailyIntake?: Maybe<OfficeCatalogGetProductDailyIntakePayload>;
  catalogGetProductMonthlyCost?: Maybe<OfficeCatalogGetProductMonthlyCostPayload>;
  categoryDelete?: Maybe<OfficeCategoryDeletePayload>;
  categorySave?: Maybe<OfficeCategorySavePayload>;
  clerkArchive?: Maybe<ClerkArchivePayload>;
  clerkRestore?: Maybe<ClerkRestorePayload>;
  clerkUpdate?: Maybe<ClerkUpdatePayload>;
  createTemplateDraft?: Maybe<OfficeTemplateCreateDraftPayload>;
  defaultDosageInstructionSave?: Maybe<OfficeDefaultDosageInstructionSavePayload>;
  documentAttachmentDelete?: Maybe<OfficeDocumentAttachmentDeletePayload>;
  documentAttachmentSave?: Maybe<OfficeDocumentAttachmentSavePayload>;
  documentAttachmentUndo?: Maybe<OfficeDocumentAttachmentUndoPayload>;
  draftTemplateReset?: Maybe<OfficeTemplatesDraftResetPayload>;
  draftTreatmentPlanReset?: Maybe<OfficeTreatmentPlansDraftResetPayload>;
  dynamicDailyPacksAddTemplateToPlan?: Maybe<AddDynamicDailyPackTemplateToPlanPayload>;
  dynamicDailyPacksAddToTreatmentPlan?: Maybe<AddDynamicDailyPackToTreatmentPlanPayload>;
  dynamicDailyPacksDeleteDailyPackTemplate?: Maybe<DeleteDailyPackTemplatePayload>;
  dynamicDailyPacksManage?: Maybe<ManageDynamicDailyPackPayload>;
  dynamicDailyPacksModifyType?: Maybe<ModifyPayload>;
  dynamicDailyPacksSaveDailyPackTemplate?: Maybe<SaveDailyPackTemplatePayload>;
  elationLinkPatient?: Maybe<ElationLinkPatientPayload>;
  elationLinkPractitioner?: Maybe<ElationLinkPractitionerPayload>;
  externalMetadatumDeactivate?: Maybe<OfficeExternalMetadatumDeactivatePayload>;
  favoriteProductAdd?: Maybe<FavoriteProductAddPayload>;
  favoriteProductRemove?: Maybe<FavoriteProductRemovePayload>;
  integrationAuthorizationConsentsCreate?: Maybe<IntegrationAuthorizationConsentsCreatePayload>;
  integrationListingDisconnect?: Maybe<OfficeIntegrationListingDisconnectPayload>;
  labFulfillmentsLabBiomarkerResultInterpretationToggleAreaOfConcern?: Maybe<OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernPayload>;
  labFulfillmentsLabBiomarkerResultInterpretationUpdate?: Maybe<OfficeLabsJourneysBiomarkerResultInterpretationsUpdatePayload>;
  labFulfillmentsLabResultInterpretationReleaseToPatient?: Maybe<OfficeLabsJourneysLabResultInterpretationsReleaseToPatientPayload>;
  labFulfillmentsLabResultInterpretationUpdate?: Maybe<OfficeLabsJourneysInterpretationsUpdatePayload>;
  labsAddTestToPlan?: Maybe<OfficeLabsAddTestToPlanPayload>;
  labsFavoritesAdd?: Maybe<OfficeLabsFavoritesAddPayload>;
  labsFavoritesRemove?: Maybe<OfficeLabsFavoritesRemovePayload>;
  labsJourneysAddToPlan?: Maybe<OfficeLabsJourneysAddToPlanPayload>;
  labsJourneysCancelSubscription?: Maybe<OfficeLabsJourneysCancelSubscriptionPayload>;
  labsJourneysRemoveFromPlan?: Maybe<OfficeLabsJourneysRemoveFromPlanPayload>;
  labsJourneysUpdateDetails?: Maybe<OfficeLabsJourneysUpdateDetailsPayload>;
  labsJourneysUpdateFrequency?: Maybe<OfficeLabsJourneysUpdateFrequencyPayload>;
  labsOnboardingAcceptAgreement?: Maybe<LabsOnboardingAcceptAgreementPayload>;
  labsOnboardingGenerateBaaAgreement?: Maybe<LabsOnboardingGenerateBaaAgreementPayload>;
  labsPanelActivate?: Maybe<OfficeLabsPanelsActivatePayload>;
  labsPanelAddTest?: Maybe<OfficeLabsPanelsAddTestPayload>;
  labsPanelApplyToPlan?: Maybe<OfficeLabsPanelsApplyToPlanPayload>;
  labsPanelCancel?: Maybe<OfficeLabsPanelsCancelPayload>;
  labsPanelDuplicate?: Maybe<OfficeLabsPanelsDuplicatePayload>;
  labsPanelFindOrCreateDraft?: Maybe<OfficeLabsPanelsFindOrCreateDraftPayload>;
  labsPanelRemoveFromPlan?: Maybe<OfficeLabsPanelsRemoveFromPlanPayload>;
  labsPanelRemoveTest?: Maybe<OfficeLabsPanelsRemoveTestPayload>;
  labsPanelSave?: Maybe<OfficeLabsPanelsSavePayload>;
  labsPractitionersReviewResult?: Maybe<LabsPractitionersReviewResultPayload>;
  labsPractitionersSettingsUpdate?: Maybe<LabsPractitionersSettingsUpdatePayload>;
  labsRecommendationCustomFeesUpdate?: Maybe<OfficeLabsRecommendationCustomFeesUpdatePayload>;
  labsRecommendationDelete?: Maybe<OfficeLabsRecommendationDeletePayload>;
  labsRecommendationDeleteTest?: Maybe<OfficeLabsRecommendationDeleteTestPayload>;
  labsRecommendationSave?: Maybe<OfficeLabsRecommendationSavePayload>;
  labsRecommendationSaveAsPanel?: Maybe<OfficeLabsRecommendationSaveAsPanelPayload>;
  labsRecommendationSendPatientInstructions?: Maybe<OfficeLabsRecommendationSendPatientInstructionsPayload>;
  labsRecommendationSendRequisition?: Maybe<OfficeLabsRecommendationSendRequisitionPayload>;
  labsWaitlistJoin?: Maybe<OfficeLabsWaitlistsJoinPayload>;
  multiPatientPlanTemplateApply?: Maybe<OfficeMultiPatientPlanTemplateApplyPayload>;
  multiPatientPlansActivate?: Maybe<MultiPatientPlansActivatePayload>;
  multiPatientPlansCreate?: Maybe<MultiPatientPlansCreatePayload>;
  multiPatientPlansCreateAll?: Maybe<MultiPatientPlansCreateAllPayload>;
  multiPatientPlansDelete?: Maybe<MultiPatientPlansDeletePayload>;
  multiPatientPlansDraftReset?: Maybe<MultiPatientPlansDraftResetPayload>;
  multiPatientPlansUpdate?: Maybe<MultiPatientPlansUpdatePayload>;
  multiPatientPlansUpdateAccess?: Maybe<MultiPatientPlansUpdateAccessPayload>;
  onboardingModalLog?: Maybe<OnboardingModalLogPayload>;
  /** @deprecated Use retailCheckoutAddToCart instead. */
  orderPopulateFromTreatmentPlan?: Maybe<OfficeOrderPopulateFromTreatmentPlanPayload>;
  /** @deprecated Use retailCheckoutAddToCart instead. */
  patientAddToBasket?: Maybe<OfficePatientAddToBasketPayload>;
  patientImportEntriesUpdate?: Maybe<OfficePatientImportEntriesUpdatePayload>;
  patientImportExpire?: Maybe<OfficePatientImportExpirePayload>;
  patientImportProcess?: Maybe<OfficePatientImportProcessPayload>;
  patientImportSave?: Maybe<OfficePatientImportSavePayload>;
  patientLogViewCart?: Maybe<OfficePatientLogViewCartPayload>;
  patientSave?: Maybe<OfficePatientSavePayload>;
  patientUnlink?: Maybe<OfficePatientUnlinkPayload>;
  peerReferralsCreate?: Maybe<PeerReferralsCreatePayload>;
  peerReferralsDelete?: Maybe<PeerReferralsDeletePayload>;
  peerReferralsUpdateBonusMarginEnabled?: Maybe<PeerReferralsUpdateBonusMarginEnabledPayload>;
  plansConvert?: Maybe<OfficesPlansConvertPayload>;
  practitionerArchive?: Maybe<PractitionerArchivePayload>;
  practitionerDocumentDelete?: Maybe<OfficePractitionerDocumentDeletePayload>;
  practitionerDocumentSave?: Maybe<OfficePractitionerDocumentSavePayload>;
  practitionerNotificationSettingsUpdate?: Maybe<NotificationSettingsUpdatePayload>;
  /** @deprecated Use prescribing_personalization_practitioner_update instead. */
  practitionerPrescribingPersonalizationToggle?: Maybe<OfficePrescribingPersonalizationPractitionerUpdatePayload>;
  practitionerPublicProfileUpdate?: Maybe<PublicProfileUpdatePayload>;
  practitionerRestore?: Maybe<PractitionerRestorePayload>;
  practitionerStoreMerchantDataUpdate?: Maybe<PractitionerAndStoreMerchantDataUpdatePayload>;
  practitionerToggleFullscriptContentFavourite?: Maybe<OfficesPractitionersToggleFullscriptContentFavouritePayload>;
  practitionerUpdate?: Maybe<PractitionerUpdatePayload>;
  practitionerUpdateLoginEmail?: Maybe<PractitionerUpdateLoginEmailPayload>;
  prescribingPersonalizationPractitionerUpdate?: Maybe<OfficePrescribingPersonalizationPractitionerUpdatePayload>;
  prescribingPersonalizationStoreUpdate?: Maybe<OfficePrescribingPersonalizationStoreUpdatePayload>;
  productCategorySave?: Maybe<OfficeProductsCategorySavePayload>;
  productSwappingSwap?: Maybe<OfficeProductSwappingSwapPayload>;
  productSwappingUnswap?: Maybe<OfficeProductSwappingUnswapPayload>;
  recommendationAlternativeFeedbacksCreate?: Maybe<OfficeRecommendationAlternativesFeedbackCreatePayload>;
  recommendationAlternativeFeedbacksDelete?: Maybe<OfficeRecommendationAlternativesFeedbackDeletePayload>;
  recommendationAlternativesExists?: Maybe<OfficeRecommendationAlternativesExistsPayload>;
  recommendationAlternativesGetProducts?: Maybe<OfficeRecommendationAlternativesGetProductsPayload>;
  recommendationDelete?: Maybe<OfficeRecommendationDeletePayload>;
  recommendationReplaceVariant?: Maybe<OfficeRecommendationReplaceVariantPayload>;
  recommendationSave?: Maybe<OfficeRecommendationSavePayload>;
  recommendationSubstitutesBulkSave?: Maybe<OfficeRecommendationSubstitutesBulkSavePayload>;
  recommendationSubstitutesCancel?: Maybe<OfficeRecommendationSubstitutesCancelPayload>;
  recommendationSubstitutesSave?: Maybe<OfficeRecommendationSubstitutesSavePayload>;
  recommendationTreatmentPlanApply?: Maybe<OfficeRecommendationTreatmentPlanApplyPayload>;
  resendInvitation?: Maybe<OfficeStaffSettingResendInvitationPayload>;
  settingsCatalogPermissionsUpdate?: Maybe<CatalogPermissionsUpdatePayload>;
  settingsDispensaryInfoUpdate?: Maybe<SettingsDispensaryInfoUpdatePayload>;
  settingsGlobalPatientAccessUpdate?: Maybe<GlobalPatientAccessUpdatePayload>;
  settingsPaymentsAndTaxesAcceptStripeTos?: Maybe<OfficeSettingsPaymentsAndTaxesAcceptStripeTosPayload>;
  settingsPaymentsAndTaxesSetup?: Maybe<OfficeSettingsPaymentsAndTaxesSetupPayload>;
  settingsReportAccessUpdate?: Maybe<ReportAccessUpdatePayload>;
  settingsUpdate?: Maybe<OfficeSettingsUpdatePayload>;
  staffSettingsClerkCreate?: Maybe<OfficeStaffSettingClerkCreatePayload>;
  staffSettingsPractitionerCreate?: Maybe<StaffSettingsPractitionerCreatePayload>;
  staffSettingsPractitionerUpdate?: Maybe<StaffSettingsPractitionerUpdatePayload>;
  staticDailyPacksDiscontinue?: Maybe<DiscontinuePayload>;
  staticDailyPacksManage?: Maybe<ManageStaticDailyPackPayload>;
  storeOnboardingUpdate?: Maybe<StoreOnboardingUpdatePayload>;
  storeSetupEmersonSubmitAddressInformation?: Maybe<StoreSetupEmersonSubmitAddressInformationPayload>;
  storeSetupEmersonSubmitCertUpload?: Maybe<StoreSetupEmersonSubmitCertUploadPayload>;
  storeSetupMoveSetupToComplete?: Maybe<StoreSetupMoveSetupToCompletePayload>;
  storeSetupSubmitAddressInformation?: Maybe<StoreSetupSubmitAddressInformationPayload>;
  storeSetupSubmitCertUploadV2?: Maybe<StoreSetupSubmitCertUploadV2Payload>;
  storeSetupSubmitCertUploadV3?: Maybe<StoreSetupSubmitCertUploadV3Payload>;
  storeSetupSubmitLoyalistCert?: Maybe<StoreSetupSubmitLoyalistCertPayload>;
  storeSetupSubmitStateAndPhoneInformation?: Maybe<StoreSetupSubmitStateAndPhoneInformationPayload>;
  swapVariant?: Maybe<OfficeProductSwappingSwapVariantPayload>;
  templateDailyPacksManage?: Maybe<ManageTemplateDailyPackPayload>;
  templateFromMultiPatientPlanCreate?: Maybe<OfficeTemplateCreateFromMultiPatientPlanPayload>;
  templateFromTreatmentPlanCreate?: Maybe<OfficeTemplateCreateFromTreatmentPlanPayload>;
  templateShareableGlobalUpdate?: Maybe<OfficeTemplateShareableGlobalUpdatePayload>;
  templateShareableWithPatientsUpdate?: Maybe<OfficeTemplateShareableWithPatientsUpdatePayload>;
  templateShareableWithPractitionersUpdate?: Maybe<OfficeTemplateShareableWithPractitionersUpdatePayload>;
  treatmentPlanActivate?: Maybe<OfficeTreatmentPlanActivatePayload>;
  treatmentPlanApplyDurationToAll?: Maybe<OfficeTreatmentPlanApplyDurationToAllPayload>;
  treatmentPlanCreateDraft?: Maybe<OfficeTreatmentPlansCreateDraftPayload>;
  treatmentPlanDraftMessageSave?: Maybe<OfficeTreatmentPlanDraftMessageSavePayload>;
  /** @deprecated Use OfficeTreatmentPlansCreateDraft instead. */
  treatmentPlanFindOrCreateDraft?: Maybe<OfficeTreatmentPlansFindOrCreateDraftPayload>;
  treatmentPlanInsightsLogEvent?: Maybe<TreatmentPlanInsightsLogPayload>;
  treatmentPlanLabsSigningPractitionerUpdate?: Maybe<OfficeTreatmentPlanLabSigningPractitionerUpdatePayload>;
  treatmentPlanPatientUpdate?: Maybe<OfficeTreatmentPlanPatientUpdatePayload>;
  treatmentPlanSnippetDelete?: Maybe<TreatmentPlanSnippetDeletePayload>;
  treatmentPlanSnippetSave?: Maybe<TreatmentPlanSnippetSavePayload>;
  treatmentPlanTemplateApply?: Maybe<OfficeTreatmentPlanApplyTemplatePayload>;
  treatmentPlanTemplateMakeCopy?: Maybe<OfficeTreatmentPlanTemplateMakeCopyPayload>;
  treatmentPlanTemplateSave?: Maybe<OfficeTreatmentPlanTemplateSavePayload>;
  wellevateAuthenticateCredentials?: Maybe<AuthenticateWellevateCredentialsPayload>;
  wellevateHideMigrationBanner?: Maybe<HideMigrationBannerPayload>;
  wellevateHideNotInCatalogBanner?: Maybe<HideNotInCatalogBannerPayload>;
  wellevateImportLoyalistData?: Maybe<ImportLoyalistDataPayload>;
  wellevateInitiateMigration?: Maybe<InitiateMigrationPayload>;
  wellevatePractitionerSelfEnroll?: Maybe<PractitionerSelfEnrollPayload>;
  wholePersonPlanAcceptAgreement?: Maybe<WholePersonPlanAcceptAgreementPayload>;
  wholesaleCertExpressLinkGenerate?: Maybe<OfficeWholesalesCertExpressLinkGeneratePayload>;
};


export type OfficeMutationAddressCreateArgs = {
  input: OfficeAddressCreateInput;
};


export type OfficeMutationAddressDeleteArgs = {
  input: OfficeAddressDeleteInput;
};


export type OfficeMutationAddressUpdateArgs = {
  input: OfficeAddressUpdateInput;
};


export type OfficeMutationApiEmbeddableEventPatientSelectedArgs = {
  input: OfficeApiEmbeddableEventPatientSelectedInput;
};


export type OfficeMutationBulkPatientAssignPractitionerArgs = {
  input: OfficeBulkPatientAssignPractitionerInput;
};


export type OfficeMutationBulkPatientReassignPractitionerArgs = {
  input: OfficeBulkPatientReassignPractitionerInput;
};


export type OfficeMutationBulkSavingsUpdateArgs = {
  input: OfficeBulkSavingsUpdateInput;
};


export type OfficeMutationBulkSavingsUpdateAllArgs = {
  input: OfficeBulkSavingsUpdateAllInput;
};


export type OfficeMutationBulkSwapVariantArgs = {
  input: OfficeProductSwappingBulkSwapVariantInput;
};


export type OfficeMutationCatalogGetProductDailyIntakeArgs = {
  input: OfficeCatalogGetProductDailyIntakeInput;
};


export type OfficeMutationCatalogGetProductMonthlyCostArgs = {
  input: OfficeCatalogGetProductMonthlyCostInput;
};


export type OfficeMutationCategoryDeleteArgs = {
  input: OfficeCategoryDeleteInput;
};


export type OfficeMutationCategorySaveArgs = {
  input: OfficeCategorySaveInput;
};


export type OfficeMutationClerkArchiveArgs = {
  input: ClerkArchiveInput;
};


export type OfficeMutationClerkRestoreArgs = {
  input: ClerkRestoreInput;
};


export type OfficeMutationClerkUpdateArgs = {
  input: ClerkUpdateInput;
};


export type OfficeMutationCreateTemplateDraftArgs = {
  input: OfficeTemplateCreateDraftInput;
};


export type OfficeMutationDefaultDosageInstructionSaveArgs = {
  input: OfficeDefaultDosageInstructionSaveInput;
};


export type OfficeMutationDocumentAttachmentDeleteArgs = {
  input: OfficeDocumentAttachmentDeleteInput;
};


export type OfficeMutationDocumentAttachmentSaveArgs = {
  input: OfficeDocumentAttachmentSaveInput;
};


export type OfficeMutationDocumentAttachmentUndoArgs = {
  input: OfficeDocumentAttachmentUndoInput;
};


export type OfficeMutationDraftTemplateResetArgs = {
  input: OfficeTemplatesDraftResetInput;
};


export type OfficeMutationDraftTreatmentPlanResetArgs = {
  input: OfficeTreatmentPlansDraftResetInput;
};


export type OfficeMutationDynamicDailyPacksAddTemplateToPlanArgs = {
  input: AddDynamicDailyPackTemplateToPlanInput;
};


export type OfficeMutationDynamicDailyPacksAddToTreatmentPlanArgs = {
  input: AddDynamicDailyPackToTreatmentPlanInput;
};


export type OfficeMutationDynamicDailyPacksDeleteDailyPackTemplateArgs = {
  input: DeleteDailyPackTemplateInput;
};


export type OfficeMutationDynamicDailyPacksManageArgs = {
  input: ManageDynamicDailyPackInput;
};


export type OfficeMutationDynamicDailyPacksModifyTypeArgs = {
  input: ModifyInput;
};


export type OfficeMutationDynamicDailyPacksSaveDailyPackTemplateArgs = {
  input: SaveDailyPackTemplateInput;
};


export type OfficeMutationElationLinkPatientArgs = {
  input: ElationLinkPatientInput;
};


export type OfficeMutationElationLinkPractitionerArgs = {
  input: ElationLinkPractitionerInput;
};


export type OfficeMutationExternalMetadatumDeactivateArgs = {
  input: OfficeExternalMetadatumDeactivateInput;
};


export type OfficeMutationFavoriteProductAddArgs = {
  input: FavoriteProductAddInput;
};


export type OfficeMutationFavoriteProductRemoveArgs = {
  input: FavoriteProductRemoveInput;
};


export type OfficeMutationIntegrationAuthorizationConsentsCreateArgs = {
  input: IntegrationAuthorizationConsentsCreateInput;
};


export type OfficeMutationIntegrationListingDisconnectArgs = {
  input: OfficeIntegrationListingDisconnectInput;
};


export type OfficeMutationLabFulfillmentsLabBiomarkerResultInterpretationToggleAreaOfConcernArgs = {
  input: OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernInput;
};


export type OfficeMutationLabFulfillmentsLabBiomarkerResultInterpretationUpdateArgs = {
  input: OfficeLabsJourneysBiomarkerResultInterpretationsUpdateInput;
};


export type OfficeMutationLabFulfillmentsLabResultInterpretationReleaseToPatientArgs = {
  input: OfficeLabsJourneysLabResultInterpretationsReleaseToPatientInput;
};


export type OfficeMutationLabFulfillmentsLabResultInterpretationUpdateArgs = {
  input: OfficeLabsJourneysInterpretationsUpdateInput;
};


export type OfficeMutationLabsAddTestToPlanArgs = {
  input: OfficeLabsAddTestToPlanInput;
};


export type OfficeMutationLabsFavoritesAddArgs = {
  input: OfficeLabsFavoritesAddInput;
};


export type OfficeMutationLabsFavoritesRemoveArgs = {
  input: OfficeLabsFavoritesRemoveInput;
};


export type OfficeMutationLabsJourneysAddToPlanArgs = {
  input: OfficeLabsJourneysAddToPlanInput;
};


export type OfficeMutationLabsJourneysCancelSubscriptionArgs = {
  input: OfficeLabsJourneysCancelSubscriptionInput;
};


export type OfficeMutationLabsJourneysRemoveFromPlanArgs = {
  input: OfficeLabsJourneysRemoveFromPlanInput;
};


export type OfficeMutationLabsJourneysUpdateDetailsArgs = {
  input: OfficeLabsJourneysUpdateDetailsInput;
};


export type OfficeMutationLabsJourneysUpdateFrequencyArgs = {
  input: OfficeLabsJourneysUpdateFrequencyInput;
};


export type OfficeMutationLabsOnboardingAcceptAgreementArgs = {
  input: LabsOnboardingAcceptAgreementInput;
};


export type OfficeMutationLabsOnboardingGenerateBaaAgreementArgs = {
  input: LabsOnboardingGenerateBaaAgreementInput;
};


export type OfficeMutationLabsPanelActivateArgs = {
  input: OfficeLabsPanelsActivateInput;
};


export type OfficeMutationLabsPanelAddTestArgs = {
  input: OfficeLabsPanelsAddTestInput;
};


export type OfficeMutationLabsPanelApplyToPlanArgs = {
  input: OfficeLabsPanelsApplyToPlanInput;
};


export type OfficeMutationLabsPanelCancelArgs = {
  input: OfficeLabsPanelsCancelInput;
};


export type OfficeMutationLabsPanelDuplicateArgs = {
  input: OfficeLabsPanelsDuplicateInput;
};


export type OfficeMutationLabsPanelFindOrCreateDraftArgs = {
  input: OfficeLabsPanelsFindOrCreateDraftInput;
};


export type OfficeMutationLabsPanelRemoveFromPlanArgs = {
  input: OfficeLabsPanelsRemoveFromPlanInput;
};


export type OfficeMutationLabsPanelRemoveTestArgs = {
  input: OfficeLabsPanelsRemoveTestInput;
};


export type OfficeMutationLabsPanelSaveArgs = {
  input: OfficeLabsPanelsSaveInput;
};


export type OfficeMutationLabsPractitionersReviewResultArgs = {
  input: LabsPractitionersReviewResultInput;
};


export type OfficeMutationLabsPractitionersSettingsUpdateArgs = {
  input: LabsPractitionersSettingsUpdateInput;
};


export type OfficeMutationLabsRecommendationCustomFeesUpdateArgs = {
  input: OfficeLabsRecommendationCustomFeesUpdateInput;
};


export type OfficeMutationLabsRecommendationDeleteArgs = {
  input: OfficeLabsRecommendationDeleteInput;
};


export type OfficeMutationLabsRecommendationDeleteTestArgs = {
  input: OfficeLabsRecommendationDeleteTestInput;
};


export type OfficeMutationLabsRecommendationSaveArgs = {
  input: OfficeLabsRecommendationSaveInput;
};


export type OfficeMutationLabsRecommendationSaveAsPanelArgs = {
  input: OfficeLabsRecommendationSaveAsPanelInput;
};


export type OfficeMutationLabsRecommendationSendPatientInstructionsArgs = {
  input: OfficeLabsRecommendationSendPatientInstructionsInput;
};


export type OfficeMutationLabsRecommendationSendRequisitionArgs = {
  input: OfficeLabsRecommendationSendRequisitionInput;
};


export type OfficeMutationLabsWaitlistJoinArgs = {
  input: OfficeLabsWaitlistsJoinInput;
};


export type OfficeMutationMultiPatientPlanTemplateApplyArgs = {
  input: OfficeMultiPatientPlanTemplateApplyInput;
};


export type OfficeMutationMultiPatientPlansActivateArgs = {
  input: MultiPatientPlansActivateInput;
};


export type OfficeMutationMultiPatientPlansCreateArgs = {
  input: MultiPatientPlansCreateInput;
};


export type OfficeMutationMultiPatientPlansCreateAllArgs = {
  input: MultiPatientPlansCreateAllInput;
};


export type OfficeMutationMultiPatientPlansDeleteArgs = {
  input: MultiPatientPlansDeleteInput;
};


export type OfficeMutationMultiPatientPlansDraftResetArgs = {
  input: MultiPatientPlansDraftResetInput;
};


export type OfficeMutationMultiPatientPlansUpdateArgs = {
  input: MultiPatientPlansUpdateInput;
};


export type OfficeMutationMultiPatientPlansUpdateAccessArgs = {
  input: MultiPatientPlansUpdateAccessInput;
};


export type OfficeMutationOnboardingModalLogArgs = {
  input: OnboardingModalLogInput;
};


export type OfficeMutationOrderPopulateFromTreatmentPlanArgs = {
  input: OfficeOrderPopulateFromTreatmentPlanInput;
};


export type OfficeMutationPatientAddToBasketArgs = {
  input: OfficePatientAddToBasketInput;
};


export type OfficeMutationPatientImportEntriesUpdateArgs = {
  input: OfficePatientImportEntriesUpdateInput;
};


export type OfficeMutationPatientImportExpireArgs = {
  input: OfficePatientImportExpireInput;
};


export type OfficeMutationPatientImportProcessArgs = {
  input: OfficePatientImportProcessInput;
};


export type OfficeMutationPatientImportSaveArgs = {
  input: OfficePatientImportSaveInput;
};


export type OfficeMutationPatientLogViewCartArgs = {
  input: OfficePatientLogViewCartInput;
};


export type OfficeMutationPatientSaveArgs = {
  input: OfficePatientSaveInput;
};


export type OfficeMutationPatientUnlinkArgs = {
  input: OfficePatientUnlinkInput;
};


export type OfficeMutationPeerReferralsCreateArgs = {
  input: PeerReferralsCreateInput;
};


export type OfficeMutationPeerReferralsDeleteArgs = {
  input: PeerReferralsDeleteInput;
};


export type OfficeMutationPeerReferralsUpdateBonusMarginEnabledArgs = {
  input: PeerReferralsUpdateBonusMarginEnabledInput;
};


export type OfficeMutationPlansConvertArgs = {
  input: OfficesPlansConvertInput;
};


export type OfficeMutationPractitionerArchiveArgs = {
  input: PractitionerArchiveInput;
};


export type OfficeMutationPractitionerDocumentDeleteArgs = {
  input: OfficePractitionerDocumentDeleteInput;
};


export type OfficeMutationPractitionerDocumentSaveArgs = {
  input: OfficePractitionerDocumentSaveInput;
};


export type OfficeMutationPractitionerNotificationSettingsUpdateArgs = {
  input: NotificationSettingsUpdateInput;
};


export type OfficeMutationPractitionerPrescribingPersonalizationToggleArgs = {
  input: OfficePrescribingPersonalizationPractitionerUpdateInput;
};


export type OfficeMutationPractitionerPublicProfileUpdateArgs = {
  input: PublicProfileUpdateInput;
};


export type OfficeMutationPractitionerRestoreArgs = {
  input: PractitionerRestoreInput;
};


export type OfficeMutationPractitionerStoreMerchantDataUpdateArgs = {
  input: PractitionerAndStoreMerchantDataUpdateInput;
};


export type OfficeMutationPractitionerToggleFullscriptContentFavouriteArgs = {
  input: OfficesPractitionersToggleFullscriptContentFavouriteInput;
};


export type OfficeMutationPractitionerUpdateArgs = {
  input: PractitionerUpdateInput;
};


export type OfficeMutationPractitionerUpdateLoginEmailArgs = {
  input: PractitionerUpdateLoginEmailInput;
};


export type OfficeMutationPrescribingPersonalizationPractitionerUpdateArgs = {
  input: OfficePrescribingPersonalizationPractitionerUpdateInput;
};


export type OfficeMutationPrescribingPersonalizationStoreUpdateArgs = {
  input: OfficePrescribingPersonalizationStoreUpdateInput;
};


export type OfficeMutationProductCategorySaveArgs = {
  input: OfficeProductsCategorySaveInput;
};


export type OfficeMutationProductSwappingSwapArgs = {
  input: OfficeProductSwappingSwapInput;
};


export type OfficeMutationProductSwappingUnswapArgs = {
  input: OfficeProductSwappingUnswapInput;
};


export type OfficeMutationRecommendationAlternativeFeedbacksCreateArgs = {
  input: OfficeRecommendationAlternativesFeedbackCreateInput;
};


export type OfficeMutationRecommendationAlternativeFeedbacksDeleteArgs = {
  input: OfficeRecommendationAlternativesFeedbackDeleteInput;
};


export type OfficeMutationRecommendationAlternativesExistsArgs = {
  input: OfficeRecommendationAlternativesExistsInput;
};


export type OfficeMutationRecommendationAlternativesGetProductsArgs = {
  input: OfficeRecommendationAlternativesGetProductsInput;
};


export type OfficeMutationRecommendationDeleteArgs = {
  input: OfficeRecommendationDeleteInput;
};


export type OfficeMutationRecommendationReplaceVariantArgs = {
  input: OfficeRecommendationReplaceVariantInput;
};


export type OfficeMutationRecommendationSaveArgs = {
  input: OfficeRecommendationSaveInput;
};


export type OfficeMutationRecommendationSubstitutesBulkSaveArgs = {
  input: OfficeRecommendationSubstitutesBulkSaveInput;
};


export type OfficeMutationRecommendationSubstitutesCancelArgs = {
  input: OfficeRecommendationSubstitutesCancelInput;
};


export type OfficeMutationRecommendationSubstitutesSaveArgs = {
  input: OfficeRecommendationSubstitutesSaveInput;
};


export type OfficeMutationRecommendationTreatmentPlanApplyArgs = {
  input: OfficeRecommendationTreatmentPlanApplyInput;
};


export type OfficeMutationResendInvitationArgs = {
  input: OfficeStaffSettingResendInvitationInput;
};


export type OfficeMutationSettingsCatalogPermissionsUpdateArgs = {
  input: CatalogPermissionsUpdateInput;
};


export type OfficeMutationSettingsDispensaryInfoUpdateArgs = {
  input: SettingsDispensaryInfoUpdateInput;
};


export type OfficeMutationSettingsGlobalPatientAccessUpdateArgs = {
  input: GlobalPatientAccessUpdateInput;
};


export type OfficeMutationSettingsPaymentsAndTaxesAcceptStripeTosArgs = {
  input: OfficeSettingsPaymentsAndTaxesAcceptStripeTosInput;
};


export type OfficeMutationSettingsPaymentsAndTaxesSetupArgs = {
  input: OfficeSettingsPaymentsAndTaxesSetupInput;
};


export type OfficeMutationSettingsReportAccessUpdateArgs = {
  input: ReportAccessUpdateInput;
};


export type OfficeMutationSettingsUpdateArgs = {
  input: OfficeSettingsUpdateInput;
};


export type OfficeMutationStaffSettingsClerkCreateArgs = {
  input: OfficeStaffSettingClerkCreateInput;
};


export type OfficeMutationStaffSettingsPractitionerCreateArgs = {
  input: StaffSettingsPractitionerCreateInput;
};


export type OfficeMutationStaffSettingsPractitionerUpdateArgs = {
  input: StaffSettingsPractitionerUpdateInput;
};


export type OfficeMutationStaticDailyPacksDiscontinueArgs = {
  input: DiscontinueInput;
};


export type OfficeMutationStaticDailyPacksManageArgs = {
  input: ManageStaticDailyPackInput;
};


export type OfficeMutationStoreOnboardingUpdateArgs = {
  input: StoreOnboardingUpdateInput;
};


export type OfficeMutationStoreSetupEmersonSubmitAddressInformationArgs = {
  input: StoreSetupEmersonSubmitAddressInformationInput;
};


export type OfficeMutationStoreSetupEmersonSubmitCertUploadArgs = {
  input: StoreSetupEmersonSubmitCertUploadInput;
};


export type OfficeMutationStoreSetupMoveSetupToCompleteArgs = {
  input: StoreSetupMoveSetupToCompleteInput;
};


export type OfficeMutationStoreSetupSubmitAddressInformationArgs = {
  input: StoreSetupSubmitAddressInformationInput;
};


export type OfficeMutationStoreSetupSubmitCertUploadV2Args = {
  input: StoreSetupSubmitCertUploadV2Input;
};


export type OfficeMutationStoreSetupSubmitCertUploadV3Args = {
  input: StoreSetupSubmitCertUploadV3Input;
};


export type OfficeMutationStoreSetupSubmitLoyalistCertArgs = {
  input: StoreSetupSubmitLoyalistCertInput;
};


export type OfficeMutationStoreSetupSubmitStateAndPhoneInformationArgs = {
  input: StoreSetupSubmitStateAndPhoneInformationInput;
};


export type OfficeMutationSwapVariantArgs = {
  input: OfficeProductSwappingSwapVariantInput;
};


export type OfficeMutationTemplateDailyPacksManageArgs = {
  input: ManageTemplateDailyPackInput;
};


export type OfficeMutationTemplateFromMultiPatientPlanCreateArgs = {
  input: OfficeTemplateCreateFromMultiPatientPlanInput;
};


export type OfficeMutationTemplateFromTreatmentPlanCreateArgs = {
  input: OfficeTemplateCreateFromTreatmentPlanInput;
};


export type OfficeMutationTemplateShareableGlobalUpdateArgs = {
  input: OfficeTemplateShareableGlobalUpdateInput;
};


export type OfficeMutationTemplateShareableWithPatientsUpdateArgs = {
  input: OfficeTemplateShareableWithPatientsUpdateInput;
};


export type OfficeMutationTemplateShareableWithPractitionersUpdateArgs = {
  input: OfficeTemplateShareableWithPractitionersUpdateInput;
};


export type OfficeMutationTreatmentPlanActivateArgs = {
  input: OfficeTreatmentPlanActivateInput;
};


export type OfficeMutationTreatmentPlanApplyDurationToAllArgs = {
  input: OfficeTreatmentPlanApplyDurationToAllInput;
};


export type OfficeMutationTreatmentPlanCreateDraftArgs = {
  input: OfficeTreatmentPlansCreateDraftInput;
};


export type OfficeMutationTreatmentPlanDraftMessageSaveArgs = {
  input: OfficeTreatmentPlanDraftMessageSaveInput;
};


export type OfficeMutationTreatmentPlanFindOrCreateDraftArgs = {
  input: OfficeTreatmentPlansFindOrCreateDraftInput;
};


export type OfficeMutationTreatmentPlanInsightsLogEventArgs = {
  input: TreatmentPlanInsightsLogInput;
};


export type OfficeMutationTreatmentPlanLabsSigningPractitionerUpdateArgs = {
  input: OfficeTreatmentPlanLabSigningPractitionerUpdateInput;
};


export type OfficeMutationTreatmentPlanPatientUpdateArgs = {
  input: OfficeTreatmentPlanPatientUpdateInput;
};


export type OfficeMutationTreatmentPlanSnippetDeleteArgs = {
  input: TreatmentPlanSnippetDeleteInput;
};


export type OfficeMutationTreatmentPlanSnippetSaveArgs = {
  input: TreatmentPlanSnippetSaveInput;
};


export type OfficeMutationTreatmentPlanTemplateApplyArgs = {
  input: OfficeTreatmentPlanApplyTemplateInput;
};


export type OfficeMutationTreatmentPlanTemplateMakeCopyArgs = {
  input: OfficeTreatmentPlanTemplateMakeCopyInput;
};


export type OfficeMutationTreatmentPlanTemplateSaveArgs = {
  input: OfficeTreatmentPlanTemplateSaveInput;
};


export type OfficeMutationWellevateAuthenticateCredentialsArgs = {
  input: AuthenticateWellevateCredentialsInput;
};


export type OfficeMutationWellevateHideMigrationBannerArgs = {
  input: HideMigrationBannerInput;
};


export type OfficeMutationWellevateHideNotInCatalogBannerArgs = {
  input: HideNotInCatalogBannerInput;
};


export type OfficeMutationWellevateImportLoyalistDataArgs = {
  input: ImportLoyalistDataInput;
};


export type OfficeMutationWellevateInitiateMigrationArgs = {
  input: InitiateMigrationInput;
};


export type OfficeMutationWellevatePractitionerSelfEnrollArgs = {
  input: PractitionerSelfEnrollInput;
};


export type OfficeMutationWholePersonPlanAcceptAgreementArgs = {
  input: WholePersonPlanAcceptAgreementInput;
};


export type OfficeMutationWholesaleCertExpressLinkGenerateArgs = {
  input: OfficeWholesalesCertExpressLinkGenerateInput;
};

export type OfficeOrderPopulateFromTreatmentPlanAttributes = {
  resetOrder?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

export type OfficeOrderPopulateFromTreatmentPlanError = {
  fields?: Maybe<OfficeOrderPopulateFromTreatmentPlanFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeOrderPopulateFromTreatmentPlanFieldError = {
  resetOrder?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeOrderPopulateFromTreatmentPlanInput = {
  attributes: OfficeOrderPopulateFromTreatmentPlanAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeOrderPopulateFromTreatmentPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeOrderPopulateFromTreatmentPlanError;
  order?: Maybe<Order>;
};

export type OfficePatientAddToBasketError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePatientAddToBasketInput = {
  cart: Array<CartItemAttributes>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
};

export type OfficePatientAddToBasketPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficePatientAddToBasketError;
  inOfficeBasket?: Maybe<Order>;
};

export type OfficePatientAttributes = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstOrderDiscount?: InputMaybe<Scalars['Int']['input']>;
  intake?: InputMaybe<Scalars['Boolean']['input']>;
  labsTosAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  sex?: InputMaybe<Scalars['String']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
};

export type OfficePatientImportEntriesUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePatientImportEntriesUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientImportEntries?: Array<PatientImportEntryAttributes>;
};

export type OfficePatientImportEntriesUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficePatientImportEntriesUpdateError>;
  patientImportEntries?: Maybe<Array<PatientImportEntry>>;
};

export type OfficePatientImportExpireError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePatientImportExpireInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientImportId: Scalars['ID']['input'];
};

export type OfficePatientImportExpirePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficePatientImportExpireError;
  patientImport?: Maybe<PatientImport>;
};

export type OfficePatientImportProcessAttributes = {
  patientImportId: Scalars['ID']['input'];
  sendInvitation: Scalars['Boolean']['input'];
};

export type OfficePatientImportProcessError = {
  fields?: Maybe<OfficePatientImportProcessFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePatientImportProcessFieldError = {
  patientImportId?: Maybe<Array<Scalars['String']['output']>>;
  sendInvitation?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficePatientImportProcessInput = {
  attributes: OfficePatientImportProcessAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficePatientImportProcessPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficePatientImportProcessError>;
  patientImport?: Maybe<PatientImport>;
};

export type OfficePatientImportSaveError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePatientImportSaveInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficePatientImportSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficePatientImportSaveError>;
  patientImport?: Maybe<PatientImport>;
};

export type OfficePatientLogViewCartError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePatientLogViewCartInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  entryPoint: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};

export type OfficePatientLogViewCartPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficePatientLogViewCartError;
};

export type OfficePatientSaveError = {
  fields?: Maybe<OfficePatientSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePatientSaveFieldError = {
  archived?: Maybe<Array<Scalars['String']['output']>>;
  dateOfBirth?: Maybe<Array<Scalars['String']['output']>>;
  discount?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  firstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  labsTosAgreement?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerIds?: Maybe<Array<Scalars['String']['output']>>;
  sendWelcomeEmail?: Maybe<Array<Scalars['String']['output']>>;
  sex?: Maybe<Array<Scalars['String']['output']>>;
  smsNumber?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficePatientSaveInput = {
  attributes: OfficePatientAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createFsJsEvent?: InputMaybe<Scalars['Boolean']['input']>;
  externalPatientId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficePatientSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficePatientSaveError>;
  fsJsEventPayload?: Maybe<Scalars['JSON']['output']>;
  patient?: Maybe<Patient>;
};

export type OfficePatientUnlinkError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePatientUnlinkInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalPatientId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
};

export type OfficePatientUnlinkPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficePatientUnlinkError>;
};

export type OfficePractitionerDocumentDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePractitionerDocumentDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

export type OfficePractitionerDocumentDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentId?: Maybe<Scalars['String']['output']>;
  errors: OfficePractitionerDocumentDeleteError;
};

export type OfficePractitionerDocumentSaveAttributes = {
  attachment?: InputMaybe<Scalars['Upload']['input']>;
  documentCategoryId?: InputMaybe<Scalars['ID']['input']>;
  documentId?: InputMaybe<Scalars['ID']['input']>;
  documentLibrary?: InputMaybe<Scalars['Boolean']['input']>;
  documentTypeId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export type OfficePractitionerDocumentSaveError = {
  fields?: Maybe<OfficePractitionerDocumentSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePractitionerDocumentSaveFieldError = {
  attachment?: Maybe<Array<Scalars['String']['output']>>;
  documentCategoryId?: Maybe<Array<Scalars['String']['output']>>;
  documentId?: Maybe<Array<Scalars['String']['output']>>;
  documentLibrary?: Maybe<Array<Scalars['String']['output']>>;
  documentTypeId?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficePractitionerDocumentSaveInput = {
  attributes: OfficePractitionerDocumentSaveAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficePractitionerDocumentSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: OfficePractitionerDocumentSaveError;
};

export type OfficePrescribingPersonalizationPractitionerUpdateAttributes = {
  isEnabledForPractitioner: Scalars['Boolean']['input'];
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  prescribingPersonalizationId: Scalars['ID']['input'];
};

export type OfficePrescribingPersonalizationPractitionerUpdateError = {
  fields?: Maybe<OfficePrescribingPersonalizationPractitionerUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePrescribingPersonalizationPractitionerUpdateFieldError = {
  isEnabledForPractitioner?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  prescribingPersonalizationId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficePrescribingPersonalizationPractitionerUpdateInput = {
  attributes: OfficePrescribingPersonalizationPractitionerUpdateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficePrescribingPersonalizationPractitionerUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficePrescribingPersonalizationPractitionerUpdateError;
  personalization?: Maybe<PrescribingPersonalizationValue>;
  /** @deprecated Use 'personalization' field instead */
  prescribingPersonalization?: Maybe<PrescribingPersonalization>;
};

export type OfficePrescribingPersonalizationStoreUpdateError = {
  fields?: Maybe<OfficePrescribingPersonalizationStoreUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficePrescribingPersonalizationStoreUpdateFieldError = {
  isEnabled?: Maybe<Array<Scalars['String']['output']>>;
  overridable?: Maybe<Array<Scalars['String']['output']>>;
  prescribingPersonalizationId?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficePrescribingPersonalizationStoreUpdateInput = {
  attributes: OfficePrescribingPersonalizationUpdateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficePrescribingPersonalizationStoreUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficePrescribingPersonalizationStoreUpdateError;
  personalization?: Maybe<PrescribingPersonalizationValue>;
  /** @deprecated Use 'personalization' field instead */
  prescribingPersonalization?: Maybe<PrescribingPersonalization>;
};

export type OfficePrescribingPersonalizationUpdateAttributes = {
  isEnabled: Scalars['Boolean']['input'];
  overridable: Scalars['Boolean']['input'];
  prescribingPersonalizationId: Scalars['ID']['input'];
  storeId: Scalars['ID']['input'];
};

export type OfficeProductCategoryAttributes = {
  categoryId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

export type OfficeProductSwappingBulkSwapVariantAttributes = {
  excludedPlanIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  message?: InputMaybe<Scalars['String']['input']>;
  notifyPatients?: InputMaybe<Scalars['Boolean']['input']>;
  planIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  planType: PlanTypes;
  previousVariantId: Scalars['ID']['input'];
  recommendationAttributes?: InputMaybe<OfficeRecommendationAttributes>;
  replacementVariantId: Scalars['ID']['input'];
  selectAllPlans?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfficeProductSwappingBulkSwapVariantError = {
  fields?: Maybe<OfficeProductSwappingBulkSwapVariantFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeProductSwappingBulkSwapVariantFieldError = {
  excludedPlanIds?: Maybe<Array<Scalars['String']['output']>>;
  message?: Maybe<Array<Scalars['String']['output']>>;
  notifyPatients?: Maybe<Array<Scalars['String']['output']>>;
  planIds?: Maybe<Array<Scalars['String']['output']>>;
  planType?: Maybe<Array<Scalars['String']['output']>>;
  previousVariantId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationAttributes?: Maybe<Array<Scalars['String']['output']>>;
  replacementVariantId?: Maybe<Array<Scalars['String']['output']>>;
  selectAllPlans?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeProductSwappingBulkSwapVariantInput = {
  attributes: OfficeProductSwappingBulkSwapVariantAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeProductSwappingBulkSwapVariantPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeProductSwappingBulkSwapVariantError;
};

export type OfficeProductSwappingSwapError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeProductSwappingSwapInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
  replacementProductId: Scalars['ID']['input'];
  sendEmails: Scalars['Boolean']['input'];
};

export type OfficeProductSwappingSwapPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeProductSwappingSwapError;
  replaceVariant?: Maybe<ReplaceVariant>;
};

export type OfficeProductSwappingSwapVariantAttributes = {
  planId: Scalars['ID']['input'];
  planType: PlanTypes;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  recommendationId: Scalars['ID']['input'];
  replacementRecommendationAttributes: OfficeRecommendationAttributes;
  replacementVariantId: Scalars['ID']['input'];
};

export type OfficeProductSwappingSwapVariantError = {
  fields?: Maybe<OfficeProductSwappingSwapVariantFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeProductSwappingSwapVariantFieldError = {
  planId?: Maybe<Array<Scalars['String']['output']>>;
  planType?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationId?: Maybe<Array<Scalars['String']['output']>>;
  replacementRecommendationAttributes?: Maybe<Array<Scalars['String']['output']>>;
  replacementVariantId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeProductSwappingSwapVariantInput = {
  attributes: OfficeProductSwappingSwapVariantAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeProductSwappingSwapVariantPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeProductSwappingSwapVariantError;
  recommendation?: Maybe<Recommendation>;
};

export type OfficeProductSwappingUnswapError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeProductSwappingUnswapInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  replaceVariantId: Scalars['ID']['input'];
};

export type OfficeProductSwappingUnswapPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeProductSwappingUnswapError;
  replaceVariant?: Maybe<ReplaceVariant>;
};

export type OfficeProductsCategorySaveError = {
  fields?: Maybe<OfficeProductsCategorySaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeProductsCategorySaveFieldError = {
  categoryId?: Maybe<Array<Scalars['String']['output']>>;
  productId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeProductsCategorySaveInput = {
  attributes: OfficeProductCategoryAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeProductsCategorySavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeProductsCategorySaveError;
  product?: Maybe<Product>;
};

export type OfficeRecommendationAlternativeFeedbackAttributes = {
  baseProductId: Scalars['ID']['input'];
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  otherOption?: InputMaybe<Scalars['String']['input']>;
  similarProductId: Scalars['ID']['input'];
  thumbsUp: Scalars['Boolean']['input'];
};

export type OfficeRecommendationAlternativesExistsError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationAlternativesExistsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

export type OfficeRecommendationAlternativesExistsPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationAlternativesExistsError;
  exists: Scalars['Boolean']['output'];
};

export type OfficeRecommendationAlternativesFeedbackCreateError = {
  fields?: Maybe<OfficeRecommendationAlternativesFeedbackCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationAlternativesFeedbackCreateFieldError = {
  baseProductId?: Maybe<Array<Scalars['String']['output']>>;
  options?: Maybe<Array<Scalars['String']['output']>>;
  otherOption?: Maybe<Array<Scalars['String']['output']>>;
  similarProductId?: Maybe<Array<Scalars['String']['output']>>;
  thumbsUp?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeRecommendationAlternativesFeedbackCreateInput = {
  attributes: OfficeRecommendationAlternativeFeedbackAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeRecommendationAlternativesFeedbackCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationAlternativesFeedbackCreateError;
  feedback?: Maybe<RecommendationAlternativeFeedback>;
};

export type OfficeRecommendationAlternativesFeedbackDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationAlternativesFeedbackDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  recommendationAlternativeFeedbackId: Scalars['ID']['input'];
};

export type OfficeRecommendationAlternativesFeedbackDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationAlternativesFeedbackDeleteError;
  feedback?: Maybe<RecommendationAlternativeFeedback>;
};

export type OfficeRecommendationAlternativesGetProductsError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationAlternativesGetProductsInput = {
  amount: Scalars['String']['input'];
  baseVariantId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
};

export type OfficeRecommendationAlternativesGetProductsPayload = {
  alternativeProducts?: Maybe<Array<VariantWithDosage>>;
  baseProduct?: Maybe<VariantWithDosage>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationAlternativesGetProductsError;
};

export type OfficeRecommendationAttributes = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['String']['input']>;
  currentState?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  isFilledOnSite?: InputMaybe<Scalars['Boolean']['input']>;
  multiPatientPlanId?: InputMaybe<Scalars['ID']['input']>;
  recommendationAlternativeSource?: InputMaybe<Scalars['String']['input']>;
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
  refill?: InputMaybe<Scalars['Boolean']['input']>;
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Removing practitioner ability to select dosage quantity. */
  unitsToPurchase?: InputMaybe<Scalars['Int']['input']>;
  variantId?: InputMaybe<Scalars['ID']['input']>;
  withFood?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfficeRecommendationDeleteError = {
  fields?: Maybe<OfficeRecommendationDeleteFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationDeleteFieldError = {
  additionalInfo?: Maybe<Array<Scalars['String']['output']>>;
  amount?: Maybe<Array<Scalars['String']['output']>>;
  currentState?: Maybe<Array<Scalars['String']['output']>>;
  duration?: Maybe<Array<Scalars['String']['output']>>;
  format?: Maybe<Array<Scalars['String']['output']>>;
  frequency?: Maybe<Array<Scalars['String']['output']>>;
  isFilledOnSite?: Maybe<Array<Scalars['String']['output']>>;
  multiPatientPlanId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationAlternativeSource?: Maybe<Array<Scalars['String']['output']>>;
  recommendationId?: Maybe<Array<Scalars['String']['output']>>;
  refill?: Maybe<Array<Scalars['String']['output']>>;
  takeWith?: Maybe<Array<Scalars['String']['output']>>;
  timeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  unitsToPurchase?: Maybe<Array<Scalars['String']['output']>>;
  variantId?: Maybe<Array<Scalars['String']['output']>>;
  withFood?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeRecommendationDeleteInput = {
  attributes: OfficeRecommendationAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeRecommendationDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationDeleteError;
  recommendation?: Maybe<Recommendation>;
};

export type OfficeRecommendationReplaceVariantAttributes = {
  alternativeVariantId: Scalars['ID']['input'];
  amount?: InputMaybe<Scalars['String']['input']>;
  currentRecommendationId: Scalars['ID']['input'];
  format?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeRecommendationReplaceVariantError = {
  fields?: Maybe<OfficeRecommendationReplaceVariantFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationReplaceVariantFieldError = {
  alternativeVariantId?: Maybe<Array<Scalars['String']['output']>>;
  amount?: Maybe<Array<Scalars['String']['output']>>;
  currentRecommendationId?: Maybe<Array<Scalars['String']['output']>>;
  format?: Maybe<Array<Scalars['String']['output']>>;
  frequency?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeRecommendationReplaceVariantInput = {
  attributes: OfficeRecommendationReplaceVariantAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeRecommendationReplaceVariantPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationReplaceVariantError;
  recommendation?: Maybe<Recommendation>;
};

export type OfficeRecommendationSaveError = {
  fields?: Maybe<OfficeRecommendationSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationSaveFieldError = {
  additionalInfo?: Maybe<Array<Scalars['String']['output']>>;
  amount?: Maybe<Array<Scalars['String']['output']>>;
  currentState?: Maybe<Array<Scalars['String']['output']>>;
  duration?: Maybe<Array<Scalars['String']['output']>>;
  format?: Maybe<Array<Scalars['String']['output']>>;
  frequency?: Maybe<Array<Scalars['String']['output']>>;
  isFilledOnSite?: Maybe<Array<Scalars['String']['output']>>;
  multiPatientPlanId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationAlternativeSource?: Maybe<Array<Scalars['String']['output']>>;
  recommendationId?: Maybe<Array<Scalars['String']['output']>>;
  refill?: Maybe<Array<Scalars['String']['output']>>;
  takeWith?: Maybe<Array<Scalars['String']['output']>>;
  timeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  unitsToPurchase?: Maybe<Array<Scalars['String']['output']>>;
  variantId?: Maybe<Array<Scalars['String']['output']>>;
  withFood?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeRecommendationSaveInput = {
  attributes: OfficeRecommendationAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeRecommendationSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationSaveError;
  recommendation?: Maybe<Recommendation>;
};

export type OfficeRecommendationSubstitutesAttributes = {
  mainRecommendationAttributes: OfficeRecommendationAttributes;
  substituteRecommendationAttributes: Array<OfficeRecommendationAttributes>;
  substituteRecommendationIdsToRemove?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OfficeRecommendationSubstitutesBulkSaveAttributes = {
  excludedPlanIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  mainVariantId: Scalars['ID']['input'];
  notifyPatients?: InputMaybe<Scalars['Boolean']['input']>;
  planIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  planType: PlanTypes;
  selectAllPlans?: InputMaybe<Scalars['Boolean']['input']>;
  substituteRecommendationAttributes: Array<OfficeRecommendationAttributes>;
};

export type OfficeRecommendationSubstitutesBulkSaveError = {
  fields?: Maybe<OfficeRecommendationSubstitutesBulkSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationSubstitutesBulkSaveFieldError = {
  excludedPlanIds?: Maybe<Array<Scalars['String']['output']>>;
  mainVariantId?: Maybe<Array<Scalars['String']['output']>>;
  notifyPatients?: Maybe<Array<Scalars['String']['output']>>;
  planIds?: Maybe<Array<Scalars['String']['output']>>;
  planType?: Maybe<Array<Scalars['String']['output']>>;
  selectAllPlans?: Maybe<Array<Scalars['String']['output']>>;
  substituteRecommendationAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeRecommendationSubstitutesBulkSaveInput = {
  attributes: OfficeRecommendationSubstitutesBulkSaveAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeRecommendationSubstitutesBulkSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationSubstitutesBulkSaveError;
};

export type OfficeRecommendationSubstitutesCancelError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationSubstitutesCancelInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  substituteRecommendationId: Scalars['ID']['input'];
};

export type OfficeRecommendationSubstitutesCancelPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationSubstitutesCancelError;
  substituteRecommendation?: Maybe<Recommendation>;
};

export type OfficeRecommendationSubstitutesSaveError = {
  fields?: Maybe<OfficeRecommendationSubstitutesSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationSubstitutesSaveFieldError = {
  mainRecommendationAttributes?: Maybe<Array<Scalars['String']['output']>>;
  substituteRecommendationAttributes?: Maybe<Array<Scalars['String']['output']>>;
  substituteRecommendationIdsToRemove?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeRecommendationSubstitutesSaveInput = {
  attributes: OfficeRecommendationSubstitutesAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeRecommendationSubstitutesSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationSubstitutesSaveError;
  mainRecommendation?: Maybe<Recommendation>;
  recommendationSubstitutes?: Maybe<Array<RecommendationSubstitute>>;
};

export type OfficeRecommendationTreatmentPlanApplyError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeRecommendationTreatmentPlanApplyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fromTreatmentPlanId: Scalars['ID']['input'];
  toTreatmentPlanId: Scalars['ID']['input'];
};

export type OfficeRecommendationTreatmentPlanApplyPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationTreatmentPlanApplyError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeRoleUnion = Clerk | Practitioner;

export type OfficeSettingsPaymentsAndTaxesAcceptStripeTosError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeSettingsPaymentsAndTaxesAcceptStripeTosInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeSettingsPaymentsAndTaxesAcceptStripeTosPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeSettingsPaymentsAndTaxesAcceptStripeTosError;
  store?: Maybe<Store>;
};

export type OfficeSettingsPaymentsAndTaxesErrors = {
  fields?: Maybe<OfficeSettingsPaymentsAndTaxesFieldErrors>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeSettingsPaymentsAndTaxesFieldErrors = {
  accountType?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountHeldBy?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountHolder?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountNumber?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountType?: Maybe<Array<Scalars['String']['output']>>;
  bankRoutingNumber?: Maybe<Array<Scalars['String']['output']>>;
  businessAddress?: Maybe<BusinessAddressFieldErrors>;
  businessName?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  classification?: Maybe<Array<Scalars['String']['output']>>;
  dateOfBirth?: Maybe<Array<Scalars['String']['output']>>;
  directors?: Maybe<Array<OfficeSettingsStripePersonErrors>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  employerId?: Maybe<Array<Scalars['String']['output']>>;
  equityOwners?: Maybe<Array<OfficeSettingsStripePersonErrors>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  invoiceAddress?: Maybe<BusinessAddressFieldErrors>;
  jobTitle?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  postalCode?: Maybe<Array<Scalars['String']['output']>>;
  profitMargin?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
  ssn?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeSettingsPaymentsAndTaxesSetupInput = {
  attributes: SettingsPaymentsAndTaxesAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeSettingsPaymentsAndTaxesSetupPayload = {
  bankAccount?: Maybe<BankAccount>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeSettingsPaymentsAndTaxesErrors>;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
};

export type OfficeSettingsStripePersonErrors = {
  errors?: Maybe<OfficeSettingsStripePersonFieldErrors>;
  index?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeSettingsStripePersonFieldErrors = {
  city?: Maybe<Array<Scalars['String']['output']>>;
  dateOfBirth?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  postalCode?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress2?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeSettingsUpdateError = {
  fields?: Maybe<OfficeSettingsUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeSettingsUpdateFieldError = {
  hasAdvancedSecurity?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeSettingsUpdateInput = {
  attributes: StoreSettingsAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeSettingsUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeSettingsUpdateError;
  store?: Maybe<Store>;
};

export type OfficeStaffSettingClerkCreateError = {
  fields?: Maybe<OfficeStaffSettingClerkCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeStaffSettingClerkCreateFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeStaffSettingClerkCreateInput = {
  attributes: StoreClerkAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeStaffSettingClerkCreatePayload = {
  clerk?: Maybe<Clerk>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeStaffSettingClerkCreateError;
};

export type OfficeStaffSettingResendInvitationError = {
  fields?: Maybe<OfficeStaffSettingResendInvitationFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeStaffSettingResendInvitationFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  isClerk?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeStaffSettingResendInvitationInput = {
  attributes: StaffSettingsResendInvitationAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeStaffSettingResendInvitationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeStaffSettingResendInvitationError;
};

export type OfficeStaticDailyPackAttributes = {
  dailyPackId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  variantAttributes?: InputMaybe<Array<OfficeDynamicDailyPackVariantAttributes>>;
};

export type OfficeTemplateCreateDraftError = {
  fields?: Maybe<OfficeTemplateCreateDraftFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTemplateCreateDraftFieldError = {
  attachmentsUploadableIds?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationsAttributes?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanTemplateIdToApply?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeTemplateCreateDraftInput = {
  attributes: TemplateCreateDraftAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeTemplateCreateDraftPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateCreateDraftError;
  treatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
};

export type OfficeTemplateCreateFromMultiPatientPlanError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTemplateCreateFromMultiPatientPlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeTemplateCreateFromMultiPatientPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateCreateFromMultiPatientPlanError;
  template?: Maybe<TreatmentPlanTemplate>;
};

export type OfficeTemplateCreateFromTreatmentPlanError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTemplateCreateFromTreatmentPlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publishTemplatePlan?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

export type OfficeTemplateCreateFromTreatmentPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateCreateFromTreatmentPlanError;
  template?: Maybe<TreatmentPlanTemplate>;
};

export type OfficeTemplateDailyPackAttributes = {
  dailyPackId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  variantAttributes?: InputMaybe<Array<OfficeDynamicDailyPackVariantAttributes>>;
};

export type OfficeTemplateDraftResetAttributes = {
  templateId: Scalars['ID']['input'];
};

export type OfficeTemplateShareableGlobalUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTemplateShareableGlobalUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shareable: Scalars['Boolean']['input'];
  templateId: Scalars['ID']['input'];
};

export type OfficeTemplateShareableGlobalUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateShareableGlobalUpdateError;
  template?: Maybe<TreatmentPlanTemplate>;
};

export type OfficeTemplateShareableWithPatientsUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTemplateShareableWithPatientsUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sharingLevel: Scalars['String']['input'];
  templateId: Scalars['ID']['input'];
};

export type OfficeTemplateShareableWithPatientsUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateShareableWithPatientsUpdateError;
  template?: Maybe<TreatmentPlanTemplate>;
};

export type OfficeTemplateShareableWithPractitionersUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTemplateShareableWithPractitionersUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sharingLevel: Scalars['String']['input'];
  templateId: Scalars['ID']['input'];
};

export type OfficeTemplateShareableWithPractitionersUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateShareableWithPractitionersUpdateError;
  template?: Maybe<TreatmentPlanTemplate>;
};

export type OfficeTemplatesDraftResetError = {
  fields?: Maybe<OfficeTemplatesDraftResetFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTemplatesDraftResetFieldError = {
  templateId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeTemplatesDraftResetInput = {
  attributes: OfficeTemplateDraftResetAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeTemplatesDraftResetPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplatesDraftResetError;
  template?: Maybe<TreatmentPlanTemplate>;
};

export type OfficeTreatmentPlanActivateError = {
  fields?: Maybe<OfficeTreatmentPlanActivateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlanActivateFieldError = {
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  signingPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeTreatmentPlanActivateInput = {
  attributes: OfficeTreatmentPlanAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createFsJsEvent?: InputMaybe<Scalars['Boolean']['input']>;
  externalIntegration?: InputMaybe<Scalars['JSON']['input']>;
};

export type OfficeTreatmentPlanActivatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeTreatmentPlanActivateError>;
  fsJsEventPayload?: Maybe<Scalars['JSON']['output']>;
  store?: Maybe<Store>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeTreatmentPlanApplyDurationToAllError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlanApplyDurationToAllInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['String']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

export type OfficeTreatmentPlanApplyDurationToAllPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeTreatmentPlanApplyDurationToAllError>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeTreatmentPlanApplyTemplateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlanApplyTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

export type OfficeTreatmentPlanApplyTemplatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlanApplyTemplateError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeTreatmentPlanAttributes = {
  patientId?: InputMaybe<Scalars['ID']['input']>;
  signingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

export type OfficeTreatmentPlanDraftMessageSaveError = {
  fields?: Maybe<OfficeTreatmentPlanDraftMessageSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlanDraftMessageSaveFieldError = {
  fromPrePopulated?: Maybe<Array<Scalars['String']['output']>>;
  message?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeTreatmentPlanDraftMessageSaveInput = {
  attributes: OfficeTreatmentPlanMessageAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeTreatmentPlanDraftMessageSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlanDraftMessageSaveError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeTreatmentPlanDraftResetAttributes = {
  treatmentPlanId: Scalars['ID']['input'];
};

export type OfficeTreatmentPlanLabSigningPractitionerUpdateError = {
  fields?: Maybe<OfficeTreatmentPlanLabSigningPractitionerUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlanLabSigningPractitionerUpdateFieldError = {
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  signingPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeTreatmentPlanLabSigningPractitionerUpdateInput = {
  attributes: OfficeTreatmentPlanAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeTreatmentPlanLabSigningPractitionerUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeTreatmentPlanLabSigningPractitionerUpdateError>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeTreatmentPlanMessageAttributes = {
  fromPrePopulated?: InputMaybe<Scalars['Boolean']['input']>;
  message: Scalars['String']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

export type OfficeTreatmentPlanPatientUpdateError = {
  fields?: Maybe<OfficeTreatmentPlanPatientUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlanPatientUpdateFieldError = {
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  signingPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeTreatmentPlanPatientUpdateInput = {
  attributes: OfficeTreatmentPlanAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeTreatmentPlanPatientUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeTreatmentPlanPatientUpdateError>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeTreatmentPlanTemplateAttributes = {
  attachmentsUploadableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  currentState?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  recommendationsAttributes?: InputMaybe<Array<OfficeRecommendationAttributes>>;
  treatmentPlanTemplateId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanTemplateIdToApply?: InputMaybe<Scalars['ID']['input']>;
};

export type OfficeTreatmentPlanTemplateMakeCopyError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlanTemplateMakeCopyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
};

export type OfficeTreatmentPlanTemplateMakeCopyPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlanTemplateMakeCopyError;
  template?: Maybe<TreatmentPlanTemplate>;
};

export type OfficeTreatmentPlanTemplateSaveError = {
  fields?: Maybe<OfficeTreatmentPlanTemplateSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlanTemplateSaveFieldError = {
  attachmentsUploadableIds?: Maybe<Array<Scalars['String']['output']>>;
  currentState?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationsAttributes?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanTemplateId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanTemplateIdToApply?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeTreatmentPlanTemplateSaveInput = {
  attributes: OfficeTreatmentPlanTemplateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeTreatmentPlanTemplateSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlanTemplateSaveError;
  treatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
};

export type OfficeTreatmentPlansCreateDraftError = {
  fields?: Maybe<OfficeTreatmentPlansCreateDraftFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlansCreateDraftFieldError = {
  attachmentsUploadableIds?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationsAttributes?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeTreatmentPlansCreateDraftInput = {
  attributes: TreatmentPlansCreateDraftAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeTreatmentPlansCreateDraftPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlansCreateDraftError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeTreatmentPlansDraftResetError = {
  fields?: Maybe<OfficeTreatmentPlansDraftResetFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlansDraftResetFieldError = {
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeTreatmentPlansDraftResetInput = {
  attributes: OfficeTreatmentPlanDraftResetAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeTreatmentPlansDraftResetPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlansDraftResetError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeTreatmentPlansFindOrCreateDraftError = {
  fields?: Maybe<OfficeTreatmentPlansFindOrCreateDraftFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeTreatmentPlansFindOrCreateDraftFieldError = {
  attachmentsUploadableIds?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationsAttributes?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficeTreatmentPlansFindOrCreateDraftInput = {
  attributes: TreatmentPlansFindOrCreateDraftAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeTreatmentPlansFindOrCreateDraftPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlansFindOrCreateDraftError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type OfficeWholesalesCertExpressLinkGenerateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficeWholesalesCertExpressLinkGenerateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeWholesalesCertExpressLinkGeneratePayload = {
  avataxCustomer?: Maybe<AvataxCustomer>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeWholesalesCertExpressLinkGenerateError;
};

export type OfficesPlansConvertError = {
  fields?: Maybe<OfficesPlansConvertFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OfficesPlansConvertFieldError = {
  convertTo?: Maybe<Array<Scalars['String']['output']>>;
  planId?: Maybe<Array<Scalars['String']['output']>>;
  planName?: Maybe<Array<Scalars['String']['output']>>;
  planType?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfficesPlansConvertInput = {
  attributes: PlansConvertAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OfficesPlansConvertPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficesPlansConvertError;
  plan?: Maybe<PlanUnion>;
};

export type OfficesPractitionersToggleFullscriptContentFavouriteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fullscriptContentId: Scalars['ID']['input'];
};

export type OfficesPractitionersToggleFullscriptContentFavouritePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  fullscriptContent?: Maybe<ImatContent>;
};

export type OnboardingChecklistLoggerAttributes = {
  action: Actions;
  actionStep: ActionSteps;
};

export type OnboardingChecklistLoggerError = {
  fields?: Maybe<OnboardingChecklistLoggerFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OnboardingChecklistLoggerFieldError = {
  action?: Maybe<Array<Scalars['String']['output']>>;
  actionStep?: Maybe<Array<Scalars['String']['output']>>;
};

export type OnboardingChecklistLoggerInput = {
  attributes: OnboardingChecklistLoggerAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingChecklistLoggerPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OnboardingChecklistLoggerError;
};

export const OnboardingExperimentSources = {
  Banner: 'BANNER',
  ChecklistStep: 'CHECKLIST_STEP',
  Dropdown: 'DROPDOWN',
  MessageBox: 'MESSAGE_BOX',
  NewChecklist: 'NEW_CHECKLIST',
  NewChecklistStep: 'NEW_CHECKLIST_STEP',
  PractitionerBox: 'PRACTITIONER_BOX',
  RxSent: 'RX_SENT'
} as const;

export type OnboardingExperimentSources = typeof OnboardingExperimentSources[keyof typeof OnboardingExperimentSources];
export const OnboardingExperiments = {
  Control: 'CONTROL',
  MessageBox: 'MESSAGE_BOX',
  OrangeBanner: 'ORANGE_BANNER',
  OrangeBannerNewChecklist: 'ORANGE_BANNER_NEW_CHECKLIST'
} as const;

export type OnboardingExperiments = typeof OnboardingExperiments[keyof typeof OnboardingExperiments];
export const OnboardingModalActionSteps = {
  Certificate: 'CERTIFICATE',
  StudentIdentification: 'STUDENT_IDENTIFICATION'
} as const;

export type OnboardingModalActionSteps = typeof OnboardingModalActionSteps[keyof typeof OnboardingModalActionSteps];
export const OnboardingModalActions = {
  Dismiss: 'DISMISS',
  OpenModal: 'OPEN_MODAL'
} as const;

export type OnboardingModalActions = typeof OnboardingModalActions[keyof typeof OnboardingModalActions];
export type OnboardingModalLogError = {
  fields?: Maybe<OnboardingModalLogFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type OnboardingModalLogFieldError = {
  action?: Maybe<Array<Scalars['String']['output']>>;
  actionStep?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  source?: Maybe<Array<Scalars['String']['output']>>;
};

export type OnboardingModalLogInput = {
  attributes: OnboardingModalLoggerAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingModalLogPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OnboardingModalLogError;
};

export type OnboardingModalLoggerAttributes = {
  action: OnboardingModalActions;
  actionStep: OnboardingModalActionSteps;
  pageVersion?: InputMaybe<OnboardingExperiments>;
  source?: InputMaybe<OnboardingExperimentSources>;
};

export type OptimizedMenuAttribute = {
  ids?: Maybe<Array<Scalars['ID']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OptimizedMenuCategory = {
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subCategories?: Maybe<Array<OptimizedMenuSubCategory>>;
};

export const OptimizedMenuCategoryKind = {
  Patient: 'PATIENT',
  Practitioner: 'PRACTITIONER'
} as const;

export type OptimizedMenuCategoryKind = typeof OptimizedMenuCategoryKind[keyof typeof OptimizedMenuCategoryKind];
export type OptimizedMenuSubCategory = {
  attributes?: Maybe<Array<OptimizedMenuAttribute>>;
  column?: Maybe<Scalars['Int']['output']>;
  ids?: Maybe<Array<Scalars['ID']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Order = Node & Timestamps & {
  additionalTaxTotal?: Maybe<Scalars['Float']['output']>;
  adjustmentTotal?: Maybe<Scalars['Float']['output']>;
  awayFromFreeShippingThreshold?: Maybe<Scalars['Float']['output']>;
  billingAddress?: Maybe<Address>;
  commentary?: Maybe<Commentary>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  consolidatedLabStatus?: Maybe<Scalars['String']['output']>;
  consolidatedStatus: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creditCard?: Maybe<CreditCard>;
  freeShippingThreshold: Scalars['Float']['output'];
  freeShippingThresholdType: Scalars['String']['output'];
  friendlyCompletedOn?: Maybe<Scalars['String']['output']>;
  fsFundedPromotionName?: Maybe<Scalars['String']['output']>;
  fsFundedPromotionTotal?: Maybe<Scalars['String']['output']>;
  hasLabs: Scalars['Boolean']['output'];
  hasPillPacks: Scalars['Boolean']['output'];
  hasShippables: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAutoship: Scalars['Boolean']['output'];
  isLabsPractitionerPay: Scalars['Boolean']['output'];
  isTrackable: Scalars['Boolean']['output'];
  labShipmentTotal?: Maybe<Scalars['Float']['output']>;
  labTotal: Scalars['Float']['output'];
  labsCustomFeesSummary?: Maybe<Array<LabsRecommendationCustomFee>>;
  labsSubTotal: Scalars['Float']['output'];
  labsTaxTotal: Scalars['Float']['output'];
  lineItems: Array<LineItem>;
  msrpTotal?: Maybe<Scalars['Float']['output']>;
  number: Scalars['String']['output'];
  orderLabsReceiptUrl: Scalars['String']['output'];
  orderPdfUrl: Scalars['String']['output'];
  orderReceiptUrl: Scalars['String']['output'];
  patient: Patient;
  patientPromotionDiscount?: Maybe<Scalars['Int']['output']>;
  patientPromotionDiscountTotal?: Maybe<Scalars['Float']['output']>;
  paymentState?: Maybe<Scalars['String']['output']>;
  previousStoreDiscountTotal?: Maybe<Scalars['Float']['output']>;
  productShipmentTotal?: Maybe<Scalars['Float']['output']>;
  qualifyingItemTotal?: Maybe<Scalars['Float']['output']>;
  refundableLineItems?: Maybe<Array<LineItem>>;
  refundableLineItemsQuantity?: Maybe<Scalars['Int']['output']>;
  resellerModelScore?: Maybe<Scalars['Float']['output']>;
  savingsTotal?: Maybe<Scalars['Float']['output']>;
  shipmentState?: Maybe<Scalars['String']['output']>;
  shipmentTax?: Maybe<Scalars['Float']['output']>;
  shipmentTotal?: Maybe<Scalars['Float']['output']>;
  shipments?: Maybe<Array<Shipment>>;
  shipperAddresses?: Maybe<Array<ShipperAddress>>;
  shippingAddress?: Maybe<Address>;
  shippingDaysLate?: Maybe<Scalars['Int']['output']>;
  shippingMethod?: Maybe<Scalars['String']['output']>;
  spreePromoTotal?: Maybe<Scalars['Float']['output']>;
  state: Scalars['String']['output'];
  storeFundedPromotionName?: Maybe<Scalars['String']['output']>;
  storeFundedPromotionTotal?: Maybe<Scalars['String']['output']>;
  storesPatientPromotion?: Maybe<StoresPatientPromotion>;
  subTotal?: Maybe<Scalars['Float']['output']>;
  supplementsItemTotal: Scalars['Float']['output'];
  supplementsMsrpTotal: Scalars['Float']['output'];
  supplementsShipmentTotal?: Maybe<Scalars['Float']['output']>;
  supplementsTaxTotal: Scalars['Float']['output'];
  supplementsTotal?: Maybe<Scalars['Float']['output']>;
  taxTotal?: Maybe<Scalars['Float']['output']>;
  taxesDeferred: Scalars['Boolean']['output'];
  total?: Maybe<Scalars['Float']['output']>;
  totalCompanyRequisitionFee?: Maybe<Scalars['Float']['output']>;
  totalPhysicianNetworkFee?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  nodes?: Maybe<Array<Maybe<Order>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Order>;
};

export type OrderFilterObject = {
  completedBeforeDate?: InputMaybe<Scalars['Date']['input']>;
  completedWithinDays?: InputMaybe<Scalars['Int']['input']>;
  createdBeforeDate?: InputMaybe<Scalars['Date']['input']>;
  hasLineItems?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderSort = {
  direction: SortDirection;
  field: OrderSortField;
};

export const OrderSortField = {
  CompletedAt: 'COMPLETED_AT',
  CreatedAt: 'CREATED_AT'
} as const;

export type OrderSortField = typeof OrderSortField[keyof typeof OrderSortField];
export const OrderingPermissions = {
  Allowed: 'allowed',
  Blocked: 'blocked',
  NotAllowed: 'not_allowed',
  RequiresAuthorization: 'requires_authorization'
} as const;

export type OrderingPermissions = typeof OrderingPermissions[keyof typeof OrderingPermissions];
export type PackPill = Timestamps & {
  amDosage?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  pill: DailyPackPill;
  pmDosage?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PackPillConnection = {
  edges?: Maybe<Array<Maybe<PackPillEdge>>>;
  nodes?: Maybe<Array<Maybe<PackPill>>>;
  pageInfo: PageInfo;
};

export type PackPillEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<PackPill>;
};

export const PackTypes = {
  StaticDailyPack: 'STATIC_DAILY_PACK',
  TemplateDailyPack: 'TEMPLATE_DAILY_PACK'
} as const;

export type PackTypes = typeof PackTypes[keyof typeof PackTypes];
export type PageInfo = {
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PageViewAttributes = {
  pageVersion: Scalars['String']['input'];
};

export type PanelTemplateSort = {
  direction: SortDirection;
  field: PanelTemplateSortField;
};

export const PanelTemplateSortField = {
  CreatedAt: 'CREATED_AT'
} as const;

export type PanelTemplateSortField = typeof PanelTemplateSortField[keyof typeof PanelTemplateSortField];
export type PartnerStoreConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<PartnerStoreEdge>>>;
  nodes?: Maybe<Array<Maybe<PartnerStore>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PartnerStoreFilterObject = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Patient = Actionable & LegacyId & Node & Roleable & Timestamps & {
  _legacyId: Scalars['Int']['output'];
  activePractitioners: Array<Practitioner>;
  activeVariants: VariantConnection;
  addresses: Array<PatientAddress>;
  anyCredentialApproved: Scalars['Boolean']['output'];
  archived?: Maybe<Scalars['Boolean']['output']>;
  audioSessions: AudioSessionConnection;
  autoshipDiscountExperimentGroup?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Avatar>;
  canOrderLabs: Scalars['Boolean']['output'];
  checkoutCreditCards: Array<CreditCard>;
  createdAt: Scalars['DateTime']['output'];
  creditCards: Array<CreditCard>;
  currentSignInAt?: Maybe<Scalars['DateTime']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  /** @deprecated Use capped displayDiscount instead */
  discount: Scalars['Int']['output'];
  displayDiscount: Scalars['Int']['output'];
  displayFirstOrderDiscount: Scalars['Int']['output'];
  eligibleForSelfServeRefunds: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  hasActiveFullscriptTreatmentPlan: Scalars['Boolean']['output'];
  hasActiveWellevateTreatmentPlan: Scalars['Boolean']['output'];
  hasDraftTreatmentPlan: Scalars['Boolean']['output'];
  hasFirstOrder: Scalars['Boolean']['output'];
  hasWellevateOrder: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  inAppNotifications: InAppPatientNotificationConnection;
  inOfficeBasket?: Maybe<Order>;
  isCurrentPatient: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  isRetailCheckoutGqlExperimentGroup: Scalars['Boolean']['output'];
  isWellevate: Scalars['Boolean']['output'];
  labRecommendations: LabsRecommendationConnection;
  labsTosAgreedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  migrationStatus?: Maybe<WellevatePatientMigrationStatus>;
  multiPatientPlans: MultiPatientPlanConnection;
  name: Scalars['String']['output'];
  nextAutoRefillDates: Array<NextAutoRefillDate>;
  notificationSettings: Array<PatientNotificationSetting>;
  numberOfActiveLabRecommendations: Scalars['Int']['output'];
  numberOfActivePractitioners: Scalars['Int']['output'];
  orders: OrderConnection;
  patientCart?: Maybe<Order>;
  patientIntake?: Maybe<PatientIntakeType>;
  practitionerPayBasket?: Maybe<Order>;
  practitioners?: Maybe<Array<Practitioner>>;
  previouslyOrderedVariantsNotInAutoship: VariantConnection;
  productAccessRequests?: Maybe<ProductAccessRequestConnection>;
  sessionNotes: SessionNoteConnection;
  sex?: Maybe<Scalars['String']['output']>;
  shipState?: Maybe<State>;
  smsNumber: Scalars['String']['output'];
  sourcePlatform: Scalars['String']['output'];
  store: Store;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeName?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<Autoship>;
  subscriptions: Array<Autoship>;
  subscriptionsGroups?: Maybe<Array<AutoshipGroup>>;
  suggestSupplementPlanProducts?: Maybe<Array<Product>>;
  supplementPlan?: Maybe<SupplementPlanType>;
  swappedVariants: Array<ReplaceVariant>;
  textMessageNotification: Scalars['Boolean']['output'];
  /** @deprecated Patient dispatch is deprecated */
  textMessageSubscription: Scalars['Boolean']['output'];
  totalAutoshipDiscount: Scalars['Int']['output'];
  totalDiscount: Scalars['Int']['output'];
  treatmentPlanTemplates: TreatmentPlanTemplateConnection;
  treatmentPlans: TreatmentPlanConnection;
  type: Scalars['String']['output'];
  unreadInAppNotificationCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variantNotifications: VariantNotificationConnection;
  wellevateOrders: WellevateOrderConnection;
  wellevateTreatmentPlans: WellevateTreatmentPlanConnection;
};


export type PatientActiveVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterObject?: InputMaybe<ActiveVariantFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientAddressesArgs = {
  filterObject?: InputMaybe<AddressFilterObject>;
};


export type PatientAudioSessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientCheckoutCreditCardsArgs = {
  orderSource: Scalars['String']['input'];
};


export type PatientInAppNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<InAppPatientNotificationFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientLabRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LabRecommendationFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<LabRecommendationSort>>;
};


export type PatientMultiPatientPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<MultiPatientPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MultiPatientPlanSort>>;
};


export type PatientNotificationSettingsArgs = {
  channel?: InputMaybe<Scalars['String']['input']>;
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type PatientOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrderSort>>;
};


export type PatientPreviouslyOrderedVariantsNotInAutoshipArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientProductAccessRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<ProductAccessRequestStatuses>;
};


export type PatientSessionNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientSubscriptionsArgs = {
  filters?: InputMaybe<AutoshipFilterObject>;
};


export type PatientSubscriptionsGroupsArgs = {
  filters?: InputMaybe<AutoshipGroupFilterObject>;
};


export type PatientSuggestSupplementPlanProductsArgs = {
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};


export type PatientTreatmentPlanTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientTreatmentPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TreatmentPlanSort>>;
};


export type PatientVariantNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterObject?: InputMaybe<VariantNotificationFilterObject>;
  filters?: InputMaybe<Array<VariantNotificationFilters>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientWellevateOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrderSort>>;
};


export type PatientWellevateTreatmentPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TreatmentPlanSort>>;
};

export type PatientAccountAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  patientAddressType?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  shippingAndBilling?: InputMaybe<Scalars['Boolean']['input']>;
  stateId: Scalars['ID']['input'];
  zipcode: Scalars['String']['input'];
};

export type PatientAddress = Timestamps & {
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  addressType?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  default?: Maybe<Scalars['Boolean']['output']>;
  defaultPatientAddress?: Maybe<Scalars['Boolean']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  shippingAndBilling: Scalars['Boolean']['output'];
  state: State;
  stateId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zipcode: Scalars['String']['output'];
};

export type PatientAddressAttributes = {
  billingAddress1?: InputMaybe<Scalars['String']['input']>;
  billingAddress2?: InputMaybe<Scalars['String']['input']>;
  billingAddressId?: InputMaybe<Scalars['ID']['input']>;
  billingCity?: InputMaybe<Scalars['String']['input']>;
  billingStateId?: InputMaybe<Scalars['ID']['input']>;
  billingZipcode?: InputMaybe<Scalars['String']['input']>;
  shippingAddress1?: InputMaybe<Scalars['String']['input']>;
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
  shippingAndBilling: Scalars['Boolean']['input'];
  shippingCity?: InputMaybe<Scalars['String']['input']>;
  shippingStateId?: InputMaybe<Scalars['ID']['input']>;
  shippingZipcode?: InputMaybe<Scalars['String']['input']>;
};

export type PatientAddressCreateError = {
  fields?: Maybe<PatientAddressCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientAddressCreateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  shippingAndBilling?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type PatientAddressCreateInput = {
  attributes: PatientAccountAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientAddressCreatePayload = {
  address?: Maybe<PatientAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientAddressCreateError;
  patient?: Maybe<Patient>;
};

export type PatientAddressDeleteInput = {
  addressId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientAddressDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
};

export type PatientAddressUpdateError = {
  fields?: Maybe<PatientAddressUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientAddressUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  shippingAndBilling?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type PatientAddressUpdateInput = {
  addressId: Scalars['ID']['input'];
  attributes: PatientAccountAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientAddressUpdatePayload = {
  address?: Maybe<PatientAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientAddressUpdateError;
  patient?: Maybe<Patient>;
};

export type PatientAttributes = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Scalars['String']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
  textMessageNotification?: InputMaybe<Scalars['Boolean']['input']>;
  textMessageSubscription?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PatientBrowseAbandonTriggerError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientBrowseAbandonTriggerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

export type PatientBrowseAbandonTriggerPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientBrowseAbandonTriggerError;
  patient?: Maybe<Patient>;
};

export type PatientBulkEditAllFilterObject = {
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PatientBulkEditFilterObject = {
  patientIds: Array<Scalars['ID']['input']>;
};

export type PatientCartAddToCartInput = {
  cart: Array<CartItemAttributes>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanTemplateId?: InputMaybe<Scalars['ID']['input']>;
};

export type PatientCartAddToCartPayload = {
  cart?: Maybe<Order>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

export type PatientCartResetInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientCartResetPayload = {
  cart?: Maybe<Order>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

export type PatientCartUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lineItemId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  timeSelected?: InputMaybe<Scalars['String']['input']>;
};

export type PatientCartUpdatePayload = {
  cart?: Maybe<Order>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

export type PatientCreateAddressesInput = {
  attributes: PatientAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientCreateAddressesPayload = {
  addresses: Array<PatientAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: BaseAddressMutationError;
};

export type PatientCreateCreditCardError = {
  fields?: Maybe<PatientCreateCreditCardFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientCreateCreditCardFieldError = {
  avsState?: Maybe<Array<Scalars['String']['output']>>;
  ccType?: Maybe<Array<Scalars['String']['output']>>;
  gatewayPaymentProfileId?: Maybe<Array<Scalars['String']['output']>>;
  lastDigits?: Maybe<Array<Scalars['String']['output']>>;
  month?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  oneTimeUse?: Maybe<Array<Scalars['String']['output']>>;
  year?: Maybe<Array<Scalars['String']['output']>>;
};

export type PatientCreateCreditCardInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  attributes: PatientCreditCardAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientCreateCreditCardPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  creditCard?: Maybe<CreditCard>;
  errors: PatientCreateCreditCardError;
  patient?: Maybe<Patient>;
};

export type PatientCreateRefundInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  refundAttributes: SelfServeRefundAttributes;
};

export type PatientCreateRefundPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  results?: Maybe<Array<Scalars['String']['output']>>;
};

export type PatientCreditCardAttributes = {
  avsState?: InputMaybe<Scalars['String']['input']>;
  ccType?: InputMaybe<Scalars['String']['input']>;
  gatewayPaymentProfileId?: InputMaybe<Scalars['String']['input']>;
  lastDigits?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oneTimeUse?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type PatientEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Patient>;
};

export type PatientFavouriteCreateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientFavouriteCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  favouriteableId: Scalars['ID']['input'];
};

export type PatientFavouriteCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientFavouriteCreateError;
  favouriteable?: Maybe<PlanUnion>;
};

export type PatientFavouriteDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientFavouriteDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  favouriteableId: Scalars['ID']['input'];
};

export type PatientFavouriteDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientFavouriteDeleteError;
  favouriteable?: Maybe<PlanUnion>;
};

export type PatientFunnelLoggerInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
  variantId: Scalars['ID']['input'];
};

export type PatientFunnelLoggerPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

export type PatientImport = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  entries: PatientImportEntryConnection;
  failureCsvUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  presignedUrl: Scalars['String']['output'];
  sendInvitation: Scalars['Boolean']['output'];
  store: Store;
  totalRecords?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userRole: Roleable;
};


export type PatientImportEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PatientImportEntryFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PatientImportEntry = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  discount?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  failureMessage?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  firstOrderDiscount?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  patientImport: PatientImport;
  smsNumber?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PatientImportEntryAttributes = {
  discount?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type PatientImportEntryConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<PatientImportEntryEdge>>>;
  nodes?: Maybe<Array<Maybe<PatientImportEntry>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PatientImportEntryEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<PatientImportEntry>;
};

export type PatientImportEntryFilterObject = {
  state?: InputMaybe<Array<PatientImportEntryFilters>>;
};

export const PatientImportEntryFilters = {
  ScopeFailed: 'SCOPE_FAILED',
  ScopeNotStarted: 'SCOPE_NOT_STARTED',
  ScopeProcessed: 'SCOPE_PROCESSED',
  ScopeRemoved: 'SCOPE_REMOVED',
  ScopeStarted: 'SCOPE_STARTED'
} as const;

export type PatientImportEntryFilters = typeof PatientImportEntryFilters[keyof typeof PatientImportEntryFilters];
export type PatientIntakeSendRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  practitionerId: Scalars['ID']['input'];
};

export type PatientIntakeSendRequestPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient: Patient;
};

export const PatientIntakeStatus = {
  Completed: 'COMPLETED',
  Initiated: 'INITIATED'
} as const;

export type PatientIntakeStatus = typeof PatientIntakeStatus[keyof typeof PatientIntakeStatus];
export type PatientIntakeType = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  requestedPractitioner?: Maybe<Practitioner>;
  status?: Maybe<PatientIntakeStatus>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PatientIntakeUpdateStatusInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  status: PatientIntakeStatus;
};

export type PatientIntakeUpdateStatusPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patientIntake: PatientIntakeType;
};

export type PatientLabTosAttributes = {
  dateOfBirth: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  labsTosAgreement: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  sex: Scalars['String']['input'];
  smsNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PatientLabsJourneysCancelSubscriptionError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientLabsJourneysCancelSubscriptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  journeySubscriptionId: Scalars['ID']['input'];
};

export type PatientLabsJourneysCancelSubscriptionPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientLabsJourneysCancelSubscriptionError;
  journeySubscription?: Maybe<LabsJourneysSubscription>;
};

export type PatientLabsUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientLabsUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientAttributes: PatientLabTosAttributes;
};

export type PatientLabsUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientLabsUpdateError;
  patient?: Maybe<Patient>;
};

export const PatientMigrationBanners = {
  OnboardingModal: 'ONBOARDING_MODAL'
} as const;

export type PatientMigrationBanners = typeof PatientMigrationBanners[keyof typeof PatientMigrationBanners];
export type PatientMigrationBannersVisibility = {
  onboardingModal: Scalars['Boolean']['output'];
};

export type PatientMutation = {
  createAddresses?: Maybe<PatientCreateAddressesPayload>;
  labsJourneysCancelSubscription?: Maybe<PatientLabsJourneysCancelSubscriptionPayload>;
  labsUpdate?: Maybe<PatientLabsUpdatePayload>;
  orderLogger?: Maybe<PatientOrderLoggerPayload>;
  patientCreateRefund?: Maybe<PatientCreateRefundPayload>;
  patientFavouriteCreate?: Maybe<PatientFavouriteCreatePayload>;
  patientFavouriteDelete?: Maybe<PatientFavouriteDeletePayload>;
  patientRefundShipping?: Maybe<PatientRefundShippingPayload>;
  treatmentPlanTemplateFavoriteCreate?: Maybe<TreatmentPlanTemplateFavoriteCreatePayload>;
  treatmentPlanTemplateFavoriteDelete?: Maybe<TreatmentPlanTemplateFavoriteDeletePayload>;
  treatmentPlanTemplateLogView?: Maybe<TreatmentPlanTemplateLogViewPayload>;
  updateAddresses?: Maybe<PatientUpdateAddressesPayload>;
  wellevateAccountActivation?: Maybe<AccountActivationPayload>;
  wellevateHidePatientMigrationBanner?: Maybe<HidePatientMigrationBannerPayload>;
  wellevateSendEmail?: Maybe<SendWellevatePatientEmailPayload>;
};


export type PatientMutationCreateAddressesArgs = {
  input: PatientCreateAddressesInput;
};


export type PatientMutationLabsJourneysCancelSubscriptionArgs = {
  input: PatientLabsJourneysCancelSubscriptionInput;
};


export type PatientMutationLabsUpdateArgs = {
  input: PatientLabsUpdateInput;
};


export type PatientMutationOrderLoggerArgs = {
  input: PatientOrderLoggerInput;
};


export type PatientMutationPatientCreateRefundArgs = {
  input: PatientCreateRefundInput;
};


export type PatientMutationPatientFavouriteCreateArgs = {
  input: PatientFavouriteCreateInput;
};


export type PatientMutationPatientFavouriteDeleteArgs = {
  input: PatientFavouriteDeleteInput;
};


export type PatientMutationPatientRefundShippingArgs = {
  input: PatientRefundShippingInput;
};


export type PatientMutationTreatmentPlanTemplateFavoriteCreateArgs = {
  input: TreatmentPlanTemplateFavoriteCreateInput;
};


export type PatientMutationTreatmentPlanTemplateFavoriteDeleteArgs = {
  input: TreatmentPlanTemplateFavoriteDeleteInput;
};


export type PatientMutationTreatmentPlanTemplateLogViewArgs = {
  input: TreatmentPlanTemplateLogViewInput;
};


export type PatientMutationUpdateAddressesArgs = {
  input: PatientUpdateAddressesInput;
};


export type PatientMutationWellevateAccountActivationArgs = {
  input: AccountActivationInput;
};


export type PatientMutationWellevateHidePatientMigrationBannerArgs = {
  input: HidePatientMigrationBannerInput;
};


export type PatientMutationWellevateSendEmailArgs = {
  input: SendWellevatePatientEmailInput;
};

export type PatientNotificationSetting = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  patient: Patient;
  subscribed?: Maybe<Scalars['Boolean']['output']>;
  type: NotificationSettingType;
  updatedAt: Scalars['DateTime']['output'];
};

export type PatientNotificationSettingPushInitiateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientNotificationSettingPushInitiateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscribed: Scalars['Boolean']['input'];
};

export type PatientNotificationSettingPushInitiatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientNotificationSettingPushInitiateError;
  notificationSettings: Array<PatientNotificationSetting>;
  userNotificationSettings: Array<UserNotificationSetting>;
};

export type PatientNotificationSettingPushUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientNotificationSettingPushUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notificationSettingIds: Array<Scalars['ID']['input']>;
  subscribed: Scalars['Boolean']['input'];
};

export type PatientNotificationSettingPushUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientNotificationSettingPushUpdateError;
  notificationSettings: Array<PatientNotificationSetting>;
  userNotificationSettings: Array<UserNotificationSetting>;
};

export type PatientNotificationSettingSubscribeAllError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientNotificationSettingSubscribeAllInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

export type PatientNotificationSettingSubscribeAllPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientNotificationSettingSubscribeAllError;
  notificationSettings: Array<PatientNotificationSetting>;
};

export type PatientNotificationSettingToggleAllError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientNotificationSettingToggleAllInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

export type PatientNotificationSettingToggleAllPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientNotificationSettingToggleAllError;
  notificationSettings: Array<PatientNotificationSetting>;
};

export type PatientNotificationSettingUnsubscribeAllError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientNotificationSettingUnsubscribeAllInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

export type PatientNotificationSettingUnsubscribeAllPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientNotificationSettingUnsubscribeAllError;
  notificationSettings: Array<PatientNotificationSetting>;
};

export type PatientNotificationSettingUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientNotificationSettingUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
  subscribed: Scalars['Boolean']['input'];
};

export type PatientNotificationSettingUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<PatientNotificationSettingUpdateError>;
  setting?: Maybe<PatientNotificationSetting>;
};

export type PatientOrderLoggerAttributes = {
  eventType: EventTypes;
  orderId: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  trackingId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientOrderLoggerError = {
  fields?: Maybe<PatientOrderLoggerFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PatientOrderLoggerFieldError = {
  eventType?: Maybe<Array<Scalars['String']['output']>>;
  orderId?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  trackingId?: Maybe<Array<Scalars['String']['output']>>;
};

export type PatientOrderLoggerInput = {
  attributes: PatientOrderLoggerAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientOrderLoggerPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientOrderLoggerError;
};

export type PatientPromotion = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  cutoffDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  durationDays: Scalars['Int']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  targetedDiscount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PatientPromotionConnection = {
  edges?: Maybe<Array<Maybe<PatientPromotionEdge>>>;
  nodes?: Maybe<Array<Maybe<PatientPromotion>>>;
  pageInfo: PageInfo;
};

export type PatientPromotionEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<PatientPromotion>;
};

export const PatientRecommendationStates = {
  Cancelled: 'cancelled',
  Delivered: 'delivered',
  Expired: 'expired',
  Incomplete: 'incomplete',
  InterpretationShared: 'interpretation_shared',
  NotPurchased: 'not_purchased',
  PartialResults: 'partial_results',
  Processing: 'processing',
  Purchased: 'purchased',
  Refunded: 'refunded',
  Rejected: 'rejected',
  RequisitionPending: 'requisition_pending',
  RequisitionReady: 'requisition_ready',
  ResultsAmended: 'results_amended',
  ResultsReady: 'results_ready',
  Shipped: 'shipped',
  SpecimenIssue: 'specimen_issue'
} as const;

export type PatientRecommendationStates = typeof PatientRecommendationStates[keyof typeof PatientRecommendationStates];
export type PatientRefundShippingInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderNumber: Scalars['String']['input'];
};

export type PatientRefundShippingPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
};

export type PatientSearchConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<PatientEdge>>>;
  nodes?: Maybe<Array<Maybe<Patient>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PatientSort = {
  direction: SortDirection;
  field: PatientSortField;
};

export const PatientSortField = {
  CreatedAt: 'CREATED_AT',
  FirstName: 'FIRST_NAME',
  LastName: 'LAST_NAME',
  TreatmentPlansCount: 'TREATMENT_PLANS_COUNT'
} as const;

export type PatientSortField = typeof PatientSortField[keyof typeof PatientSortField];
export type PatientUpdateAddressesInput = {
  attributes: PatientAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientUpdateAddressesPayload = {
  addresses: Array<PatientAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: BaseAddressMutationError;
};

export type PatientUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientAttributes: PatientAttributes;
};

export type PatientUpdateIterableLastAppLoginInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientUpdateIterableLastAppLoginPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
};

export type PatientUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  user?: Maybe<User>;
};

export type PatientVariantNotificationDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

export type PatientVariantNotificationDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  variant?: Maybe<Variant>;
};

export type PatientVariantNotificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

export type PatientVariantNotificationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  variant?: Maybe<Variant>;
};

export type PatientsFilterObject = {
  allByLatestTp?: InputMaybe<Scalars['Boolean']['input']>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<PatientSort>>;
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  withNoAssignedPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pavr = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  enteredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  ignoredAt?: Maybe<Scalars['DateTime']['output']>;
  patientId: Scalars['ID']['output'];
  suggestedAddress1?: Maybe<Scalars['String']['output']>;
  suggestedAddress2?: Maybe<Scalars['String']['output']>;
  suggestedCity?: Maybe<Scalars['String']['output']>;
  suggestedCountryId?: Maybe<Scalars['ID']['output']>;
  suggestedState?: Maybe<Scalars['String']['output']>;
  suggestedZipcode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  validatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PayoutDate = {
  payoutDate: Scalars['Date']['output'];
  payoutId: Scalars['ID']['output'];
};

export type PayoutEntryType = {
  bonusProceeds?: Maybe<Scalars['Currency']['output']>;
  costOfGoods?: Maybe<Scalars['Currency']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  msrp?: Maybe<Scalars['Currency']['output']>;
  netProceeds?: Maybe<Scalars['Currency']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['String']['output']>;
  patientDiscount?: Maybe<Scalars['Currency']['output']>;
  patientEmail?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['String']['output']>;
  patientName?: Maybe<Scalars['String']['output']>;
  sellingPrice?: Maybe<Scalars['Currency']['output']>;
  shipmentNumber?: Maybe<Scalars['String']['output']>;
};

export type PayoutReportsType = {
  cancellations?: Maybe<Array<PayoutEntryType>>;
  cancellationsSubtotal?: Maybe<Scalars['Currency']['output']>;
  depositTotal?: Maybe<Scalars['Currency']['output']>;
  earnings?: Maybe<Array<PayoutEntryType>>;
  earningsSubtotal?: Maybe<Scalars['Currency']['output']>;
  hasBonusProceeds?: Maybe<Scalars['Boolean']['output']>;
  returns?: Maybe<Array<PayoutEntryType>>;
  returnsSubtotal?: Maybe<Scalars['Currency']['output']>;
  statementNumber?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Currency']['output']>;
  taxes?: Maybe<Scalars['Currency']['output']>;
  total?: Maybe<Scalars['Currency']['output']>;
};

export type PeerReferral = Node & Timestamps & {
  bonusMarginEarned?: Maybe<Scalars['Int']['output']>;
  bonusMarginExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  bonusMarginStartDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  firstCertApprovedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isDeleteable: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  referredEmail?: Maybe<Scalars['String']['output']>;
  referredFirstName?: Maybe<Scalars['String']['output']>;
  referredLastName?: Maybe<Scalars['String']['output']>;
  referrer: OfficeRoleUnion;
  signedUpAt?: Maybe<Scalars['DateTime']['output']>;
  status: PeerReferralStatuses;
  updatedAt: Scalars['DateTime']['output'];
};

export type PeerReferralConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<PeerReferralEdge>>>;
  nodes?: Maybe<Array<Maybe<PeerReferral>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PeerReferralCreateAttributes = {
  referredEmail: Scalars['String']['input'];
  referredFirstName: Scalars['String']['input'];
  referredLastName: Scalars['String']['input'];
};

export type PeerReferralEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<PeerReferral>;
};

export type PeerReferralProgram = {
  bonusMarginEnabled: Scalars['Boolean']['output'];
  capAmountReached?: Maybe<Scalars['Boolean']['output']>;
  currentBonusMargin: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  peerReferrals: PeerReferralConnection;
  shareableReferralLink: Scalars['String']['output'];
  showBonusMarginDisabledBanner: Scalars['Boolean']['output'];
  stackedBonusMarginExpirationDate?: Maybe<Scalars['DateTime']['output']>;
};


export type PeerReferralProgramPeerReferralsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PeerReferralSort>>;
};

export type PeerReferralProgramConfiguration = Node & {
  bonusMarginPercentage: Scalars['Int']['output'];
  bonusMarginPeriodPerReferral: Scalars['Int']['output'];
  eligibleReferralsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  referralInviteExpiration: Scalars['Int']['output'];
  totalAmountLimit: Scalars['Int']['output'];
};

export type PeerReferralSort = {
  direction: SortDirection;
  field: PeerReferralSortField;
};

export const PeerReferralSortField = {
  CreatedAt: 'CREATED_AT'
} as const;

export type PeerReferralSortField = typeof PeerReferralSortField[keyof typeof PeerReferralSortField];
export const PeerReferralStatuses = {
  CompletingAccount: 'COMPLETING_ACCOUNT',
  Expired: 'EXPIRED',
  MarginApplied: 'MARGIN_APPLIED',
  ReferralSent: 'REFERRAL_SENT',
  Referred: 'REFERRED'
} as const;

export type PeerReferralStatuses = typeof PeerReferralStatuses[keyof typeof PeerReferralStatuses];
export type PeerReferralsCreateError = {
  fields?: Maybe<PeerReferralsCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PeerReferralsCreateFieldError = {
  referredEmail?: Maybe<Array<Scalars['String']['output']>>;
  referredFirstName?: Maybe<Array<Scalars['String']['output']>>;
  referredLastName?: Maybe<Array<Scalars['String']['output']>>;
};

export type PeerReferralsCreateInput = {
  attributes: PeerReferralCreateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PeerReferralsCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PeerReferralsCreateError;
  referral?: Maybe<PeerReferral>;
};

export type PeerReferralsDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PeerReferralsDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  peerReferralId: Scalars['ID']['input'];
};

export type PeerReferralsDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<PeerReferralsDeleteError>;
  peerReferralProgram?: Maybe<PeerReferralProgram>;
  referral?: Maybe<PeerReferral>;
};

export type PeerReferralsUpdateBonusMarginEnabledError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PeerReferralsUpdateBonusMarginEnabledInput = {
  bonusMarginEnabled: Scalars['Boolean']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PeerReferralsUpdateBonusMarginEnabledPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<PeerReferralsUpdateBonusMarginEnabledError>;
  peerReferralProgram?: Maybe<PeerReferralProgram>;
};

export const PhotoSizeFilters = {
  LargeSize: 'LARGE_SIZE',
  SmallSize: 'SMALL_SIZE'
} as const;

export type PhotoSizeFilters = typeof PhotoSizeFilters[keyof typeof PhotoSizeFilters];
export type PillCategory = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variants?: Maybe<VariantConnection>;
};


export type PillCategoryVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PlainPatient = {
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<Scalars['String']['output']>;
  smsNumber?: Maybe<Scalars['String']['output']>;
};

export type PlanIngredient = {
  alternativeDosage?: Maybe<Scalars['Float']['output']>;
  alternativeDosageUnit?: Maybe<Scalars['String']['output']>;
  childIngredients?: Maybe<Array<PlanIngredient>>;
  dosage?: Maybe<Scalars['Float']['output']>;
  dosageUnit?: Maybe<Scalars['String']['output']>;
  ingredient: Ingredient;
  productCount?: Maybe<Scalars['Int']['output']>;
  productNames?: Maybe<Array<Scalars['String']['output']>>;
};

export type PlanOptimizationCategory = {
  name: Scalars['String']['output'];
  recommendationAlternatives?: Maybe<Array<RecommendationAlternative>>;
};

export const PlanTypes = {
  MultiPatientPlan: 'MULTI_PATIENT_PLAN',
  TreatmentPlan: 'TREATMENT_PLAN'
} as const;

export type PlanTypes = typeof PlanTypes[keyof typeof PlanTypes];
export type PlanUnion = MultiPatientPlan | TreatmentPlan | TreatmentPlanTemplate;

export type PlansConvertAttributes = {
  convertTo: Scalars['String']['input'];
  planId: Scalars['ID']['input'];
  planName?: InputMaybe<Scalars['String']['input']>;
  planType: Scalars['String']['input'];
};

export type PlatformRedirectError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PlatformRedirectInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  targetPlatformName: StorePlatformType;
};

export type PlatformRedirectPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PlatformRedirectError;
  redirectPath?: Maybe<Scalars['String']['output']>;
};

export type Population = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Practitioner = Node & Roleable & Timestamps & {
  acceptedAgreements?: Maybe<Array<PractitionerAgreement>>;
  activeLabPromotions?: Maybe<Array<PromotionType>>;
  activePlansWithLabsCount: Scalars['Int']['output'];
  agreementUrl?: Maybe<Scalars['String']['output']>;
  anyCredentialApproved: Scalars['Boolean']['output'];
  atRiskProducts?: Maybe<Array<Product>>;
  atRiskVariants?: Maybe<VariantConnection>;
  atRiskVariantsCount: Scalars['Int']['output'];
  avatar?: Maybe<Avatar>;
  canAccessWpp: Scalars['Boolean']['output'];
  canOrderLabs: Scalars['Boolean']['output'];
  certifications: Array<Certification>;
  createdAt: Scalars['DateTime']['output'];
  dispensingChecklist: PractitionerDispensingChecklist;
  email: Scalars['String']['output'];
  emersonNotificationEmail: Scalars['String']['output'];
  externalIntegrations?: Maybe<Array<ApiExternalMetadatum>>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  hasAcceptedLabsBaa: Scalars['Boolean']['output'];
  hasFailedLabsVerification: Scalars['Boolean']['output'];
  hasMultipleStorePlatforms: Scalars['Boolean']['output'];
  hasReportAccess: Scalars['Boolean']['output'];
  hasSentFirstTpWithLabs: Scalars['Boolean']['output'];
  hasSentFourthTpWithLabs: Scalars['Boolean']['output'];
  hasSentSecondTpWithLabs: Scalars['Boolean']['output'];
  hasSentSixthTpWithLabs: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  internalWholesaleCarts?: Maybe<Array<WholesaleOrder>>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isLocked: Scalars['Boolean']['output'];
  isOnLabsWaitlist: Scalars['Boolean']['output'];
  isSignupPending: Scalars['Boolean']['output'];
  isStoreOwner: Scalars['Boolean']['output'];
  isWellevate: Scalars['Boolean']['output'];
  labsLicenseStatus?: Maybe<LabsLicenseStatuses>;
  labsOrderingPermissionLevel: LabsOrderingPermissionLevels;
  lastName: Scalars['String']['output'];
  latestLicense?: Maybe<PractitionerLicense>;
  libraryContents: ImatContentConnection;
  migrationStatus?: Maybe<MigrationStatus>;
  multiPatientPlansWithAtRiskVariant: MultiPatientPlanConnection;
  name: Scalars['String']['output'];
  notificationSettings?: Maybe<NotificationSettings>;
  /** @deprecated Use npiNumber instead */
  npi?: Maybe<Scalars['String']['output']>;
  npiApprovalState?: Maybe<Scalars['Int']['output']>;
  npiNumber?: Maybe<Scalars['String']['output']>;
  panels?: Maybe<LabsPanelConnection>;
  panelsAndTemplates?: Maybe<TemplateUnionConnection>;
  pastSwaps?: Maybe<Array<ReplaceVariant>>;
  patientCount: Scalars['Int']['output'];
  patientTerm?: Maybe<Scalars['String']['output']>;
  personalizations: Array<PrescribingPersonalizationValue>;
  practitionerType: PractitionerType;
  /** @deprecated Use 'personalizations' field instead */
  prescribingPersonalizations: Array<PrescribingPersonalization>;
  properties?: Maybe<PractitionerProperties>;
  publicProfile?: Maybe<PublicProfile>;
  sharedTemplates: TreatmentPlanTemplateConnection;
  shipState?: Maybe<State>;
  store: Store;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeName?: Maybe<Scalars['String']['output']>;
  storePlatforms: Array<StorePlatform>;
  studentProfile?: Maybe<StudentProfile>;
  suffix?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  treatmentPlanList: Array<TreatmentPlanList>;
  treatmentPlanSnippets: Array<TreatmentPlanSnippet>;
  /** @deprecated Use treatmentPlanTemplates in currentOffice instead. */
  treatmentPlanTemplates: TreatmentPlanTemplateConnection;
  treatmentPlanTerm?: Maybe<Scalars['String']['output']>;
  treatmentPlans: TreatmentPlanConnection;
  treatmentPlansWithAtRiskVariant: TreatmentPlanConnection;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  wellevatePractitioner?: Maybe<WellevatePractitioner>;
};


export type PractitionerAgreementUrlArgs = {
  agreementType: PractitionerAgreementTypes;
};


export type PractitionerAtRiskVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters: AtRiskVariantsFilterObject;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AtRiskVariantsSort>;
};


export type PractitionerAtRiskVariantsCountArgs = {
  filters?: InputMaybe<AtRiskVariantsFilterObject>;
};


export type PractitionerCertificationsArgs = {
  storePlatform?: InputMaybe<StorePlatformType>;
};


export type PractitionerLibraryContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LibraryFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FullscriptContentSort>>;
};


export type PractitionerMultiPatientPlansWithAtRiskVariantArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  atRiskVariantId: Scalars['ID']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PractitionerPanelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PractitionerPanelsAndTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PanelTemplateSort>>;
  templateFilters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
};


export type PractitionerPatientCountArgs = {
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PractitionerPersonalizationsArgs = {
  filters?: InputMaybe<PrescribingPersonalizationFilterObject>;
};


export type PractitionerPrescribingPersonalizationsArgs = {
  filters?: InputMaybe<PrescribingPersonalizationFilterObject>;
};


export type PractitionerSharedTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PractitionerTreatmentPlanListArgs = {
  filterObject?: InputMaybe<TreatmentPlanListFilterObject>;
  filters?: InputMaybe<Array<TreatmentPlanFilters>>;
  sort?: InputMaybe<Array<TreatmentPlanSort>>;
};


export type PractitionerTreatmentPlanSnippetsArgs = {
  filters?: InputMaybe<TreatmentPlanSnippetFilterObject>;
  sort?: InputMaybe<Array<TreatmentPlanSnippetSort>>;
};


export type PractitionerTreatmentPlanTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PractitionerTreatmentPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TreatmentPlanSort>>;
};


export type PractitionerTreatmentPlansWithAtRiskVariantArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  atRiskVariantId: Scalars['ID']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PractitionerAgreement = Timestamps & {
  accepted: Scalars['Boolean']['output'];
  acceptedAt: Scalars['DateTime']['output'];
  agreementType: PractitionerAgreementTypes;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  practitioner?: Maybe<Practitioner>;
  updatedAt: Scalars['DateTime']['output'];
};

export const PractitionerAgreementTypes = {
  IntegrationBusinessAssociateAgreement: 'integration_business_associate_agreement',
  LabsBusinessAssociateAgreement: 'labs_business_associate_agreement',
  LabsFullscriptTos: 'labs_fullscript_tos',
  LabsNpiSubmission: 'labs_npi_submission',
  LabsPhysicianNetworkTos: 'labs_physician_network_tos',
  PlanWritingAssistantAgreement: 'plan_writing_assistant_agreement'
} as const;

export type PractitionerAgreementTypes = typeof PractitionerAgreementTypes[keyof typeof PractitionerAgreementTypes];
export type PractitionerAndStoreMerchantDataAttributes = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PractitionerAndStoreMerchantDataUpdateError = {
  fields?: Maybe<PractitionerAndStoreMerchantDataUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerAndStoreMerchantDataUpdateFieldError = {
  businessName?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  postalCode?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress2?: Maybe<Array<Scalars['String']['output']>>;
  suffix?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Array<Scalars['String']['output']>>;
};

export type PractitionerAndStoreMerchantDataUpdateInput = {
  attributes: PractitionerAndStoreMerchantDataAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PractitionerAndStoreMerchantDataUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerAndStoreMerchantDataUpdateError;
  merchantData?: Maybe<MerchantData>;
  practitioner?: Maybe<Practitioner>;
};

export type PractitionerArchiveError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerArchiveInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerId: Scalars['ID']['input'];
  substitutePractitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export type PractitionerArchivePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerArchiveError;
  practitioner?: Maybe<Practitioner>;
};

export type PractitionerAttributes = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  npiApprovalState?: InputMaybe<Scalars['Int']['input']>;
  patientTerm?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTerm?: InputMaybe<Scalars['String']['input']>;
};

export type PractitionerCertificationAttributes = {
  certificate: Scalars['Upload']['input'];
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};

export type PractitionerCertificationAttributesV2 = {
  certificate?: InputMaybe<Scalars['Upload']['input']>;
  graduationDate?: InputMaybe<Scalars['String']['input']>;
  npiNumber?: InputMaybe<Scalars['String']['input']>;
  overwrites?: InputMaybe<CertificationOverwrites>;
  phone: Scalars['String']['input'];
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
  region: Scalars['String']['input'];
};

export type PractitionerCertificationAttributesV3 = {
  certificate?: InputMaybe<Scalars['Upload']['input']>;
  graduationDate?: InputMaybe<Scalars['String']['input']>;
  npiNumber?: InputMaybe<Scalars['String']['input']>;
  overwrites?: InputMaybe<CertificationOverwrites>;
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type PractitionerCertificationCreateError = {
  fields?: Maybe<PractitionerCertificationCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerCertificationCreateFieldError = {
  certificate?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
};

export type PractitionerCertificationCreateInput = {
  attributes: PractitionerCertificationAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PractitionerCertificationCreatePayload = {
  certification?: Maybe<Certification>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerCertificationCreateError;
};

export type PractitionerCertificationLoyalistAttributes = {
  phone: Scalars['String']['input'];
  region: Scalars['String']['input'];
};

export type PractitionerCertificationUpdateAttributes = {
  id: Scalars['ID']['input'];
  licensingStateId?: InputMaybe<Scalars['ID']['input']>;
};

export type PractitionerCertificationUpdateError = {
  fields?: Maybe<PractitionerCertificationUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerCertificationUpdateFieldError = {
  id?: Maybe<Array<Scalars['String']['output']>>;
  licensingStateId?: Maybe<Array<Scalars['String']['output']>>;
};

export type PractitionerCertificationUpdateInput = {
  attributes: PractitionerCertificationUpdateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PractitionerCertificationUpdatePayload = {
  certification?: Maybe<Certification>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerCertificationUpdateError;
};

export type PractitionerConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<PractitionerEdge>>>;
  nodes?: Maybe<Array<Maybe<Practitioner>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PractitionerDispensingChecklist = {
  shouldRenderChecklist: Scalars['Boolean']['output'];
};

export type PractitionerEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Practitioner>;
};

export type PractitionerEmersonCertificationAttributes = {
  certificate: Scalars['Upload']['input'];
};

export type PractitionerEmersonNotificationEmailUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerEmersonNotificationEmailUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

export type PractitionerEmersonNotificationEmailUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<PractitionerEmersonNotificationEmailUpdateError>;
  notificationEmail?: Maybe<Scalars['String']['output']>;
};

export type PractitionerFilterObject = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  nameAndEmailQuery?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type PractitionerLicense = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  licenseExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licenseState?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  practitioner: Practitioner;
  practitionerType?: Maybe<PractitionerType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PractitionerNotificationSettingsAttributes = {
  notifyLabs?: InputMaybe<Scalars['Boolean']['input']>;
  notifyOrders?: InputMaybe<Scalars['Boolean']['input']>;
  notifyOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  notifyPatients?: InputMaybe<Scalars['Boolean']['input']>;
  notifyProductAvailability?: InputMaybe<Scalars['String']['input']>;
  notifyShipments?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PractitionerProperties = {
  canView1099kBanner: Scalars['Boolean']['output'];
  canViewTaxReport: Scalars['Boolean']['output'];
  hasGlobalPatients: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isEligibleForTaxExemption: Scalars['Boolean']['output'];
  isGlobalAlertVisible: Scalars['Boolean']['output'];
  isNeverMarginStore: Scalars['Boolean']['output'];
  isStoreOwner: Scalars['Boolean']['output'];
  isStudent: Scalars['Boolean']['output'];
  isStudentGraduationDateInTheFuture: Scalars['Boolean']['output'];
};

export const PractitionerRecommendationStates = {
  Approved: 'approved',
  Cancelled: 'cancelled',
  Complete: 'complete',
  Delivered: 'delivered',
  Expired: 'expired',
  Incomplete: 'incomplete',
  InterpretationReady: 'interpretation_ready',
  InterpretationShared: 'interpretation_shared',
  NotPurchased: 'not_purchased',
  PartialResults: 'partial_results',
  Processing: 'processing',
  Purchased: 'purchased',
  Refunded: 'refunded',
  Rejected: 'rejected',
  ResultsAmended: 'results_amended',
  ResultsReady: 'results_ready',
  Shipped: 'shipped',
  SpecimenIssue: 'specimen_issue'
} as const;

export type PractitionerRecommendationStates = typeof PractitionerRecommendationStates[keyof typeof PractitionerRecommendationStates];
export type PractitionerRequestSharedAccountVerificationEmailError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerRequestSharedAccountVerificationEmailInput = {
  captchaToken: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  requestedPlatform: Scalars['String']['input'];
};

export type PractitionerRequestSharedAccountVerificationEmailPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerRequestSharedAccountVerificationEmailError;
  sent?: Maybe<Scalars['Boolean']['output']>;
};

export type PractitionerRestoreError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerRestoreInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerId: Scalars['ID']['input'];
};

export type PractitionerRestorePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerRestoreError;
  practitioner?: Maybe<Practitioner>;
};

export type PractitionerSelfEnrollError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerSelfEnrollInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PractitionerSelfEnrollPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerSelfEnrollError;
  migrationStatus?: Maybe<MigrationStatus>;
};

export type PractitionerType = Timestamps & {
  code: Scalars['String']['output'];
  core: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  explorePopularSearches?: Maybe<Array<ExplorePopularSearch>>;
  hasTopRecommendedProducts?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  topRecommendedProducts?: Maybe<ProductConnection>;
  updatedAt: Scalars['DateTime']['output'];
};


export type PractitionerTypeTopRecommendedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TopRecommendedProductSort>;
};

export type PractitionerTypeUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerTypeUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerTypeId: Scalars['ID']['input'];
};

export type PractitionerTypeUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<PractitionerTypeUpdateError>;
  practitioner?: Maybe<Practitioner>;
};

export type PractitionerUpdateError = {
  fields?: Maybe<PractitionerUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerUpdateFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  npiApprovalState?: Maybe<Array<Scalars['String']['output']>>;
  patientTerm?: Maybe<Array<Scalars['String']['output']>>;
  suffix?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanTerm?: Maybe<Array<Scalars['String']['output']>>;
};

export type PractitionerUpdateInput = {
  attributes: PractitionerAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PractitionerUpdateLoginEmailError = {
  fields?: Maybe<PractitionerUpdateLoginEmailFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PractitionerUpdateLoginEmailFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
};

export type PractitionerUpdateLoginEmailInput = {
  attributes: UserEmailAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PractitionerUpdateLoginEmailPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerUpdateLoginEmailError;
  user?: Maybe<User>;
};

export type PractitionerUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerUpdateError;
  practitioner?: Maybe<Practitioner>;
};

export type PractitionerVariantNotificationDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

export type PractitionerVariantNotificationDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  variant?: Maybe<Variant>;
};

export type PractitionerVariantNotificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

export type PractitionerVariantNotificationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  variant?: Maybe<Variant>;
};

export type PrescribingPersonalization = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use the value on PrescribingPersonalizationValue instead */
  isEnabledForPractitioner?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use the value on PrescribingPersonalizationValue instead */
  isEnabledForStore?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated Use the value on PrescribingPersonalizationValue instead */
  overridableByPractitioner?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PrescribingPersonalizationFilterObject = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type PrescribingPersonalizationValue = {
  id: Scalars['ID']['output'];
  isEnabledForPractitioner?: Maybe<Scalars['Boolean']['output']>;
  isEnabledForStore?: Maybe<Scalars['Boolean']['output']>;
  overridableByPractitioner?: Maybe<Scalars['Boolean']['output']>;
  prescribingPersonalization: PrescribingPersonalization;
};

export type Product = LegacyId & Node & Timestamps & {
  _legacyId: Scalars['Int']['output'];
  ageRestrictedStates?: Maybe<Array<AgeRestrictedProductState>>;
  allergens: Array<Allergen>;
  amDosageTotal?: Maybe<Scalars['Int']['output']>;
  anySimilarProductAvailable?: Maybe<Scalars['Boolean']['output']>;
  availableOrMasterVariant: Variant;
  brand: Brand;
  brandName: Scalars['String']['output'];
  categories?: Maybe<CategoryConnection>;
  complementaryProducts: Array<Product>;
  conditions: Array<HealthCondition>;
  createdAt: Scalars['DateTime']['output'];
  defaultDosageInstruction?: Maybe<DefaultDosageInstruction>;
  defaultPatientVariant?: Maybe<Variant>;
  description?: Maybe<Scalars['String']['output']>;
  dosageAmount?: Maybe<Scalars['String']['output']>;
  dosageFormat?: Maybe<Scalars['String']['output']>;
  dosageFrequency?: Maybe<Scalars['String']['output']>;
  dosageInstructions?: Maybe<Scalars['String']['output']>;
  dosagePermutations?: Maybe<Scalars['String']['output']>;
  emersonDescription?: Maybe<Scalars['String']['output']>;
  emersonWarnings?: Maybe<Scalars['String']['output']>;
  eqpLevel?: Maybe<EqpLevelType>;
  hasDosage: Scalars['Boolean']['output'];
  heatSensitive?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  inOffice: Scalars['Boolean']['output'];
  ingredients: Array<ProductIngredient>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  isInStoreCategories: Scalars['Boolean']['output'];
  latestPatientRecommendation?: Maybe<Recommendation>;
  /** @deprecated Use `availableOrMasterVariant` instead. */
  masterVariant: Variant;
  methodOfExposure?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  otherIngredients?: Maybe<Scalars['String']['output']>;
  patientStatistics?: Maybe<ProductPatientStatisticConnection>;
  pmDosageTotal?: Maybe<Scalars['Int']['output']>;
  prescriptionOnly: Scalars['Boolean']['output'];
  productOrderHistory: ProductOrderHistory;
  prop65Chemicals?: Maybe<Array<Prop65Chemical>>;
  prop65PrimaryChemical?: Maybe<Prop65Chemical>;
  prop65Risks?: Maybe<Array<Prop65Risk>>;
  proposition65: Scalars['Boolean']['output'];
  recommendationAlternativeFeedback?: Maybe<RecommendationAlternativeFeedback>;
  servingSize?: Maybe<Scalars['Float']['output']>;
  servingUnit?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  shortShelfLife?: Maybe<Scalars['Boolean']['output']>;
  similarProducts: ProductConnection;
  slug?: Maybe<Scalars['String']['output']>;
  storeAllowedWholesale: Scalars['Boolean']['output'];
  suggestedAmount?: Maybe<Scalars['String']['output']>;
  suggestedFormat?: Maybe<Scalars['String']['output']>;
  suggestedFrequency?: Maybe<Scalars['String']['output']>;
  taxonomies: Array<ProductTaxonomy>;
  thirdPartyCertifications: Array<ThirdPartyCertification>;
  updatedAt: Scalars['DateTime']['output'];
  variantCount: Scalars['Int']['output'];
  variantForSwapOrMaster: Variant;
  variants: Array<Variant>;
  warnings?: Maybe<Scalars['String']['output']>;
};


export type ProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductPatientStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductRecommendationAlternativeFeedbackArgs = {
  similarProductId: Scalars['ID']['input'];
};


export type ProductSimilarProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductVariantsArgs = {
  filters?: InputMaybe<VariantFilterObject>;
};

export type ProductAccessRequest = Timestamps & {
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  patient: Patient;
  product: Product;
  recommendation?: Maybe<Recommendation>;
  requestedAt: Scalars['DateTime']['output'];
  status: ProductAccessRequestStatuses;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductAccessRequestConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<ProductAccessRequestEdge>>>;
  nodes?: Maybe<Array<Maybe<ProductAccessRequest>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductAccessRequestEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductAccessRequest>;
};

export const ProductAccessRequestStatuses = {
  Approved: 'APPROVED',
  Pending: 'PENDING',
  Rejected: 'REJECTED'
} as const;

export type ProductAccessRequestStatuses = typeof ProductAccessRequestStatuses[keyof typeof ProductAccessRequestStatuses];
export type ProductConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  nodes?: Maybe<Array<Maybe<Product>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Product>;
};

export const ProductFilterListField = {
  NewOnFullscript: 'NEW_ON_FULLSCRIPT',
  PopularByWeek: 'POPULAR_BY_WEEK',
  WholesalePopularByWeek: 'WHOLESALE_POPULAR_BY_WEEK'
} as const;

export type ProductFilterListField = typeof ProductFilterListField[keyof typeof ProductFilterListField];
export type ProductFilterObject = {
  byBrand?: InputMaybe<Scalars['ID']['input']>;
  list?: InputMaybe<ProductFilterListField>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductIngredient = Node & Timestamps & {
  amount?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ingredient: Ingredient;
  parentIngredient?: Maybe<Ingredient>;
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductOrderHistory = {
  hasBeenOrderedBefore: Scalars['Boolean']['output'];
  lastOrdered?: Maybe<Scalars['DateTime']['output']>;
  recommendedAutoshipLength?: Maybe<Scalars['String']['output']>;
};

export type ProductPatientStatistic = Node & {
  id: Scalars['ID']['output'];
  lastPrescribedAt: Scalars['DateTime']['output'];
  patient: Patient;
  prescribedCount: Scalars['Int']['output'];
  product: Product;
};

export type ProductPatientStatisticConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<ProductPatientStatisticEdge>>>;
  nodes?: Maybe<Array<Maybe<ProductPatientStatistic>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductPatientStatisticEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductPatientStatistic>;
};

export type ProductSearchConnection = RecordCounts & {
  aggregations: Scalars['String']['output'];
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  nodes?: Maybe<Array<Maybe<Product>>>;
  pageInfo: PageInfo;
  pinnedIds?: Maybe<Array<Scalars['String']['output']>>;
  searchQueryType: ProductSearchQueryType;
  searchSessionId?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export const ProductSearchQueryType = {
  Organic: 'ORGANIC',
  Pinned: 'PINNED'
} as const;

export type ProductSearchQueryType = typeof ProductSearchQueryType[keyof typeof ProductSearchQueryType];
export type ProductSearchSort = {
  direction: SortDirection;
  field: ProductSearchSortField;
};

export const ProductSearchSortField = {
  Alphabetical: 'ALPHABETICAL',
  EqpLevel: 'EQP_LEVEL',
  MostPrescribed: 'MOST_PRESCRIBED',
  Price: 'PRICE',
  PricePerUnit: 'PRICE_PER_UNIT',
  Relevance: 'RELEVANCE',
  WholesaleTimesOrdered: 'WHOLESALE_TIMES_ORDERED'
} as const;

export type ProductSearchSortField = typeof ProductSearchSortField[keyof typeof ProductSearchSortField];
export const ProductSearchSourceType = {
  Dtp: 'DTP',
  Wholesale: 'WHOLESALE'
} as const;

export type ProductSearchSourceType = typeof ProductSearchSourceType[keyof typeof ProductSearchSourceType];
export type ProductSearchV2FilterObject = {
  allergenIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  brandIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  brandSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  childTags?: InputMaybe<Array<Scalars['String']['input']>>;
  conditionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dosageFormatIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  eeNew?: InputMaybe<Scalars['Boolean']['input']>;
  emersonVariantId?: InputMaybe<Scalars['ID']['input']>;
  eqpLevels?: InputMaybe<Array<EqpLevelType>>;
  excludedIngredients?: InputMaybe<Array<IngredientSearchAttributes>>;
  inStockOnly?: InputMaybe<Scalars['Boolean']['input']>;
  includeWholesale?: InputMaybe<Scalars['Boolean']['input']>;
  ingredients?: InputMaybe<Array<IngredientSearchAttributes>>;
  onlyIngredient?: InputMaybe<Scalars['Boolean']['input']>;
  onlyShowFavorites?: InputMaybe<Scalars['Boolean']['input']>;
  onlyWholesaleHistory?: InputMaybe<Scalars['Boolean']['input']>;
  optimizedTaxonomyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  populationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sku?: InputMaybe<Scalars['String']['input']>;
  supplementTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  taxonomyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  thirdPartyCertificationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  wholesaleDiscounted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductSpotlight = Node & {
  id: Scalars['ID']['output'];
  productSpotlightCategories: Array<ProductSpotlightCategory>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ProductSpotlightCategory = Node & {
  id: Scalars['ID']['output'];
  products: ProductConnection;
  title: Scalars['String']['output'];
};


export type ProductSpotlightCategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductSuggestions = {
  suggestionGroups: Array<SuggestionGroup>;
};

export type ProductSuggestionsAttributes = {
  includeDemographics?: InputMaybe<Scalars['Boolean']['input']>;
  ingredientAttributes?: Array<IngredientAttributes>;
  patientId: Scalars['ID']['input'];
};

export type ProductTaxonomy = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<ProductTaxonomy>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Promotion = Node & Timestamps & {
  campaign?: Maybe<PromotionCampaign>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PromotionCampaign = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  promotions: Array<Promotion>;
  slug: Scalars['String']['output'];
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PromotionType = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PromotionTypeUnion = WholesalePercentOnLineItem | WholesaleTieredPercentItemQuantity | WholesaleTieredPercentItemTotal;

export type Prop65Chemical = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  regulation: Scalars['String']['output'];
};

export type Prop65Risk = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  regulation: Scalars['String']['output'];
};

export type PubMedCondition = {
  healthArea: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PubMedConditionTypeahead = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PublicProfile = Timestamps & {
  address?: Maybe<BaseAddress>;
  bio?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type PublicProfileAttributes = {
  bio?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type PublicProfileUpdateError = {
  fields?: Maybe<PublicProfileUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PublicProfileUpdateFieldError = {
  bio?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  publicName?: Maybe<Array<Scalars['String']['output']>>;
  website?: Maybe<Array<Scalars['String']['output']>>;
};

export type PublicProfileUpdateInput = {
  attributes: PublicProfileAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PublicProfileUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PublicProfileUpdateError;
  practitioner?: Maybe<Practitioner>;
};

export type Query = {
  currentAdmin?: Maybe<Admin>;
  currentClerk?: Maybe<Clerk>;
  currentInOfficePatient?: Maybe<Patient>;
  currentOffice?: Maybe<Office>;
  currentPatient?: Maybe<Patient>;
  currentPractitioner?: Maybe<Practitioner>;
  currentRole?: Maybe<Roleable>;
  currentStore?: Maybe<Store>;
  currentUser?: Maybe<User>;
  practitionerTypes: Array<PractitionerType>;
  signInPath: Scalars['String']['output'];
  viewer?: Maybe<Viewer>;
};


export type QuerySignInPathArgs = {
  email: Scalars['String']['input'];
};

export const QueryOriginTypes = {
  PopularSearch: 'POPULAR_SEARCH',
  RecentSearch: 'RECENT_SEARCH',
  StandardSearch: 'STANDARD_SEARCH'
} as const;

export type QueryOriginTypes = typeof QueryOriginTypes[keyof typeof QueryOriginTypes];
export type RecentlyRecommendedProductSort = {
  direction: SortDirection;
  field?: InputMaybe<RecentlyRecommendedProductSortField>;
};

export const RecentlyRecommendedProductSortField = {
  Alphabetical: 'ALPHABETICAL',
  CreatedAt: 'CREATED_AT',
  Price: 'PRICE'
} as const;

export type RecentlyRecommendedProductSortField = typeof RecentlyRecommendedProductSortField[keyof typeof RecentlyRecommendedProductSortField];
export type RecentlyViewedProductsAddInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

export type RecentlyViewedProductsAddPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  recentlyViewedProducts: ProductConnection;
};


export type RecentlyViewedProductsAddPayloadRecentlyViewedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RecentlyViewedProductsClearInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type RecentlyViewedProductsClearPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  recentlyViewedProducts: ProductConnection;
};


export type RecentlyViewedProductsClearPayloadRecentlyViewedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Recommendation = Node & Timestamps & {
  additionalInfo?: Maybe<Scalars['String']['output']>;
  alreadyRequestedTherapeuticSwitching: Scalars['Boolean']['output'];
  amount?: Maybe<Scalars['String']['output']>;
  availableAt?: Maybe<Scalars['DateTime']['output']>;
  baseFormat?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  isFilledOnSite?: Maybe<Scalars['Boolean']['output']>;
  isSupplierDosage: Scalars['Boolean']['output'];
  isTextOnly: Scalars['Boolean']['output'];
  multiPatientPlan?: Maybe<MultiPatientPlan>;
  originalSource?: Maybe<Recommendation>;
  originalSourceId?: Maybe<Scalars['ID']['output']>;
  patient?: Maybe<Patient>;
  practitioner: Practitioner;
  recommendationAlternative?: Maybe<VariantWithAlternativesType>;
  recommendationSubstitutes?: Maybe<Array<RecommendationSubstitute>>;
  refillable?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use swappedRecommendation instead. */
  replaceVariant?: Maybe<ReplaceVariant>;
  /** @deprecated Use swappedRecommendation instead. */
  replacement?: Maybe<Recommendation>;
  swappedRecommendation?: Maybe<Recommendation>;
  takeWith?: Maybe<Scalars['String']['output']>;
  timeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlan?: Maybe<TreatmentPlan>;
  /** @deprecated Removing practitioner ability to select dosage quantity. */
  unitsToPurchase?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  variant: Variant;
  withFood: Scalars['Boolean']['output'];
};


export type RecommendationRecommendationAlternativeArgs = {
  alternativeVariantId: Scalars['ID']['input'];
};

export type RecommendationAlternative = {
  alternativeVariant: Variant;
  alternativeVariantValue?: Maybe<Scalars['String']['output']>;
  baseVariant: Variant;
  baseVariantValue?: Maybe<Scalars['String']['output']>;
  dailyDosage?: Maybe<Scalars['String']['output']>;
  recommendation: Recommendation;
};

export type RecommendationAlternativeFeedback = Node & Timestamps & {
  baseProduct: Product;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  options?: Maybe<Array<Scalars['String']['output']>>;
  otherOption?: Maybe<Scalars['String']['output']>;
  similarProduct: Product;
  thumbsUp: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const RecommendationEventTypes = {
  AllResultsReceived: 'all_results_received',
  AuthorizedByNetwork: 'authorized_by_network',
  Delivered: 'delivered',
  InitialPatientRelease: 'initial_patient_release',
  Processing: 'processing',
  Purchased: 'purchased',
  RejectedByNetwork: 'rejected_by_network',
  RequisitionReady: 'requisition_ready',
  ResultsAmended: 'results_amended',
  ResultsReceived: 'results_received',
  ResultsSentToPatient: 'results_sent_to_patient',
  SentToPatient: 'sent_to_patient',
  Shipped: 'shipped',
  SpecimenIssue: 'specimen_issue',
  SubsequentPatientRelease: 'subsequent_patient_release'
} as const;

export type RecommendationEventTypes = typeof RecommendationEventTypes[keyof typeof RecommendationEventTypes];
export type RecommendationFilterObject = {
  state?: InputMaybe<Array<RecommendationFilters>>;
  variantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export const RecommendationFilterTypeObject = {
  All: 'ALL',
  OnlyJourneys: 'ONLY_JOURNEYS',
  OnlyUpcomingJourneys: 'ONLY_UPCOMING_JOURNEYS',
  WithoutJourneys: 'WITHOUT_JOURNEYS',
  WithInitialJourneys: 'WITH_INITIAL_JOURNEYS'
} as const;

export type RecommendationFilterTypeObject = typeof RecommendationFilterTypeObject[keyof typeof RecommendationFilterTypeObject];
export const RecommendationFilters = {
  Active: 'ACTIVE',
  Cancelled: 'CANCELLED',
  Inactive: 'INACTIVE'
} as const;

export type RecommendationFilters = typeof RecommendationFilters[keyof typeof RecommendationFilters];
export type RecommendationSubstitute = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  recommendation: Recommendation;
  updatedAt: Scalars['DateTime']['output'];
};

export type RecommendedIngredient = {
  demographicVariations?: Maybe<Array<DemographicVariation>>;
  description: Scalars['String']['output'];
  dosageAmount?: Maybe<Scalars['String']['output']>;
  dosageUnit?: Maybe<Scalars['String']['output']>;
  evidence?: Maybe<Array<Evidence>>;
  id: Scalars['ID']['output'];
  isMain?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type RecordCounts = {
  totalCount: Scalars['Int']['output'];
};

export type ReplaceVariant = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
  newVariant: Variant;
  oldVariant: Variant;
  practitioner: Practitioner;
  treatmentPlan?: Maybe<TreatmentPlan>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ReportAccessAttributes = {
  clerks?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitioners?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ReportAccessUpdateError = {
  fields?: Maybe<ReportAccessUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ReportAccessUpdateFieldError = {
  clerks?: Maybe<Array<Scalars['String']['output']>>;
  practitioners?: Maybe<Array<Scalars['String']['output']>>;
};

export type ReportAccessUpdateInput = {
  attributes: ReportAccessAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ReportAccessUpdatePayload = {
  clerks?: Maybe<Array<Clerk>>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ReportAccessUpdateError;
  practitioners?: Maybe<Array<Practitioner>>;
};

export type RequestedIngredientDetails = {
  dosageAmount?: Maybe<Scalars['String']['output']>;
  dosageUnit?: Maybe<Scalars['String']['output']>;
  ingredient: Ingredient;
};

export type ResearchAssistantArticle = {
  authors: Scalars['String']['output'];
  link: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ResearchAssistantConditionIngredient = {
  condition: Scalars['String']['output'];
  ingredients: Array<ResearchAssistantIngredient>;
};

export type ResearchAssistantDemographic = {
  articles: Array<ResearchAssistantArticle>;
  demographicName: Scalars['String']['output'];
  dosageRange?: Maybe<Scalars['String']['output']>;
  dosageUnit?: Maybe<Scalars['String']['output']>;
};

export type ResearchAssistantIngredient = {
  demographics: Array<ResearchAssistantDemographic>;
  howItHelps: Scalars['String']['output'];
  ingredientName: Scalars['String']['output'];
};

export type RetailCartProp65SwapError = {
  type?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Variant>;
};

export type RetailCheckoutAddToCartInput = {
  cart: Array<CartItemAttributes>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanTemplateId?: InputMaybe<Scalars['ID']['input']>;
};

export type RetailCheckoutAddToCartPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type RetailCheckoutPlaceOrderInput = {
  billAddressAttributes?: InputMaybe<AddressAttributes>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCardAttributes?: InputMaybe<RetailCreditCardAttributes>;
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  selectedCreditCardId?: InputMaybe<Scalars['ID']['input']>;
};

export type RetailCheckoutPlaceOrderPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type RetailCheckoutPrevalidateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RetailCheckoutPrevalidatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type RetailCheckoutProp65SwapInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RetailCheckoutProp65SwapPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<RetailCartProp65SwapError>>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type RetailCheckoutRemoveLabInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RetailCheckoutRemoveLabPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type RetailCheckoutSelectShippingAddressInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  expectedPavrId: Scalars['ID']['input'];
  ignore?: InputMaybe<Scalars['Boolean']['input']>;
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  selectedOption: Scalars['String']['input'];
};

export type RetailCheckoutSelectShippingAddressPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type RetailCheckoutSelectShippingRateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  shippingMethodId?: InputMaybe<Scalars['ID']['input']>;
};

export type RetailCheckoutSelectShippingRatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type RetailCheckoutUpdateCartInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  lineItemId: Scalars['ID']['input'];
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  quantity: Scalars['Int']['input'];
  timeSelected?: InputMaybe<Scalars['String']['input']>;
};

export type RetailCheckoutUpdateCartPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type RetailCheckoutUpdateShippingAddressInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  shipAddressAttributes: AddressAttributes;
};

export type RetailCheckoutUpdateShippingAddressPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

export type RetailCreditCardAttributes = {
  avsState?: InputMaybe<Scalars['String']['input']>;
  ccType?: InputMaybe<Scalars['String']['input']>;
  gatewayPaymentProfileId?: InputMaybe<Scalars['String']['input']>;
  lastDigits?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oneTimeUse?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type RoleFilterObject = {
  excludeArchivedClerk?: InputMaybe<Scalars['Boolean']['input']>;
  excludeArchivedPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
  notArchivedPatients?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<RoleFilterRoleField>>;
};

export const RoleFilterRoleField = {
  Admin: 'ADMIN',
  Clerk: 'CLERK',
  Patients: 'PATIENTS',
  Practitioner: 'PRACTITIONER'
} as const;

export type RoleFilterRoleField = typeof RoleFilterRoleField[keyof typeof RoleFilterRoleField];
export const RoleTypes = {
  Admin: 'ADMIN',
  Clerk: 'CLERK',
  Patient: 'PATIENT',
  Practitioner: 'PRACTITIONER'
} as const;

export type RoleTypes = typeof RoleTypes[keyof typeof RoleTypes];
export type RoleUnion = Clerk | Patient | Practitioner;

export type Roleable = {
  anyCredentialApproved: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  storeName?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type SaveDailyPackTemplateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SaveDailyPackTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dailyPackId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export type SaveDailyPackTemplatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: SaveDailyPackTemplateError;
};

export type SavePatientDefaultAddressInput = {
  billingAddressId?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
};

export type SavePatientDefaultAddressPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
};

export type SearchFilterAttributes = {
  filterContent: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
};

export type SearchFilterObject = {
  list?: InputMaybe<Array<TypeaheadFilterListField>>;
};

export type SearchHistoriesClearError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SearchHistoriesClearInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type SearchHistoriesClearPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SearchHistoriesClearError>;
};

export type SearchHistoriesDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SearchHistoriesDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  searchHistoryId: Scalars['ID']['input'];
};

export type SearchHistoriesDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SearchHistoriesDeleteError>;
};

export type SearchHistoriesSaveError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SearchHistoriesSaveInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filterId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<ProductSearchSourceType>;
};

export type SearchHistoriesSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: SearchHistoriesSaveError;
};

export type SearchHistory = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  filterableId?: Maybe<Scalars['ID']['output']>;
  filterableName?: Maybe<Scalars['String']['output']>;
  filterableType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  query: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SelfServeRefundAttributes = {
  orderNumber: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type SendWellevatePatientEmailError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SendWellevatePatientEmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type SendWellevatePatientEmailPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: SendWellevatePatientEmailError;
};

export type SessionNote = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  patient: Patient;
  patientInfo?: Maybe<Scalars['String']['output']>;
  patientMessage?: Maybe<Scalars['String']['output']>;
  prescription?: Maybe<Scalars['String']['output']>;
  resources?: Maybe<Scalars['String']['output']>;
  supplementList?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SessionNoteConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<SessionNoteEdge>>>;
  nodes?: Maybe<Array<Maybe<SessionNote>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SessionNoteEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<SessionNote>;
};

export type SessionNotesCreateResourcesError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SessionNotesCreateResourcesInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotSessionNoteId: Scalars['ID']['input'];
};

export type SessionNotesCreateResourcesPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SessionNotesCreateResourcesError>;
  sessionNote?: Maybe<SessionNote>;
};

export type SessionNotesCreateSummaryMessageError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SessionNotesCreateSummaryMessageInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotSessionNoteId: Scalars['ID']['input'];
};

export type SessionNotesCreateSummaryMessagePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SessionNotesCreateSummaryMessageError>;
  sessionNote?: Maybe<SessionNote>;
};

export type SessionNotesCreateSupplementsError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SessionNotesCreateSupplementsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotSessionNoteId: Scalars['ID']['input'];
};

export type SessionNotesCreateSupplementsPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SessionNotesCreateSupplementsError>;
  sessionNote?: Maybe<SessionNote>;
};

export type SessionNotesSaveAttributes = {
  copilotSessionNoteId?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
  patientInfo?: InputMaybe<Scalars['String']['input']>;
  prescription?: InputMaybe<Scalars['String']['input']>;
};

export type SessionNotesSaveError = {
  fields?: Maybe<SessionNotesSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type SessionNotesSaveFieldError = {
  copilotSessionNoteId?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  patientInfo?: Maybe<Array<Scalars['String']['output']>>;
  prescription?: Maybe<Array<Scalars['String']['output']>>;
};

export type SessionNotesSaveInput = {
  attributes: SessionNotesSaveAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionNotesSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SessionNotesSaveError>;
  sessionNote?: Maybe<SessionNote>;
};

export type SettingsDispensaryInfoUpdateError = {
  fields?: Maybe<SettingsDispensaryInfoUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type SettingsDispensaryInfoUpdateFieldError = {
  businessNumber?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  merchantDataAddressAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

export type SettingsDispensaryInfoUpdateInput = {
  attributes: StoreDispensaryInfoAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type SettingsDispensaryInfoUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: SettingsDispensaryInfoUpdateError;
  store?: Maybe<Store>;
};

export type SettingsPaymentsAndTaxesAttributes = {
  accountType: Scalars['String']['input'];
  bankAccountHeldBy?: InputMaybe<Scalars['String']['input']>;
  bankAccountHolder?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankAccountType?: InputMaybe<Scalars['String']['input']>;
  bankRoutingNumber?: InputMaybe<Scalars['String']['input']>;
  businessAddress?: InputMaybe<BusinessAddressAttributes>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  classification?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['String']['input'];
  directors?: InputMaybe<Array<StripePersonAttributes>>;
  email?: InputMaybe<Scalars['String']['input']>;
  employerId?: InputMaybe<Scalars['String']['input']>;
  equityOwners?: InputMaybe<Array<StripePersonAttributes>>;
  firstName: Scalars['String']['input'];
  invoiceAddress?: InputMaybe<InvoiceAddressAttributes>;
  isEquityOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isOauth?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  profitMargin?: InputMaybe<Scalars['Int']['input']>;
  region: Scalars['String']['input'];
  ssn: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  useBusinessAddressAsInvoiceAddress?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShipStateUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ShipStateUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  stateId: Scalars['ID']['input'];
};

export type ShipStateUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ShipStateUpdateError;
  state?: Maybe<State>;
};

export type Shipment = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  estimatedShippingDate?: Maybe<Scalars['String']['output']>;
  friendlyShippedOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inventoryUnits: Array<InventoryUnit>;
  lineItems?: Maybe<Array<LineItem>>;
  number: Scalars['String']['output'];
  shippedAt?: Maybe<Scalars['DateTime']['output']>;
  shippingRates?: Maybe<Array<ShippingRate>>;
  state: Scalars['String']['output'];
  tracking?: Maybe<Scalars['String']['output']>;
  trackingInstance?: Maybe<TrackingType>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ShipperAddress = Timestamps & {
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  default?: Maybe<Scalars['Boolean']['output']>;
  defaultPatientAddress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stateId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type ShippingAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  stateId: Scalars['ID']['input'];
  storePlatform?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};

export type ShippingAddressConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<StoreAddressEdge>>>;
  nodes?: Maybe<Array<Maybe<StoreAddress>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShippingAddressCreateError = {
  fields?: Maybe<ShippingAddressCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ShippingAddressCreateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  country?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type ShippingAddressCreateInput = {
  attributes: ShippingAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShippingAddressCreatePayload = {
  address?: Maybe<StoreAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ShippingAddressCreateError;
};

export type ShippingAddressDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ShippingAddressDeleteInput = {
  addressId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ShippingAddressDeletePayload = {
  address?: Maybe<StoreAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ShippingAddressDeleteError;
};

export type ShippingAddressUpdateError = {
  fields?: Maybe<ShippingAddressUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ShippingAddressUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  country?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type ShippingAddressUpdateInput = {
  addressId: Scalars['ID']['input'];
  attributes: ShippingAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShippingAddressUpdatePayload = {
  address?: Maybe<StoreAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ShippingAddressUpdateError;
};

export type ShippingRate = Node & Timestamps & {
  cost: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  estimatedShippingDates?: Maybe<Array<Scalars['Date']['output']>>;
  id: Scalars['ID']['output'];
  isShippingFree: Scalars['Boolean']['output'];
  selected: Scalars['Boolean']['output'];
  shippingMethod: Scalars['String']['output'];
  shippingMethodId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  usingReward: Scalars['Boolean']['output'];
};

export const SortDirection = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
export type StaffSettingsCreatePractitionerAttributes = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  practitionerTypeId: Scalars['ID']['input'];
};

export type StaffSettingsPractitionerCreateError = {
  fields?: Maybe<StaffSettingsPractitionerCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StaffSettingsPractitionerCreateFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StaffSettingsPractitionerCreateInput = {
  attributes: StaffSettingsCreatePractitionerAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StaffSettingsPractitionerCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StaffSettingsPractitionerCreateError;
  practitioner?: Maybe<Practitioner>;
};

export type StaffSettingsPractitionerUpdateError = {
  fields?: Maybe<StaffSettingsPractitionerUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StaffSettingsPractitionerUpdateFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  npi?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StaffSettingsPractitionerUpdateInput = {
  attributes: StaffSettingsUpdatePractitionerAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StaffSettingsPractitionerUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StaffSettingsPractitionerUpdateError;
  practitioner?: Maybe<Practitioner>;
};

export type StaffSettingsResendInvitationAttributes = {
  email: Scalars['String']['input'];
  isClerk: Scalars['Boolean']['input'];
};

export type StaffSettingsUpdatePractitionerAttributes = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type State = Node & Timestamps & {
  abbr: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type StepDetails = {
  name: Scalars['String']['output'];
  stepCompleted: Scalars['Boolean']['output'];
};

export type Store = LegacyId & Node & Timestamps & {
  _legacyId: Scalars['Int']['output'];
  accountModality: Scalars['String']['output'];
  activeBulkEditProcess?: Maybe<BulkEditProcess>;
  addresses: Array<StoreAddress>;
  avataxCustomer?: Maybe<AvataxCustomer>;
  bankAccounts: Array<BankAccount>;
  bulkEditProcesses?: Maybe<Array<BulkEditProcess>>;
  bulkEditSummary: BulkEditSummary;
  businessNumber?: Maybe<Scalars['String']['output']>;
  catalogPermission: CatalogPermissionTypes;
  categories: CategoryConnection;
  certifications?: Maybe<Array<Certification>>;
  clerks: Array<Clerk>;
  combinedEmersonBackorders: EmersonClassicOrderConnection;
  combinedEmersonHistoricOrders: EmersonClassicOrderConnection;
  combinedEmersonHistoricOrdersActiveYears: Array<Scalars['String']['output']>;
  combinedEmersonReorderLineItems: EmersonClassicReorderLineItemConnection;
  commonlyPrescribedProducts: ProductConnection;
  /** @deprecated Being replaced by commonly_prescribed_products */
  commonlyPrescribedVariants?: Maybe<Array<Variant>>;
  completedBulkEditProcess?: Maybe<BulkEditProcess>;
  contactDetails: StoreContactDetails;
  createdAt: Scalars['DateTime']['output'];
  currentSetupPage: Scalars['String']['output'];
  directors?: Maybe<Array<StripeAccountPersonType>>;
  discount: Scalars['Int']['output'];
  displayEnlargedDrawer: Scalars['Boolean']['output'];
  displayPracIaMessaging: Scalars['Boolean']['output'];
  eDeliveryConsent1099k?: Maybe<Scalars['Boolean']['output']>;
  equityOwners?: Maybe<Array<StripeAccountPersonType>>;
  favoriteSearch: ProductSearchConnection;
  favoriteVariants: VariantConnection;
  formattedCurrentBalance?: Maybe<Scalars['String']['output']>;
  formattedPayoutDate?: Maybe<Scalars['String']['output']>;
  freePriorityShipping: Scalars['Boolean']['output'];
  fsExperienceUpgradeStatus?: Maybe<FsExperienceUpgradeStatus>;
  globalPatientPromotion?: Maybe<GlobalPatientPromotion>;
  has1099kCapability: Scalars['Boolean']['output'];
  hasActivePatientPromotion: Scalars['Boolean']['output'];
  hasAdvancedSecurity?: Maybe<Scalars['Boolean']['output']>;
  hasAtLeastOneApprovedCertification?: Maybe<Scalars['Boolean']['output']>;
  hasCertificationForLabs?: Maybe<Scalars['Boolean']['output']>;
  hasCertificationsUnderReview?: Maybe<Scalars['Boolean']['output']>;
  hasCompletedNpSync: Scalars['Boolean']['output'];
  hasGlobalPatientAccess: Scalars['Boolean']['output'];
  hasGrandfatherBonusMargin: Scalars['Boolean']['output'];
  hasMultipleStorePlatforms: Scalars['Boolean']['output'];
  hasNpCustomer: Scalars['Boolean']['output'];
  hasNpiApprovedCertification?: Maybe<Scalars['Boolean']['output']>;
  hasUnexpiredWholesaleTaxCertificate?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  intentionSelectionRequired: Scalars['Boolean']['output'];
  isAddressInfoCompleted: Scalars['Boolean']['output'];
  isApprovedToSendOrdersToDistributor?: Maybe<Scalars['Boolean']['output']>;
  isCheckoutEnabled: Scalars['Boolean']['output'];
  isFs2Experience?: Maybe<Scalars['Boolean']['output']>;
  isInFs2UpgradePeriod?: Maybe<Scalars['Boolean']['output']>;
  isSupplementPlanStore?: Maybe<Scalars['Boolean']['output']>;
  labGlobalCustomFee?: Maybe<StoreGlobalCustomFee>;
  labSigningPractitioners: Array<Practitioner>;
  marginType: Scalars['String']['output'];
  merchantData: MerchantData;
  metricsForRange?: Maybe<StoreMetrics>;
  name: Scalars['String']['output'];
  npOrders: NpOrderConnection;
  numberOfExpiringAvataxCertificates: Scalars['Int']['output'];
  onboarding?: Maybe<StoreOnboarding>;
  partnerStores: PartnerStoreConnection;
  patientDiscountIncrements: Array<Scalars['Int']['output']>;
  patientOrders: OrderConnection;
  patientPromotionOptInStatus?: Maybe<Scalars['Boolean']['output']>;
  patients: PatientSearchConnection;
  personalizations: Array<PrescribingPersonalizationValue>;
  practitionerSearch: PractitionerConnection;
  practitioners: Array<Practitioner>;
  /** @deprecated Use 'personalizations' instead */
  prescribingPersonalizations: Array<PrescribingPersonalization>;
  profitMargin?: Maybe<Scalars['Float']['output']>;
  profitMarginIncrements?: Maybe<Array<Scalars['Int']['output']>>;
  /** @deprecated Public profiles for stores are deprecated */
  publicProfile?: Maybe<PublicProfile>;
  reached1099kThreshold: Scalars['Boolean']['output'];
  realMarginType: Scalars['String']['output'];
  recentlyRecommendedProducts: ProductConnection;
  referralLink: Scalars['String']['output'];
  shippingAddresses: ShippingAddressConnection;
  slug: Scalars['String']['output'];
  storeAdditionalDiscounts?: Maybe<Array<StoreAdditionalDiscount>>;
  storeLandingPage?: Maybe<StoreLandingPage>;
  storeOwnerFullName: Scalars['String']['output'];
  storeOwnerName: Scalars['String']['output'];
  storeOwnerPatientTerm: Scalars['String']['output'];
  storeOwnerTreatmentPlanTerm: Scalars['String']['output'];
  storeSetup: StoreSetup;
  storeTaxReports?: Maybe<StoreTaxReports>;
  storesPatientPromotions?: Maybe<StoresPatientPromotionConnection>;
  stripeAccountState?: Maybe<StripeAccountState>;
  subPractitioners: Array<Practitioner>;
  supplementPlanStore?: Maybe<SupplementPlanStore>;
  treatmentPlans: TreatmentPlanConnection;
  unassignedPatientsCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  wellevatePatientOrders: WellevateOrderConnection;
  wholesale: Scalars['Boolean']['output'];
  wholesaleOrders: WholesaleOrderConnection;
  wholesaleOrdersActiveYears: Array<Scalars['String']['output']>;
  wholesaleReorderLineItems: WholesaleLineItemConnection;
};


export type StoreAddressesArgs = {
  filterObject?: InputMaybe<StoreAddressFilterObject>;
};


export type StoreBulkEditSummaryArgs = {
  filters?: InputMaybe<PatientBulkEditFilterObject>;
  newDiscount?: InputMaybe<Scalars['Int']['input']>;
  selectAllFilters?: InputMaybe<PatientBulkEditAllFilterObject>;
};


export type StoreCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type StoreClerksArgs = {
  filters?: InputMaybe<ClerkFilterObject>;
};


export type StoreCombinedEmersonBackordersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CombinedOrdersFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EmersonClassicCombinedOrdersSort>>;
};


export type StoreCombinedEmersonHistoricOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CombinedOrdersFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EmersonClassicCombinedOrdersSort>>;
};


export type StoreCombinedEmersonReorderLineItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CombinedReorderLineItemsFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CombinedReorderLineItemsSort>>;
};


export type StoreCommonlyPrescribedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  sort?: InputMaybe<CommonlyPrescribedProductSort>;
};


export type StoreCommonlyPrescribedVariantsArgs = {
  filters?: InputMaybe<CommonlyPrescribedVariantFilterObject>;
};


export type StoreFavoriteSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<FavoriteSearchSort>>;
};


export type StoreFavoriteVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<FavoriteVariantFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<FavoriteVariantSort>;
};


export type StoreMetricsForRangeArgs = {
  rangeInDays?: InputMaybe<Scalars['Int']['input']>;
};


export type StoreNpOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<NpOrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<NpOrderSort>>;
};


export type StorePartnerStoresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PartnerStoreFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type StorePatientOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrderSort>>;
};


export type StorePatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PatientsFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type StorePersonalizationsArgs = {
  filters?: InputMaybe<PrescribingPersonalizationFilterObject>;
};


export type StorePractitionerSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PractitionerFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type StorePractitionersArgs = {
  filters?: InputMaybe<PractitionerFilterObject>;
};


export type StorePrescribingPersonalizationsArgs = {
  filters?: InputMaybe<PrescribingPersonalizationFilterObject>;
};


export type StoreRecentlyRecommendedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['ID']['input'];
  sort?: InputMaybe<RecentlyRecommendedProductSort>;
};


export type StoreShippingAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  storePlatform?: InputMaybe<StorePlatformType>;
};


export type StoreStoreSetupArgs = {
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};


export type StoreStoresPatientPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<StoresPatientPromotionFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type StoreSubPractitionersArgs = {
  filters?: InputMaybe<PractitionerFilterObject>;
};


export type StoreTreatmentPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TreatmentPlanSort>>;
};


export type StoreWellevatePatientOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrderSort>>;
};


export type StoreWholesaleOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<WholesaleOrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WholesaleOrderSort>>;
};


export type StoreWholesaleReorderLineItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<WholesaleLineItemFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WholesaleLineItemSort>>;
};

export type StoreAdditionalDiscount = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  discountType: StoreAdditionalDiscountTypes;
  discountValue?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  store: Store;
  updatedAt: Scalars['DateTime']['output'];
};

export type StoreAdditionalDiscountAttributes = {
  discountType: StoreAdditionalDiscountTypes;
  discountValue: Scalars['Int']['input'];
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export const StoreAdditionalDiscountTypes = {
  Autoship: 'AUTOSHIP'
} as const;

export type StoreAdditionalDiscountTypes = typeof StoreAdditionalDiscountTypes[keyof typeof StoreAdditionalDiscountTypes];
export type StoreAdditionalDiscountsUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreAdditionalDiscountsUpdateInput = {
  additionalDiscounts?: InputMaybe<Array<StoreAdditionalDiscountAttributes>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  discount: Scalars['Int']['input'];
};

export type StoreAdditionalDiscountsUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreAdditionalDiscountsUpdateError;
  store?: Maybe<Store>;
};

export type StoreAddress = Timestamps & {
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  approvalStatus: StoreAddressApprovalStates;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  default?: Maybe<Scalars['Boolean']['output']>;
  defaultPatientAddress?: Maybe<Scalars['Boolean']['output']>;
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state: State;
  stateId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zipcode: Scalars['String']['output'];
};

export const StoreAddressApprovalStates = {
  Cleared: 'cleared',
  Failed: 'failed',
  Held: 'held',
  Passed: 'passed',
  Pending: 'pending',
  Rejected: 'rejected',
  Skipped: 'skipped'
} as const;

export type StoreAddressApprovalStates = typeof StoreAddressApprovalStates[keyof typeof StoreAddressApprovalStates];
export type StoreAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  default?: InputMaybe<Scalars['Boolean']['input']>;
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  stateId: Scalars['ID']['input'];
  zipcode: Scalars['String']['input'];
};

export type StoreAddressCreateError = {
  fields?: Maybe<StoreAddressCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreAddressCreateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  default?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreAddressCreateInput = {
  attributes: StoreAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreAddressCreatePayload = {
  address?: Maybe<StoreAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreAddressCreateError;
};

export type StoreAddressDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreAddressDeleteInput = {
  addressId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreAddressDeletePayload = {
  address?: Maybe<StoreAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreAddressDeleteError;
};

export type StoreAddressEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<StoreAddress>;
};

export type StoreAddressFilterObject = {
  state?: InputMaybe<Array<StoreAddressStates>>;
};

export type StoreAddressNewUpdateError = {
  fields?: Maybe<StoreAddressNewUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreAddressNewUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  default?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreAddressNewUpdateInput = {
  addressId: Scalars['ID']['input'];
  attributes: StoreAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreAddressNewUpdatePayload = {
  address?: Maybe<StoreAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreAddressNewUpdateError;
};

export const StoreAddressStates = {
  Approved: 'APPROVED',
  Held: 'HELD'
} as const;

export type StoreAddressStates = typeof StoreAddressStates[keyof typeof StoreAddressStates];
export type StoreAddressUpdateError = {
  fields?: Maybe<StoreAddressUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreAddressUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  default?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreAddressUpdateInput = {
  addressId: Scalars['ID']['input'];
  attributes: StoreAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreAddressUpdatePayload = {
  address?: Maybe<StoreAddress>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreAddressUpdateError;
};

export type StoreClerkAttributes = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type StoreContactDetails = {
  email: Scalars['String']['output'];
};

export type StoreCurrentSetupPageAttributes = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  forProfit?: InputMaybe<Scalars['Boolean']['input']>;
  skipToStep?: InputMaybe<Scalars['String']['input']>;
};

export type StoreCurrentSetupPageUpdateError = {
  fields?: Maybe<StoreCurrentSetupPageUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreCurrentSetupPageUpdateFieldError = {
  apiClientId?: Maybe<Array<Scalars['String']['output']>>;
  forProfit?: Maybe<Array<Scalars['String']['output']>>;
  skipToStep?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreCurrentSetupPageUpdateInput = {
  attributes?: InputMaybe<StoreCurrentSetupPageAttributes>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreCurrentSetupPageUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreCurrentSetupPageUpdateError;
  store: Store;
};

export type StoreDispensaryInfoAttributes = {
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  merchantDataAddressAttributes?: InputMaybe<MerchantDataAddressAttributes>;
};

export type StoreGlobalCustomFee = Node & Timestamps & {
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StoreLabCustomFeeApplyAttributes = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

export type StoreLabCustomFeeAttributes = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['ID']['input'];
};

export type StoreLabCustomFeesAddToStoreError = {
  fields?: Maybe<StoreLabCustomFeesAddToStoreFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreLabCustomFeesAddToStoreFieldError = {
  amount?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreLabCustomFeesAddToStoreInput = {
  attributes: StoreLabCustomFeeAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreLabCustomFeesAddToStorePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabCustomFeesAddToStoreError;
  store?: Maybe<Store>;
};

export type StoreLabCustomFeesApplyToPlanError = {
  fields?: Maybe<StoreLabCustomFeesApplyToPlanFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreLabCustomFeesApplyToPlanFieldError = {
  amount?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreLabCustomFeesApplyToPlanInput = {
  attributes: StoreLabCustomFeeApplyAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreLabCustomFeesApplyToPlanPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabCustomFeesApplyToPlanError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type StoreLabCustomFeesRemoveFromStoreError = {
  fields?: Maybe<StoreLabCustomFeesRemoveFromStoreFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreLabCustomFeesRemoveFromStoreFieldError = {
  amount?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreLabCustomFeesRemoveFromStoreInput = {
  attributes: StoreLabCustomFeeAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreLabCustomFeesRemoveFromStorePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabCustomFeesRemoveFromStoreError;
  store?: Maybe<Store>;
};

export type StoreLabCustomFeesUpdateError = {
  fields?: Maybe<StoreLabCustomFeesUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreLabCustomFeesUpdateFieldError = {
  amount?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreLabCustomFeesUpdateInput = {
  attributes: StoreLabCustomFeeAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreLabCustomFeesUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabCustomFeesUpdateError;
  store?: Maybe<Store>;
};

export type StoreLabSigningPractitionerAddToStoreAttributes = {
  practitionerId: Scalars['ID']['input'];
};

export type StoreLabSigningPractitionerRemoveFromStoreAttributes = {
  practitionerId: Scalars['ID']['input'];
};

export type StoreLabSigningPractitionersAddToStoreError = {
  fields?: Maybe<StoreLabSigningPractitionersAddToStoreFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreLabSigningPractitionersAddToStoreFieldError = {
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreLabSigningPractitionersAddToStoreInput = {
  attributes: StoreLabSigningPractitionerAddToStoreAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreLabSigningPractitionersAddToStorePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabSigningPractitionersAddToStoreError;
  store?: Maybe<Store>;
};

export type StoreLabSigningPractitionersRemoveFromStoreError = {
  fields?: Maybe<StoreLabSigningPractitionersRemoveFromStoreFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreLabSigningPractitionersRemoveFromStoreFieldError = {
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreLabSigningPractitionersRemoveFromStoreInput = {
  attributes: StoreLabSigningPractitionerRemoveFromStoreAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreLabSigningPractitionersRemoveFromStorePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabSigningPractitionersRemoveFromStoreError;
  store?: Maybe<Store>;
};

export type StoreLandingPage = {
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type StoreLandingPageUpdateAttributes = {
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['ID']['input'];
};

export type StoreLandingPageUpdateError = {
  fields?: Maybe<StoreLandingPageUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreLandingPageUpdateFieldError = {
  logo?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  slug?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreLandingPageUpdateInput = {
  attributes: StoreLandingPageUpdateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreLandingPageUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLandingPageUpdateError;
  store?: Maybe<Store>;
};

export type StoreMetrics = {
  activePatientsCount: Scalars['Int']['output'];
  discountsTotal: Scalars['Float']['output'];
  earnedTotal: Scalars['Float']['output'];
  ordersCount: Scalars['Int']['output'];
  patientsCount: Scalars['Int']['output'];
  previousActivePatientsCount: Scalars['Int']['output'];
  previousEarnedTotal: Scalars['Float']['output'];
  previousOrdersCount: Scalars['Int']['output'];
};

export type StoreOnboarding = {
  id: Scalars['ID']['output'];
  intentionUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  labsIntention: Scalars['Boolean']['output'];
  rxIntention: Scalars['Boolean']['output'];
  stockInOffice?: Maybe<Scalars['Boolean']['output']>;
  store: Store;
  wholesaleIntention: Scalars['Boolean']['output'];
};

export type StoreOnboardingAttributes = {
  justSeeSoftware?: InputMaybe<Scalars['Boolean']['input']>;
  labsIntention?: InputMaybe<Scalars['Boolean']['input']>;
  rxIntention?: InputMaybe<Scalars['Boolean']['input']>;
  stockInOffice?: InputMaybe<Scalars['Boolean']['input']>;
  wholesaleIntention?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoreOnboardingUpdateError = {
  fields?: Maybe<StoreOnboardingUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreOnboardingUpdateFieldError = {
  justSeeSoftware?: Maybe<Array<Scalars['String']['output']>>;
  labsIntention?: Maybe<Array<Scalars['String']['output']>>;
  rxIntention?: Maybe<Array<Scalars['String']['output']>>;
  stockInOffice?: Maybe<Array<Scalars['String']['output']>>;
  wholesaleIntention?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreOnboardingUpdateInput = {
  attributes: StoreOnboardingAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreOnboardingUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreOnboardingUpdateError;
  storeOnboarding?: Maybe<StoreOnboarding>;
};

export type StorePlatform = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const StorePlatformType = {
  Emerson: 'EMERSON',
  Fullscript: 'FULLSCRIPT'
} as const;

export type StorePlatformType = typeof StorePlatformType[keyof typeof StorePlatformType];
export type StoreProfitMarginAttributes = {
  profitMargin?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreProfitMarginUpdateError = {
  fields?: Maybe<StoreProfitMarginUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreProfitMarginUpdateFieldError = {
  profitMargin?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreProfitMarginUpdateInput = {
  attributes: StoreProfitMarginAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreProfitMarginUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoreProfitMarginUpdateError>;
  store?: Maybe<Store>;
};

export type StoreResetToAccountStepUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreResetToAccountStepUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreResetToAccountStepUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreResetToAccountStepUpdateError;
  store: Store;
};

export type StoreSettingsAttributes = {
  hasAdvancedSecurity?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoreSetup = {
  currentStep: Scalars['String']['output'];
  currentStepNumber: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isSetupComplete: Scalars['Boolean']['output'];
  nextStep: NextStep;
  previousStep?: Maybe<Scalars['String']['output']>;
  stepDetails: Array<StepDetails>;
  totalStepCount: TotalStepCount;
};

export type StoreSetupEmersonSubmitAddressInformationError = {
  fields?: Maybe<StoreSetupEmersonSubmitAddressInformationFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreSetupEmersonSubmitAddressInformationFieldError = {
  city?: Maybe<Array<Scalars['String']['output']>>;
  graduationDate?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  postalCode?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress2?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreSetupEmersonSubmitAddressInformationInput = {
  attributes: EmersonMerchantDataAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreSetupEmersonSubmitAddressInformationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoreSetupEmersonSubmitAddressInformationError>;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
  studentProfile?: Maybe<StudentProfile>;
};

export type StoreSetupEmersonSubmitCertUploadError = {
  fields?: Maybe<StoreSetupEmersonSubmitCertUploadFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreSetupEmersonSubmitCertUploadFieldError = {
  certificate?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreSetupEmersonSubmitCertUploadInput = {
  attributes: PractitionerEmersonCertificationAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreSetupEmersonSubmitCertUploadPayload = {
  certification?: Maybe<Certification>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreSetupEmersonSubmitCertUploadError;
  store?: Maybe<Store>;
};

export type StoreSetupMoveSetupToCompleteError = {
  fields?: Maybe<StoreSetupMoveSetupToCompleteFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreSetupMoveSetupToCompleteFieldError = {
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreSetupMoveSetupToCompleteInput = {
  attributes: MoveToSetupCompleteAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreSetupMoveSetupToCompletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoreSetupMoveSetupToCompleteError>;
  store?: Maybe<Store>;
};

export type StoreSetupSubmitAddressInformationError = {
  fields?: Maybe<StoreSetupSubmitAddressInformationFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreSetupSubmitAddressInformationFieldError = {
  accountType?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountHeldBy?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountHolder?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountNumber?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountType?: Maybe<Array<Scalars['String']['output']>>;
  bankRoutingNumber?: Maybe<Array<Scalars['String']['output']>>;
  businessAddress?: Maybe<Array<Scalars['String']['output']>>;
  businessName?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  directors?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  employerId?: Maybe<Array<Scalars['String']['output']>>;
  equityOwners?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  invoiceAddress?: Maybe<Array<Scalars['String']['output']>>;
  isEquityOwner?: Maybe<Array<Scalars['String']['output']>>;
  jobTitle?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  postalCode?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress2?: Maybe<Array<Scalars['String']['output']>>;
  useBusinessAddressAsInvoiceAddress?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreSetupSubmitAddressInformationInput = {
  attributes: MerchantDataAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreSetupSubmitAddressInformationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoreSetupSubmitAddressInformationError>;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
};

export type StoreSetupSubmitCertUploadV2Error = {
  errorPayload?: Maybe<Scalars['JSON']['output']>;
  fields?: Maybe<StoreSetupSubmitCertUploadV2FieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreSetupSubmitCertUploadV2FieldError = {
  certificate?: Maybe<Array<Scalars['String']['output']>>;
  graduationDate?: Maybe<Array<Scalars['String']['output']>>;
  npiNumber?: Maybe<Array<Scalars['String']['output']>>;
  overwrites?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreSetupSubmitCertUploadV2Input = {
  attributes: PractitionerCertificationAttributesV2;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreSetupSubmitCertUploadV2Payload = {
  certification?: Maybe<Certification>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreSetupSubmitCertUploadV2Error;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
  studentProfile?: Maybe<StudentProfile>;
};

export type StoreSetupSubmitCertUploadV3Error = {
  errorPayload?: Maybe<Scalars['JSON']['output']>;
  fields?: Maybe<StoreSetupSubmitCertUploadV3FieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreSetupSubmitCertUploadV3FieldError = {
  certificate?: Maybe<Array<Scalars['String']['output']>>;
  graduationDate?: Maybe<Array<Scalars['String']['output']>>;
  npiNumber?: Maybe<Array<Scalars['String']['output']>>;
  overwrites?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreSetupSubmitCertUploadV3Input = {
  attributes: PractitionerCertificationAttributesV3;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreSetupSubmitCertUploadV3Payload = {
  certification?: Maybe<Certification>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreSetupSubmitCertUploadV3Error;
  npiData?: Maybe<Scalars['JSON']['output']>;
  store?: Maybe<Store>;
  studentProfile?: Maybe<StudentProfile>;
};

export type StoreSetupSubmitLoyalistCertError = {
  fields?: Maybe<StoreSetupSubmitLoyalistCertFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreSetupSubmitLoyalistCertFieldError = {
  phone?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreSetupSubmitLoyalistCertInput = {
  attributes: PractitionerCertificationLoyalistAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreSetupSubmitLoyalistCertPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreSetupSubmitLoyalistCertError;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
};

export type StoreSetupSubmitStateAndPhoneInformationError = {
  fields?: Maybe<StoreSetupSubmitStateAndPhoneInformationFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoreSetupSubmitStateAndPhoneInformationFieldError = {
  phone?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoreSetupSubmitStateAndPhoneInformationInput = {
  attributes: MerchantDataAddressConfirmationAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreSetupSubmitStateAndPhoneInformationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoreSetupSubmitStateAndPhoneInformationError>;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
};

export type StoreTaxReports = {
  activeBankAccount: BankAccount;
  annual1099kFiledReportUrl?: Maybe<Scalars['String']['output']>;
  annual1099kFiledReportYears?: Maybe<Array<Scalars['String']['output']>>;
  annualReportUrl?: Maybe<Scalars['String']['output']>;
  annualReportYears: Array<Scalars['String']['output']>;
  balanceExportYears: Array<Scalars['String']['output']>;
  balanceSheet?: Maybe<BalanceSheet>;
  currentBalance?: Maybe<Scalars['String']['output']>;
  earningsReport?: Maybe<EarningsReport>;
  earningsReportYears: Array<Scalars['String']['output']>;
  msrpAllTimeCurrency: Scalars['Currency']['output'];
  msrpPast30DaysCurrency: Scalars['Currency']['output'];
  msrpPastYearCurrency: Scalars['Currency']['output'];
  nextPayoutDate?: Maybe<Scalars['String']['output']>;
  payoutDates: Array<PayoutDate>;
  payoutReport?: Maybe<PayoutReportsType>;
  shouldDisplay1099kFiledDownloads: Scalars['Boolean']['output'];
  shouldDisplay1099kReports: Scalars['Boolean']['output'];
  shouldDisplayPractitionerEarningsReports?: Maybe<Scalars['Boolean']['output']>;
  shouldDisplayT4aReports: Scalars['Boolean']['output'];
  shouldDisplayWellevateReports: Scalars['Boolean']['output'];
};


export type StoreTaxReportsAnnual1099kFiledReportUrlArgs = {
  year: Scalars['String']['input'];
};


export type StoreTaxReportsAnnualReportUrlArgs = {
  year: Scalars['String']['input'];
};


export type StoreTaxReportsBalanceSheetArgs = {
  year: Scalars['Int']['input'];
};


export type StoreTaxReportsEarningsReportArgs = {
  payoutIds: Array<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};


export type StoreTaxReportsPayoutDatesArgs = {
  year?: InputMaybe<Scalars['Int']['input']>;
};


export type StoreTaxReportsPayoutReportArgs = {
  payoutId: Scalars['String']['input'];
};

export type StoresPatientPromotion = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Use duration_days on PatientPromotionType instead. */
  durationDays: Scalars['String']['output'];
  /** @deprecated Use end_date on PatientPromotionType instead. */
  endDate: Scalars['DateTime']['output'];
  fromGlobalOptIn?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  optIn: Scalars['Boolean']['output'];
  patientPromotion: PatientPromotion;
  previousDiscount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use start_date on PatientPromotionType instead. */
  startDate: Scalars['DateTime']['output'];
  targetedDiscount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StoresPatientPromotionAttributes = {
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  patientPromotionId: Scalars['ID']['input'];
  targetedDiscount?: InputMaybe<Scalars['Int']['input']>;
};

export type StoresPatientPromotionConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<StoresPatientPromotionEdge>>>;
  nodes?: Maybe<Array<Maybe<StoresPatientPromotion>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StoresPatientPromotionEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<StoresPatientPromotion>;
};

export type StoresPatientPromotionFilterObject = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoresPatientPromotionUpdateError = {
  fields?: Maybe<StoresPatientPromotionUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StoresPatientPromotionUpdateFieldError = {
  optIn?: Maybe<Array<Scalars['String']['output']>>;
  patientPromotionId?: Maybe<Array<Scalars['String']['output']>>;
  targetedDiscount?: Maybe<Array<Scalars['String']['output']>>;
};

export type StoresPatientPromotionUpdateInput = {
  attributes: StoresPatientPromotionAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StoresPatientPromotionUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoresPatientPromotionUpdateError>;
  storesPatientPromotion?: Maybe<StoresPatientPromotion>;
};

export type StripeAccountPersonType = {
  city?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDirector?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  streetAddress2?: Maybe<Scalars['String']['output']>;
};

export type StripeAccountState = Timestamps & {
  accountVerifyFieldsNeeded?: Maybe<Scalars['String']['output']>;
  additionalVerificationState?: Maybe<StripeAdditionalVerificationStates>;
  chargesEnabled?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  futureAccountFieldsNeeded?: Maybe<Scalars['String']['output']>;
  futureRequirementsDeadline?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isFullSsnRequired: Scalars['Boolean']['output'];
  legalEntityVerified?: Maybe<Scalars['String']['output']>;
  pendingVerificationFields?: Maybe<Scalars['String']['output']>;
  tosAcceptanceRequired: Scalars['Boolean']['output'];
  transfersEnabled?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const StripeAdditionalVerificationStates = {
  Pending: 'pending',
  Rejected: 'rejected',
  Unverified: 'unverified',
  Verified: 'verified'
} as const;

export type StripeAdditionalVerificationStates = typeof StripeAdditionalVerificationStates[keyof typeof StripeAdditionalVerificationStates];
export type StripePersonAttributes = {
  _delete?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isDirector?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
};

export type StudentProfile = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  graduationDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  practitioner?: Maybe<Practitioner>;
  practitionerType?: Maybe<PractitionerType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type StudentProfileAttributes = {
  graduationDate?: InputMaybe<Scalars['String']['input']>;
  practitionerTypeId?: InputMaybe<Scalars['String']['input']>;
};

export type StudentProfileCreateError = {
  fields?: Maybe<StudentProfileCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type StudentProfileCreateFieldError = {
  graduationDate?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type StudentProfileCreateInput = {
  attributes: StudentProfileAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type StudentProfileCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StudentProfileCreateError>;
  studentProfile?: Maybe<StudentProfile>;
};

export type SubscriptionAddressesCreateError = {
  fields?: Maybe<SubscriptionAddressesCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionAddressesCreateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  shippingAndBilling?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

export type SubscriptionAddressesCreateInput = {
  attributes: PatientAccountAddressAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createBillingAddress: Scalars['Boolean']['input'];
};

export type SubscriptionAddressesCreatePayload = {
  /** @deprecated Go through patient type */
  addresses?: Maybe<Array<PatientAddress>>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: SubscriptionAddressesCreateError;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

export type SubscriptionCreateCreditCardError = {
  fields?: Maybe<SubscriptionCreateCreditCardFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionCreateCreditCardFieldError = {
  avsState?: Maybe<Array<Scalars['String']['output']>>;
  ccType?: Maybe<Array<Scalars['String']['output']>>;
  gatewayPaymentProfileId?: Maybe<Array<Scalars['String']['output']>>;
  lastDigits?: Maybe<Array<Scalars['String']['output']>>;
  month?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  oneTimeUse?: Maybe<Array<Scalars['String']['output']>>;
  year?: Maybe<Array<Scalars['String']['output']>>;
};

export type SubscriptionCreateCreditCardInput = {
  attributes: SubscriptionCreditCardAttributes;
  billingAddressId: Scalars['ID']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionCreateCreditCardPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Go through patient type */
  creditCard?: Maybe<CreditCard>;
  errors: SubscriptionCreateCreditCardError;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

export type SubscriptionCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  subscriptionsVariants?: InputMaybe<Array<SubscriptionsVariantsAttributes>>;
};

export type SubscriptionCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

export type SubscriptionCreditCardAttributes = {
  avsState?: InputMaybe<Scalars['String']['input']>;
  ccType?: InputMaybe<Scalars['String']['input']>;
  gatewayPaymentProfileId?: InputMaybe<Scalars['String']['input']>;
  lastDigits?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oneTimeUse?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionProp65SwapInput = {
  checkOnly?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionProp65SwapPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<RetailCartProp65SwapError>>;
  patient?: Maybe<Patient>;
};

export type SubscriptionScheduleMultipleError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionScheduleMultipleInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  scheduleAttributes: Array<AutoshipScheduleAttributes>;
};

export type SubscriptionScheduleMultiplePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SubscriptionScheduleMultipleError>;
  numberOfScheduledVariants: Scalars['Int']['output'];
};

export type SubscriptionUpdateInput = {
  billingAddressId?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCardId?: InputMaybe<Scalars['ID']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  preferredShippingMethodId?: InputMaybe<Scalars['ID']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
  subscriptionsVariants?: InputMaybe<Array<SubscriptionsVariantsAttributes>>;
};

export type SubscriptionUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

export type SubscriptionVariantFilterObject = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  validForSale?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubscriptionsGroupUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionsGroupUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingMethodId: Scalars['ID']['input'];
  subscriptionsGroupId: Scalars['ID']['input'];
};

export type SubscriptionsGroupUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SubscriptionsGroupUpdateError>;
  patient?: Maybe<Patient>;
};

export type SubscriptionsVariant = Node & Timestamps & {
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  frequency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lineItem?: Maybe<LineItem>;
  nextOrderDate: Scalars['Date']['output'];
  quantity: Scalars['Int']['output'];
  skipOnNextOrder: Scalars['Boolean']['output'];
  subscription: Autoship;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  variant: Variant;
};

export type SubscriptionsVariantCreateInput = {
  active: Scalars['Boolean']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  skipOnNextOrder?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
  variantId: Scalars['ID']['input'];
};

export type SubscriptionsVariantCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscriptionsVariant?: Maybe<SubscriptionsVariant>;
};

export type SubscriptionsVariantFrequencyUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionsVariantFrequencyUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
  subscriptionsVariantId: Scalars['ID']['input'];
};

export type SubscriptionsVariantFrequencyUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: SubscriptionsVariantFrequencyUpdateError;
  patient?: Maybe<Patient>;
  subscription?: Maybe<Autoship>;
};

export type SubscriptionsVariantUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  skipOnNextOrder?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionsVariantId: Scalars['ID']['input'];
  variantId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscriptionsVariantUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscriptionsVariant?: Maybe<SubscriptionsVariant>;
};

export type SubscriptionsVariantsAttributes = {
  active: Scalars['Boolean']['input'];
  quantity: Scalars['Int']['input'];
  variantId: Scalars['ID']['input'];
};

export type SuggestedVariant = {
  dosageAmount?: Maybe<Scalars['String']['output']>;
  dosageFormat?: Maybe<Scalars['String']['output']>;
  dosageFrequency?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  variant: Variant;
};

export type SuggestionGroup = {
  requestedIngredientDetails: Array<RequestedIngredientDetails>;
  suggestedVariants: Array<SuggestedVariant>;
};

export type SupplementPlanAddCustomMedicationInput = {
  brandName: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageFormat?: InputMaybe<Scalars['String']['input']>;
  dosageFrequency?: InputMaybe<Scalars['String']['input']>;
  isDefaultTime?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  reminderTimes?: InputMaybe<Array<Scalars['String']['input']>>;
  remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SupplementPlanAddCustomMedicationPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  supplementPlan?: Maybe<SupplementPlanType>;
};

export type SupplementPlanAddCustomProductInput = {
  brandName: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageFormat?: InputMaybe<Scalars['String']['input']>;
  dosageFrequency?: InputMaybe<Scalars['String']['input']>;
  isDefaultTime?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  reminderTimes?: InputMaybe<Array<Scalars['String']['input']>>;
  remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SupplementPlanAddCustomProductPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  supplementPlan?: Maybe<SupplementPlanType>;
};

export type SupplementPlanAddProductInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageFrequency?: InputMaybe<Scalars['String']['input']>;
  isDefaultTime?: InputMaybe<Scalars['Boolean']['input']>;
  productId: Scalars['ID']['input'];
  reminderTimes?: InputMaybe<Array<Scalars['String']['input']>>;
  remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SupplementPlanAddProductPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  supplementPlan?: Maybe<SupplementPlanType>;
};

export type SupplementPlanAddProductsInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productIds: Array<Scalars['ID']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type SupplementPlanAddProductsPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

export type SupplementPlanArchiveProductInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

export type SupplementPlanArchiveProductPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  supplementPlan?: Maybe<SupplementPlanType>;
};

export type SupplementPlanCustomMedication = Node & {
  brandName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SupplementPlanCustomProduct = Node & {
  brandName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SupplementPlanProductByTimeOfDayType = {
  id: Scalars['ID']['output'];
  supplementPlanProducts: Array<SupplementPlanProductType>;
  timeOfDay?: Maybe<Scalars['String']['output']>;
};

export type SupplementPlanProductFilterObject = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

export type SupplementPlanProductReminder = {
  id: Scalars['ID']['output'];
  sendTime: Scalars['String']['output'];
};

export type SupplementPlanProductType = Node & Timestamps & {
  baseFormat?: Maybe<Scalars['String']['output']>;
  brandName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dosageAmount?: Maybe<Scalars['String']['output']>;
  dosageFormat: Scalars['String']['output'];
  dosageFrequency?: Maybe<Scalars['String']['output']>;
  dosageTimeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  ingredients: Array<PlanIngredient>;
  name: Scalars['String']['output'];
  nextSendTime?: Maybe<Scalars['String']['output']>;
  productable: SupplementPlanProductableType;
  productableId: Scalars['ID']['output'];
  productableType: Scalars['String']['output'];
  reminders?: Maybe<Array<SupplementPlanProductReminder>>;
  remindersEnabled?: Maybe<Scalars['Boolean']['output']>;
  takeWith?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SupplementPlanProductableType = ExternalProduct | MedicationProduct | Product | SupplementPlanCustomMedication | SupplementPlanCustomProduct;

export type SupplementPlanStore = {
  id: Scalars['ID']['output'];
};

export type SupplementPlanType = Timestamps & {
  aggregatedIngredients: Array<PlanIngredient>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  medicationAggregatedIngredients: Array<PlanIngredient>;
  medicationProductsWithoutIngredients: Array<Scalars['String']['output']>;
  productsByTimeOfDay?: Maybe<Array<SupplementPlanProductByTimeOfDayType>>;
  productsWithoutIngredients: Array<Scalars['String']['output']>;
  reminderTimes: Array<Scalars['String']['output']>;
  supplementPlanProducts: Array<SupplementPlanProductType>;
  updatedAt: Scalars['DateTime']['output'];
};


export type SupplementPlanTypeProductsByTimeOfDayArgs = {
  includeMedications?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SupplementPlanTypeSupplementPlanProductsArgs = {
  filters?: InputMaybe<SupplementPlanProductFilterObject>;
  includeMedications?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SupplementPlanUpdateProductInput = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageFormat?: InputMaybe<Scalars['String']['input']>;
  dosageFrequency?: InputMaybe<Scalars['String']['input']>;
  isDefaultTime?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reminderTimes?: InputMaybe<Array<Scalars['String']['input']>>;
  remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  supplementPlanProductId: Scalars['ID']['input'];
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SupplementPlanUpdateProductPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  supplementPlan?: Maybe<SupplementPlanType>;
};

export type SupplementType = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SupplementTypeConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<SupplementTypeEdge>>>;
  nodes?: Maybe<Array<Maybe<SupplementType>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SupplementTypeEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<SupplementType>;
};

export type Tag = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  depth: Scalars['Int']['output'];
  grandParentId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isStub?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  productsCount?: Maybe<Scalars['Int']['output']>;
  root: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TagConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<TagEdge>>>;
  nodes?: Maybe<Array<Maybe<Tag>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TagEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Tag>;
};

export const TagFilterDepthField = {
  One: 'ONE',
  Root: 'ROOT',
  Three: 'THREE',
  Two: 'TWO'
} as const;

export type TagFilterDepthField = typeof TagFilterDepthField[keyof typeof TagFilterDepthField];
export type TagFilterObject = {
  depth: TagFilterDepthField;
  excludeFiltersTag?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Taxonomy = Node & Timestamps & {
  children?: Maybe<Array<Taxonomy>>;
  createdAt: Scalars['DateTime']['output'];
  depth: Scalars['Int']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TemplateCreateDraftAttributes = {
  attachmentsUploadableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  recommendationsAttributes?: InputMaybe<Array<OfficeRecommendationAttributes>>;
  treatmentPlanTemplateIdToApply?: InputMaybe<Scalars['ID']['input']>;
};

export type TemplateUnion = LabsPanel | TreatmentPlanTemplate;

export type TemplateUnionConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<TemplateUnionEdge>>>;
  nodes?: Maybe<Array<Maybe<TemplateUnion>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TemplateUnionEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<TemplateUnion>;
};

export type TherapeuticSwitchingRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  recommendationId: Scalars['ID']['input'];
};

export type TherapeuticSwitchingRequestPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  recommendation?: Maybe<Recommendation>;
};

export type ThirdPartyCertification = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const ThreeSixtyImageFilterFormatField = {
  Png: 'PNG',
  Webp: 'WEBP'
} as const;

export type ThreeSixtyImageFilterFormatField = typeof ThreeSixtyImageFilterFormatField[keyof typeof ThreeSixtyImageFilterFormatField];
export type ThreeSixtyImageFilterObject = {
  format?: InputMaybe<ThreeSixtyImageFilterFormatField>;
  size?: InputMaybe<ThreeSixtyImageFilterSizeField>;
};

export const ThreeSixtyImageFilterSizeField = {
  Large: 'LARGE',
  Medium: 'MEDIUM',
  Small: 'SMALL'
} as const;

export type ThreeSixtyImageFilterSizeField = typeof ThreeSixtyImageFilterSizeField[keyof typeof ThreeSixtyImageFilterSizeField];
export type TieredPercentItem = {
  max?: Maybe<Scalars['Float']['output']>;
  min: Scalars['Float']['output'];
  percentage: Scalars['Float']['output'];
};

export type TileClickAttributes = {
  pageVersion: Scalars['String']['input'];
  tileName: Scalars['String']['input'];
};

export type TimedSupplyInformation = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  dailyDosageText: Scalars['String']['output'];
  dailyUnits: Scalars['Float']['output'];
  dosageAmount: Scalars['String']['output'];
  dosageDuration?: Maybe<Scalars['String']['output']>;
  dosageFormat: Scalars['String']['output'];
  dosageFrequency: Scalars['String']['output'];
  dosagePermutations?: Maybe<Scalars['String']['output']>;
  dosageQuantity: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lineItem: LineItem;
  sourceType: Scalars['String']['output'];
  timeSelected: Scalars['String']['output'];
  unitsPerContainer: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TimedSupplyOption = {
  duration: Scalars['Int']['output'];
  durationUnit: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  option: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  rxRecommendation: Scalars['Boolean']['output'];
  sellPrice: Scalars['Float']['output'];
  units: Scalars['Int']['output'];
};

export type Timestamps = {
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TopBrand = Node & Timestamps & {
  /** @deprecated Never used. */
  blurb?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TopRecommendedProductSort = {
  direction: SortDirection;
  field?: InputMaybe<TopRecommendedProductSortField>;
};

export const TopRecommendedProductSortField = {
  Alphabetical: 'ALPHABETICAL',
  Price: 'PRICE',
  Rank: 'RANK'
} as const;

export type TopRecommendedProductSortField = typeof TopRecommendedProductSortField[keyof typeof TopRecommendedProductSortField];
export type TotalStepCount = {
  neverMargin?: Maybe<Scalars['Int']['output']>;
  variableMargin?: Maybe<Scalars['Int']['output']>;
};

export type Tour = Timestamps & {
  action?: Maybe<Scalars['String']['output']>;
  controller?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  subAction?: Maybe<Scalars['String']['output']>;
  templateName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ToursUsersCreateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ToursUsersCreateInput = {
  action: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controller: Scalars['String']['input'];
};

export type ToursUsersCreateOrUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ToursUsersCreateOrUpdateInput = {
  action: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controller: Scalars['String']['input'];
};

export type ToursUsersCreateOrUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ToursUsersCreateOrUpdateError;
  user?: Maybe<User>;
};

export type ToursUsersCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ToursUsersCreateError;
  user?: Maybe<User>;
};

export type TrackingType = Node & {
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  deliveryStatus?: Maybe<Scalars['String']['output']>;
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  friendlyDeliveredOn?: Maybe<Scalars['String']['output']>;
  friendlyEstimatedDeliveryDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlan = Attachable & LegacyId & Node & Timestamps & {
  _legacyId: Scalars['Int']['output'];
  adherenceFeedback: AdherenceFeedback;
  aggregatedIngredients: Array<PlanIngredient>;
  associatedOrderCount: Scalars['Int']['output'];
  availableAt?: Maybe<Scalars['DateTime']['output']>;
  biomarkerSummary: Array<LabsBiomarker>;
  canCancel: Scalars['Boolean']['output'];
  clerk?: Maybe<Clerk>;
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  documentAttachments: DocumentAttachmentConnection;
  documents: Array<Document>;
  draftDynamicDailyPack?: Maybe<DraftDynamicDailyPack>;
  hasRestrictedTestsForStore: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDirty: Scalars['Boolean']['output'];
  isPractitionerPayAllowed: Scalars['Boolean']['output'];
  isValidDailyPacks: Scalars['Boolean']['output'];
  labJourneySubscriptions: Array<LabsJourneysSubscription>;
  labRecommendations: Array<LabsRecommendation>;
  labSigningPractitioner?: Maybe<LabsTreatmentPlanSigningPractitioner>;
  labsCustomFeesSummary?: Maybe<Array<LabsRecommendationCustomFee>>;
  labsSubtotal: Scalars['Float']['output'];
  logs: Array<TreatmentPlanLog>;
  message?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TreatmentPlanMessage>>;
  patient?: Maybe<Patient>;
  patientStatus?: Maybe<TreatmentPlanPatientStatus>;
  planOptimizationCategories?: Maybe<Array<PlanOptimizationCategory>>;
  planType: Scalars['String']['output'];
  practitioner: Practitioner;
  priceBreakdown: TreatmentPlanPriceBreakdown;
  productsWithoutIngredients: Array<Scalars['String']['output']>;
  recommendations: Array<Recommendation>;
  signingAuthorizationRequired: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  sourcePlatform: Scalars['String']['output'];
  totalActiveRecommendations: Scalars['Int']['output'];
  totalDiscountedPrice: Scalars['Float']['output'];
  totalLabAuthorizationFee: Scalars['Float']['output'];
  totalLabCompanyRequisitionFee: Scalars['Float']['output'];
  totalLabCustomFee: Scalars['Float']['output'];
  totalLabPrice: Scalars['Float']['output'];
  totalLabRecommendations: Scalars['Int']['output'];
  totalPrice: Scalars['Float']['output'];
  totalSavings: Scalars['Float']['output'];
  totalWithoutLabsAndSavings: Scalars['Float']['output'];
  totalWithoutSavings: Scalars['Float']['output'];
  treatmentPlanPdfUrl: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type TreatmentPlanDocumentAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderByAddedAt?: InputMaybe<Scalars['Boolean']['input']>;
};


export type TreatmentPlanLabRecommendationsArgs = {
  filters?: InputMaybe<LabRecommendationFilterObject>;
  sort?: InputMaybe<Array<LabRecommendationSort>>;
};


export type TreatmentPlanMessagesArgs = {
  sort?: InputMaybe<Array<TreatmentPlanMessageSort>>;
};


export type TreatmentPlanRecommendationsArgs = {
  filterObject?: InputMaybe<RecommendationFilterObject>;
  isFullscriptProtocol?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TreatmentPlanCancelError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanCancelInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

export type TreatmentPlanCancelPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanCancelError>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type TreatmentPlanConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<TreatmentPlanEdge>>>;
  nodes?: Maybe<Array<Maybe<TreatmentPlan>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TreatmentPlanDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

export type TreatmentPlanDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanDeleteError>;
};

export type TreatmentPlanEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<TreatmentPlan>;
};

export type TreatmentPlanFilterObject = {
  options?: InputMaybe<Array<TreatmentPlanFilters>>;
  ordered?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export const TreatmentPlanFilters = {
  SavedPlansOnly: 'SAVED_PLANS_ONLY',
  ScopeActive: 'SCOPE_ACTIVE',
  ScopeActiveAndCancelled: 'SCOPE_ACTIVE_AND_CANCELLED',
  ScopeCancelled: 'SCOPE_CANCELLED',
  ScopeDraft: 'SCOPE_DRAFT',
  ScopeWithoutTemplates: 'SCOPE_WITHOUT_TEMPLATES'
} as const;

export type TreatmentPlanFilters = typeof TreatmentPlanFilters[keyof typeof TreatmentPlanFilters];
export type TreatmentPlanInsightsLogError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanInsightsLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  editClick?: InputMaybe<EditClickAttributes>;
  followUpClicked?: InputMaybe<FollowUpClickedAttributes>;
  followUpSent?: InputMaybe<FollowUpSentAttributes>;
  metricTileClick?: InputMaybe<TileClickAttributes>;
  pageView?: InputMaybe<PageViewAttributes>;
  searchFilter?: InputMaybe<SearchFilterAttributes>;
  treatmentPlanTileClick?: InputMaybe<TreatmentPlanTileClickAttributes>;
};

export type TreatmentPlanInsightsLogPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: TreatmentPlanInsightsLogError;
};

export type TreatmentPlanList = {
  availableAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
};

export type TreatmentPlanListFilterObject = {
  state?: InputMaybe<Array<TreatmentPlanStates>>;
};

export type TreatmentPlanLog = Node & Timestamps & {
  actions: Array<TreatmentPlanLogAction>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  practitioner: Practitioner;
  treatmentPlan: TreatmentPlan;
  updatedAt: Scalars['DateTime']['output'];
};

export type TreatmentPlanLogAction = Node & Timestamps & {
  actionable?: Maybe<Actionable>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  treatmentPlanLog: TreatmentPlanLog;
  updatedAt: Scalars['DateTime']['output'];
};

export type TreatmentPlanMessage = Node & Timestamps & {
  contents?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentState?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TreatmentPlanMessageSort = {
  direction: SortDirection;
  field: TreatmentPlanMessageSortField;
};

export const TreatmentPlanMessageSortField = {
  CreatedAt: 'CREATED_AT',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type TreatmentPlanMessageSortField = typeof TreatmentPlanMessageSortField[keyof typeof TreatmentPlanMessageSortField];
export const TreatmentPlanPatientStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE'
} as const;

export type TreatmentPlanPatientStatus = typeof TreatmentPlanPatientStatus[keyof typeof TreatmentPlanPatientStatus];
export type TreatmentPlanPriceBreakdown = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  discount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  labsCustomFees: Array<LabsRecommendationCustomFee>;
  labsTotal: Scalars['Float']['output'];
  labsTotalAuthNetworkFees: Scalars['Float']['output'];
  labsTotalCompanyRequisitionFees: Scalars['Float']['output'];
  labsTotalCustomFees: Scalars['Float']['output'];
  labsTotalMsrp: Scalars['Float']['output'];
  supplementsDiscount: Scalars['Float']['output'];
  supplementsTotal: Scalars['Float']['output'];
  supplementsTotalMsrp: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  totalBeforeDiscount: Scalars['Float']['output'];
  totalMsrp: Scalars['Float']['output'];
  totalMsrpAfterDiscount: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TreatmentPlanSendReminderError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanSendReminderInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isText?: InputMaybe<Scalars['Boolean']['input']>;
  patientNumber?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

export type TreatmentPlanSendReminderPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanSendReminderError>;
};

export type TreatmentPlanSnippet = Node & Timestamps & {
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isPinned: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TreatmentPlanSnippetAttributes = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export type TreatmentPlanSnippetDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanSnippetDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  snippetId: Scalars['ID']['input'];
};

export type TreatmentPlanSnippetDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: TreatmentPlanSnippetDeleteError;
};

export type TreatmentPlanSnippetFilterObject = {
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TreatmentPlanSnippetSaveError = {
  fields?: Maybe<TreatmentPlanSnippetSaveFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanSnippetSaveFieldError = {
  content?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Array<Scalars['String']['output']>>;
  isPinned?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

export type TreatmentPlanSnippetSaveInput = {
  attributes: TreatmentPlanSnippetAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type TreatmentPlanSnippetSavePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: TreatmentPlanSnippetSaveError;
  treatmentPlanSnippet?: Maybe<TreatmentPlanSnippet>;
};

export type TreatmentPlanSnippetSort = {
  direction: SortDirection;
  field: TreatmentPlanSnippetSortField;
};

export const TreatmentPlanSnippetSortField = {
  IsPinned: 'IS_PINNED',
  Name: 'NAME'
} as const;

export type TreatmentPlanSnippetSortField = typeof TreatmentPlanSnippetSortField[keyof typeof TreatmentPlanSnippetSortField];
export type TreatmentPlanSort = {
  direction: SortDirection;
  field: TreatmentPlanSortField;
};

export const TreatmentPlanSortField = {
  AvailableAt: 'AVAILABLE_AT',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type TreatmentPlanSortField = typeof TreatmentPlanSortField[keyof typeof TreatmentPlanSortField];
export const TreatmentPlanStates = {
  Active: 'ACTIVE',
  Cancelled: 'CANCELLED',
  Draft: 'DRAFT',
  Template: 'TEMPLATE'
} as const;

export type TreatmentPlanStates = typeof TreatmentPlanStates[keyof typeof TreatmentPlanStates];
export type TreatmentPlanTemplate = LegacyId & Node & Timestamps & {
  _legacyId: Scalars['Int']['output'];
  clerk?: Maybe<Clerk>;
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  fullscriptProtocol?: Maybe<FullscriptProtocol>;
  hasPrescriptionOnlyProducts: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAppliedToPlan: Scalars['Boolean']['output'];
  isPatientFavorited: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  patientShareableUrl?: Maybe<Scalars['String']['output']>;
  patientSharingOptions?: Maybe<Array<Scalars['String']['output']>>;
  planType: Scalars['String']['output'];
  practitioner: Practitioner;
  practitionerSharingLevel: Scalars['String']['output'];
  shareable: Scalars['Boolean']['output'];
  shareableWithPatients: Scalars['String']['output'];
  sharedPatients?: Maybe<Array<Patient>>;
  sharedPatientsCount: Scalars['Int']['output'];
  sharedWithPractitioners?: Maybe<Array<Practitioner>>;
  showUnavailableProducts: Scalars['Boolean']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  sourceTreatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
  treatmentPlan: TreatmentPlan;
  treatmentPlanId?: Maybe<Scalars['ID']['output']>;
  unavailableWvProtocolItems?: Maybe<Array<UnavailableWvProtocolItem>>;
  updatedAt: Scalars['DateTime']['output'];
};


export type TreatmentPlanTemplateIsAppliedToPlanArgs = {
  planId: Scalars['ID']['input'];
};

export const TreatmentPlanTemplateAuthors = {
  All: 'ALL',
  FullscriptTemplates: 'FULLSCRIPT_TEMPLATES',
  MyOrSharedTemplates: 'MY_OR_SHARED_TEMPLATES',
  MyTemplates: 'MY_TEMPLATES',
  SharedTemplates: 'SHARED_TEMPLATES'
} as const;

export type TreatmentPlanTemplateAuthors = typeof TreatmentPlanTemplateAuthors[keyof typeof TreatmentPlanTemplateAuthors];
export type TreatmentPlanTemplateCancelError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanTemplateCancelInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

export type TreatmentPlanTemplateCancelPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanTemplateCancelError>;
  treatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
};

export type TreatmentPlanTemplateConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<TreatmentPlanTemplateEdge>>>;
  nodes?: Maybe<Array<Maybe<TreatmentPlanTemplate>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TreatmentPlanTemplateEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<TreatmentPlanTemplate>;
};

export type TreatmentPlanTemplateFavoriteCreateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanTemplateFavoriteCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

export type TreatmentPlanTemplateFavoriteCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: TreatmentPlanTemplateFavoriteCreateError;
  treatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
};

export type TreatmentPlanTemplateFavoriteDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanTemplateFavoriteDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

export type TreatmentPlanTemplateFavoriteDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: TreatmentPlanTemplateFavoriteDeleteError;
  treatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
};

export type TreatmentPlanTemplateFilterObject = {
  authors?: InputMaybe<TreatmentPlanTemplateAuthors>;
  containsLabs?: InputMaybe<Scalars['Boolean']['input']>;
  currentState?: InputMaybe<Scalars['String']['input']>;
  fullscriptProtocolCategoryId?: InputMaybe<Scalars['ID']['input']>;
  hideTemplatesWithRestrictedLabTests?: InputMaybe<Scalars['Boolean']['input']>;
  nameQuery?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

export type TreatmentPlanTemplateLogCopyUrlError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanTemplateLogCopyUrlInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

export type TreatmentPlanTemplateLogCopyUrlPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanTemplateLogCopyUrlError>;
};

export type TreatmentPlanTemplateLogViewError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type TreatmentPlanTemplateLogViewInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

export type TreatmentPlanTemplateLogViewPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanTemplateLogViewError>;
};

export type TreatmentPlanTemplateSort = {
  direction: SortDirection;
  field: TreatmentPlanTemplateSortField;
};

export const TreatmentPlanTemplateSortField = {
  Alphabetical: 'ALPHABETICAL',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type TreatmentPlanTemplateSortField = typeof TreatmentPlanTemplateSortField[keyof typeof TreatmentPlanTemplateSortField];
export type TreatmentPlanTileClickAttributes = {
  filterSearchTerms: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
  treatmentPlanId: Scalars['ID']['input'];
  treatmentPlanStatus: Scalars['String']['input'];
};

export type TreatmentPlansCreateDraftAttributes = {
  attachmentsUploadableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  recommendationsAttributes?: InputMaybe<Array<OfficeRecommendationAttributes>>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type TreatmentPlansFindOrCreateDraftAttributes = {
  attachmentsUploadableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  recommendationsAttributes?: InputMaybe<Array<OfficeRecommendationAttributes>>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type TwoFactorAuthentication = Timestamps & {
  backupCode: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  otpMethod: TwoFactorAuthenticationMethods;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export const TwoFactorAuthenticationMethods = {
  App: 'app',
  Email: 'email',
  Sms: 'sms'
} as const;

export type TwoFactorAuthenticationMethods = typeof TwoFactorAuthenticationMethods[keyof typeof TwoFactorAuthenticationMethods];
export type Typeahead = Timestamps & {
  brandName?: Maybe<Scalars['String']['output']>;
  categoryProductIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  entityType: Scalars['String']['output'];
  entityTypeId: Scalars['String']['output'];
  highlightedName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  productSlug?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TypeaheadEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Typeahead>;
};

export const TypeaheadFilterListField = {
  Brands: 'BRANDS',
  Categories: 'CATEGORIES',
  Conditions: 'CONDITIONS',
  Ingredients: 'INGREDIENTS',
  Products: 'PRODUCTS',
  SupplementTypes: 'SUPPLEMENT_TYPES'
} as const;

export type TypeaheadFilterListField = typeof TypeaheadFilterListField[keyof typeof TypeaheadFilterListField];
export type TypeaheadGroupType = {
  data: Array<Typeahead>;
  entityType: Scalars['String']['output'];
};

export type TypeaheadSearchConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<TypeaheadEdge>>>;
  nodes?: Maybe<Array<Maybe<Typeahead>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UnavailableWvProtocolItem = Node & Timestamps & {
  brand: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Uploadable = {
  attachment: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAttached: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};


export type UploadableIsAttachedArgs = {
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

export type User = Node & Timestamps & {
  avatar?: Maybe<Avatar>;
  confirmPassword: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  currentUserRole?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  experimentGroup?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  hasIncompleteCredentials: Scalars['Boolean']['output'];
  hasMultipleStorePlatforms: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  iterableJwt?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  mobileIterableJwt?: Maybe<Scalars['String']['output']>;
  notificationSettings: Array<UserNotificationSetting>;
  recentSearches?: Maybe<Array<SearchHistory>>;
  roles: Array<Roleable>;
  signInCount: Scalars['Int']['output'];
  systemGeneratedPassword: Scalars['Boolean']['output'];
  tours?: Maybe<Array<Tour>>;
  twoFactorAuthentication?: Maybe<TwoFactorAuthentication>;
  updatedAt: Scalars['DateTime']['output'];
  variantNotifications: VariantNotificationConnection;
};


export type UserConfirmPasswordArgs = {
  password: Scalars['String']['input'];
};


export type UserExperimentGroupArgs = {
  experimentName: Scalars['String']['input'];
};


export type UserNotificationSettingsArgs = {
  channel?: InputMaybe<Array<Scalars['String']['input']>>;
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type UserRecentSearchesArgs = {
  source?: InputMaybe<ProductSearchSourceType>;
};


export type UserRolesArgs = {
  filters?: InputMaybe<RoleFilterObject>;
};


export type UserToursArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  controller?: InputMaybe<Scalars['String']['input']>;
};


export type UserVariantNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterObject?: InputMaybe<VariantNotificationFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAttributes = {
  confirmNewPassword?: InputMaybe<Scalars['String']['input']>;
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  newPassword: Scalars['String']['input'];
};

export type UserAvatarAttributes = {
  photo: Scalars['Upload']['input'];
};

export type UserAvatarCreateError = {
  fields?: Maybe<UserAvatarCreateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UserAvatarCreateFieldError = {
  photo?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserAvatarCreateInput = {
  attributes: UserAvatarAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type UserAvatarCreatePayload = {
  avatar?: Maybe<Avatar>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UserAvatarCreateError;
};

export type UserAvatarDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type UserAvatarDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAvatarId: Scalars['ID']['input'];
};

export type UserAvatarDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UserAvatarDeleteError;
};

export type UserChangeStoreError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type UserChangeStoreInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

export type UserChangeStorePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UserChangeStoreError;
};

export type UserEmailAttributes = {
  email: Scalars['String']['input'];
};

export type UserForgotPasswordEmailError = {
  fields?: Maybe<UserForgotPasswordEmailFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UserForgotPasswordEmailFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserForgotPasswordEmailInput = {
  attributes: AuthUserForgotPasswordEmailAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type UserForgotPasswordEmailPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  emailSent?: Maybe<Scalars['Boolean']['output']>;
  errors: UserForgotPasswordEmailError;
};

export type UserMobileSignInWithAppleInput = {
  attributes: AuthUserMobileSingleSignOnAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type UserMobileSignInWithApplePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UserMobileSignInWithSsoError;
  user?: Maybe<User>;
};

export type UserMobileSignInWithGoogleInput = {
  attributes: AuthUserMobileSingleSignOnAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type UserMobileSignInWithGooglePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UserMobileSignInWithSsoError;
  user?: Maybe<User>;
};

export type UserMobileSignInWithSsoError = {
  fields?: Maybe<UserMobileSignInWithSsoFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UserMobileSignInWithSsoFieldError = {
  accessToken?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  idToken?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  nonce?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserNotificationSetting = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  subscribed?: Maybe<Scalars['Boolean']['output']>;
  type: NotificationSettingType;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type UserNotificationSettingUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type UserNotificationSettingUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  subscribed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserNotificationSettingUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<UserNotificationSettingUpdateError>;
  setting?: Maybe<UserNotificationSetting>;
};

export type UserRoleAddress = Timestamps & {
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  addressType?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  default?: Maybe<Scalars['Boolean']['output']>;
  defaultPatientAddress?: Maybe<Scalars['Boolean']['output']>;
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state: State;
  stateId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userRole: Roleable;
  zipcode: Scalars['String']['output'];
};

export type UserRoleAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  stateId: Scalars['ID']['input'];
  zipcode: Scalars['String']['input'];
};

export type UserSecurityAttributes = {
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UserSecurityFieldsUpdateError = {
  fields?: Maybe<UserSecurityFieldsUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UserSecurityFieldsUpdateFieldError = {
  currentPassword?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserSecurityFieldsUpdateInput = {
  attributes: UserSecurityAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type UserSecurityFieldsUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<UserSecurityFieldsUpdateError>;
  user?: Maybe<User>;
};

export type UserVariantNotificationDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type UserVariantNotificationDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantNotificationId: Scalars['String']['input'];
};

export type UserVariantNotificationDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<UserVariantNotificationDeleteError>;
  variantNotification: VariantNotification;
};

export type UsersMapAgreement = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mapAgreement: MapAgreement;
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  status: UsersMapAgreementStatusTypes;
  store: Store;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type UsersMapAgreementCreateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type UsersMapAgreementCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mapAgreementId: Scalars['ID']['input'];
  status: UsersMapAgreementStatusTypes;
};

export type UsersMapAgreementCreatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<UsersMapAgreementCreateError>;
  usersMapAgreement?: Maybe<UsersMapAgreement>;
};

export const UsersMapAgreementStatusTypes = {
  Accepted: 'ACCEPTED',
  Blocked: 'BLOCKED',
  None: 'NONE',
  Rejected: 'REJECTED',
  Replaced: 'REPLACED'
} as const;

export type UsersMapAgreementStatusTypes = typeof UsersMapAgreementStatusTypes[keyof typeof UsersMapAgreementStatusTypes];
export type Variant = Actionable & Node & Timestamps & Viewable & {
  activePlansCount: Scalars['Int']['output'];
  autoshipPrice?: Maybe<Scalars['Float']['output']>;
  availabilityStatus: Scalars['String']['output'];
  brand: Brand;
  brandName: Scalars['String']['output'];
  countOnHand: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  dailyPack?: Maybe<DailyPack>;
  defaultDosageInstruction?: Maybe<DefaultDosageInstruction>;
  defaultProductDosageAmount?: Maybe<Scalars['String']['output']>;
  defaultProductDosageFrequency?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  descriptor: Scalars['String']['output'];
  dosageFormat?: Maybe<Scalars['String']['output']>;
  emersonRetailPrice?: Maybe<Scalars['Float']['output']>;
  emersonVariantId?: Maybe<Scalars['String']['output']>;
  expectedReturnDate?: Maybe<Scalars['Date']['output']>;
  hasRecommendationAlternatives: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  imageNew: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated Images is deprecated, please use 'images_new' instead. The return type will soon change from [Viewable] to [String] */
  images: Array<Viewable>;
  imagesNew: Array<Scalars['String']['output']>;
  ingredients: Array<Ingredient>;
  isAllowedInPublicMultiPatientPlan: Scalars['Boolean']['output'];
  isAllowedInSelectiveMultiPatientPlan: Scalars['Boolean']['output'];
  isAllowedToRepurchase: Scalars['Boolean']['output'];
  isAllowedToShowcase: Scalars['Boolean']['output'];
  isAvailableForPurchase: Scalars['Boolean']['output'];
  isBackorderNotifiable: Scalars['Boolean']['output'];
  isBackorderNotificationSubscribed: Scalars['Boolean']['output'];
  isMaster: Scalars['Boolean']['output'];
  isNew?: Maybe<Scalars['Boolean']['output']>;
  labTest?: Maybe<LabsTest>;
  msrp?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  pillPack: Scalars['Boolean']['output'];
  populations: Array<Population>;
  product: Product;
  productDailyIntake: Array<PlanIngredient>;
  productMonthlyCost: Scalars['Float']['output'];
  productName: Scalars['String']['output'];
  recommendationAlternatives?: Maybe<VariantWithAlternativesType>;
  recommendationAlternativesWithoutIntakes?: Maybe<VariantWithAlternativesType>;
  retail: Scalars['Boolean']['output'];
  sellPrice: Scalars['Float']['output'];
  sellPriceCurrency: Scalars['Currency']['output'];
  servingsPerContainer?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  /** @deprecated three_sixty_images is deprecated, please use 'image' or 'images' instead */
  threeSixtyImages: Array<Scalars['String']['output']>;
  toBeDiscontinuedDate?: Maybe<Scalars['Date']['output']>;
  type: Scalars['String']['output'];
  upc?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  urlLarge: Scalars['String']['output'];
  urlMini: Scalars['String']['output'];
  urlOriginal: Scalars['String']['output'];
  urlProduct: Scalars['String']['output'];
  urlSmall: Scalars['String']['output'];
  urlXlarge: Scalars['String']['output'];
  validForSale: Scalars['Boolean']['output'];
  validForSaleWithPrescription: Scalars['Boolean']['output'];
  variantComparisons?: Maybe<VariantWithAlternativesType>;
  variantNotifications: VariantNotificationConnection;
  variantType: Scalars['String']['output'];
  wholesale: Scalars['Boolean']['output'];
  wholesalePrice?: Maybe<Scalars['Float']['output']>;
  wholesalePriceCurrency?: Maybe<Scalars['Currency']['output']>;
  wholesalePromotionDetails: WholesalePromotionDetails;
};


export type VariantActivePlansCountArgs = {
  planType: PlanTypes;
};


export type VariantImageArgs = {
  filters?: InputMaybe<ImageFilterObject>;
};


export type VariantImageNewArgs = {
  filters?: InputMaybe<ImageFilterObject>;
};


export type VariantImagesNewArgs = {
  filters?: InputMaybe<ImageFilterObject>;
};


export type VariantProductDailyIntakeArgs = {
  amount?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
};


export type VariantProductMonthlyCostArgs = {
  amount: Scalars['String']['input'];
  frequency: Scalars['String']['input'];
};


export type VariantRecommendationAlternativesArgs = {
  amount: Scalars['String']['input'];
  frequency: Scalars['String']['input'];
};


export type VariantRecommendationAlternativesWithoutIntakesArgs = {
  amount?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
};


export type VariantThreeSixtyImagesArgs = {
  filters?: InputMaybe<ThreeSixtyImageFilterObject>;
};


export type VariantVariantComparisonsArgs = {
  alternatives: Array<AlternativeAttributes>;
  baseVariantAmount?: InputMaybe<Scalars['String']['input']>;
  baseVariantFrequency?: InputMaybe<Scalars['String']['input']>;
};


export type VariantVariantNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterObject?: InputMaybe<VariantNotificationFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type VariantConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<VariantEdge>>>;
  nodes?: Maybe<Array<Maybe<Variant>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VariantEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Variant>;
};

export type VariantFilterObject = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type VariantNotification = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  notificationSentAt?: Maybe<Scalars['DateTime']['output']>;
  patient?: Maybe<Patient>;
  updatedAt: Scalars['DateTime']['output'];
  variant: Variant;
};

export type VariantNotificationConnection = {
  edges?: Maybe<Array<Maybe<VariantNotificationEdge>>>;
  nodes?: Maybe<Array<Maybe<VariantNotification>>>;
  pageInfo: PageInfo;
};

export type VariantNotificationEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<VariantNotification>;
};

export type VariantNotificationFilterObject = {
  notified?: InputMaybe<Scalars['Boolean']['input']>;
  watching?: InputMaybe<Scalars['Boolean']['input']>;
};

export const VariantNotificationFilters = {
  ScopeNotified: 'SCOPE_NOTIFIED',
  ScopeNotNotified: 'SCOPE_NOT_NOTIFIED',
  ScopeNotWatching: 'SCOPE_NOT_WATCHING',
  ScopeWatching: 'SCOPE_WATCHING'
} as const;

export type VariantNotificationFilters = typeof VariantNotificationFilters[keyof typeof VariantNotificationFilters];
export type VariantWithAlternativesType = {
  alternativeProducts?: Maybe<Array<VariantWithDosage>>;
  baseProduct: VariantWithDosage;
};

export type VariantWithDosage = {
  dailyDosage: Scalars['Float']['output'];
  dailyIntake?: Maybe<Array<PlanIngredient>>;
  feedback?: Maybe<RecommendationAlternativeFeedback>;
  matchType?: Maybe<Scalars['String']['output']>;
  monthlyCost: Scalars['Float']['output'];
  multiIngredientDailyIntake?: Maybe<MultiIngredientDailyIntake>;
  refillRate?: Maybe<Scalars['Float']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  variant: Variant;
};

export type Viewable = {
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  urlLarge: Scalars['String']['output'];
  urlMini: Scalars['String']['output'];
  urlOriginal: Scalars['String']['output'];
  urlProduct: Scalars['String']['output'];
  urlSmall: Scalars['String']['output'];
  urlXlarge: Scalars['String']['output'];
};

export type Viewer = {
  accountTypes: Array<AccountType>;
  activeProductSpotlight?: Maybe<ProductSpotlight>;
  alertsBanners: Array<AlertsBanner>;
  allergens: Array<Allergen>;
  analyticsAnonymousId?: Maybe<Scalars['String']['output']>;
  authenticationProvider: AuthenticationProviders;
  brand: Brand;
  brands: BrandConnection;
  brandsList: Array<BrandList>;
  /** @deprecated Use taxonomies instead */
  categories: CategoryConnection;
  conditions: CategoryGroupConnection;
  countryIso: Scalars['String']['output'];
  csuid: Scalars['String']['output'];
  dailyPackPillCategories: Array<PillCategory>;
  enabledFlippers: Array<Scalars['String']['output']>;
  exploreAreas: Explore;
  flippers: Array<Flipper>;
  forms?: Maybe<Array<DosageFormat>>;
  fullscriptProtocol?: Maybe<FullscriptProtocol>;
  fullscriptProtocolCategories: Array<FullscriptProtocolCategory>;
  fullscriptProtocols: FullscriptProtocolConnection;
  healthConditions: Array<HealthCondition>;
  imatContentCategories: Array<ImatContentCategory>;
  imatContentTypes: Array<ImatContentType>;
  imatContents: ImatContentConnection;
  ingredientUnits: Ingredient;
  ingredients: IngredientConnection;
  isAdminImpersonating: Scalars['Boolean']['output'];
  isUserSignedIn: Scalars['Boolean']['output'];
  /** @deprecated Use current_user instead */
  iterableJwt?: Maybe<Scalars['String']['output']>;
  labBiomarkers: Array<LabsBiomarker>;
  labCompanies: Array<LabsCompany>;
  labFulfillmentFailedAuthorizations: Array<LabFulfillmentsAuthorization>;
  labFulfillmentFailedInvocations: Array<LabFulfillmentsInvocation>;
  labHealthCategories: Array<LabsHealthCategory>;
  labJourneyVariants: Array<LabsJourneysVariant>;
  labPatientRestrictedStates: Array<State>;
  labSampleTypes: Array<LabsSampleType>;
  labTest?: Maybe<LabsTest>;
  labTestTypes: Array<LabsTestType>;
  labTests: LabsTestConnection;
  labTestsComparisons: Array<LabsTest>;
  labTestsComparisonsBiomarkers?: Maybe<LabsBiomarkerConnection>;
  labTypeaheadSearch: Array<LabTypeahead>;
  labTypeaheadSearchV2: Array<LabTypeaheadGroupType>;
  mapAgreements: Array<MapAgreement>;
  medicationProductIngredients: Array<MedicationProductIngredients>;
  /** @deprecated Use medication_product_ingredients instead */
  medicationProducts: Array<MedicationProduct>;
  medicationsTypeahead: Array<MedicationsTypeahead>;
  menuCategories: Array<MenuCategory>;
  multiSourceProductSearch?: Maybe<MultiSourceProductConnection>;
  node?: Maybe<Node>;
  nodes: Array<Maybe<Node>>;
  notificationSettingTypes: Array<NotificationSettingType>;
  oauthApplication?: Maybe<OauthApplication>;
  optimizedMenuCategories: Array<OptimizedMenuCategory>;
  patientPromotionCutOffDate?: Maybe<Scalars['DateTime']['output']>;
  patientPromotions: PatientPromotionConnection;
  /** @deprecated Use alerts_banners instead. */
  patientsAlertsBanners: Array<AlertsBanner>;
  peerReferralProgramConfiguration: PeerReferralProgramConfiguration;
  populations: Array<Population>;
  practitionerSignUpAdditionalAttributes: AuthPractitionerSignUpAdditionalAttributes;
  product: Product;
  productSearchV2: ProductSearchConnection;
  products: ProductConnection;
  profitMarginIncrements: Array<Scalars['Int']['output']>;
  promotionCampaigns: Array<PromotionCampaign>;
  pubMedCondition: PubMedCondition;
  pubMedConditionsTypeahead: Array<PubMedConditionTypeahead>;
  recentlyViewedProducts: ProductConnection;
  reference: Scalars['ID']['output'];
  signInAdditionalAttributes: AuthUserSignInAdditionalAttributes;
  states: Array<State>;
  stripeMobileApplePayPublicKey: Scalars['String']['output'];
  supplementTypes: SupplementTypeConnection;
  tags: TagConnection;
  taxonomies: Array<Taxonomy>;
  thirdPartyCertifications: Array<ThirdPartyCertification>;
  typeaheadSearch: TypeaheadSearchConnection;
  typeaheadSearchV2: Array<TypeaheadGroupType>;
  user: User;
};


export type ViewerAccountTypesArgs = {
  filterObject?: InputMaybe<AccountTypeFilterObject>;
};


export type ViewerBrandArgs = {
  npSlug?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type ViewerBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<BrandFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerConditionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CategoryGroupFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerFormsArgs = {
  sort?: InputMaybe<Array<DosageFormatSort>>;
};


export type ViewerFullscriptProtocolArgs = {
  slug: Scalars['String']['input'];
};


export type ViewerFullscriptProtocolsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<FullscriptProtocolFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FullscriptProtocolSort>>;
};


export type ViewerImatContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ImatContentFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FullscriptContentSort>>;
};


export type ViewerIngredientUnitsArgs = {
  ingredientId?: Scalars['ID']['input'];
};


export type ViewerIngredientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<IngredientFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<IngredientSort>>;
};


export type ViewerLabTestArgs = {
  id: Scalars['ID']['input'];
};


export type ViewerLabTestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LabTestSearchFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<LabTestSearchSort>;
};


export type ViewerLabTestsComparisonsArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  testIds: Array<Scalars['ID']['input']>;
};


export type ViewerLabTestsComparisonsBiomarkersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  testIds: Array<Scalars['ID']['input']>;
};


export type ViewerLabTypeaheadSearchArgs = {
  query?: Scalars['String']['input'];
};


export type ViewerLabTypeaheadSearchV2Args = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: Scalars['String']['input'];
};


export type ViewerMedicationProductIngredientsArgs = {
  medicationGroupIds: Array<Scalars['ID']['input']>;
};


export type ViewerMedicationProductsArgs = {
  medicationGroupId: Scalars['ID']['input'];
};


export type ViewerMedicationsTypeaheadArgs = {
  searchQuery: Scalars['String']['input'];
};


export type ViewerMenuCategoriesArgs = {
  kind: MenuCategoryType;
};


export type ViewerMultiSourceProductSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isUpc?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type ViewerNodeArgs = {
  id: Scalars['ID']['input'];
};


export type ViewerNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type ViewerOauthApplicationArgs = {
  clientId: Array<Scalars['ID']['input']>;
};


export type ViewerOptimizedMenuCategoriesArgs = {
  categoryName?: InputMaybe<Scalars['String']['input']>;
  kind: OptimizedMenuCategoryKind;
};


export type ViewerPatientPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerPractitionerSignUpAdditionalAttributesArgs = {
  attributes?: InputMaybe<AuthPractitionerSignUpAdditionalAttributesInput>;
};


export type ViewerProductArgs = {
  npProductId?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type ViewerProductSearchV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ProductSearchV2FilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  initialFilterSelectionId?: InputMaybe<Scalars['ID']['input']>;
  initialFilterSelectionQuery?: InputMaybe<Scalars['String']['input']>;
  isUpc?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  origin?: InputMaybe<QueryOriginTypes>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProductSearchSort>>;
  source?: InputMaybe<ProductSearchSourceType>;
  typeaheadSelectionId?: InputMaybe<Scalars['ID']['input']>;
};


export type ViewerProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ProductFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerPubMedConditionArgs = {
  pubMedConditionId: Scalars['ID']['input'];
};


export type ViewerPubMedConditionsTypeaheadArgs = {
  query?: Scalars['String']['input'];
};


export type ViewerRecentlyViewedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerReferenceArgs = {
  id: Scalars['String']['input'];
  resource: Scalars['String']['input'];
};


export type ViewerSupplementTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TagFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerTaxonomiesArgs = {
  levels?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  topLevelName?: InputMaybe<Scalars['String']['input']>;
};


export type ViewerTypeaheadSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<SearchFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProductSearchSort>>;
};


export type ViewerTypeaheadSearchV2Args = {
  filters?: InputMaybe<SearchFilterObject>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProductSearchSort>>;
  useSkuIdentifier?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WellevateAddress = {
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  lastname: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  zipcode: Scalars['String']['output'];
};

export type WellevateInventoryUnit = {
  lineItem: WellevateLineItem;
};

export type WellevateLineItem = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  msrpPrice?: Maybe<Scalars['Float']['output']>;
  price: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variant?: Maybe<Variant>;
  wellevateProduct?: Maybe<WellevateProduct>;
};

export type WellevateMigrationErrorProperties = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fsProtocolId?: Maybe<Scalars['String']['output']>;
  fsProtocolName?: Maybe<Scalars['String']['output']>;
  fsProtocolUrl?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type WellevateMigrationErrorType = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<WellevateMigrationErrorProperties>;
  updatedAt: Scalars['DateTime']['output'];
  wellevateRecordIdentifier: Scalars['String']['output'];
  wellevateRecordType: Scalars['String']['output'];
};

export type WellevateOrder = Node & Timestamps & {
  billingAddress: WellevateAddress;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  consolidatedStatus: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isAutoship: Scalars['Boolean']['output'];
  isTrackable: Scalars['Boolean']['output'];
  lineItems: Array<WellevateLineItem>;
  msrpTotal?: Maybe<Scalars['Float']['output']>;
  number: Scalars['String']['output'];
  patient: Patient;
  paymentState?: Maybe<Scalars['String']['output']>;
  productShipmentTotal?: Maybe<Scalars['Float']['output']>;
  savingsTotal?: Maybe<Scalars['Float']['output']>;
  shipmentState?: Maybe<Scalars['String']['output']>;
  shipmentTotal: Scalars['Float']['output'];
  shipments?: Maybe<Array<WellevateShipment>>;
  shippingAddress?: Maybe<WellevateAddress>;
  sourcePlatform: Scalars['String']['output'];
  state: Scalars['String']['output'];
  subTotal: Scalars['Float']['output'];
  taxTotal?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WellevateOrderConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<WellevateOrderEdge>>>;
  nodes?: Maybe<Array<Maybe<WellevateOrder>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WellevateOrderEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<WellevateOrder>;
};

export type WellevatePatientMigrationStatus = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  hasAutoshipOrder: Scalars['Boolean']['output'];
  hasMobileOrder: Scalars['Boolean']['output'];
  migrationBannersVisibility?: Maybe<PatientMigrationBannersVisibility>;
  practitioner: Practitioner;
  practitionerFullName: Scalars['String']['output'];
  status: WellevatePatientMigrationStatuses;
  updatedAt: Scalars['DateTime']['output'];
};

export const WellevatePatientMigrationStatuses = {
  Completed: 'COMPLETED',
  OptedOut: 'OPTED_OUT',
  Pending: 'PENDING'
} as const;

export type WellevatePatientMigrationStatuses = typeof WellevatePatientMigrationStatuses[keyof typeof WellevatePatientMigrationStatuses];
export type WellevatePractitioner = {
  dispensaryName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  marginType: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  practitionerId: Scalars['ID']['output'];
  profitMargin?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type WellevateProduct = {
  brand: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type WellevateRecommendation = Node & Timestamps & {
  additionalInfo?: Maybe<Scalars['String']['output']>;
  availableAt: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  treatmentPlan: WellevateTreatmentPlan;
  updatedAt: Scalars['DateTime']['output'];
  variant?: Maybe<Variant>;
  wellevateProduct?: Maybe<WellevateProduct>;
};

export type WellevateShipment = {
  inventoryUnits: Array<WellevateInventoryUnit>;
};

export type WellevateTreatmentPlan = LegacyId & Node & Timestamps & {
  _legacyId: Scalars['Int']['output'];
  availableAt: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  labRecommendations: Array<LabsRecommendation>;
  message?: Maybe<Scalars['String']['output']>;
  /** @deprecated A Wellevate Treatment Plan will only have one message in the ERP database. Use message instead. */
  messages?: Maybe<Array<WellevateTreatmentPlanMessage>>;
  name: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  practitioner: Practitioner;
  recommendations: Array<WellevateRecommendation>;
  sourcePlatform: Scalars['String']['output'];
  totalDiscountedPrice: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type WellevateTreatmentPlanMessagesArgs = {
  sort?: InputMaybe<Array<TreatmentPlanMessageSort>>;
};


export type WellevateTreatmentPlanRecommendationsArgs = {
  filterObject?: InputMaybe<RecommendationFilterObject>;
};

export type WellevateTreatmentPlanConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<WellevateTreatmentPlanEdge>>>;
  nodes?: Maybe<Array<Maybe<WellevateTreatmentPlan>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WellevateTreatmentPlanEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<WellevateTreatmentPlan>;
};

export type WellevateTreatmentPlanMessage = Node & Timestamps & {
  contents?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentState?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type WholePersonPlanAcceptAgreementError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholePersonPlanAcceptAgreementInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type WholePersonPlanAcceptAgreementPayload = {
  agreement?: Maybe<PractitionerAgreement>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: WholePersonPlanAcceptAgreementError;
  practitioner?: Maybe<Practitioner>;
};

export type WholePersonPlanPubMedCondition = {
  healthArea: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  recommendedIngredients: Array<RecommendedIngredient>;
};

export type WholesaleAddressUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleAddressUpdateInput = {
  billingAttributes?: InputMaybe<AddressAttributes>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingAttributes?: InputMaybe<AddressAttributes>;
};

export type WholesaleAddressUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleAddressUpdateError>;
};

export type WholesaleAdjustment = Timestamps & {
  amount: Scalars['Float']['output'];
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isExpired: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  promotionActionType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type WholesaleCartAddToCartError = {
  fields?: Maybe<WholesaleCartAddToCartFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleCartAddToCartFieldError = {
  mapAgreements?: Maybe<Array<Scalars['String']['output']>>;
};

export type WholesaleCartAddToCartInput = {
  cart: Array<CartItemAttributes>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  storePlatform?: InputMaybe<StorePlatformType>;
};

export type WholesaleCartAddToCartPayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartAddToCartError>;
  storePlatform?: Maybe<WholesaleOrder>;
};

export type WholesaleCartAdminCreateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleCartAdminCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type WholesaleCartAdminCreatePayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartAdminCreateError>;
};

export type WholesaleCartAdminDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleCartAdminDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
};

export type WholesaleCartAdminDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartAdminDeleteError>;
  order?: Maybe<WholesaleOrder>;
};

export type WholesaleCartAdminSelectError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleCartAdminSelectInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
};

export type WholesaleCartAdminSelectPayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartAdminSelectError>;
};

export type WholesaleCartConfirmError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleCartConfirmInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  placeOrder: Scalars['Boolean']['input'];
};

export type WholesaleCartConfirmPayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartConfirmError>;
};

export type WholesaleCartPoNumberUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleCartPoNumberUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  poNumber: Scalars['String']['input'];
};

export type WholesaleCartPoNumberUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartPoNumberUpdateError>;
};

export type WholesaleCartProp65SwapError = {
  type?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Variant>;
};

export type WholesaleCartProp65SwapInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  storePlatform?: InputMaybe<StorePlatformType>;
};

export type WholesaleCartProp65SwapPayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<WholesaleCartProp65SwapError>>;
};

export type WholesaleCartResetError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleCartResetInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type WholesaleCartResetPayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartResetError>;
};

export type WholesaleCartUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleCartUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lineItemId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type WholesaleCartUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartUpdateError>;
};

export type WholesaleInventoryUnit = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lineItem: WholesaleLineItem;
  quantity: Scalars['Int']['output'];
  requestedCancellation: Scalars['Boolean']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variant: Variant;
};

export type WholesaleInventoryUnitFilterObject = {
  notShipped?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WholesaleLineItem = Node & Timestamps & {
  adjustmentTotal: Scalars['Float']['output'];
  amount: Scalars['Float']['output'];
  costPrice?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orderedAt?: Maybe<Scalars['DateTime']['output']>;
  price: Scalars['Float']['output'];
  promoTotal: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
  taxTotal: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variant: Variant;
};

export type WholesaleLineItemConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<WholesaleLineItemEdge>>>;
  nodes?: Maybe<Array<Maybe<WholesaleLineItem>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WholesaleLineItemEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<WholesaleLineItem>;
};

export type WholesaleLineItemFilterObject = {
  orderedAt?: InputMaybe<DateRangeFilter>;
  /** @deprecated Use current_store_platform instead */
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};

export type WholesaleLineItemSort = {
  direction: SortDirection;
  field: WholesaleLineItemSortField;
};

export const WholesaleLineItemSortField = {
  CompletedAt: 'COMPLETED_AT',
  Name: 'NAME',
  Price: 'PRICE'
} as const;

export type WholesaleLineItemSortField = typeof WholesaleLineItemSortField[keyof typeof WholesaleLineItemSortField];
export type WholesaleOrder = LegacyId & Node & Timestamps & {
  _legacyId: Scalars['Int']['output'];
  adjustmentTotal: Scalars['Float']['output'];
  billingAddress?: Maybe<Address>;
  commentary?: Maybe<Commentary>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  estimatedTotal: Scalars['Float']['output'];
  firstOrderPromotionPercentage: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isCreatedByPractitioner: Scalars['Boolean']['output'];
  isFirstOrderPromotionApplied: Scalars['Boolean']['output'];
  isFromCyberMonday: Scalars['Boolean']['output'];
  itemCount: Scalars['Int']['output'];
  itemTotal: Scalars['Float']['output'];
  lineItems: Array<WholesaleLineItem>;
  number: Scalars['String']['output'];
  orderReceiptUrl: Scalars['String']['output'];
  payment?: Maybe<WholesalePayment>;
  paymentState?: Maybe<Scalars['String']['output']>;
  paymentTotal: Scalars['Float']['output'];
  poNumber?: Maybe<Scalars['String']['output']>;
  promoTotal: Scalars['Float']['output'];
  promotions: Array<WholesaleAdjustment>;
  savingsTotal: Scalars['Float']['output'];
  shipmentState?: Maybe<Scalars['String']['output']>;
  shipmentTotal: Scalars['Float']['output'];
  shipments: Array<WholesaleShipment>;
  shippingAddress?: Maybe<Address>;
  shippingTotal?: Maybe<Scalars['Float']['output']>;
  state: Scalars['String']['output'];
  storePlatform?: Maybe<StorePlatform>;
  taxTotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};


export type WholesaleOrderShipmentsArgs = {
  filters?: InputMaybe<WholesaleShipmentFilterObject>;
};

export type WholesaleOrderConnection = RecordCounts & {
  edges?: Maybe<Array<Maybe<WholesaleOrderEdge>>>;
  nodes?: Maybe<Array<Maybe<WholesaleOrder>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WholesaleOrderEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<WholesaleOrder>;
};

export type WholesaleOrderFilterObject = {
  completedAt?: InputMaybe<DateRangeFilter>;
  completedWithinDays?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  storePlatforms?: InputMaybe<Array<StorePlatformType>>;
  unfulfilled?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type WholesaleOrderSort = {
  direction: SortDirection;
  field: WholesaleOrderSortField;
};

export const WholesaleOrderSortField = {
  CompletedAt: 'COMPLETED_AT',
  Total: 'TOTAL'
} as const;

export type WholesaleOrderSortField = typeof WholesaleOrderSortField[keyof typeof WholesaleOrderSortField];
export type WholesalePayment = Node & Timestamps & {
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  source: CreditCard;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WholesalePaymentUpdateError = {
  fields?: Maybe<WholesalePaymentUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesalePaymentUpdateFieldError = {
  defaultCard?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  oneTimeUse?: Maybe<Array<Scalars['String']['output']>>;
  token?: Maybe<Array<Scalars['String']['output']>>;
};

export type WholesalePaymentUpdateInput = {
  attributes?: InputMaybe<CreditCardAttributes>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCardId?: InputMaybe<Scalars['ID']['input']>;
};

export type WholesalePaymentUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesalePaymentUpdateError>;
};

export type WholesalePercentOnLineItem = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  percentage: Scalars['Int']['output'];
  promotionTypes?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type WholesalePromotionApplyError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesalePromotionApplyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

export type WholesalePromotionApplyPayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesalePromotionApplyError>;
};

export type WholesalePromotionDetails = Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  descriptions: Array<Scalars['String']['output']>;
  metadata: Array<PromotionTypeUnion>;
  percentageOff?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type WholesalePromotionRemoveError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesalePromotionRemoveInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

export type WholesalePromotionRemovePayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesalePromotionRemoveError>;
};

export type WholesaleRequestBackorderCancellationError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleRequestBackorderCancellationInput = {
  classicBackorderedItem?: InputMaybe<EmersonClassicBackorderedItemInput>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  wholesaleInventoryUnitId?: InputMaybe<Scalars['ID']['input']>;
};

export type WholesaleRequestBackorderCancellationPayload = {
  classicBackorderedItem?: Maybe<EmersonClassicOrderLineItem>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: WholesaleRequestBackorderCancellationError;
  wholesaleInventoryUnit?: Maybe<WholesaleInventoryUnit>;
};

export type WholesaleShipment = Node & Timestamps & {
  adjustmentTotal: Scalars['Float']['output'];
  cost: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  finalPriceWithItems: Scalars['Float']['output'];
  grandTotal: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  inventoryUnits: Array<WholesaleInventoryUnit>;
  lineItems: Array<WholesaleLineItem>;
  master: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  promoTotal: Scalars['Float']['output'];
  shipmentReceiptUrl: Scalars['String']['output'];
  shippedAt?: Maybe<Scalars['DateTime']['output']>;
  shippingRates?: Maybe<Array<ShippingRate>>;
  state: Scalars['String']['output'];
  taxTotal: Scalars['Float']['output'];
  trackingInstance?: Maybe<TrackingType>;
  trackingService: Scalars['String']['output'];
  trackings?: Maybe<Array<WholesaleTracking>>;
  updatedAt: Scalars['DateTime']['output'];
};


export type WholesaleShipmentInventoryUnitsArgs = {
  filters?: InputMaybe<WholesaleInventoryUnitFilterObject>;
};

export type WholesaleShipmentFilterObject = {
  shipped?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WholesaleShippingAddressSelectError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleShippingAddressSelectInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  expectedAvrId: Scalars['ID']['input'];
  ignore?: InputMaybe<Scalars['Boolean']['input']>;
  selectedOption: Scalars['String']['input'];
};

export type WholesaleShippingAddressSelectPayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleShippingAddressSelectError>;
};

export type WholesaleShippingRateUpdateError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WholesaleShippingRateUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingRateId: Scalars['ID']['input'];
};

export type WholesaleShippingRateUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleShippingRateUpdateError>;
  warnings?: Maybe<Scalars['String']['output']>;
};

export type WholesaleTieredPercentItemQuantity = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  promotionTypes?: Maybe<Scalars['String']['output']>;
  tiers?: Maybe<Array<TieredPercentItem>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type WholesaleTieredPercentItemTotal = Node & Timestamps & {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  promotionTypes?: Maybe<Scalars['String']['output']>;
  tiers?: Maybe<Array<TieredPercentItem>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type WholesaleTracking = {
  id: Scalars['ID']['output'];
  trackingNumber: Scalars['String']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export type ZendeskPatientDeleteError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type ZendeskPatientDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ZendeskPatientDeletePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<ZendeskPatientDeleteError>;
  patient?: Maybe<Patient>;
};

export type LabsPractitionersReviewResultError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type LabsPractitionersReviewResultInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labResultId: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type LabsPractitionersReviewResultPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: LabsPractitionersReviewResultError;
  labRecommendationEvent?: Maybe<LabsRecommendationEvent>;
};

export type LabsPractitionersSettingsUpdateError = {
  fields?: Maybe<LabsPractitionersSettingsUpdateFieldError>;
  message?: Maybe<Scalars['String']['output']>;
};

export type LabsPractitionersSettingsUpdateFieldError = {
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  licenseExpiryDate?: Maybe<Array<Scalars['String']['output']>>;
  licenseNumber?: Maybe<Array<Scalars['String']['output']>>;
  licenseState?: Maybe<Array<Scalars['String']['output']>>;
  npi?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
};

export type LabsPractitionersSettingsUpdateInput = {
  attributes: LabPractitionerSettingsUpdateAttributes;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type LabsPractitionersSettingsUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: LabsPractitionersSettingsUpdateError;
  practitioner?: Maybe<Practitioner>;
};

export type OauthApplication = Timestamps & {
  clientId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  externalType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  patchVersion: Scalars['String']['output'];
  redirectUri: Scalars['String']['output'];
  scopeDescriptions: Array<OauthScopeDescription>;
  scopes: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PartnerStore = Node & Timestamps & {
  activatedAt?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  partnerKey: Scalars['String']['output'];
  revokedAt?: Maybe<Scalars['Date']['output']>;
  store: Store;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
};

export type PartnerStoreEdge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<PartnerStore>;
};

export type PractitionerSignUpInput = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  practitionerTypeId: Scalars['String']['input'];
  signupSource: Scalars['String']['input'];
};

export type PractitionerSignUpPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type UserPasswordUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAttributes: UserAttributes;
};

export type UserPasswordUpdatePayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type UserSignInInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  isOauth?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  prioritizedRole?: InputMaybe<RoleTypes>;
};

export type UserSignInPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type WellevateNewAccountEmailError = {
  message?: Maybe<Scalars['String']['output']>;
};

export type WellevateNewAccountEmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

export type WellevateNewAccountEmailPayload = {
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: WellevateNewAccountEmailError;
};
